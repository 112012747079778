import React from "react";
import { Link } from "react-router-dom";
import SimilarGroups from "../common/SimilarGroups";

const Forum = () => {
  return (
    <div className="tab-pane fade show active" id="forum">
      <div className="training_outer">
        <div className="row">
          <div className="col-lg-8">
            <div className="course_detailspnl">
              <div className="contnt_hdng tab_Hdng">
                <h4>forum</h4>
              </div>
              <div className="form_commnts_area">
                <textarea
                  className="form-control"
                  placeholder="Write your query here"
                ></textarea>
                <div className="furmcmnt_btn">
                  <button className="cmnt_btn">
                    <span className="material-icons-outlined">image</span>
                  </button>
                  <button className="cmnt_btn">
                    <span className="material-icons-outlined">send</span>
                  </button>
                </div>
              </div>
              <div className="rply_bxArea">
                <div className="reply_forumBx forum_grayBx">
                  <div className="forum_cmntsRow">
                    <div className="share_btn forum_shreBtn">
                      <Link to="#">
                        <span className="material-icons-outlined">share</span>
                      </Link>
                    </div>
                    <div className="team_userOuter forum_usrOuter">
                      <div className="team_usr_pic">
                        <img src="images/expert1.png" alt="" />
                      </div>
                      <div className="team_usrTxt">
                        <h3>Roma Keller</h3>
                        <h5>20/07/2021 17:31</h5>
                        <div className="forum_para">
                          <p>
                            My bad, I don't actually have those Processor's to
                            test, but, the i5 9600k is about ( a little worse)
                            on par with the Ryzen 5 2600, so I am just going to
                            go with it. I read on some website that the 1600 and
                            2600 was better, but, they are both worse than the
                            9600k. And, anyways I already have a B365 LGA 1151
                            Gen 2 motherboard, so, it's the most logical
                            upgrade.
                          </p>
                        </div>
                        <div className="forumpic_outer">
                          <div className="forum_pic">
                            <img src="images/forum1.jpg" alt="" />
                          </div>
                          <div className="forum_pic">
                            <img src="images/forum2.jpg" alt="" />
                          </div>
                        </div>
                        <div className="form_share">
                          <Link to="#">
                            <i className="material-icons-outlined">thumb_up</i>
                            <em>Likes</em>
                            <span>2</span>
                          </Link>
                          <Link to="#">
                            <i className="material-icons-outlined">chat</i>
                            <em>Comment</em>
                            <span>3</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="forum_cmntsRow">
                    <div className="share_btn forum_shreBtn">
                      <Link to="#">
                        <span className="material-icons-outlined">share</span>
                      </Link>
                    </div>
                    <div className="team_userOuter forum_usrOuter">
                      <div className="team_usr_pic">
                        <img src="images/expert2.png" alt="" />
                      </div>
                      <div className="team_usrTxt">
                        <h3>Roma Keller</h3>
                        <h5>20/07/2021 17:31</h5>
                        <div className="forum_para">
                          <p>
                            There are 2 types of Ryzen 1600(x), There's 1600AE
                            which is from first gen Ryzen and 1600AF which is
                            2nd gen, same gen as 2600(x).
                          </p>
                        </div>

                        <div className="form_share">
                          <Link to="#">
                            <i className="material-icons-outlined">thumb_up</i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="forum_cmntsRow">
                    <div className="share_btn forum_shreBtn">
                      <Link to="#">
                        <span className="material-icons-outlined">share</span>
                      </Link>
                    </div>
                    <div className="team_userOuter forum_usrOuter">
                      <div className="team_usr_pic">
                        <img src="images/expert3.png" alt="" />
                      </div>
                      <div className="team_usrTxt">
                        <h3>Yannicka Alleno</h3>
                        <h5>20/07/2021 17:31</h5>
                        <div className="forum_para">
                          <p>
                            ryzen first gen has peformance issues with windows
                            11 and microsoft doesnt plan to fix it...
                          </p>
                        </div>

                        <div className="form_share">
                          <Link to="#">
                            <i className="material-icons-outlined">thumb_up</i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="forum_cmntsRow">
                    <div className="share_btn forum_shreBtn">
                      <Link to="#">
                        <span className="material-icons-outlined">share</span>
                      </Link>
                    </div>
                    <div className="team_userOuter forum_usrOuter">
                      <div className="team_usr_pic">
                        <img src="images/expert4.png" alt="" />
                      </div>
                      <div className="team_usrTxt">
                        <h3>Martina Berasategui</h3>
                        <h5>20/07/2021 17:31</h5>
                        <div className="forum_para">
                          <p>
                            I was going to go with a core i5 9600k, but the
                            Ryzen 5 1600 is cheaper, and from what I have read,
                            is more powerful.
                          </p>
                        </div>

                        <div className="form_share">
                          <Link to="#">
                            <i className="material-icons-outlined">thumb_up</i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reply_forumBx forum_whiteBx">
                  <div className="forum_cmntsRow">
                    <div className="share_btn forum_shreBtn">
                      <Link to="#">
                        <span className="material-icons-outlined">share</span>
                      </Link>
                    </div>
                    <div className="team_userOuter forum_usrOuter">
                      <div className="team_usr_pic">
                        <img src="images/expert5.png" alt="" />
                      </div>
                      <div className="team_usrTxt">
                        <h3>Gordon Ramsay</h3>
                        <h5>20/07/2021 17:31</h5>
                        <div className="forum_para">
                          <p>
                            I read on some website that the 1600 and 2600 was
                            better, but, they are both worse than the 9600k.
                            And, anyways I already have a B365 LGA 1151 Gen 2
                            motherboard, so, it's the most logical upgrade.
                          </p>
                        </div>
                        <div className="form_share">
                          <Link to="#">
                            <i className="material-icons-outlined">thumb_up</i>
                            <em>Likes</em>
                            <span>2</span>
                          </Link>
                          <Link to="#">
                            <i className="material-icons-outlined">chat</i>
                            <em>Comment</em>
                            <span>3</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="forum_cmntsRow">
                    <div className="share_btn forum_shreBtn">
                      <Link to="#">
                        <span className="material-icons-outlined">share</span>
                      </Link>
                    </div>
                    <div className="team_userOuter forum_usrOuter">
                      <div className="team_usr_pic">
                        <img src="images/expert6.png" alt="" />
                      </div>
                      <div className="team_usrTxt">
                        <h3>Alian Ducasse</h3>
                        <h5>20/07/2021 17:31</h5>
                        <div className="forum_para">
                          <p>
                            They are both worse than the 9600k. And, anyways I
                            already have a B365 LGA 1151 Gen 2 motherboard, so,
                            it's the most logical upgrade.
                          </p>
                        </div>
                        <div className="form_share">
                          <Link to="#">
                            <i className="material-icons-outlined">thumb_up</i>
                            <em>Likes</em>
                            <span>2</span>
                          </Link>
                          <Link to="#">
                            <i className="material-icons-outlined">chat</i>
                            <em>Comment</em>
                            <span>3</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="forum_cmntsRow">
                    <div className="share_btn forum_shreBtn">
                      <Link to="#">
                        <span className="material-icons-outlined">share</span>
                      </Link>
                    </div>
                    <div className="team_userOuter forum_usrOuter">
                      <div className="team_usr_pic">
                        <img src="images/expert7.png" alt="" />
                      </div>
                      <div className="team_usrTxt">
                        <h3>Rohit Walia</h3>
                        <h5>20/07/2021 17:31</h5>
                        <div className="forum_para">
                          <p>
                            I read on some website that the 1600 and 2600 was
                            better, but, they are both worse than the 9600k.
                            And, anyways I already have a B365 LGA 1151 Gen 2
                            motherboard, so, it's the most logical upgrade.
                          </p>
                        </div>
                        <div className="form_share">
                          <Link to="#">
                            <i className="material-icons-outlined">thumb_up</i>
                            <em>Likes</em>
                            <span>2</span>
                          </Link>
                          <Link to="#">
                            <i className="material-icons-outlined">chat</i>
                            <em>Comment</em>
                            <span>3</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <SimilarGroups />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forum;
