import React, { useState } from "react";
import { Link } from "react-router-dom";

// import images here
import groupImage1 from "assets/GroupAssets/images/indra_grps1.jpg";
import groupImage2 from "assets/GroupAssets/images/indra_grps2.jpg";
import grp1 from "assets/GroupAssets/images/grp1.jpg";
import grp2 from "assets/GroupAssets/images/grp2.jpg";
import grp3 from "assets/GroupAssets/images/grp3.jpg";
import grp4 from "assets/GroupAssets/images/grp4.jpg";
import grp5 from "assets/GroupAssets/images/grp5.jpg";

const GroupListArea = () => {
  // initial useState to save demo JSON data
  const [groupListData, setGroupListData] = useState([
    {
      id: 1,
      starrating: 4.8,
      totalrating: "3,940",
      groupname: "React Group",
      groupimage: groupImage1,
      tags: [
        {
          id: 1,
          tagname: "ReactJS",
        },
        {
          id: 2,
          tagname: "NodeJS",
        },
        {
          id: 3,
          tagname: "MongoDB",
        },
      ],
      images: [
        {
          id: 1,
          image: grp1,
        },
        {
          id: 2,
          image: grp2,
        },
        {
          id: 3,
          image: grp3,
        },
        {
          id: 4,
          image: grp4,
        },
        {
          id: 5,
          image: grp5,
        },
      ],
    },
    {
      id: 2,
      starrating: 4.8,
      totalrating: "3,940",
      groupname: "PHP Group",
      groupimage: groupImage2,
      tags: [
        {
          id: 1,
          tagname: "PHP",
        },
        {
          id: 2,
          tagname: "Laravel",
        },
        {
          id: 3,
          tagname: "MySQL",
        },
      ],
      images: [
        {
          id: 1,
          image: grp1,
        },
        {
          id: 2,
          image: grp2,
        },
        {
          id: 3,
          image: grp3,
        },
        {
          id: 4,
          image: grp4,
        },
        {
          id: 5,
          image: grp5,
        },
      ],
    },
  ]);

  return (
    <>
      <section className="champions_area">
        <div className="container">
          <div className="champions_outer">
            <div className="row">
              {groupListData.map((item, index) => (
                <div className="col-lg-4 col-sm-6" key={item.id}>
                  <div className="events_itemBx">
                    <div className="bookmarks_grps">
                      <Link to="#">
                        <i className="material-icons-outlined">
                          {" "}
                          bookmark_border{" "}
                        </i>
                      </Link>
                    </div>

                    <div className="groupsbx_outer">
                      <div className="groups_textbx_pnl pd_20">
                        <div className="chappions_rating">
                          <Link to="#">
                            <i className="material-icons-outlined">star</i>
                            <span>{item.starrating}</span>
                            <em>({item.totalrating})</em>
                          </Link>
                        </div>
                        <div className="leftchampion_text">
                          <h3>{item.groupname}</h3>
                        </div>
                        <div className="experts_skill">
                          <ul>
                            {item.tags.map((item, index) => (
                              <li key={item.id}>
                                <Link to="#">
                                  <span>{item.tagname}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div className="ambassadors_users grps_user">
                          <ul>
                            {item.images.map((item, index) => (
                              <li key={item.id}>
                                <Link to="#">
                                  <img src={item.image} alt="" />
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      <div className="groups_pic">
                        <img src={item.groupimage} alt="" />
                      </div>

                      <div className="details_btn pd_20">
                        <Link
                          to="/group/details/1"
                          className="basic_btn ylw_btn"
                        >
                          See Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GroupListArea;
