// import components
import Home from "pages/Home/Home";
import Login from "pages/Login/Login";
import SignUp from "pages/SignUp/SignUp";

/* ------------- Protected Routes Components ---------------- */
import Activity from "pages/Activity/Activity";
import Course from "pages/Course/Course";
import CourseList from "pages/CourseList/CourseList";
import CourseDetails from "pages/CourseDetails/CourseDetails";
import MyCourses from "pages/MyCourses/MyCourses";
import ConversationScreen from "pages/Conversation/Conversation";
import Challenges from "pages/Challenges/Challenges";
import ChallengesDetails from "pages/ChallengesDetails/ChallengesDetails";
import Champions from "pages/Champions/Champions";
import ChampionsDetails from "pages/ChampionsDetails/ChampionsDetails";
import Events from "pages/Events/Events";
import EventsDetails from "pages/EventsDetails/EventsDetails";
import Meeting from "pages/Calendar/Meeting";
import Groups from "pages/Groups/Groups";
import GroupDetails from "pages/GroupDetails/GroupDetails";
import Invitation from "pages/Invitation/Invitation";

/*---- routes for non authenticated users ----*/
const publicRoutes = [
  { path: "/", component: Home },
  { path: "/login", component: Login },
  { path: "/signup", component: SignUp },
];

/*---- routes for authenticated users ----*/
const protectedRoutes = [
  { path: "/activity", component: Activity },

  /* --------- Course and its nested routes starts here --------- */
  // { path: "/course", component: Course }, // unconfirmed path, will be required later.
  { path: "/course/add/step1", component: Course },
  { path: "/course/add/step2", component: Course },
  { path: "/course/add/step3", component: Course },
  { path: "/course/add/step4", component: Course },
  { path: "/course/add/step5", component: Course },
  { path: "/course/add/step6", component: Course },
  { path: "/course/add/step7", component: Course },
  { path: "/course/add/step8", component: Course },
  /* --------- Course and its nested routes ends here --------- */

  // path for course details components, id will be added dynamically later.
  { path: "/course/details/:id", component: CourseDetails },

  // path for course list module
  { path: "/courses", component: CourseList },

  // path for course learning module
  { path: "/mycourses", component: MyCourses },

  // path for conversation (chat) module
  { path: "/conversation", component: ConversationScreen },

  // path for challenges module
  { path: "/challenges", component: Challenges },

  // path for challenges details module
  { path: "/challenges/details/:id", component: ChallengesDetails },

  // path for champions kodule
  { path: "/champions", component: Champions },

  // path for champions details module
  { path: "/champions/details/:id", component: ChampionsDetails },

  // path for events module
  { path: "/events", component: Events },

  // path for events details module
  { path: "/events/details/:id", component: EventsDetails },

  // path for group module
  { path: "/groups", component: Groups },

  // path for group details components, id will be added dynamically later.
  { path: "/group/details/:id", component: GroupDetails },

  // path for temporary calendar module
  { path: "/calendar", component: Meeting },

  // path for my inviation
  { path: "/myinvitations", component: Invitation },
];

export { publicRoutes, protectedRoutes };
