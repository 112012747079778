import React from "react";
import { Link } from "react-router-dom";
import SimilarGroups from "../common/SimilarGroups";

const Documents = () => {
  return (
    <div className="tab-pane fade show active" id="evnt_meeting">
      <div className="training_outer">
        <div className="row">
          <div className="col-lg-8">
            <div className="course_detailspnl">
              <div className="contnt_hdng tab_Hdng">
                <h4>Documents</h4>
              </div>
              <div className="upload_area">
                <div className="upload_item">
                  <div className="uploaded_innrBx">
                    <div className="upload_icon">
                      <img src="images/pdf.svg" alt="" />
                    </div>
                    <div className="upload_txt">
                      <h4>EventReg.pdf</h4>
                      <h5>1.5mb</h5>
                    </div>
                  </div>
                </div>
                <div className="upload_item">
                  <div className="uploaded_innrBx">
                    <div className="upload_icon">
                      <img src="images/doc2.svg" alt="" />
                    </div>
                    <div className="upload_txt">
                      <h4>Managing.pdf</h4>
                      <h5>1.5mb</h5>
                    </div>
                  </div>
                </div>
                <div className="upload_item">
                  <div className="uploaded_innrBx">
                    <div className="upload_icon">
                      <img src="images/pdf.svg" alt="" />
                    </div>
                    <div className="upload_txt">
                      <h4>EventReg.pdf</h4>
                      <h5>1.5mb</h5>
                    </div>
                  </div>
                </div>
                <div className="upload_item">
                  <div className="uploaded_innrBx">
                    <div className="upload_icon">
                      <img src="images/doc2.svg" alt="" />
                    </div>
                    <div className="upload_txt">
                      <h4>Managing.pdf</h4>
                      <h5>1.5mb</h5>
                    </div>
                  </div>
                </div>
                <div className="upload_item">
                  <div className="uploaded_innrBx">
                    <div className="upload_icon">
                      <img src="images/pdf.svg" alt="" />
                    </div>
                    <div className="upload_txt">
                      <h4>EventReg.pdf</h4>
                      <h5>1.5mb</h5>
                    </div>
                  </div>
                </div>
                <div className="upload_item">
                  <div className="uploaded_innrBx">
                    <div className="upload_icon">
                      <img src="images/doc2.svg" alt="" />
                    </div>
                    <div className="upload_txt">
                      <h4>Managing.pdf</h4>
                      <h5>1.5mb</h5>
                    </div>
                  </div>
                </div>
                <div className="upload_item">
                  <div className="uploaded_innrBx">
                    <div className="upload_icon">
                      <img src="images/pdf.svg" alt="" />
                    </div>
                    <div className="upload_txt">
                      <h4>EventReg.pdf</h4>
                      <h5>1.5mb</h5>
                    </div>
                  </div>
                </div>
                <div className="upload_item">
                  <div className="uploaded_innrBx">
                    <div className="upload_icon">
                      <img src="images/doc2.svg" alt="" />
                    </div>
                    <div className="upload_txt">
                      <h4>Managing.pdf</h4>
                      <h5>1.5mb</h5>
                    </div>
                  </div>
                </div>
                <div className="upload_item">
                  <div className="uploaded_innrBx">
                    <div className="upload_icon">
                      <img src="images/pdf.svg" alt="" />
                    </div>
                    <div className="upload_txt">
                      <h4>EventReg.pdf</h4>
                      <h5>1.5mb</h5>
                    </div>
                  </div>
                </div>
                <div className="upload_item">
                  <div className="uploaded_innrBx">
                    <div className="upload_icon">
                      <img src="images/doc2.svg" alt="" />
                    </div>
                    <div className="upload_txt">
                      <h4>Managing.pdf</h4>
                      <h5>1.5mb</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <SimilarGroups />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
