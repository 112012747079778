import React from "react";

// import images here
import graphBar from "assets/ChallengesAssets/images/graph-bnr.jpg";

const Banner = () => {
  return (
    <>
      {/* ----------- Banner area starts here ----------- */}
      <section
        className="graph_bnr_area bg_area"
        style={{ backgroundImage: `url(${graphBar})` }}
      >
        <div className="container">
          <div className="bnr_graph_textPnl">
            <h1>
              <span>Challenges at </span> Indra ATM Ecosystem
            </h1>
          </div>
        </div>
      </section>
      {/* ----------- Banner area ends here ----------- */}
    </>
  );
};

export default Banner;
