import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Details from "./Tabs/Details";
import Teams from "./Tabs/Teams";
import DateTab from "./Tabs/DateTab";
import Applied from "./Tabs/Applied";
import DocTab from "./Tabs/DocTab";
import Faq from "./Tabs/Faq";
import ProcessTab from "./Tabs/ProcessTab";
import WinnerTab from "./Tabs/WinnerTab";
import Review from "./Tabs/Review";

const ChallengeDetailsBottomSection = () => {
  /*----- initial state for toggling tabs ------*/
  const [detailsTab, setDetailsTab] = useState(true);
  const [teamsTab, setTeamsTab] = useState(false);
  const [datesTab, setDatesTab] = useState(false);
  const [appliedTab, setAppliedTab] = useState(false);
  const [docTab, setDocTab] = useState(false);
  const [faqTab, setFaqTab] = useState(false);
  const [processTab, setProcessTab] = useState(false);
  const [winnerTab, setWinner] = useState(false);
  const [reviewTab, setReview] = useState(false);
  /*----- end of initial state for toggling tabs ------*/

  // function to display contents tab
  const showDetailsTab = (e) => {
    e.preventDefault();
    setDetailsTab(true);
    setTeamsTab(false);
    setDatesTab(false);
    setAppliedTab(false);
    setDocTab(false);
    setFaqTab(false);
    setProcessTab(false);
    setWinner(false);
    setReview(false);
  };

  const showTeamsTab = (e) => {
    e.preventDefault();
    setDetailsTab(false);
    setTeamsTab(true);
    setDatesTab(false);
    setAppliedTab(false);
    setDocTab(false);
    setFaqTab(false);
    setProcessTab(false);
    setWinner(false);
    setReview(false);
  };

  const showDatesTab = (e) => {
    e.preventDefault();
    setDetailsTab(false);
    setTeamsTab(false);
    setDatesTab(true);
    setAppliedTab(false);
    setDocTab(false);
    setFaqTab(false);
    setProcessTab(false);
    setWinner(false);
    setReview(false);
  };

  const showAppliedTab = (e) => {
    e.preventDefault();
    setDetailsTab(false);
    setTeamsTab(false);
    setDatesTab(false);
    setAppliedTab(true);
    setDocTab(false);
    setFaqTab(false);
    setProcessTab(false);
    setWinner(false);
    setReview(false);
  };

  const showDocTab = (e) => {
    e.preventDefault();
    setDetailsTab(false);
    setTeamsTab(false);
    setDatesTab(false);
    setAppliedTab(false);
    setDocTab(true);
    setFaqTab(false);
    setProcessTab(false);
    setWinner(false);
    setReview(false);
  };

  const showFAQTab = (e) => {
    e.preventDefault();
    setDetailsTab(false);
    setTeamsTab(false);
    setDatesTab(false);
    setAppliedTab(false);
    setDocTab(false);
    setFaqTab(true);
    setProcessTab(false);
    setWinner(false);
    setReview(false);
  };

  const showProcessTab = (e) => {
    e.preventDefault();
    setDetailsTab(false);
    setTeamsTab(false);
    setDatesTab(false);
    setAppliedTab(false);
    setDocTab(false);
    setFaqTab(false);
    setProcessTab(true);
    setWinner(false);
    setReview(false);
  };

  const showWinnerTab = (e) => {
    e.preventDefault();
    setDetailsTab(false);
    setTeamsTab(false);
    setDatesTab(false);
    setAppliedTab(false);
    setDocTab(false);
    setFaqTab(false);
    setProcessTab(false);
    setWinner(true);
    setReview(false);
  };

  const showReviewTab = (e) => {
    e.preventDefault();
    setDetailsTab(false);
    setTeamsTab(false);
    setDatesTab(false);
    setAppliedTab(false);
    setDocTab(false);
    setFaqTab(false);
    setProcessTab(false);
    setWinner(false);
    setReview(true);
  };

  // return starts here
  return (
    <section className="indra_course_tabArea">
      {/* ----- tab selcetion ----- */}
      <div className="course_tablinks_outer">
        <div className="container">
          <div className="tab_links">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link
                  className={
                    detailsTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showDetailsTab(e)}
                >
                  Details
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={
                    teamsTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showTeamsTab(e)}
                >
                  Teams
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={
                    datesTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showDatesTab(e)}
                >
                  Dates
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={
                    appliedTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showAppliedTab(e)}
                >
                  Applied
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={
                    docTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showDocTab(e)}
                >
                  Docs
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={
                    faqTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showFAQTab(e)}
                >
                  FAQ
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={
                    processTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showProcessTab(e)}
                >
                  Process
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={
                    winnerTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showWinnerTab(e)}
                >
                  Winner
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={
                    reviewTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showReviewTab(e)}
                >
                  Review
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* ----- tab selcetion end ----- */}

      {/* ----- selected tab area ----- */}
      <div className="tab_bodyArea">
        <div className="container">
          <div className="tab-content">
            {/* ----- details tab ---- */}
            <div className="tab-pane fade show active" id="details">
              {detailsTab && <Details />}
            </div>
            {/* ----- details tab end ---- */}

            {/* ---- Team tab --- */}
            <div className="tab-pane fade show active" id="teams">
              {teamsTab && <Teams />}
            </div>
            {/* ---- Team tab end --- */}

            {/* ----- Dates tab ---- */}
            <div className="tab-pane fade show active" id="dates">
              {datesTab && <DateTab />}
            </div>
            {/* ----- Dates tab end ---- */}

            {/* ----- Applied tab ---- */}
            <div className="tab-pane fade show active" id="applied">
              {appliedTab && <Applied />}
            </div>
            {/* ----- Applied tab end ---- */}

            {/* ----- Docs tab ---- */}
            <div className="tab-pane fade show active" id="docs">
              {docTab && <DocTab />}
            </div>
            {/* ----- Docs tab end ---- */}

            {/* ----- Faq tab ---- */}
            <div className="tab-pane fade show active" id="faqs">
              {faqTab && <Faq />}
            </div>
            {/* ----- Faq tab end ---- */}

            {/* ----- Process tab  ---- */}
            <div className="tab-pane fade show active" id="process">
              {processTab && <ProcessTab />}
            </div>
            {/* ----- Process tab end ---- */}

            {/* ----- Winner tab  ---- */}
            <div className="tab-pane fade show active" id="winner">
              {winnerTab && <WinnerTab />}
            </div>
            {/* ----- Winner tab end ---- */}

            {/* ----- Review tab  ---- */}
            <div className="tab-pane fade show active" id="winner">
              {reviewTab && <Review />}
            </div>
            {/* ----- Review tab end ---- */}
          </div>
        </div>
      </div>
      {/* ----- selected tab area end ----- */}
    </section>
  );
};

export default ChallengeDetailsBottomSection;
