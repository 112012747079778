/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalIndraInfo } from "App";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

//import images
import defaultUser from "assets/ActivityAssests/images/blank-champion.jpg";
import { useTranslation } from "react-i18next";

const ChampionListArea = () => {
  const token = localStorage.getItem("token");

  const {
    searchChampions,
    champName,
    champCategory,
    champCity,
    refreshChampLists,
    refreshChampionLists,
  } = useContext(GlobalIndraInfo);

  const { t } = useTranslation();

  // array for counting glow rows
  const glowCount = [1, 2, 3, 4];

  // Initial useState to set page numbers for pagination
  const [page, setPage] = useState(1);

  //initial for champions lists
  const [championsLists, setChampionsLists] = useState([]);
  // initial for loading
  const [isLoading, setIsLoading] = useState(false);

  //initial for next page number
  const [showNextPageNumber, setShowNextPageNumber] = useState(true);
  const [showPageTwo, setShowPageTwo] = useState(true);
  const [showPageThree, setShowPageThree] = useState(true);
  const [showPageFour, setShowPageFour] = useState(true);
  const [showPageFive, setShowPageFive] = useState(true);

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  // function for all champion lists
  const getChampionsLists = async () => {
    let categoryValue = "";

    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_CHAMPIONS_LISTS +
      `?token=${token}` +
      `&page=${page}`;

    if (champName != "") {
      requestURL = requestURL + `&query=${champName}`;
    }

    if (champCategory != null && champCategory.length > 0) {
      champCategory.map((data) => {
        categoryValue += `&skill[]=${data}`;
        requestURL = requestURL + `${categoryValue}`;
      });
    }

    if (champCity != "") {
      requestURL = requestURL + `&location=${champCity}`;
    }

    console.log("request url for champion lists----->", requestURL);
    try {
      const response = await getData(requestURL);
      console.log("Received Response in champion lists --------> ", response);

      if (response.status) {
        setChampionsLists(response.data);
        if (response.data.length < 12) {
          setShowNextPageNumber(false);
          if (page == 1) {
            setShowPageTwo(false);
            setShowPageThree(false);
            setShowPageFour(false);
            setShowPageFive(false);
          }
          if (page == 2) {
            setShowPageThree(false);
            setShowPageFour(false);
            setShowPageFive(false);
          }
          if (page == 3) {
            setShowPageFour(false);
            setShowPageFive(false);
          }
          if (page == 4) {
            setShowPageFive(false);
          }
        } else {
          setShowNextPageNumber(true);
          setShowPageTwo(true);
          setShowPageThree(true);
          setShowPageFour(true);
          setShowPageFive(true);
        }
      } else {
        console.log("Some error occurred ------> ", response);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect for page number
  useEffect(() => {
    getChampionsLists();
  }, [page]);

  //use Effect for search champion query
  useEffect(() => {
    if (champName != "" || champCategory.length > 0 || champCity != "") {
      getChampionsLists();
      searchChampions(null);
    }
  }, [champName, champCategory, champCity]);

  // use effect for refresh champion lists
  useEffect(() => {
    if (refreshChampLists) {
      setPage(1);
      getChampionsLists();
      refreshChampionLists(false);
    }
  }, [refreshChampLists]);

  return (
    <section className="champions_area">
      <div className="container">
        {isLoading ? (
          <div className="champions_outer">
            <div className="row">
              {glowCount.map((count) => {
                return (
                  <div className="col-lg-3 col-6" key={count}>
                    <div className="champions_item">
                      <div className="champions_pic">
                        <span
                          className="placeholder d-block"
                          style={{ width: "290px", height: "253px" }}
                        ></span>
                      </div>
                      <div className="champions_textpnl">
                        <div className="champions_txtOuter">
                          <div className="leftchampion_text">
                            <span
                              className="placeholder rounded-2 mb-2"
                              style={{ width: "134px", height: "20px" }}
                            ></span>
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "94px", height: "16px" }}
                            ></span>
                          </div>
                          <div className="chappions_rating">
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "40px", height: "24px" }}
                            ></span>
                          </div>
                        </div>
                        <div className="details_btn">
                          <span
                            className="placeholder d-block rounded-2 col-12"
                            style={{ height: "50px" }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="champions_outer">
            {championsLists ? (
              <div className="row">
                {championsLists.map((champions, index) => {
                  return (
                    <div className="col-lg-3 col-6" key={index}>
                      <div className="champions_item">
                        <div className="champions_pic">
                          <img
                            src={
                              champions.fullimage
                                ? champions.fullimage
                                : defaultUser
                            }
                            alt="user"
                          />
                        </div>
                        <div className="champions_textpnl">
                          <div className="champions_txtOuter">
                            <div className="leftchampion_text">
                              <h3>
                                {champions.name} {champions.surname}
                              </h3>
                              <h5>{champions.position}</h5>
                            </div>
                            <div className="chappions_rating">
                              <Link to="#">
                                <i className="material-icons-outlined">star</i>
                                <span>{champions.rating}</span>
                              </Link>
                            </div>
                          </div>
                          <div className="details_btn">
                            <Link
                              to={`/champions/details/${champions.id}`}
                              className="basic_btn ylw_btn"
                            >
                              {t("See Details")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* ------------ Pagination starts here ------------ */}
                <div className="pagination_list">
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="material-icons-outlined">
                          arrow_back_ios
                        </i>
                      </Link>
                    </li>
                    <li className={page == 1 ? "active" : ""}>
                      <Link to="#" onClick={() => nextPage(1)}>
                        1
                      </Link>
                    </li>

                    {showPageTwo && (
                      <li className={page == 2 ? "active" : ""}>
                        <Link to="#" onClick={() => nextPage(2)}>
                          2
                        </Link>
                      </li>
                    )}

                    {showPageThree && (
                      <li className={page == 3 ? "active" : ""}>
                        <Link to="#" onClick={() => nextPage(3)}>
                          3
                        </Link>
                      </li>
                    )}

                    {showPageFour && (
                      <li className={page == 4 ? "active" : ""}>
                        <Link to="#" onClick={() => nextPage(4)}>
                          4
                        </Link>
                      </li>
                    )}
                    {showPageFive && (
                      <li>
                        <Link to="#" onClick={() => nextPage(5)}>
                          5
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link to="#">
                        <i className="material-icons-outlined">
                          arrow_forward_ios
                        </i>
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* ------------ Pagination ends here ------------ */}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </section>
  );
};

export default ChampionListArea;
