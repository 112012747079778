import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// import images here
import certificate from "assets/CourseDetailsAssests/images/certificate_frame.png";

// importing custom components here
import About from "components/CourseDetailsComponents/Tabs/About";
import Lessons from "components/CourseDetailsComponents/Tabs/Lessons/Lessons";
import Team from "components/CourseDetailsComponents/Tabs/Team";
import Events from "components/CourseDetailsComponents/Tabs/Events";
import Forum from "components/CourseDetailsComponents/Tabs/Forum";
import Review from "components/CourseDetailsComponents/Tabs/Review";
import Docs from "components/CourseDetailsComponents/Tabs/Docs";

const CourseDetailsBottomSection = () => {
  // accessing the user's token here
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  /*----- initial state for toggling tabs ------*/
  const [aboutTab, setAboutTab] = useState(true);
  const [lessonsTab, setLessonsTab] = useState(false);
  const [teamTab, setTeamTab] = useState(false);
  const [eventsTab, setEventsTab] = useState(false);
  const [forumTab, setForumTab] = useState(false);
  const [reviewTab, setReviewTab] = useState(false);
  const [certificationTab, setCertificationTab] = useState(false);
  /*----- end of initial state for toggling tabs ------*/

  // funtion for display contents tab
  const showAboutTab = (e) => {
    e.preventDefault();
    setAboutTab(true);
    setLessonsTab(false);
    setTeamTab(false);
    setEventsTab(false);
    setForumTab(false);
    setReviewTab(false);
    setCertificationTab(false);
  };

  const showLessonsTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setLessonsTab(true);
    setTeamTab(false);
    setEventsTab(false);
    setForumTab(false);
    setReviewTab(false);
    setCertificationTab(false);
  };

  const showTeamTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setLessonsTab(false);
    setTeamTab(true);
    setEventsTab(false);
    setForumTab(false);
    setReviewTab(false);
    setCertificationTab(false);
  };

  const showEventsTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setLessonsTab(false);
    setTeamTab(false);
    setEventsTab(true);
    setForumTab(false);
    setReviewTab(false);
    setCertificationTab(false);
  };

  const showForumTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setLessonsTab(false);
    setTeamTab(false);
    setEventsTab(false);
    setForumTab(true);
    setReviewTab(false);
    setCertificationTab(false);
  };

  const showReviewTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setLessonsTab(false);
    setTeamTab(false);
    setEventsTab(false);
    setForumTab(false);
    setReviewTab(true);
    setCertificationTab(false);
  };

  const showCertificationTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setLessonsTab(false);
    setTeamTab(false);
    setEventsTab(false);
    setForumTab(false);
    setReviewTab(false);
    setCertificationTab(true);
  };

  return (
    <>
      <section className="indra_course_tabArea">
        {/* --------------- Bottom section header starts here --------------- */}
        <div className="course_tablinks_outer">
          <div className="container">
            <div className="tab_links">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <Link
                    className={
                      aboutTab == true ? "nav-link active show" : "nav-link"
                    }
                    to="#"
                    onClick={(e) => showAboutTab(e)}
                  >
                    {t("About")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      lessonsTab == true ? "nav-link active show" : "nav-link"
                    }
                    to="#"
                    onClick={(e) => showLessonsTab(e)}
                  >
                    {t("Lessons")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      teamTab == true ? "nav-link active show" : "nav-link"
                    }
                    to="#"
                    onClick={(e) => showTeamTab(e)}
                  >
                    {" "}
                    {t("Team")}{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      eventsTab == true ? "nav-link active show" : "nav-link"
                    }
                    to="#"
                    onClick={(e) => showEventsTab(e)}
                  >
                    {t("Events")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      forumTab == true ? "nav-link active show" : "nav-link"
                    }
                    to="#"
                    onClick={(e) => showForumTab(e)}
                  >
                    {t("Forum")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      reviewTab == true ? "nav-link active show" : "nav-link"
                    }
                    to="#"
                    onClick={(e) => showReviewTab(e)}
                  >
                    {t("Review")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      certificationTab == true
                        ? "nav-link active show"
                        : "nav-link"
                    }
                    to="#"
                    onClick={(e) => showCertificationTab(e)}
                  >
                    {t("Certification")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* --------------- Bottom section header ends here --------------- */}

        {/* --------------- Bottom section body starts here --------------- */}
        <div className="tab_bodyArea">
          <div className="container">
            <div className="tab-content">
              {/* --------------- Course (About) Body starts here --------------- */}

              {aboutTab && <About />}
              {/* --------------- Course (About) Body ends here --------------- */}

              {/* --------------- Lessons Body starts here --------------- */}

              {lessonsTab && <Lessons />}
              {/* --------------- Lessons Body ends here --------------- */}

              {/* --------------- Team Body starts here --------------- */}

              {teamTab && <Team />}
              {/* --------------- Team Body ends here --------------- */}

              {/* --------------- Events Body starts here --------------- */}

              {eventsTab && <Events />}
              {/* --------------- Events Body ends here --------------- */}

              {/* --------------- Forum Body starts here --------------- */}

              {forumTab && <Forum />}
              {/* --------------- Forum Body ends here --------------- */}

              {/* --------------- Forum Body starts here --------------- */}

              {reviewTab && <Review />}
              {/* --------------- Forum Body ends here --------------- */}

              {/* --------------- Doc Body starts here --------------- */}

              {certificationTab && <Docs />}
              {/* --------------- Doc Body ends here --------------- */}
            </div>
          </div>
        </div>
        {/* --------------- Bottom section body ends here --------------- */}
      </section>
    </>
  );
};

export default CourseDetailsBottomSection;
