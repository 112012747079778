/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// importing context API here
import { GlobalIndraInfo } from "App";

// import images assests here
import userDefault from "assets/CourseDetailsAssests/images/default-user.png";

// importing custom components here
import AddTagsModal from "components/CourseDetailsComponents/Modals/AddTagsModal";
import ShareModal from "components/CourseDetailsComponents/Modals/ShareModal";
import JoinCourseModal from "components/CourseDetailsComponents/Modals/JoinCourseModal";
/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

const CourseDetailsTopSection = () => {
  let { t } = useTranslation();

  // saving user token here
  const token = localStorage.getItem("token");

  // importing useContext functions here
  const {
    getCourseName,
    getCourseFeatures,
    getCourseSkills,
    getCourseAbout,
    getCourseRequirements,
    getCoursePromotionalText,
    getCourseLessons,
    getCourseTeams,
    getCourseStories,
    getCoursePromoVideo,
    getCourseCategories,
    joinNow,
    joinNowConfirm,
    joinNowLoading,
    subscriberDetails,
    lessonComplete,
    completeLesson,
  } = useContext(GlobalIndraInfo);

  // get ID from url
  const params = useParams();

  // Initial useState to save all the incoming data from API.
  const [courseData, setCourseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // initial useState to trigger the Modal
  const [courseTags, setCourseTags] = useState([]);
  const [joinCourseLoading, setJoinCourseLoading] = useState(false);
  const [modalAdminMessage, setModalAdminMessage] = useState("");

  const getCourseData = async (id) => {
    let requestURL =
      url.API_BaseUrl + url.API_VIEW_COURSE + `?token=${token}` + `&cid=${id}`;

    setIsLoading(true);

    try {
      // console.log(
      //   "requesting URL to get all the published courses ------>",
      //   requestURL
      // );

      const response = await getData(requestURL);
      console.log(
        "Received Response in course details top --------> ",
        response
      );

      if (response.status) {
        setCourseData(response.data);

        // ---------- dispatching the label here ----------
        if (response.data.label != null) {
          getCourseName(response.data.label);
        }

        // ---------- dispatching the features here ----------
        if (
          response.data.features != null &&
          response.data.features.length > 0
        ) {
          getCourseFeatures(response.data.features);
        }

        // ---------- dispatching the skills here ----------
        if (response.data.skills != null && response.data.skills.length > 0) {
          getCourseSkills(response.data.skills);
        }

        // ---------- dispatching the about here ----------
        if (response.data.about != "") {
          getCourseAbout(response.data.about);
        }

        // ---------- dispatching the requirments here ----------
        if (
          response.data.requirements != null &&
          response.data.requirements.length > 0
        ) {
          getCourseRequirements(response.data.requirements);
        }

        // ---------- dispatching the promotional text here ----------
        if (
          response.data.promotionaltext != null &&
          response.data.promotionaltext.length > 0
        ) {
          getCoursePromotionalText(response.data.promotionaltext);
        }

        // ---------- dispatching the lessons here ----------
        if (response.data.lessons != null && response.data.lessons.length > 0) {
          getCourseLessons(response.data.lessons);
        }

        // ---------- dispatching the team data here ----------
        if (response.data.members != null && response.data.members.length > 0) {
          getCourseTeams(response.data.members);
        }

        // ---------- dispatching the stories data here ----------
        if (response.data.stories != null && response.data.stories.length > 0) {
          getCourseStories(response.data.stories);
        }

        // ---------- dispatching the promo video url here ----------
        if (response.data.promotionalvideo != "") {
          getCoursePromoVideo(response.data.promotionalvideo);
        }
        if (response.data.tags.length > 0) {
          setCourseTags(response.data.tags);
        }

        if (response.data.categories.length > 0) {
          getCourseCategories(response.data.categories);
        }
        subscriberDetails(response.data.subscriber);
      } else {
        console.log("Some error occurred ------> ", response);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
    }
  };

  // function for calling API after closing add tag modal
  const afterModalClose = () => {
    getCourseData(params.id);
  };

  // function for join course
  const joinCourseHandler = async () => {
    setJoinCourseLoading(true);
    joinNowLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_JOIN_COURSE +
      `?token=${token}` +
      `&cid=${params.id}`;

    try {
      let response = await getData(requestURL);
      console.log(response);
      if (response.status) {
        if (response.data.message != "") {
          setModalAdminMessage(response.data.message);
        }
        let myModal = new bootstrap.Modal(
          document.getElementById("join_success_modal")
        );
        myModal.show();
      }
      setJoinCourseLoading(false);
      joinNowLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (joinNow) {
      joinCourseHandler();
      joinNowConfirm(false);
    }
  }, [joinNow]);

  useEffect(() => {
    if (lessonComplete) {
      getCourseData(params.id);
      completeLesson(false);
    }
  }, [lessonComplete]);

  // useEffect from here
  useEffect(() => {
    getCourseData(params.id);
  }, [params.id]);

  // return from here
  return (
    <>
      {isLoading ? (
        <section className="course_details_sec">
          <div className="container">
            <div className="course_detailsBx placeholder-glow">
              <div className="topdetails_left">
                <div className="expertholder_outer">
                  <div className="experts_holdertext w_100">
                    <span className="placeholder placeholder-sm col-4 rounded-3 mb-20 white_glow"></span>
                    <span className="placeholder placeholder-sm col-6 rounded-3 mb-20 white_glow"></span>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-2 rounded-3 ml-0 white_glow"></span>
                      <span className="placeholder placeholder-sm col-1 rounded-3 white_glow"></span>
                      <span className="placeholder placeholder-sm col-2 rounded-3 white_glow"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 ml-0 white_glow"></span>
                      <span className="placeholder placeholder-sm col-2 rounded-3 white_glow"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-2 rounded-3 ml-0 white_glow"></span>
                      <span className="placeholder placeholder-sm col-1 rounded-3 white_glow"></span>
                      <span className="placeholder placeholder-sm col-2 rounded-3 white_glow"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 ml-0 white_glow"></span>
                      <span className="placeholder placeholder-sm col-3 rounded-3 white_glow"></span>
                    </p>

                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-12 rounded-3 ml-0 white_glow"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 white_glow"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-2 rounded-3 ml-0 white_glow"></span>
                      <span className="placeholder placeholder-sm col-4 rounded-3 white_glow"></span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="topdetails_right">
                <p className="data_btnOuter mb-20">
                  <span className="placeholder placeholder-sm col-2 rounded-3 ml-0 white_glow"></span>
                  <span className="placeholder placeholder-sm col-1 rounded-3 white_glow"></span>
                  <span className="placeholder placeholder-sm col-1 rounded-3 white_glow"></span>
                </p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="course_details_sec">
          {courseData == null ? null : (
            <div className="container">
              <div className="course_detailsBx">
                {/* --------------------------- Left Section --------------------------- */}
                <div className="topdetails_left">
                  <div className="expertholder_outer">
                    <div className="experts_holdertext">
                      <h5>
                        {t("Offered By:")} {courseData.company}
                      </h5>
                      <h3>{courseData.label}</h3>
                      <div className="details_tags expert_details">
                        {/* -------- Lesson Star rating -------- */}
                        <div className="jb_rating star_color">
                          <i className="material-icons-outlined"> star </i>
                          <span>{courseData.rating}</span>
                          <h6>
                            ({courseData.reviewcount} <em>{t("reviews")}</em>)
                          </h6>
                        </div>

                        {/* -------- Lesson Lesson Count -------- */}
                        <div className="course_text">
                          <h4>
                            {courseData.lessons
                              ? courseData.lessons.length
                              : ""}

                            <em className="ms-1">{t("lessons")}</em>
                          </h4>
                        </div>

                        {/* -------- COurse Language -------- */}
                        <div className="course_duration">
                          <h5>
                            <i className="material-icons">language</i>{" "}
                            <span>{courseData.language.toUpperCase()}</span>
                          </h5>
                        </div>
                      </div>

                      {/* --------- Course Categories -------------------- */}

                      {courseData.categories != null &&
                      courseData.categories.length > 0 ? (
                        <div className="experts_skill mt-2">
                          <ul>
                            {courseData.categories.map((category, index) => {
                              return (
                                <li key={category.id}>
                                  <Link to="#">{category.label}</Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : null}

                      {/* -------- Lesson Instructor Details -------- */}
                      <div className="traning_user">
                        <label>{t("Mentors")} </label>
                        {courseData.members.map((member, index) => {
                          return (
                            <div
                              className="userexprt_pnltxt me-2 ms-2"
                              key={member.membershipid}
                            >
                              <div className="expert_usr">
                                <img
                                  src={
                                    member.image != null
                                      ? member.image
                                      : userDefault
                                  }
                                  alt="member"
                                />
                              </div>
                              <div className="exprt_text">
                                <h5>{member.name}</h5>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {/* -------- Lesson Subtitle -------- */}
                      <div className="traning_intermetiate_outer">
                        <div className="training_para">
                          <p>{courseData.subtitle}</p>
                        </div>
                      </div>

                      {/*---- join date ----*/}
                      <div className="traning_intermetiate_outer">
                        <div className="training_para">
                          <p>
                            {t("Join Date")}: {courseData.subscriptiondate}
                          </p>
                        </div>
                      </div>

                      {/* -------- Lesson Progress Bar -------- */}
                      {courseData.subscriber ? (
                        <div className="course_progress">
                          <div className="progess_outer">
                            <span
                              className="progress_bar"
                              style={{
                                width: `${courseData.coursecompletion}%`,
                              }}
                            ></span>
                          </div>
                          <div className="percentage">
                            {courseData.coursecompletion}% {t("Completed")}
                          </div>
                        </div>
                      ) : (
                        <div className="hire_btn">
                          <Link
                            to="#"
                            className="basic_btn ylw_btn"
                            onClick={joinCourseHandler}
                          >
                            {t("Join Now")}
                            {joinCourseLoading && (
                              <div
                                className="mx-2 spinner-border spinner-border-sm"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* --------------------------- Right Section --------------------------- */}
                <div className="topdetails_right">
                  <div className="asign_top">
                    {/* -------- Lesson Company Name -------- */}
                    {/* <div className="asign_outer">
                      <div className="asign_item ">
                        {courseTags.length > 0 ? (
                          <ul className="text-white">
                            {courseTags.map((tags, index) => {
                              return <li key={index}>{tags.label}</li>;
                            })}
                          </ul>
                        ) : null}
                      </div>
                    </div> */}

                    {/* --------- Add Tags Modal & Share Modal Links --------- */}
                    <div className="exprt_share traning_share">
                      <ul>
                        {/* --------- Link to activate Tags Modal --------- */}
                        <li>
                          {/* <Link to="#" onClick={(e) => triggerAddTagsModal(e)}> */}
                          <Link
                            to="#"
                            className="basic_btn activty_filter"
                            data-bs-toggle="modal"
                            data-bs-target="#addtag_mdl"
                          >
                            <i className="material-icons-outlined">more</i>
                          </Link>
                        </li>

                        {/* --------- Link to activate Share Modal --------- */}
                        <li>
                          <Link
                            to="#"
                            className="basic_btn activty_filter"
                            data-bs-toggle="modal"
                            data-bs-target="#share_modal"
                          >
                            <i className="material-icons-outlined">share</i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* --------- Tags list and dropdown start --------- */}
                  <div className="tags_menu">
                    {/* -------- Top 3 tags here -------- */}
                    {courseTags.length > 0 ? (
                      <ul className="tags_list">
                        {courseTags.slice(0, 3).map((tags, index) => {
                          return (
                            <li key={index}>
                              <Link to="#">{tags.label}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}

                    {/* -------- Remaining tags here in dropdown --------- */}
                    {courseTags.length > 3 ? (
                      <div className="tags_drop dropdown">
                        <Link to="#" data-bs-toggle="dropdown">
                          <span className="material-icons-outlined">
                            expand_more
                          </span>
                        </Link>
                        {courseTags.length > 0 ? (
                          <ul className="dropdown-menu">
                            {courseTags
                              .slice(3, courseTags.length)
                              .map((tags, index) => {
                                return (
                                  <li key={index}>
                                    <Link to="#">{tags.label}</Link>
                                  </li>
                                );
                              })}
                          </ul>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {/* --------- Tags list and dropdown end --------- */}
                </div>
              </div>
            </div>
          )}

          {/* importing the Add Tags Modal here */}
          <AddTagsModal afterModalClose={afterModalClose} />

          {/* importing the Share Modal here */}
          <ShareModal />

          <JoinCourseModal
            modalAdminMessage={modalAdminMessage}
            afterModalClose={afterModalClose}
          />
        </section>
      )}
    </>
  );
};

export default CourseDetailsTopSection;
