import React from "react";
import { Link } from "react-router-dom";

const EventDetailsBanner = () => {
  return (
    <section className="course_details_sec">
      <div className="container">
        <div className="course_detailsBx">
          <div className="topdetails_left">
            <div className="expertholder_outer">
              <div className="experts_holdertext">
                <h5>SUNDAY, 6 FEBRUARY 2022 FROM 17:00-20:00</h5>
                <h3>SSystem Administrator User Launch</h3>
                <div className="details_tags expert_details">
                  <div className="jb_rating star_color">
                    <i className="material-icons-outlined">star</i>
                    <span>4.5</span>
                    <h6>
                      (32<em>valoraciones</em>)
                    </h6>
                  </div>
                  <div className="course_text">
                    <h4>Madrid,Spain</h4>
                  </div>
                  <div className="course_text">
                    <i className="material-icons-outlined"> people</i>
                    <h4>25k Enrolled</h4>
                  </div>
                </div>

                <div className="experts_skill">
                  <ul>
                    <li>
                      <Link to="#">
                        <span>Big Tech</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span>IT Gients</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span>Technology</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="vdo_link">
                  <Link to="#">
                    <i className="material-icons-outlined">video_call</i>
                    <span>https://meet.google.com/svuryzu-dpy</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="topdetails_right">
            <div className="asign_top">
              <div className="asign_outer">
                <div className="asign_item">
                  <h4>
                    Offered By <span>Indra</span>
                  </h4>
                </div>
              </div>

              <div className="exprt_share traning_share">
                <ul>
                  <li>
                    <Link to="#">
                      <i className="material-icons-outlined">more</i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="material-icons-outlined">share</i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventDetailsBanner;
