import React, { useState } from "react";
import { Link } from "react-router-dom";

// importing custom components here
import About from "components/GroupDetailsComponents/Tabs/About";
import Members from "components/GroupDetailsComponents/Tabs/Members";
import Events from "components/GroupDetailsComponents/Tabs/Events";
import Gallery from "components/GroupDetailsComponents/Tabs/Gallery";
import Forum from "components/GroupDetailsComponents/Tabs/Forum";
import Documents from "components/GroupDetailsComponents/Tabs/Documents";
import Survey from "components/GroupDetailsComponents/Tabs/Survey";
import Review from "components/GroupDetailsComponents/Tabs/Review";

const GroupDetailsBottomSection = () => {
  /*----- initial state for toggling tabs ------*/
  const [aboutTab, setAboutTab] = useState(true);
  const [membersTab, setMembersTab] = useState(false);
  const [eventsTab, setEventsTab] = useState(false);
  const [galleryTab, setGalleryTab] = useState(false);
  const [forumTab, setForumTab] = useState(false);
  const [documentsTab, setDocumentsTab] = useState(false);
  const [surveyTab, setSurveyTab] = useState(false);
  const [reviewTab, setReviewTab] = useState(false);
  /*----- end of initial state for toggling tabs ------*/

  const showAboutTab = () => {
    setAboutTab(true);
    setMembersTab(false);
    setEventsTab(false);
    setGalleryTab(false);
    setForumTab(false);
    setDocumentsTab(false);
    setSurveyTab(false);
    setReviewTab(false);
  };

  const showMembersTab = () => {
    setAboutTab(false);
    setMembersTab(true);
    setEventsTab(false);
    setGalleryTab(false);
    setForumTab(false);
    setDocumentsTab(false);
    setSurveyTab(false);
    setReviewTab(false);
  };

  const showEventsTab = () => {
    setAboutTab(false);
    setMembersTab(false);
    setEventsTab(true);
    setGalleryTab(false);
    setForumTab(false);
    setDocumentsTab(false);
    setSurveyTab(false);
    setReviewTab(false);
  };

  const showGalleryTab = () => {
    setAboutTab(false);
    setMembersTab(false);
    setEventsTab(false);
    setGalleryTab(true);
    setForumTab(false);
    setDocumentsTab(false);
    setSurveyTab(false);
    setReviewTab(false);
  };

  const showForumTab = () => {
    setAboutTab(false);
    setMembersTab(false);
    setEventsTab(false);
    setGalleryTab(false);
    setForumTab(true);
    setDocumentsTab(false);
    setSurveyTab(false);
    setReviewTab(false);
  };

  const showDocumentsTab = () => {
    setAboutTab(false);
    setMembersTab(false);
    setEventsTab(false);
    setGalleryTab(false);
    setForumTab(false);
    setDocumentsTab(true);
    setSurveyTab(false);
    setReviewTab(false);
  };

  const showSurveyTab = () => {
    setAboutTab(false);
    setMembersTab(false);
    setEventsTab(false);
    setGalleryTab(false);
    setForumTab(false);
    setDocumentsTab(false);
    setSurveyTab(true);
    setReviewTab(false);
  };

  const showReviewTab = () => {
    setAboutTab(false);
    setMembersTab(false);
    setEventsTab(false);
    setGalleryTab(false);
    setForumTab(false);
    setDocumentsTab(false);
    setSurveyTab(false);
    setReviewTab(true);
  };

  // return starts here
  return (
    <>
      <section className="indra_course_tabArea">
        {/* --------------- Bottom section header starts here --------------- */}
        <div className="course_tablinks_outer">
          <div className="container">
            <div className="tab_links">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <Link
                    className={
                      aboutTab == true ? "nav-link active show" : "nav-link"
                    }
                    onClick={showAboutTab}
                    to="#"
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      membersTab == true ? "nav-link active show" : "nav-link"
                    }
                    onClick={showMembersTab}
                    to="#"
                  >
                    Members
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      eventsTab == true ? "nav-link active show" : "nav-link"
                    }
                    onClick={showEventsTab}
                    to="#"
                  >
                    Events
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      galleryTab == true ? "nav-link active show" : "nav-link"
                    }
                    onClick={showGalleryTab}
                    to="#"
                  >
                    Gallery
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      forumTab == true ? "nav-link active show" : "nav-link"
                    }
                    onClick={showForumTab}
                    to="#"
                  >
                    Forum
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      documentsTab == true ? "nav-link active show" : "nav-link"
                    }
                    onClick={showDocumentsTab}
                    to="#"
                  >
                    Documents
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      surveyTab == true ? "nav-link active show" : "nav-link"
                    }
                    onClick={showSurveyTab}
                    to="#"
                  >
                    Survey
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      reviewTab == true ? "nav-link active show" : "nav-link"
                    }
                    onClick={showReviewTab}
                    to="#"
                  >
                    Review
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* --------------- Bottom section header ends here --------------- */}

        {/* --------------- Bottom section body starts here --------------- */}
        <div className="tab_bodyArea">
          <div className="container">
            <div className="tab-content">
              {/* ---- About tab ---- */}
              {aboutTab && <About />}
              {/* ---- About tab end ---- */}

              {/* ---- member tab ---- */}
              {membersTab && <Members />}
              {/* ---- member tab end ---- */}

              {/* ---- event tab start ---- */}
              {eventsTab && <Events />}
              {/* ---- event tab end ---- */}

              {/* ---- Galler tab ----  */}
              {galleryTab && <Gallery />}
              {/* ---- Gallery tab end ---- */}

              {/* --- forum tab ---- */}
              {forumTab && <Forum />}
              {/* ---- forum tab end ---- */}

              {/* ---- document tab ---- */}
              {documentsTab && <Documents />}
              {/* ----- document tab ----- */}

              {/* ---- survey tab ----- */}
              {surveyTab && <Survey />}
              {/* ---- survey tab end ----*/}

              {/* ---- review tab ----- */}
              {reviewTab && <Review />}
              {/* ---- review tab end ----*/}
            </div>
          </div>
        </div>
        {/* --------------- Bottom section body ends here --------------- */}
      </section>
    </>
  );
};

export default GroupDetailsBottomSection;
