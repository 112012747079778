import React from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// import images
import sponsor1 from "assets/EventsAssets/images/sponsor1.png";
import sponsor2 from "assets/EventsAssets/images/sponsor2.png";
import sponsor3 from "assets/EventsAssets/images/sponsor3.png";

const About = () => {
  // responsive property for carousel
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 590, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="exprt_overviewOuter">
      {/* ----- slide item section start ----- */}
      <div className="course_detailspnl">
        <div className="event_catagoryslide_Outer">
          <div className="evenmt_catagory_slider">
            <Carousel ssr showDots responsive={responsive}>
              <div className="catagory_slideItem mb-4">
                <div className="catagory_slideInnr">
                  <div className="catagory_slideHdng">
                    <h3>Catagory</h3>
                  </div>
                  <div className="experts_skill">
                    <ul>
                      <li>
                        <Link to="#">Tech Event</Link>
                      </li>
                      <li>
                        <Link to="#">Latest Events</Link>
                      </li>
                      <li>
                        <Link to="#">Digital</Link>
                      </li>
                      <li>
                        <Link to="#">OS Events</Link>
                      </li>
                      <li>
                        <Link to="#">Highest Rated</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="catagory_slideItem mb-4">
                <div className="catagory_slideInnr">
                  <div className="catagory_slideHdng">
                    <h3>Group</h3>
                  </div>
                  <div className="experts_skill">
                    <ul>
                      <li>
                        <Link to="#">GameBoy</Link>
                      </li>
                      <li>
                        <Link to="#">TechGuru</Link>
                      </li>
                      <li>
                        <Link to="#">WhatisTech</Link>
                      </li>
                      <li>
                        <Link to="#">Machaionly</Link>
                      </li>
                      <li>
                        <Link to="#">Linus Tech Tips</Link>
                      </li>
                      <li>
                        <Link to="#">TG Game</Link>
                      </li>
                      <li>
                        <Link to="#">CarryMinati</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="catagory_slideItem mb-4">
                <div className="catagory_slideInnr">
                  <div className="catagory_slideHdng">
                    <h3>Search</h3>
                  </div>
                  <div className="experts_skill">
                    <ul>
                      <li>
                        <Link to="#">Tech Event</Link>
                      </li>
                      <li>
                        <Link to="#">Latest Events</Link>
                      </li>
                      <li>
                        <Link to="#">Digital</Link>
                      </li>
                      <li>
                        <Link to="#">OS Events</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="catagory_slideItem mb-4">
                <div className="catagory_slideInnr">
                  <div className="catagory_slideHdng">
                    <h3>Offer</h3>
                  </div>
                  <div className="experts_skill">
                    <ul>
                      <li>
                        <Link to="#">Tech Event</Link>
                      </li>
                      <li>
                        <Link to="#">Latest Events</Link>
                      </li>
                      <li>
                        <Link to="#">Digital</Link>
                      </li>
                      <li>
                        <Link to="#">OS Events</Link>
                      </li>
                      <li>
                        <Link to="#">Highest Rated</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="catagory_slideItem mb-4">
                <div className="catagory_slideInnr">
                  <div className="catagory_slideHdng">
                    <h3>Offer</h3>
                  </div>
                  <div className="experts_skill">
                    <ul>
                      <li>
                        <Link to="#">Tech Event</Link>
                      </li>
                      <li>
                        <Link to="#">Latest Events</Link>
                      </li>
                      <li>
                        <Link to="#">Digital</Link>
                      </li>
                      <li>
                        <Link to="#">OS Events</Link>
                      </li>
                      <li>
                        <Link to="#">Highest Rated</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="catagory_slideItem mb-4">
                <div className="catagory_slideInnr">
                  <div className="catagory_slideHdng">
                    <h3>Offer</h3>
                  </div>
                  <div className="experts_skill">
                    <ul>
                      <li>
                        <Link to="#">Tech Event</Link>
                      </li>
                      <li>
                        <Link to="#">Latest Events</Link>
                      </li>
                      <li>
                        <Link to="#">Digital</Link>
                      </li>
                      <li>
                        <Link to="#">OS Events</Link>
                      </li>
                      <li>
                        <Link to="#">Highest Rated</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
      {/* ----- end of slide item section ----- */}

      {/* ----- event summary start ----- */}
      <div className="course_detailspnl">
        <div className="contnt_hdng">
          <h4>Summary</h4>
        </div>
        <div className="results_para">
          <p>
            I love coding and problem solving. I have 3+ years of experience in
            the web development sector. Love working on both server and client
            side code. I like to get to know my clients closely to facilitate
            better communication.
          </p>
          <p>
            Superb developer, he goes over and above in most cases to make sure
            the job is done and done well. He has the right skill set for a
            developer and more importantly he aligns himself with the vision of
            the product and provides great insights on how to build a product
            the right way. Will look forward to work with him on more projects.
          </p>
        </div>
      </div>
      {/* ----- event summary end ----- */}

      {/* ----- event sponsor start ----- */}
      <div className="course_detailspnl">
        <div className="contnt_hdng">
          <h4>Sponsors</h4>
        </div>
        <div className="sponsor_cmpny">
          <ul>
            <li>
              <Link to="#">
                <i>
                  <img src={sponsor1} alt="" />
                </i>
                <span>Microsoft</span>
              </Link>
            </li>
            <li>
              <Link to="#">
                <i>
                  <img src={sponsor2} alt="" />
                </i>
                <span>Dropbox</span>
              </Link>
            </li>
            <li>
              <Link to="#">
                <i>
                  <img src={sponsor3} alt="" />
                </i>
                <span>Google</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* ----- event sponsor end ----- */}

      {/* ----- event documents start ----- */}
      <div className="course_detailspnl">
        <div className="contnt_hdng">
          <h4>Documents</h4>
        </div>
        <div className="upload_area">
          <div className="upload_item">
            <div className="uploaded_innrBx">
              <div className="upload_icon">
                <img src="images/pdf.svg" alt="" />
              </div>
              <div className="upload_txt">
                <h4>MyResume.pdf</h4>
                <h5>1.5mb</h5>
              </div>
            </div>
          </div>
          <div className="upload_item">
            <div className="uploaded_innrBx">
              <div className="upload_icon">
                <img src="images/pdf.svg" alt="" />
              </div>
              <div className="upload_txt">
                <h4>MyResume.pdf</h4>
                <h5>1.5mb</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ----- event documents end ----- */}
    </div>
  );
};

export default About;
