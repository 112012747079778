/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";

// import images here
import banner from "assets/CourseLearningAssets/images/learning-bnr.jpg";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <>
      <section
        className="graph_bnr_area bg_area"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="container">
          <div className="bnr_graph_textPnl">
            <h1>
              <span>{t("My learning")} </span> {t("Courses")}
            </h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
