/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import Gateway for API fetch call
import {
  getData,
  postData,
  postActivityFormData,
  uploadMultipleFile,
  postFormdata,
} from "utils/Gateway";

// importing images here
import PDF from "assets/ActivityAssests/svg/pdf.svg";
import XLS from "assets/ActivityAssests/svg/xls.svg";
import DOCS from "assets/ActivityAssests/svg/docs.svg";
import userBlank from "assets/ConversationAssests/images/default-user.png";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

// importing context API here
import { GlobalIndraInfo } from "App";

const Forum = () => {
  const token = localStorage.getItem("token");

  // Getting the course ID created in Step 1
  const params = useParams();

  // get info from context
  const { subscriberStatus, joinIsLoading, joinNowConfirm } =
    useContext(GlobalIndraInfo);

  // console.log("subscriber status------>", subscriberStatus);

  const [title, setTitle] = useState("");
  const [query, setQuery] = useState("");
  const [isGlobal, setIsGlobal] = useState(0);
  const [page, setPage] = useState(0);
  //initial for validation
  const [titleError, setTitleError] = useState(false);
  const [queryError, setQueryError] = useState(false);
  const [queryLists, setQueryLists] = useState([]);
  // initial state for uploaded multiple files
  const [media, setMedia] = useState([]);

  //initial state for uploaded image id
  const [fileIds, setFileIds] = useState([]);
  // initial state for posting load
  const [isSubmiting, setIsSubmiting] = useState(false);

  //initial for upload bigger file
  const [fileIsBig, setFileIsBig] = useState(false);

  // initial for uploading
  const [isUploading, setIsUploading] = useState(false);
  const [forumReplyMessage, setForumReplyMessage] = useState("");
  //initial for empty message
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [joinCourseLoading, setJoinCourseLoading] = useState(false);

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const spinCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // function for multiple file upload
  const multipleFileUploadHandler = async (e) => {
    setFileIsBig(false);

    const selectedFIles = []; // initial empty values for file array

    const targetFiles = e.target.files; // store file information

    let filesLength = targetFiles.length;

    let proceed = false;

    if (filesLength > 0) {
      for (let i = 0; i < filesLength; i++) {
        let f = targetFiles[i];

        if (f.size <= 12500000) {
          proceed = true;
        } else {
          proceed = false;
        }
      }

      // console.log(proceed);

      if (proceed) {
        setIsUploading(true);
        let requestURL =
          url.API_BaseUrl + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

        try {
          const response = await uploadMultipleFile(requestURL, targetFiles);
          //console.log("response file multiple---->", response);

          if (response.status == true) {
            let fileResponseId = response.data.map((data) => {
              return data.id;
            });
            setFileIds(fileResponseId);
          } else {
            console.log("Error in file response");
          }

          const targetFilesObject = [...targetFiles]; // store array of those files

          // check condition whether file array is empty or not
          if (media.length == 0) {
            targetFilesObject.map((file) => {
              return selectedFIles.push(file);
            });

            setMedia(selectedFIles); // changing state of files array
          } else {
            targetFilesObject.map((file) => {
              return selectedFIles.push(file);
            });

            // changing state of files array with previous file array values
            setMedia((media) => [...media, ...selectedFIles]);
          }
          setIsUploading(false);
        } catch (error) {
          console.log("Error in file upload----->", error);
        }
      } else {
        // console.log("Image must be lower then 10mb");
        setFileIsBig(true);
      }
    } else {
      return;
    }
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("forumFiles");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  // reset function for reset all fields
  const resetQuery = () => {
    setTitle("");
    setQuery("");
    resetFile();
    setMedia([]);
  };

  //---------------------- Function to submit the question --------------------
  const saveQuery = async () => {
    if (title == "" || query == "") {
      if (title == "") {
        setTitleError(true);
      } else {
        setTitleError(false);
      }
      if (query == "") {
        setQueryError(true);
      } else {
        setQueryError(false);
      }
    } else {
      setTitleError(false);
      setQueryError(false);
      setIsSubmiting(true);
      let requestURL =
        url.API_BaseUrl + url.API_ACTIVITY_CREATE_POST + `?token=${token}`;

      let queryData = {
        isglobal: isGlobal,
        course: params.id,
        title,
        message: query,
        media: fileIds,
      };

      // console.log("question data before post------>", questionData);

      try {
        const response = await postFormdata(requestURL, queryData);

        if (response.status == true) {
        } else {
          console.log("response error in ask question");
        }
        resetQuery();
        setIsSubmiting(false);
        getQuery(params.id);
      } catch (error) {
        console.log("details page error", error);
      }
    }
  };

  // function for get all forum lists
  const getQuery = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_LESSON_FORUM_LIST +
      `?token=${token}` +
      `&mod=course` +
      `&com=${id}`;

    // console.log(requestURL);
    try {
      const response = await getData(requestURL);
      console.log("response in forum---->", response);

      if (response.status && response.data.length > 0) {
        setQueryLists(response.data);
      }
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
    }
    setIsLoading(false);
  };

  // function for display question panel
  const toggleComment = (index) => {
    var x = document.getElementById(`forumComments${index}`);
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
    setForumReplyMessage("");
  };

  // function for like
  const forumLikeHandler = async (postId) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_LIKE_POST +
      `?token=${token}` +
      `&postid=${postId}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        getQuery(params.id);
      }
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
    }
  };

  // function for parent like
  const likeCommentHandler = async (commentId) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_LIKE_POST_COMMENT +
      `?token=${token}` +
      `&commentid=${commentId}`;

    try {
      const response = await getData(requestURL);
      if (response.status) {
        getQuery(params.id);
      }
    } catch (error) {
      console.log("Error in like API ----->", error);
    }
  };

  // function for post comment
  const replyToForum = async (postId, index) => {
    if (forumReplyMessage == "") {
      // alert("Comment message shouldn`t be empty");
      setShowEmptyMessage(true);
      return;
    } else {
      setShowEmptyMessage(false);
      let commentUrl =
        url.API_BaseUrl +
        url.API_ACTIVITY_POST_ANSWER +
        `${postId}?token=${token}`;

      let messageData = {
        message: forumReplyMessage,
      };

      setForumReplyMessage("");

      try {
        const response = await postData(commentUrl, messageData);
        //console.log("response answer to question details----->", response);

        if (response.status) {
          getQuery(params.id);
          toggleComment(index);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  //function for join course by clicking join button
  const joinHandler = () => {
    joinNowConfirm(true);
    getQuery(params.id);
  };

  // use effect to display glow effect by clicking join button
  useEffect(() => {
    if (joinIsLoading) {
      setJoinCourseLoading(true);
    } else {
      setJoinCourseLoading(false);
    }
  }, [joinIsLoading]);

  useEffect(() => {
    getQuery(params.id);
  }, []);

  return (
    <>
      {/* --------------- Forum Body starts here --------------- */}
      <div className="tab-pane show active fade" id="forum">
        {isLoading ? (
          <div className="course_detailspnl placeholder-glow">
            <div className="contnt_hdng tab_Hdng">
              <span
                className="placeholder rounded-3 col-2"
                style={{ height: "21px" }}
              ></span>
            </div>
            <span
              className="placeholder rounded-3 col-12"
              style={{ height: "117px" }}
            ></span>
            <div className="rply_bxArea">
              <div className="reply_forumBx forum_grayBx">
                <div className="forum_cmntsRow">
                  <div className="share_btn forum_shreBtn">
                    <Link to="#">
                      <span className="material-icons-outlined">share</span>
                    </Link>
                  </div>
                  <div className="team_userOuter forum_usrOuter">
                    <div className="team_usr_pic">
                      <span
                        className="placeholder rounded-circle"
                        style={{ width: "50px", height: "50px" }}
                      ></span>
                    </div>
                    <div className="team_usrTxt flex-fill">
                      <h3>
                        <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                      </h3>
                      <h5>
                        <span className="placeholder placeholder-sm rounded-3 col-1"></span>
                      </h5>
                      <div className="forum_para">
                        <span className="placeholder placeholder-sm rounded-3 col-12 mb-3"></span>
                        <span className="placeholder placeholder-sm rounded-3 col-8 mb-3"></span>
                        <span className="placeholder placeholder-sm rounded-3 col-4 mb-3"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {subscriberStatus ? (
              <div className="course_detailspnl">
                <div className="contnt_hdng tab_Hdng">
                  <h4>{t("Forum")}</h4>
                </div>

                <div className="activity_tagsRow">
                  <div className="reply_fill">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Enter Title")}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
                {titleError && (
                  <p className="text-danger mb-2">
                    * {t("Please Fill up Title")}
                  </p>
                )}

                {/* ---------- Comments Textbox section starts here ---------- */}
                <div className="form_commnts_area">
                  <textarea
                    className="form-control"
                    placeholder={t("Write your query here")}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  ></textarea>
                </div>
                {queryError && (
                  <p className="text-danger mb-3">
                    * {t("Please Fill up Details")}
                  </p>
                )}

                <div className="activity_tagsRow d-flex mt-4">
                  {/* ----- this style is for temporary purpose please change it in CSS   ----- */}
                  {media.map((files, index) => {
                    return (
                      <div key={index} className="uploaded-media">
                        {files.type == "image/jpeg" ||
                        files.type == "image/png" ? (
                          <img
                            src={URL.createObjectURL(files)}
                            alt="ImageFile"
                            className="rounded"
                            height="150"
                            width="150"
                          />
                        ) : null}
                        {files.type == "text/plain" ||
                        files.type ==
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                          <img
                            src={DOCS}
                            alt="docs"
                            className="rounded"
                            height="150"
                            width="150"
                          />
                        ) : null}
                        {files.type ==
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                        files.type == "application/msword" ? (
                          <img
                            src={DOCS}
                            alt="docs"
                            className="rounded"
                            height="150"
                            width="150"
                          />
                        ) : null}
                        {files.type == "application/pdf" ? (
                          <img
                            src={PDF}
                            alt="docs"
                            className="rounded"
                            height="150"
                            width="150"
                          />
                        ) : null}
                        {files.type ==
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                        files.type == "application/vnd.ms-excel" ? (
                          <img
                            src={XLS}
                            alt="docs"
                            className="rounded"
                            height="150"
                            width="150"
                          />
                        ) : null}
                        <p className="text-center">
                          <span>{files.name}</span>
                        </p>
                      </div>
                    );
                  })}
                </div>
                <div className="activity_tagsRow">
                  {/* ----- loading area for file uploading ----- */}
                  {isUploading && (
                    <div
                      className="d-flex align-items-center"
                      style={{ backgroundColor: "#044954" }}
                    >
                      <h6 className="text-white fw-bold">
                        {t("Uploading Files")}
                      </h6>
                      {spinCount.map((item) => {
                        return (
                          <div
                            key={item}
                            className="spinner-grow mx-1 text-white"
                            style={{ height: "6px", width: "6px" }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* ----- loading area for file uploading end ----- */}
                </div>
                {/* ---- file preview section end ---- */}
                {fileIsBig ? (
                  <div className="activity_tagsRow">
                    <h6 className="text-white bg-danger">
                      * {t("Uploaded File Size must be lower than 10 mb")}
                    </h6>
                  </div>
                ) : null}

                {/* ----- file upload ------ */}
                <div className="activity_tagsRow">
                  {/* <h4>{t("Attachments")}</h4> */}
                  <div className="reply_fill">
                    <input
                      type="file"
                      className="form-control"
                      id="forumFiles"
                      onChange={multipleFileUploadHandler}
                      multiple
                    />
                  </div>
                </div>

                {isSubmiting && (
                  <div className="rounded mt-2">
                    <div className="alert alert-info">
                      {t("Submiting")}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {t("Your Question")}{" "}
                      </span>
                      {t("please wait")} ....
                    </div>
                  </div>
                )}

                <div className="question_btnArea">
                  <button
                    type="submit"
                    className="basic_btn"
                    onClick={saveQuery}
                  >
                    {t("Submit your query")}
                  </button>
                </div>

                {/* ---------- Comments Textbox section ends here ---------- */}
                {/* ---------- Replies section starts here ---------- */}
                <div className="rply_bxArea">
                  {queryLists && queryLists.length > 0 ? (
                    <div className="reply_forumBx forum_grayBx">
                      {queryLists.map((queries, index) => {
                        console.log(queryLists);
                        return (
                          <div className="forum_cmntsRow" key={index}>
                            <div className="share_btn forum_shreBtn">
                              <Link to="#">
                                <span className="material-icons-outlined">
                                  share
                                </span>
                              </Link>
                            </div>
                            <div className="team_userOuter forum_usrOuter">
                              <div className="team_usr_pic">
                                <img
                                  src={
                                    queries.moderator.thumbnail
                                      ? queries.moderator.thumbnail
                                      : userBlank
                                  }
                                  alt="user"
                                />
                              </div>
                              <div className="team_usrTxt">
                                {queries.moderator.name ? (
                                  <h3>{queries.moderator.name}</h3>
                                ) : null}
                                <h5>{queries.created} 17:31</h5>
                                <div className="forum_para">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: queries.description,
                                    }}
                                  ></p>
                                </div>
                                {queries.media && queries.media.length > 0 ? (
                                  <div className="forumpic_outer">
                                    {queries.media.map((mediaSrc, index2) => {
                                      return (
                                        <div className="forum_pic" key={index2}>
                                          <img src={mediaSrc} alt="image" />
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : null}

                                {/* ----- like comment area start --- */}
                                <div className="form_share">
                                  <Link
                                    to="#"
                                    role="button"
                                    onClick={() => forumLikeHandler(queries.id)}
                                  >
                                    <i className="material-icons-outlined">
                                      thumb_up
                                    </i>
                                    <em>{t("Likes")}</em>
                                    <span>{queries.likes}</span>
                                  </Link>
                                  <Link
                                    to="#"
                                    className="recmnts_btn"
                                    role="button"
                                    onClick={() => {
                                      toggleComment(index);
                                    }}
                                  >
                                    <i className="material-icons-outlined">
                                      chat
                                    </i>
                                    <em>{t("Comment")}</em>
                                    <span>{queries.comments.count}</span>
                                  </Link>
                                </div>
                                {/* ----- like comment area end --- */}

                                {/* ---- below comments area ---- */}
                                <div
                                  className="recomments_area"
                                  id={`forumComments${index}`}
                                >
                                  <div className="commnts_send_innr">
                                    <div className="rating_hdng">
                                      <h5>Comment Now</h5>
                                    </div>
                                    <div className="commnts_sendArea">
                                      <textarea
                                        className="form-control"
                                        placeholder="Write your query here"
                                        value={forumReplyMessage}
                                        onChange={(e) =>
                                          setForumReplyMessage(e.target.value)
                                        }
                                      ></textarea>
                                      <div className="furmcmnt_btn">
                                        {/* <button className="cmnt_btn">
                                    <span className="material-icons-outlined">
                                      image
                                    </span>
                                  </button> */}
                                        <button
                                          className="cmnt_btn"
                                          onClick={() =>
                                            replyToForum(queries.id, index)
                                          }
                                        >
                                          <span className="material-icons-outlined">
                                            send
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                    {showEmptyMessage && (
                                      <p className="text-danger mt-1 ">
                                        * Please add a comment
                                      </p>
                                    )}
                                  </div>
                                  {/* ----- comments list section ---- */}
                                  {queries.comments.records.map(
                                    (commentElements, index2) => {
                                      return (
                                        <div
                                          className="recomments_row"
                                          key={index2}
                                        >
                                          <div className="cmnts_user">
                                            <img
                                              src={commentElements.senderimage}
                                              alt=""
                                            />
                                          </div>
                                          <div className="cmnts_user_textpnl">
                                            <h3>{commentElements.sender}</h3>
                                            <h5>20/07/2021 17:31</h5>
                                            <div className="forum_para">
                                              <p>{commentElements.message}</p>
                                            </div>
                                            <div className="form_share">
                                              <Link
                                                to="#"
                                                role="button"
                                                onClick={() =>
                                                  likeCommentHandler(
                                                    commentElements.id
                                                  )
                                                }
                                              >
                                                <i className="material-icons-outlined">
                                                  thumb_up
                                                </i>
                                                <em>{t("Likes")}</em>
                                                <span>
                                                  {commentElements.likes}
                                                </span>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}

                                  {/* ----- comments list section end ---- */}
                                </div>
                                {/* ---- end of below comments ---- */}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
                {/* ---------- Replies section ends here ---------- */}
              </div>
            ) : (
              <div className="course_detailspnl">
                <div className="contnt_hdng tab_Hdng">
                  <h4>forum</h4>
                </div>
                <div className="empty_lessonsArea">
                  <div className="empty_lessonpic">
                    <img src={blankLesson} alt="blanklesson" />
                  </div>
                  <div className="empty_lessons_text">
                    <h2>Oppppss....!!</h2>
                    <p>
                      To view this content, you need to join the course.
                      Moderator approval may required too
                    </p>
                    <div className="join_btn">
                      <Link
                        to="#"
                        className="basic_btn ylw_btn"
                        onClick={joinHandler}
                      >
                        Join Now
                        {joinCourseLoading && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {/* --------------- Forum Body ends here --------------- */}
    </>
  );
};

export default Forum;
