/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

const AssignmentAcceptModal = (props) => {
  return (
    <>
      <div className="question_modal_bx">
        <div
          className="modal fade"
          id="assignmentaccept_success_modal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="sucess_question_icon">
                  <span className="material-icons-outlined">task_alt</span>
                </div>
                <div className="question_success_hdng">
                  <h2>Your accpetance has reached us successfully</h2>
                  <h5>Soon we will send you a mail be ready</h5>
                  <div className="step_list">
                    <ul>
                      <li className="active">
                        <span>
                          <i className="material-icons-outlined"> done</i>
                        </span>
                        <em>Start Task</em>
                      </li>
                      <li className={`${props.isSubmit ? "active" : ""}`}>
                        <span>
                          <i className="material-icons-outlined"> done</i>
                        </span>
                        <em>Submit on Completion</em>
                      </li>
                      <li>
                        <span>
                          <i className="material-icons-outlined"> done</i>
                        </span>
                        <em>Reviewed by Instructor</em>
                      </li>
                      <li>
                        <span>
                          <i className="material-icons-outlined"> done</i>
                        </span>
                        <em>Get score to get certified</em>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="question_closeBtn">
                  <Link
                    to="#"
                    data-bs-dismiss="modal"
                    className="basic_btn ylw_btn"
                    onClick={() => {
                      props.resetSubmit();
                    }}
                  >
                    Close
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentAcceptModal;
