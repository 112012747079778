/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postFormdata } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ------------------------------------------------------------------------*/

const ContactModal = (props) => {
  // getting logged in user's token here
  const token = localStorage.getItem("token");
  const params = useParams();

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [selectedReason, setSelectedReason] = useState();
  const [reason, setReason] = useState("");
  const [selectedHistory, setSelectedHistory] = useState();
  const [champHistory, setChampHistory] = useState("");

  // custom style for select
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #D7D7D7",
      color: "#495057",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "18px",
      padding: "0px",
      margin: "2px",
      fontFamily: "sans-serif",
      border: "none",
      backgroundColor: "#fff",
      height: 50,
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#495057",
        padding: "0px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#495057" };
      return Object.assign(base, changes);
    },
  };

  const contactOptions = [
    { label: "Business Networking", value: "Business Networking" },
    { label: "Relational Connect", value: "Relational Connect" },
    { label: "Promotional", value: "Promotional" },
  ];

  const knowOptions = [
    { label: "Friends", value: "Friends" },
    { label: "Family", value: "Family" },
    { label: "Facebook", value: "Facebook" },
    { label: "Linkedin", value: "Linkedin" },
    { label: "Other Source", value: "Other Source" },
  ];

  //function for select contact
  const selectContactHandler = (val) => {
    setSelectedReason();
    setReason(val.value);
  };

  //function for select reason
  const selectHistoryHandler = (val) => {
    setSelectedHistory();
    setChampHistory(val.value);
  };

  //function for send champ connect modal
  const connectChampionHandler = async () => {
    let requestURL =
      url.API_BaseUrl +
      url.API_CHAMPION_CONTACT +
      `?token=${token}` +
      `&uid=${params.id}`;

    let connectData = {
      reason: reason,
      relation: champHistory,
      comment: message,
    };

    // console.log("Tags url -----> ", requestURL);

    try {
      const response = await postFormdata(requestURL, connectData);

      console.log("Received Tags response is ------> ", response);

      if (response.status) {
        let contactModal = document.querySelector("#contact_owner");
        let modal = bootstrap.Modal.getInstance(contactModal);
        modal.hide();
        props.getChampionsData(params.id);
      } else {
        //console.log("Some error occurred!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="conatct_modal">
      <div className="modal fade" id="contact_owner">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="owner_hdngOuter">
                {props.championData ? (
                  <h5>
                    Contact
                    <b>
                      {props.championData.name} {props.championData.surname}
                    </b>
                    <span className="online"></span>
                  </h5>
                ) : null}
                <button
                  type="button"
                  className="close ylw_closeBtn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="material-icons-outlined">close</i>
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="owner_detailsOuter">
                <div className="input_fill jobinput_fill">
                  <label>Reason of contact</label>
                  <div className="model_selectBx">
                    <Select
                      className="select2"
                      placeholder="Select Reason"
                      options={contactOptions}
                      classNamePrefix="select2-selection"
                      value={selectedReason}
                      onChange={(val) => selectContactHandler(val)}
                    />
                  </div>
                </div>
                <div className="input_fill jobinput_fill">
                  <label>How you know me?</label>
                  <div className="model_selectBx">
                    <Select
                      className="select2"
                      placeholder="Select Relation"
                      options={knowOptions}
                      classNamePrefix="select2-selection"
                      value={selectedHistory}
                      onChange={(val) => selectHistoryHandler(val)}
                    />
                  </div>
                </div>
                <div className="input_fill jobinput_fill description_fill">
                  <textarea
                    className="form-control"
                    placeholder="Message of contact"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div className="input_fill jobinput_fill">
                  <button
                    type="button"
                    className="basic_btn ylw_btn"
                    onClick={connectChampionHandler}
                  >
                    Sent
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
