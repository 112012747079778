import React, { useState } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./CalendarUtils/event-utils";
import moment from "moment";
import "moment-timezone";

const Meeting = () => {
  // Initial useState to hold the calendar event
  const [calendarState, setCalendarState] = useState({
    weekendsVisible: true,
    currentEvents: [],
  });

  const handleDateSelect = (selectInfo) => {
    console.log(
      "handleDateSelect start date --------> ",
      moment(selectInfo.startStr).format("DD/MM/YYYY")
    );

    console.log(
      "handleDateSelect start time --------> ",
      moment(selectInfo.startStr).format("HH:mm")
    );

    console.log(
      "handleDateSelect end date --------> ",
      moment(selectInfo.endStr).format("DD/MM/YYYY")
    );

    console.log(
      "handleDateSelect end time --------> ",
      moment(selectInfo.endStr).format("HH:mm")
    );

    // console.log("handleDateSelect --------> ", selectInfo);
    let title = prompt("Please enter a new title for your event");
    let calendarApi = selectInfo.view.calendar;

    console.log("handleDateSelect title --------> ", title);

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  };

  // Function to remove the state from the event from calendar
  const handleEventClick = (clickInfo) => {
    // console.log("handleEventClick -------> ", clickInfo);
    if (window.confirm("Are you sure to delete the Event ?")) {
      clickInfo.event.remove();
    }
  };

  // Function to save the created event in useState
  const handleEvents = (events) => {
    // console.log("handleEvents -------> ", events);
    // setCalendarState({
    //   ...calendarState,
    //   currentEvents: events,
    // });
    // console.log("handleEvents useState -------> ", calendarState);
  };

  // Function to display the created event in the calendar
  function renderEventContent(eventInfo) {
    // console.log("renderEventContent -------> ", eventInfo.event.title);
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={calendarState.weekendsVisible}
        initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
        select={handleDateSelect}
        eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        eventsSet={handleEvents} // called after events are initialized/added/changed/removed
        /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
      />
    </>
  );
};

export default Meeting;
