import React from "react";
import { Link } from "react-router-dom";
import SimilarGroups from "../common/SimilarGroups";

const About = () => {
  return (
    <div className="tab-pane show active fade" id="evnt_about">
      <div className="training_outer">
        <div className="row">
          <div className="col-lg-8">
            <div className="exprt_overviewOuter">
              <div className="course_detailspnl">
                <div className="event_catagoryslide_Outer">
                  <div className="group_catagory_slider">
                    <div className="catagory_slideItem">
                      <div className="catagory_slideInnr">
                        <div className="catagory_slideHdng">
                          <h3>Catagory</h3>
                        </div>
                        <div className="experts_skill">
                          <ul>
                            <li>
                              <Link to="#">Tech Event</Link>
                            </li>
                            <li>
                              <Link to="#">Latest Events</Link>
                            </li>
                            <li>
                              <Link to="#">Digital</Link>
                            </li>
                            <li>
                              <Link to="#">OS Events</Link>
                            </li>
                            <li>
                              <Link to="#">Highest Rated</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="catagory_slideItem">
                      <div className="catagory_slideInnr">
                        <div className="catagory_slideHdng">
                          <h3>Group</h3>
                        </div>
                        <div className="experts_skill">
                          <ul>
                            <li>
                              <Link to="#">GameBoy</Link>
                            </li>
                            <li>
                              <Link to="#">TechGuru</Link>
                            </li>
                            <li>
                              <Link to="#">WhatisTech</Link>
                            </li>
                            <li>
                              <Link to="#">Machaionly</Link>
                            </li>
                            <li>
                              <Link to="#">Linus Tech Tips</Link>
                            </li>
                            <li>
                              <Link to="#">TG Game</Link>
                            </li>
                            <li>
                              <Link to="#">CarryMinati</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="course_detailspnl">
                <div className="contnt_hdng">
                  <h4>Summary</h4>
                </div>
                <div className="results_para">
                  <p>
                    I love coding and problem solving. I have 3+ years of
                    experience in the web development sector. Love working on
                    both server and client side code. I like to get to know my
                    clients closely to facilitate better communication.Superb
                    developer, he goes over and above in most cases to make sure
                    the job is done and done well. He has the right skill set
                    for a developer and more importantly he aligns himself with
                    the vision of the product and provides great insights on how
                    to build a product the right way. Will look forward to work
                    with him on more projects.
                  </p>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable. If you are going to use a
                    passage of Lorem Ipsum, you need to be sure there isn't
                    anything embarrassing hidden in the middle of text. All the
                    Lorem Ipsum generators on the Internet tend to repeat
                    predefined chunks as necessary, making this the first true
                    generator on the Internet. It uses a dictionary of over 200
                    Latin words, combined with a handful of model sentence
                    structures, to generate Lorem Ipsum which looks reasonable.
                    The generated Lorem Ipsum is therefore always free from
                    repetition, injected humour, or non-characteristic words
                    etc.
                  </p>
                </div>
              </div>
              <div className="course_detailspnl">
                <div className="contnt_hdng">
                  <h4>Documents</h4>
                </div>
                <div className="upload_area">
                  <div className="upload_item">
                    <div className="uploaded_innrBx">
                      <div className="upload_icon">
                        <img src="images/pdf.svg" alt="" />
                      </div>
                      <div className="upload_txt">
                        <h4>MyResume.pdf</h4>
                        <h5>1.5mb</h5>
                      </div>
                    </div>
                  </div>
                  <div className="upload_item">
                    <div className="uploaded_innrBx">
                      <div className="upload_icon">
                        <img src="images/pdf.svg" alt="" />
                      </div>
                      <div className="upload_txt">
                        <h4>MyResume.pdf</h4>
                        <h5>1.5mb</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="course_detailspnl">
                <div className="contnt_hdng">
                  <h4>Sponsors</h4>
                </div>
                <div className="sponsor_cmpny">
                  <ul>
                    <li>
                      <Link to="#">
                        <i>
                          <img src="images/sponsor1.png" alt="" />
                        </i>
                        <span>Microsoft</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i>
                          <img src="images/sponsor2.png" alt="" />
                        </i>
                        <span>Dropbox</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i>
                          <img src="images/sponsor3.png" alt="" />
                        </i>
                        <span>Google</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <SimilarGroups />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
