import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const HomeChampionLists = () => {
  const champResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="champions_sec pd_30">
      <div className="container">
        <div className="slideHdng_outer">
          <div className="page_hdng">
            <h2>Best Champions</h2>
          </div>
          <div className="see_moreBtn">
            <Link to="#" className="border_btn ylwbrdr_btn">
              See All
            </Link>
          </div>
        </div>
        <div className="champions_outer slide_Outer">
          <div className="champions_slider slider_innr">
            <Carousel ssr showDots responsive={champResponsive}>
              <div className="slide_Item mb-4">
                <div className="champions_item">
                  <div className="champions_pic">
                    <img src="images/champions1.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="champions_txtOuter">
                      <div className="leftchampion_text">
                        <h3>Pierre Gagnaire</h3>
                        <h5>Sales Manager</h5>
                      </div>
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                        </Link>
                      </div>
                    </div>
                    <div className="details_btn">
                      <Link
                        to="champions_details.html"
                        className="basic_btn ylw_btn"
                      >
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="champions_item">
                  <div className="champions_pic">
                    <img src="images/champions2.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="champions_txtOuter">
                      <div className="leftchampion_text">
                        <h3>Pierre Gagnaire</h3>
                        <h5>Sales Manager</h5>
                      </div>
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                        </Link>
                      </div>
                    </div>
                    <div className="details_btn">
                      <Link
                        to="#"
                        //to="champions_details.html"
                        className="basic_btn ylw_btn"
                      >
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="champions_item">
                  <div className="champions_pic">
                    <img src="images/champions3.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="champions_txtOuter">
                      <div className="leftchampion_text">
                        <h3>Pierre Gagnaire</h3>
                        <h5>Sales Manager</h5>
                      </div>
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                        </Link>
                      </div>
                    </div>
                    <div className="details_btn">
                      <Link
                        to="champions_details.html"
                        className="basic_btn ylw_btn"
                      >
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="champions_item">
                  <div className="champions_pic">
                    <img src="images/champions4.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="champions_txtOuter">
                      <div className="leftchampion_text">
                        <h3>Pierre Gagnaire</h3>
                        <h5>Sales Manager</h5>
                      </div>
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                        </Link>
                      </div>
                    </div>
                    <div className="details_btn">
                      <Link
                        to="champions_details.html"
                        className="basic_btn ylw_btn"
                      >
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="champions_item">
                  <div className="champions_pic">
                    <img src="images/champions4.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="champions_txtOuter">
                      <div className="leftchampion_text">
                        <h3>Pierre Gagnaire</h3>
                        <h5>Sales Manager</h5>
                      </div>
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                        </Link>
                      </div>
                    </div>
                    <div className="details_btn">
                      <Link
                        to="#"
                        //to="champions_details.html"
                        className="basic_btn ylw_btn"
                      >
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="champions_item">
                  <div className="champions_pic">
                    <img src="images/champions4.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="champions_txtOuter">
                      <div className="leftchampion_text">
                        <h3>Pierre Gagnaire</h3>
                        <h5>Sales Manager</h5>
                      </div>
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                        </Link>
                      </div>
                    </div>
                    <div className="details_btn">
                      <Link
                        to="#"
                        //to="champions_details.html"
                        className="basic_btn ylw_btn"
                      >
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeChampionLists;
