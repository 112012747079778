import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// importing custom components here
import Header from "common/Header";
import BreadCrumb from "components/ChampionsDetailsComponents/common/BreadCrumb";
import ChampionsDetailsTopSection from "./ChampionsDetailsTopSection";
import ChampionDetailsBottomSection from "./ChampionDetailsBottomSection";
import Footer from "common/Footer";

const ChampionsDetails = () => {
  useEffect(() => {
    document.title = "Champions-Details";
  }, []);

  return (
    <>
      <Header />
      <BreadCrumb />
      <ChampionsDetailsTopSection />
      <ChampionDetailsBottomSection />
      <Footer />
    </>
  );
};

export default ChampionsDetails;
