import React, { useEffect } from "react";

//importing cutom components here
import Header from "common/Header";
import Banner from "components/GroupsComponents/Banner";
import TabArea from "common/tabarea/TabArea";
import GroupListArea from "components/GroupsComponents/GroupListArea";
import Footer from "common/Footer";

const Groups = () => {
  useEffect(() => {
    document.title = "Groups";
  }, []);

  return (
    <>
      <Header />
      <Banner />
      <TabArea module={"groups"} />
      <GroupListArea />
      <Footer />
    </>
  );
};

export default Groups;
