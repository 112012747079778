/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { GlobalIndraInfo } from "App";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, uploadFile } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Contents = (props) => {
  // accessing the user's token here
  const token = localStorage.getItem("token");

  // Getting the course ID created in Step 1
  const { courseId, lessonId, lessonTitle } = useContext(GlobalIndraInfo);

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // toast property
  const toastOption = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const [stepFourContentsTitle, setStepFourContentsTitle] = useState("");
  const [stepFourContentsDuration, setStepFourContentsDuration] = useState("");
  const [stepFourContentsInstructor, setStepFourContentsInstructor] = useState(
    []
  );
  const [stepFourContentsVideoId, setStepFourContentsVideoId] = useState("");
  const [stepFourContentsContent, setStepFourContentsContent] = useState("");

  const [showErrorParagraph, setShowErrorParagraph] = useState(false);

  // initial for uploaded video url
  const [uploadedVideo, setUploadedVideo] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [updateComplete, setUpdateComplete] = useState(false);
  const [instructorOptions, setInstructorOptions] = useState([]);
  const [unSupportWarning, setUnSupportWarning] = useState(false);

  /*---- CKEditor classic editor configuration----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      uploadUrl: "https://dev.lobees.com/web/uploads/reports/",
    },
    placeholder: "Type here...",
  };
  /*----- end of CKEditor classic editor part----- */

  /*---------- Instructor selection start -----------*/
  const [selectedInstructor, setSelectedInstructor] = useState();

  // custom style for category
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "5px",
      fontFamily: "sans-serif",
      border: "none",
      backgroundColor: "#F0F0F0",
      height: 50,
      borderRadius: "8px",
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8E8E",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  const instructorSelectionHandler = (val) => {
    let instructorVal = val.map((item) => {
      //console.log("map loop----->", item.value);
      return item.value;
    });

    //console.log("required skill------->", optionalSkill.length)
    if (instructorVal.length != 0) {
      setSelectedInstructor();
      setStepFourContentsInstructor(instructorVal); //set multiselect values in array
    }
  };

  // options for instructors
  // const instructorOptions = [
  //   { value: "1", label: "Sudip Banerjee" },
  //   { value: "2", label: "Rishav Singh" },
  //   { value: "3", label: "Koushik Sarkar" },
  // ];

  /*---------- Instructor selection end -----------*/

  // Function to upload the Video and display the preview
  const lessonVideoUpload = async (e) => {
    e.preventDefault();
    if (
      e.target.files[0].type == "video/mp4" ||
      e.target.files[0].type == "video/avi" ||
      e.target.files[0].type == "video/quicktime" ||
      e.target.files[0].type == "video/x-ms-wmv"
    ) {
      setUnSupportWarning(false);
      setIsUploading(true);
      let videoFile = e.target.files[0];

      let apiUrl = url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

      try {
        let response = await uploadFile(apiUrl, videoFile);
        console.log("response file upload ----> ", response);

        if (response.status) {
          // saving the uploaded video URL here
          setUploadedVideo(response.data.url);
          setStepFourContentsVideoId(response.data.id);

          // setup preview of video
          let preview = document.getElementById("Content_samplepreview");
          preview.setAttribute("src", `${response.data.url}`);
          setIsUploading(false);
        } else {
          console.log("Can't upload the video");
          setIsUploading(false);
        }
        setSelectedInstructor(null);
      } catch (error) {
        console.log("Error in uploading the file", error);
      }
    } else {
      setUnSupportWarning(true);
    }
  };

  // function to call API and submit lesson details
  const submitLessonHandler = async (e) => {
    e.preventDefault();
    if (
      stepFourContentsTitle == "" ||
      stepFourContentsDuration == "" ||
      stepFourContentsInstructor.length == 0 ||
      stepFourContentsDuration == ""
    ) {
      setUpdateComplete(false);
      setShowWarning(true);
      // setWarningMessage("Select instructor with lesson time");
    } else {
      setShowWarning(false);
      if (stepFourContentsContent == "") {
        setUpdateComplete(false);
        setShowErrorParagraph(true);
      } else {
        setUpdateComplete(false);

        let requestURL =
          url.API_BaseUrl +
          url.API_STEP_4_SAVE_LESSON_CONTENT +
          `?token=${token}` +
          `&cid=${courseId}` +
          `&lid=${props.lessonid}`;

        let stepFourContents = {
          title: stepFourContentsTitle,
          duration: stepFourContentsDuration,
          instructors: stepFourContentsInstructor,
          videoid: stepFourContentsVideoId,
          content: stepFourContentsContent,
        };
        try {
          const response = await postData(requestURL, stepFourContents);

          if (response.status) {
            console.log(
              "Step 4 content Modal Content Received Response -------->",
              response
            );

            let preview = document.getElementById("Content_samplepreview");
            preview.removeAttribute("src");
            preview.pause();
            preview.load();
            setUpdateComplete(true);
          } else {
            setUpdateComplete(false);
            console.log("Some error occurred while submitting the data!");
            let preview = document.getElementById("Content_samplepreview");
            preview.removeAttribute("src");
            preview.pause();
            preview.load();
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const viewLessonDetailsHandler = async (id) => {
    const requestURL =
      url.API_BaseUrl +
      url.API_VIEW_LESSON_DETAILS +
      `?token=${token}` +
      `&lid=${id}`;

    try {
      const response = await getData(requestURL);
      console.log("step 4 contents tab lesson details ----->", response);
      if (response.status) {
        setStepFourContentsDuration(response.data.duration);
        setStepFourContentsContent(response.data.content);

        // mapping the instructors here
        let instructorsArr = [];
        response.data.instructors.map((item) => {
          let instructorObject = {
            id: item.id,
            value: item.id,
            name: item.label,
            label: item.label,
            slug: item.label,
          };

          instructorsArr.push(instructorObject);
        });

        instructorSelectionHandler(instructorsArr);
        setSelectedInstructor(instructorsArr);

        if (response.data.video) {
          setTimeout(function () {
            let video = document.getElementById("Content_samplepreview");
            let videoSource = document.getElementById("sampleVideoUrl");
            videoSource.setAttribute("src", `${response.data.video}`);

            video.load();
          }, 2000);
        }
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ----------- useEffect function based on lesson title -----------
  useEffect(() => {
    if (props.lessontitle) {
      setStepFourContentsTitle(props.lessontitle);
    }
  }, [props.lessontitle]);

  useEffect(() => {
    if (props.membersInstructor.length > 0) {
      let arr = [];
      props.membersInstructor.map((item) => {
        let arrObj = {
          value: item.id,
          label: item.label,
        };
        arr.push(arrObj);
      });
      setInstructorOptions(arr);
    }
  }, [props.membersInstructor]);

  // ----------- useEffect function based on lesson id -----------
  useEffect(() => {
    if (lessonId != null) {
      viewLessonDetailsHandler(lessonId);
    }
  }, [lessonId]);

  useEffect(() => {
    if (props.resetAll) {
      setUpdateComplete(false);
    }
    props.afterClose();
  }, [props.resetAll]);

  return (
    <>
      <div className="inner_tab_pane" id="write-feedback">
        {/* -------------- Lesson name input start -------------- */}
        <div className="input_fill jobinput_fill">
          <div className="coursestep_hdng">
            <h3>{t("Lesson Name")}</h3>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Lesson Name"
            name="lesson"
            value={stepFourContentsTitle}
            onChange={(e) => setStepFourContentsTitle(e.target.value)}
          />
        </div>
        {/* -------------- Lesson name input end -------------- */}

        {/* -------------- Time (mins) input start -------------- */}
        <div className="ux_rightFill mb-3">
          <div className="input_fill jobinput_fill">
            <div className="coursestep_hdng">
              <h3>{t("Time (mins)")}</h3>
            </div>
            <input
              type="number"
              min={1}
              className="form-control"
              placeholder={t("Enter Lesson time (in minutes)")}
              value={
                stepFourContentsDuration != null ? stepFourContentsDuration : ""
              }
              onChange={(e) => setStepFourContentsDuration(e.target.value)}
            />
          </div>
        </div>
        {/* -------------- Time (mins) input end -------------- */}

        {/* ------------ Media Section starts here ----------- */}
        <div className="course_stepItem mt-2">
          <div className="coursestep_hdng">
            <h3>{t("Media Section")}</h3>
          </div>
          {/* {isUploading && (
            <div className="rounded">
              <div className="alert alert-info">
                {t("Uploading")}
                <span className="mx-1" style={{ fontWeight: "bold" }}>
                  Video
                </span>
                {t("please wait")} ....
              </div>
            </div>
          )} */}
          <div className="upload_vdoOuter">
            <div className="upload_area">
              <div className="upload_item">
                <div className="uploaded_innrBx uploaded_vdobx course_uploadBx">
                  <video
                    id="Content_samplepreview"
                    controls
                    height="155px"
                    width="155px"
                    fullscreen="true"
                  >
                    <source id="sampleVideoUrl" />
                  </video>
                  {isUploading && (
                    <div className="vdoplay_Btn">
                      <p className="text-white">{t("Uploading")}...</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="upload_item">
                <div className="upload_btn course_uploadBx">
                  <input
                    type="file"
                    className="file_uoload"
                    onChange={(e) => lessonVideoUpload(e)}
                  />
                  <div className="upload_icon">
                    <span className="material-icons-outlined">file_upload</span>
                  </div>
                  <div className="upload_txt">
                    <h4>{t("Upload")}</h4>
                    <h5>{t("Max 20mb")}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {unSupportWarning && (
            <p className="text-danger">* Unsupported File Format</p>
          )}
        </div>

        {/* ------------ Media Section ends here ----------- */}

        <div className="course_stepItem">
          {/* ------------ Section with Text Editor starts here ----------- */}
          <div className="coursestep_hdng">
            <h3>{t("Paragraph Section")}</h3>
          </div>
          <div className="text_editor">
            {showWarning && (
              <p className="text-danger ml-3 mt-2">
                * {t("Select instructor with lesson time")}
              </p>
            )}
            {/* -------------------- Text Editor starts here ------------------- */}
            <div className="text_msg">
              {showErrorParagraph && (
                <p className="text-danger mb-2">
                  * {t("Content or Subtitle is required")}
                </p>
              )}
              <CKEditor
                className="form-control"
                editor={ClassicEditor}
                config={editorConfig}
                data={stepFourContentsContent ? stepFourContentsContent : ""}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "250px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  let editContent = editor.getData();
                  //console.log(JSON.stringify(editContent));
                  setStepFourContentsContent(editContent);
                }}
              />

              {updateComplete && (
                // <p className="text-success mx-3">Updated Successfully</p>
                <div
                  className="alert alert-success alert-dismissible fade show mt-2"
                  role="alert"
                >
                  <strong>Success!</strong> All data submitted successfully.
                </div>
              )}
            </div>

            {/* -------------------- Text Editor ends here ------------------- */}
          </div>
          {/* ------------ Section with Text Editor ends here ----------- */}

          {/* --------- Instructor selector starts here --------- */}
          <div className="ux_leftFill mt-3">
            <div className="input_fill jobinput_fill">
              <div className="coursestep_hdng">
                <h3>{t("Select Instructor")}</h3>
              </div>
              <Select
                placeholder={t("Select Instructor")}
                options={instructorOptions}
                value={selectedInstructor}
                isMulti={true}
                onChange={(val, e) => {
                  console.log(e);
                  instructorSelectionHandler(val);
                }}
                classNamePrefix="select2-selection"
                styles={customSelectStyle}
              />
            </div>
          </div>
          {/* --------- Instructor selector ends here --------- */}

          {/* --------- Update button starts here --------- */}
          <div className="update_btn mt-2">
            <button
              type="submit"
              className="basic_btn"
              onClick={(e) => {
                submitLessonHandler(e);
              }}
            >
              {t("Update")}
            </button>
          </div>
          {/* --------- Update button ends here --------- */}
        </div>
      </div>
      {/*--- toast alert ----*/}
      {/* <ToastWaring warningMessage={warningMessage} showWarning={showWarning} /> */}
      <ToastContainer />
    </>
  );
};

export default Contents;
