import React from "react";
import { Link } from "react-router-dom";
import SimilarGroups from "../common/SimilarGroups";

const Events = () => {
  return (
    <div className="tab-pane fade show active" id="events">
      <div className="training_outer">
        <div className="row">
          <div className="col-lg-8">
            <div className="course_detailspnl">
              <div className="contnt_hdng tab_Hdng">
                <h4>Events</h4>
              </div>
              <div className="evntbx_outer">
                <div className="row">
                  <div className="col-md-6">
                    <div className="job_bxItem event_bxItem">
                      <div className="event_hdng">
                        <h3>DTDC Business Meeting</h3>
                        <div className="date_ratingOuter">
                          <h5>
                            <i className="material-icons-outlined">
                              event_note
                            </i>
                            <span>Saturday, 12 January 2022</span>
                          </h5>
                          <div className="team_rating star_color">
                            <h3>
                              <i className="material-icons-outlined">star</i>
                              <span>4.5</span>
                            </h3>
                            <h6>(32)</h6>
                          </div>
                        </div>
                      </div>
                      <div className="experts_skill">
                        <ul>
                          <li>
                            <Link to="#">Wedding</Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="material-icons-outlined">place</i>
                              Madrid, Spain
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="ambassadors_users event_user">
                        <ul>
                          <li>
                            <Link to="#">
                              <img src="images/expert1.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert2.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert3.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert4.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert5.png" alt="" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="details_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn jbdetails_btn"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="job_bxItem event_bxItem">
                      <div className="event_hdng">
                        <h3>DTDC Business Meeting</h3>
                        <div className="date_ratingOuter">
                          <h5>
                            <i className="material-icons-outlined">
                              event_note
                            </i>
                            <span>Saturday, 12 January 2022</span>
                          </h5>
                          <div className="team_rating star_color">
                            <h3>
                              <i className="material-icons-outlined">star</i>
                              <span>4.5</span>
                            </h3>
                            <h6>(32)</h6>
                          </div>
                        </div>
                      </div>
                      <div className="experts_skill">
                        <ul>
                          <li>
                            <Link to="#">Wedding</Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="material-icons-outlined">place</i>
                              Madrid, Spain
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="ambassadors_users event_user">
                        <ul>
                          <li>
                            <Link to="#">
                              <img src="images/expert1.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert2.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert3.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert4.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert5.png" alt="" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="details_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn jbdetails_btn"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="job_bxItem event_bxItem">
                      <div className="event_hdng">
                        <h3>DTDC Business Meeting</h3>
                        <div className="date_ratingOuter">
                          <h5>
                            <i className="material-icons-outlined">
                              event_note
                            </i>
                            <span>Saturday, 12 January 2022</span>
                          </h5>
                          <div className="team_rating star_color">
                            <h3>
                              <i className="material-icons-outlined">star</i>
                              <span>4.5</span>
                            </h3>
                            <h6>(32)</h6>
                          </div>
                        </div>
                      </div>
                      <div className="experts_skill">
                        <ul>
                          <li>
                            <Link to="#">Wedding</Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="material-icons-outlined">place</i>
                              Madrid, Spain
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="ambassadors_users event_user">
                        <ul>
                          <li>
                            <Link to="#">
                              <img src="images/expert1.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert2.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert3.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert4.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert5.png" alt="" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="details_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn jbdetails_btn"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="job_bxItem event_bxItem">
                      <div className="event_hdng">
                        <h3>DTDC Business Meeting</h3>
                        <div className="date_ratingOuter">
                          <h5>
                            <i className="material-icons-outlined">
                              event_note
                            </i>
                            <span>Saturday, 12 January 2022</span>
                          </h5>
                          <div className="team_rating star_color">
                            <h3>
                              <i className="material-icons-outlined">star</i>
                              <span>4.5</span>
                            </h3>
                            <h6>(32)</h6>
                          </div>
                        </div>
                      </div>
                      <div className="experts_skill">
                        <ul>
                          <li>
                            <Link to="#">Wedding</Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="material-icons-outlined">place</i>
                              Madrid, Spain
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="ambassadors_users event_user">
                        <ul>
                          <li>
                            <Link to="#">
                              <img src="images/expert1.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert2.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert3.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert4.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert5.png" alt="" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="details_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn jbdetails_btn"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="job_bxItem event_bxItem">
                      <div className="event_hdng">
                        <h3>DTDC Business Meeting</h3>
                        <div className="date_ratingOuter">
                          <h5>
                            <i className="material-icons-outlined">
                              event_note
                            </i>
                            <span>Saturday, 12 January 2022</span>
                          </h5>
                          <div className="team_rating star_color">
                            <h3>
                              <i className="material-icons-outlined">star</i>
                              <span>4.5</span>
                            </h3>
                            <h6>(32)</h6>
                          </div>
                        </div>
                      </div>
                      <div className="experts_skill">
                        <ul>
                          <li>
                            <Link to="#">Wedding</Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="material-icons-outlined">place</i>
                              Madrid, Spain
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="ambassadors_users event_user">
                        <ul>
                          <li>
                            <Link to="#">
                              <img src="images/expert1.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert2.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert3.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert4.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert5.png" alt="" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="details_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn jbdetails_btn"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="job_bxItem event_bxItem">
                      <div className="event_hdng">
                        <h3>DTDC Business Meeting</h3>
                        <div className="date_ratingOuter">
                          <h5>
                            <i className="material-icons-outlined">
                              event_note
                            </i>
                            <span>Saturday, 12 January 2022</span>
                          </h5>
                          <div className="team_rating star_color">
                            <h3>
                              <i className="material-icons-outlined">star</i>
                              <span>4.5</span>
                            </h3>
                            <h6>(32)</h6>
                          </div>
                        </div>
                      </div>
                      <div className="experts_skill">
                        <ul>
                          <li>
                            <Link to="#">Wedding</Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="material-icons-outlined">place</i>
                              Madrid, Spain
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="ambassadors_users event_user">
                        <ul>
                          <li>
                            <Link to="#">
                              <img src="images/expert1.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert2.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert3.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert4.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert5.png" alt="" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="details_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn jbdetails_btn"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="job_bxItem event_bxItem">
                      <div className="event_hdng">
                        <h3>DTDC Business Meeting</h3>
                        <div className="date_ratingOuter">
                          <h5>
                            <i className="material-icons-outlined">
                              event_note
                            </i>
                            <span>Saturday, 12 January 2022</span>
                          </h5>
                          <div className="team_rating star_color">
                            <h3>
                              <i className="material-icons-outlined">star</i>
                              <span>4.5</span>
                            </h3>
                            <h6>(32)</h6>
                          </div>
                        </div>
                      </div>
                      <div className="experts_skill">
                        <ul>
                          <li>
                            <Link to="#">Wedding</Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="material-icons-outlined">place</i>
                              Madrid, Spain
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="ambassadors_users event_user">
                        <ul>
                          <li>
                            <Link to="#">
                              <img src="images/expert1.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert2.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert3.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert4.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert5.png" alt="" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="details_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn jbdetails_btn"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="job_bxItem event_bxItem">
                      <div className="event_hdng">
                        <h3>DTDC Business Meeting</h3>
                        <div className="date_ratingOuter">
                          <h5>
                            <i className="material-icons-outlined">
                              event_note
                            </i>
                            <span>Saturday, 12 January 2022</span>
                          </h5>
                          <div className="team_rating star_color">
                            <h3>
                              <i className="material-icons-outlined">star</i>
                              <span>4.5</span>
                            </h3>
                            <h6>(32)</h6>
                          </div>
                        </div>
                      </div>
                      <div className="experts_skill">
                        <ul>
                          <li>
                            <Link to="#">Wedding</Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="material-icons-outlined">place</i>
                              Madrid, Spain
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="ambassadors_users event_user">
                        <ul>
                          <li>
                            <Link to="#">
                              <img src="images/expert1.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert2.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert3.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert4.png" alt="" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src="images/expert5.png" alt="" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="details_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn jbdetails_btn"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <SimilarGroups />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
