/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

//import images
import loginPic from "assets/CourseAssets/images/login-pic.jpg";

// import custom component
import Header from "common/Header";
import Footer from "common/Footer";

const SignUp = () => {
  const token = localStorage.getItem("token");
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    let preUrl = document.referrer.split("/").pop();
    if (token) {
      if (preUrl == "" || preUrl == null) {
        history.push("/");
      } else {
        history.push(`/${preUrl}`);
      }
    }
  }, [token]);

  return (
    <>
      <Header />
      <section className="login_area">
        <div className="container">
          <div className="login_bxArea">
            <div className="signupleft_pnl">
              <div className="login_innr">
                <div className="login_hdng">
                  <h2>{t("Create Account")}</h2>
                  <h5>{t("Please enter details to proceed")}</h5>
                </div>
                <form>
                  <div className="login_fillOuter">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input_fill jobinput_fill">
                          <label>{t("First Name")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("First Name")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input_fill jobinput_fill">
                          <label>{t("Last Name")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Last Name")}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input_fill jobinput_fill">
                          <label>{t("Email")}</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder={t("Email")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input_fill jobinput_fill">
                          <label>{t("Password")}</label>
                          <div className="eye_btnfill">
                            <input
                              type="password"
                              className="form-control"
                              placeholder={t("Password")}
                            />
                            <Link to="#" className="copy_link">
                              <span className="material-icons-outlined">
                                visibility
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input_fill jobinput_fill">
                          <label>{t("Confirm Password")}</label>
                          <div className="eye_btnfill">
                            <input
                              type="password"
                              className="form-control"
                              placeholder={t("Confirm Password")}
                            />
                            <Link to="#" className="copy_link">
                              <span className="material-icons-outlined">
                                visibility
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="create_chk_outer">
                    <div className="trms_check">
                      <label className="radio-check">
                        <input type="checkbox" />
                        <span></span>
                        <p>
                          {t("Agree with the")}{" "}
                          <Link to="#">{t("Legal Notice")}</Link>
                          &amp; <Link to="#">{t("Privacy Policy")}</Link>
                        </p>
                      </label>
                    </div>
                    <div className="trms_check">
                      <label className="radio-check">
                        <input type="checkbox" />
                        <span></span>
                        <p>
                          {t(
                            "I agree to receive commercial information that MPFORALL Business"
                          )}
                          &amp;
                          {t(
                            "Collaboration considers appropriate to send me by email"
                          )}
                        </p>
                      </label>
                    </div>
                  </div>

                  <div className="create_sbmit">
                    <button type="submit" className="basic_btn ylw_btn">
                      {t("Create Account")}
                    </button>
                  </div>
                  <div className="create_userOuter">
                    <div className="create_text">
                      <p>
                        {t("Have an account?")}{" "}
                        <Link to="/login">{t("Sign in")}</Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="signupright_pnl">
              <div className="signup_pic">
                <figure>
                  <img src={loginPic} alt="login" />
                </figure>
                <div className="login_caption">
                  <h2>
                    <span>{t("Discover Indra")} </span> ATM Ecosystem
                  </h2>
                  <p>
                    {t(
                      "More than 25% of the world s total airspace is managed with Indra s Automation Systems"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SignUp;
