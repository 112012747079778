/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// importing images here
import PDF from "assets/ActivityAssests/svg/pdf.svg";
import XLS from "assets/ActivityAssests/svg/xls.svg";
import DOCS from "assets/ActivityAssests/svg/docs.svg";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
import { useTranslation, Trans } from "react-i18next";

// import inages
import defaultUser from "assets/ActivityAssests/images/default-user.png";

const ActivityRightpanel = (props) => {
  const token = localStorage.getItem("token");

  // saving loggedin user's Id
  const loggedInUserID = localStorage.getItem("userID");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // console.log("props right panel----->", loggedInUserID);

  const postId = props.activityPostId;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [commentMessage, setCommentMessage] = useState("");
  const [loadTopics, setLoadTopics] = useState(false);

  //initial state for toggle reply box
  const [toggleReplyBox, setToggleReplyBox] = useState(null);
  const [assignClass, setAssignClass] = useState(false);

  // ---------------------------- useState to change color of the icons ---------------------------------------
  const [trophyColor, setTrophyColor] = useState("grey");
  const [isLiked, setIsLiked] = useState(false);
  // ----------------------------------------------------------------------------------------------------------

  // initial state for reply to parent message
  const [replyMessage, setReplyMessage] = useState("");

  //initial for empty message
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);

  // initial for reply box
  const [replyBoxIndex, setReplyBoxIndex] = useState(null);

  // ref for point comment box
  const commentBoxRef = useRef(null);

  // function for load details
  const loadDetails = async (postId) => {
    const requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_DETAILS +
      `${postId}` +
      `?token=${token}`;

    //console.log("request url in activity right panel----->",requestURL);

    try {
      const response = await getData(requestURL);
      // console.log("response in right panel details----->", response.data);

      if (response.status == true) {
        if (response.data.length == 0) {
          setLoadTopics(false);
        } else {
          setDetailsData(response.data);
          setLoadTopics(false);
        }
      }
    } catch (error) {
      // console.log("error in right panel ---->", error);
      setError(error);
      setLoadTopics(false);
    }
  };

  // function for post comment
  const answerToQuestion = async () => {
    if (commentMessage == "") {
      // alert("Comment message shouldn`t be empty");
      setShowEmptyMessage(true);
      return;
    } else {
      setShowEmptyMessage(false);
      let commentUrl =
        url.API_BaseUrl +
        url.API_ACTIVITY_POST_ANSWER +
        `${postId}?token=${token}`;

      let messageData = {
        message: commentMessage,
      };

      setCommentMessage("");

      try {
        const response = await postData(commentUrl, messageData);
        //console.log("response answer to question details----->", response);

        if (response.status) {
          loadDetails(postId);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // function for toggling reply box
  const showReplyBox = (index) => {
    if (replyBoxIndex == null) {
      setReplyBoxIndex(index);
    } else {
      setReplyBoxIndex(null);
    }
  };

  // function for reply comments
  const replyToAnswerHandler = async (item) => {
    //console.log("reply---->", item);

    if (replyMessage == "") {
      return;
    } else {
      let commentUrl =
        url.API_BaseUrl +
        url.API_ACTIVITY_REPLY_TO_ANSWER +
        `${postId}?token=${token}`;

      let messageData = {
        message: replyMessage,
        commentid: item.id,
      };

      //console.log("message------>", messageData);

      setReplyMessage("");
      setReplyBoxIndex(null);

      try {
        const response = await postData(commentUrl, messageData);
        //console.log("response reply to answer details----->", response);

        if (response.status == true) {
          loadDetails(postId);
        } else {
          console.log("error in response");
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  // function for parent like
  const likeCommentHandler = async (commentId) => {
    // console.log(commentId);
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_LIKE_POST_COMMENT +
      `?token=${token}` +
      `&commentid=${commentId}`;

    //console.log("Like Comment URL ------> ", requestURL);

    try {
      const response = await getData(requestURL);

      //console.log("Received response is ------> ", response);

      loadDetails(postId);
    } catch (error) {
      console.log("Error in like API ----->", error);
    }
  };

  // function for like topics
  const likePostHandler = async (postId) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_LIKE_POST +
      `?token=${token}` +
      `&postid=${postId}`;

    //console.log("Like Post URL ------> ", requestURL);

    try {
      const response = await getData(requestURL);

      //console.log("Received response is ------> ", response);
      if (response) {
        loadDetails(postId);
      }
    } catch (error) {
      console.log("Error of activity post is----->", error);
    }
  };

  // function for focus to commentbox
  const commentBoxRefToggle = () => {
    commentBoxRef.current?.scrollIntoView();
  };

  // function for set champion
  const championHandler = async (item) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_SET_WINNER +
      `?token=${token}` +
      `&cid=${item.id}`;

    try {
      const response = await getData(requestURL);

      // console.log("Champion response---> ", response);

      if (response.status) {
        loadDetails(postId);
      } else {
        console.log("error in response");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  //function for delete comment
  const deleteComment = async (item) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_REMOVE_COMMENTS +
      `?token=${token}` +
      `&cid=${item.id}`;

    // console.log("remove comment url ---->", requestURL);

    try {
      const response = await getData(requestURL);

      // console.log("Remove response---> ", response);

      if (response.status) {
        loadDetails(postId);
      } else {
        console.log("error in response");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // useefect for get details by post id
  useEffect(() => {
    if (postId != null) {
      setLoadTopics(true);
      loadDetails(postId);
    } else {
      setDetailsData(null);
    }
  }, [postId]);

  return (
    <>
      {loadTopics ? (
        <div className="glow_area">
          <div className="plan p-3 border rounded-3 placeholder-glow">
            <span className="placeholder placeholder-sm col-12 rounded-3 mb-4"></span>
            <div className="user_placeHoledr">
              <div className="default_usr">
                <img src={defaultUser} alt="default-user" />
              </div>
              <div className="default_usr_name">
                <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              </div>
            </div>
            <div className="data_btnOuter">
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
            </div>
            <p className="card-text placeholder-glow">
              <span className="placeholder placeholder-sm col-12 rounded-3"></span>
              <span className="placeholder placeholder-sm col-10 rounded-3"></span>
              <span className="placeholder placeholder-sm col-9 rounded-3"></span>
              <span className="placeholder placeholder-sm col-8 rounded-3"></span>
              <span className="placeholder placeholder-sm col-7 rounded-3"></span>
              <span className="placeholder placeholder-sm col-6 rounded-3"></span>
            </p>
          </div>
        </div>
      ) : (
        <div className="activity_rightpnl">
          <div className="activity_backBtn">
            {/* --- back button for mobile version ---- */}
            <Link
              to="#"
              role="button"
              onClick={() => {
                document.body.classList.remove("activity_open");
              }}
            >
              <i className="material-icons-outlined">arrow_back</i>
              <span>{t("Back")}</span>
            </Link>
            {/* --- back button for mobile version end ---- */}
          </div>
          {detailsData == null ? null : (
            <div className="pnl_innr">
              <div className="activity_rightItem">
                {/* ---- post title ---- */}
                <div className="activity_rightHdng">
                  <h3>{detailsData.title}</h3>
                </div>
                {/* ---- post title end ---- */}

                {/* ---- post owner with image and date start ----- */}
                <div className="activity_userOuter">
                  <div className="activity_usrPnl">
                    {detailsData.moderator == undefined ? null : (
                      <div className="user_activty">
                        <img
                          src={detailsData.moderator.thumbnail}
                          alt="owner"
                        />
                      </div>
                    )}
                    {detailsData.moderator == undefined ? null : (
                      <div className="activty_usrname">
                        <h4>{detailsData.moderator.name}</h4>
                      </div>
                    )}
                  </div>
                  <div className="activity_date">
                    <i className="material-icons-round">calendar_today</i>
                    <span>{detailsData.created}</span>
                  </div>
                </div>
                {/* ---- post owner with image and date end ----- */}
                <div className="winers_likeOuter">
                  <div className="likecmnts_pnl">
                    <div className="form_share">
                      <Link
                        to="#"
                        role="button"
                        onClick={() => likePostHandler(postId)}
                      >
                        <i className="material-icons-outlined">thumb_up</i>
                        <em>{t("Likes")}</em>
                        <span>{detailsData.likes}</span>
                      </Link>
                      <Link to="#" role="button" onClick={commentBoxRefToggle}>
                        <i className="material-icons-outlined">chat</i>
                        <em>{t("Comment")}</em>
                        {detailsData.comments ? (
                          <span>{detailsData.comments.count}</span>
                        ) : null}
                      </Link>
                    </div>
                  </div>
                  {/* <div className="winner_user">
                <div className="activty_usrname">
                  <h5>Winner</h5>
                </div>
                <div className="user_activty">
                  <img src="images/expert2.png" alt="" />
                </div>
              </div> */}
                </div>

                {detailsData.media == null ? null : (
                  <div className="d-flex">
                    {detailsData.media.map((item, index) => {
                      return (
                        <div
                          className="post-media"
                          style={{ cursor: "pointer" }}
                          key={index}
                          onClick={() => window.open(item, "_blank")}
                        >
                          {item.split(".").pop() == "jpg" ||
                          item.split(".").pop() == "JPG" ||
                          item.split(".").pop() == "png" ? (
                            <img
                              src={item}
                              alt="docs"
                              className="rounded"
                              height="50"
                              width="50"
                            />
                          ) : (
                            <div>
                              {item.split(".").pop() == "pdf" ? (
                                <img
                                  src={PDF}
                                  alt="docs"
                                  className="rounded"
                                  height="50"
                                  width="50"
                                />
                              ) : (
                                <div>
                                  {item.split(".").pop() == "xlsx" ||
                                  item.split(".").pop() == "xls" ? (
                                    <img
                                      src={XLS}
                                      alt="docs"
                                      className="rounded"
                                      height="50"
                                      width="50"
                                    />
                                  ) : (
                                    <div>
                                      {item.split(".").pop() == "docx" ||
                                      item.split(".").pop() == "txt" ||
                                      item.split(".").pop() == "doc" ? (
                                        <img
                                          src={DOCS}
                                          alt="docs"
                                          className="rounded"
                                          height="50"
                                          width="50"
                                        />
                                      ) : null}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              {/* ----- post tags start ---- */}

              {detailsData.tags.length == 0 ? null : (
                <div className="activity_rightItem post-tags">
                  <div className="experts_skill activity_skill">
                    <ul>
                      {detailsData.tags.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link to="#">{item.label}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}

              {/* ----- post tags end ---- */}

              {/* ----- post category start ---- */}
              {detailsData.categories.length == 0 ? null : (
                <div className="activity_rightItem post-categories">
                  <div className="experts_skill activity_skill">
                    <ul>
                      {detailsData.categories.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link to="#">{item.label}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}

              {/* ----- post category end ---- */}

              {/* ----- post descritption start ---- */}
              <div className="activity_rightItem">
                {/* <div className="activity_para">
                  <p>{detailsData.description}</p>
                </div> */}

                <div
                  dangerouslySetInnerHTML={{
                    __html: detailsData.description,
                  }}
                  className="activity_para"
                ></div>
              </div>

              {/* ----- post descritption end ---- */}
              <div ref={commentBoxRef} name="scrollRefDiv" />
              {/* ----- post reply box start ---- */}
              <div className="activity_rightItem">
                <div className="activity_commnets">
                  <h3>{t("Share Your Idea")}</h3>
                  <div className="form_commnts_area">
                    <textarea
                      className="form-control"
                      placeholder={t("Write your idea here")}
                      value={commentMessage}
                      onChange={(e) => setCommentMessage(e.target.value)}
                    ></textarea>
                    <div className="furmcmnt_btn">
                      <button className="cmnt_btn" onClick={answerToQuestion}>
                        <span className="material-icons-outlined">send</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* ----- post reply box end ---- */}

              {/* ----- post comments start ---- */}
              {detailsData.comments == undefined ? null : (
                <>
                  {detailsData.comments.records.map((item, index) => {
                    return (
                      <div className="activity_rightItem" key={index}>
                        <div className="chat_likeBx">
                          <div className="activity_para">
                            <p>{item.message}</p>
                          </div>
                          <div className="likecmnts_pnl">
                            <div className="cmnts_usr">
                              <img src={item.senderimage} alt="" />
                            </div>
                            <div className="cmnts_usertext">
                              <h3>{item.sender}</h3>
                              <div className="form_share">
                                <Link
                                  to="#"
                                  role="button"
                                  onClick={() => likeCommentHandler(item.id)}
                                >
                                  <i className="material-icons-outlined">
                                    thumb_up
                                  </i>
                                  <em>{t("Likes")}</em>
                                  <span>{item.likes}</span>
                                </Link>
                                <Link
                                  to="#"
                                  role="button"
                                  onClick={() => showReplyBox(index)}
                                >
                                  <i className="material-icons-outlined">
                                    chat
                                  </i>
                                  <em>{t("Comment")}</em>
                                  <span>{item.reply.count}</span>
                                </Link>
                                {detailsData.moderator.id == loggedInUserID ||
                                item.senderid == loggedInUserID ? (
                                  <Link
                                    role="button"
                                    to="#"
                                    onClick={() => deleteComment(item)}
                                  >
                                    <i className="material-icons-outlined">
                                      delete
                                    </i>
                                    <em>{t("Remove")}</em>
                                  </Link>
                                ) : null}

                                {/* ------ Winner icon displayed here --------- */}
                                {detailsData.moderator.id == loggedInUserID ? (
                                  <Link
                                    to="#"
                                    className={
                                      item.winner != true || item.winner == null
                                        ? "nowinner"
                                        : "activewinner"
                                    }
                                    onClick={() => {
                                      championHandler(item);
                                    }}
                                  >
                                    <i className="material-icons-round">
                                      emoji_events
                                    </i>
                                  </Link>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        {replyBoxIndex == index ? (
                          <div className="activity_comntsRow">
                            <div className="input_fill">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("Reply to comment")}
                                name="replyMessage"
                                value={replyMessage}
                                onChange={(e) =>
                                  setReplyMessage(e.target.value)
                                }
                              />
                            </div>
                            <button
                              className="cmnt_btn"
                              onClick={() => replyToAnswerHandler(item)}
                            >
                              <span className="material-icons-outlined">
                                send
                              </span>
                            </button>
                          </div>
                        ) : null}

                        {/* ----- post reply of comments start ---- */}
                        {item.reply.records.map((item2, index2) => {
                          return (
                            <div className="activity_rightItem" key={index2}>
                              <div className="chat_likeBx">
                                <div className="activity_para">
                                  <p>
                                    <Link to="#">@{item2.receiver} </Link>
                                    {item2.message}
                                  </p>
                                </div>
                                <div className="likecmnts_pnl">
                                  <div className="cmnts_usr">
                                    <img src={item2.senderimage} alt="" />
                                  </div>
                                  <div className="cmnts_usertext">
                                    <h3>{item2.sender}</h3>
                                    <div className="form_share">
                                      <Link
                                        to="#"
                                        role="button"
                                        onClick={() =>
                                          likeCommentHandler(item2.id)
                                        }
                                      >
                                        <i className="material-icons-outlined">
                                          thumb_up
                                        </i>
                                        <em>{t("Likes")}</em>
                                        <span>{item2.likes}</span>
                                      </Link>
                                      {/* <Link to="#">
                                      <i className="material-icons-outlined">
                                        chat
                                      </i>
                                      <em>{t("Comment")}</em>
                                      <span>{item2.reply.count}</span>
                                    </Link> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {/* ----- post reply of comments end ---- */}
                      </div>
                    );
                  })}
                </>
              )}
              {/* ----- post comments end ---- */}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ActivityRightpanel;
