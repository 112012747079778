import React from "react";

// Importing custom components here
import Header from "common/Header";
import Banner from "components/MyCoursesComponents/Banner";
import MyCoursesListArea from "components/MyCoursesComponents/MyCoursesListArea";
import Footer from "common/Footer";

const CourseLearning = () => {
  return (
    <>
      <Header />
      <Banner />
      <MyCoursesListArea />
      <Footer />
    </>
  );
};

export default CourseLearning;
