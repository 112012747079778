/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation, Trans } from "react-i18next";
import { GlobalIndraInfo } from "App";

import UserMenu from "./UserMenu";

// importing the images here
import logo from "assets/CourseAssets/svg/logo.svg";

const Header = () => {
  const token = localStorage.getItem("token");

  const { getCourseId } = useContext(GlobalIndraInfo);

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // Function for changing language
  function handleSelectedLanguage(val) {
    i18n.changeLanguage(val);
  }

  // function for toggle hamburger menu
  const toggleMenu = () => {
    getCourseId(null);
    document.body.classList.toggle("menu_open");
    document.body.classList.toggle("append_menu");
  };

  return (
    <>
      <header className="header_outer">
        {/* ---- user top menu ---- */}
        <UserMenu />

        {/* ----- header bottom menu ----- */}
        <div className="hdr_bttm">
          <div className="container">
            <div className="header_innr">
              <div className="hdr_logo">
                <Link className="navbar-brand" to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="header_menu">
                <span className="menu_cross" onClick={toggleMenu}>
                  <i className="material-icons-outlined"> close </i>
                </span>
                <div className="inner_pnlBody" id="application_menu">
                  <ul>
                    <li>
                      <Link to="/champions" onClick={toggleMenu}>
                        {t("Champions")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/groups" onClick={toggleMenu}>
                        {t("Groups")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/activity" onClick={toggleMenu}>
                        {t("Forum")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/conversation" onClick={toggleMenu}>
                        {t("Chat")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/events" onClick={toggleMenu}>
                        {t("Events")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/course/add/step1" onClick={toggleMenu}>
                        Course Upload
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/courses" onClick={toggleMenu}>
                        {t("Courses")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/mycourses" onClick={toggleMenu}>
                        My Courses
                      </Link>
                    </li> */}

                    {/* <li>
                      <Link to="/course/details/0" onClick={toggleMenu}>
                        Course Details
                      </Link>
                    </li> */}

                    <li>
                      <Link to="/challenges" onClick={toggleMenu}>
                        {t("Challenges")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lang_slect_bx mobile_langslct">
                <select
                  className="select"
                  name="languageSelector"
                  onChange={(e) => handleSelectedLanguage(e.target.value)}
                >
                  <option value="en">English</option>
                  <option value="es">Spain</option>
                </select>
              </div>
              <div className="menu_bar">
                <Link to="#" role="button" onClick={toggleMenu}>
                  <span className="material-icons-round"> menu</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="overlay"></div>
      </header>
    </>
  );
};

export default Header;
