import React from "react";
import { Link } from "react-router-dom";

// import images here
import certificate from "assets/CourseDetailsAssests/images/certificate_frame.png";

const Docs = () => {
  return (
    <>
      {/* --------------- Doc Body starts here --------------- */}
      <div className="tab-pane show active fade" id="doc">
        <div className="scores_pnl lesson_pnl">
          <div className="scorebx_outer">
            <div className="scorebx_item">
              <div className="scorebx_innr">
                <h5>Date of Exam</h5>
                <h4>25th August 2021</h4>
              </div>
            </div>
            <div className="scorebx_item">
              <div className="scorebx_innr">
                <h5>Score</h5>
                <h4>250 out of 300</h4>
              </div>
            </div>
            <div className="scorebx_item">
              <div className="scorebx_innr">
                <h5>Rank</h5>
                <h4>25 out of 85</h4>
              </div>
            </div>
          </div>

          <div className="certificate_bx">
            <div
              className="donate_certificate"
              style={{
                backgroundImage: certificate,
              }}
            >
              <div className="certificate_hdng">
                <h2>certificate OF donation</h2>
                <span className="cerficate_line">
                  <img src="images/line.png" alt="" />
                </span>
              </div>
              <div className="certificate_nameHdng">
                <p>We hereby express our sincere appreciation to</p>
                <h3>Peter Johnston</h3>
              </div>
              <div className="certificate_para">
                <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Richard McClintock,
                  a Latin professor at Hampden-Sydney College in Virginia,
                  looked up one of the more obscure.
                </p>
              </div>
              <div className="crtificate_signOuter">
                <div className="certificate_date">
                  <h5>
                    Date <span>01-11-2022</span>
                  </h5>
                </div>
                <div className="certificate_stamp">
                  <img src="images/certificate_stamp.png" alt="" />
                </div>
                <div className="certificate_sign">
                  <img src="images/signed-copy.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="download_btn">
            <Link to="#" className="basic_btn">
              Download PDF
            </Link>
          </div>
        </div>
      </div>
      {/* --------------- Doc Body ends here --------------- */}
    </>
  );
};

export default Docs;
