import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BreadCrumb = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="bredcum_area">
        <div className="container">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{t("Home")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/champions">{t("Champions")}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {t("My Invitations")}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </>
  );
};

export default BreadCrumb;
