import React, { useState, useEffect } from "react";

//importing images here
import empty from "assets/ConversationAssests/images/empty-pic.png";

const EmptyMessage = (props) => {
  return (
    <>
      <div className="chating_mdlPnl">
        <div className="chatting_innr chating_start">
          <div className="nothing_pic">
            <figure>
              <img src={empty} alt="nothing" />
            </figure>
            <div className="nothing_caption">
              <p>
                Nothing found.....Refresh or Send your first {props.messageType}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmptyMessage;
