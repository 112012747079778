import React from "react";
import { Link } from "react-router-dom";

const EventGallery = () => {
  return (
    <div className="course_detailspnl">
      <div className="contnt_hdng">
        <h4>Portfolio</h4>
      </div>
      <div className="portfolio_outer gallery_outer">
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery1.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery2.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery3.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery4.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery5.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery6.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery7.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery8.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery9.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery10.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery1.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery2.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery3.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery4.jpg" alt="" />
          </Link>
        </div>
        <div className="portfolio_gallery_item">
          <Link to="#">
            <img src="images/gallery5.jpg" alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EventGallery;
