/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

const AppMenu = (props) => {
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // Function for changing language
  function handleSelectedLanguage(val) {
    i18n.changeLanguage(val);
  }

  // function for toggle hamburger menu
  const toggleMenu = () => {
    document.body.classList.toggle("menu_open");
    document.body.classList.toggle("append_menu");
  };

  return (
    <div className="hamberger_menuPnl">
      <div className="pro_back_btn">
        <Link
          to="#"
          onClick={() => {
            document.body.classList.toggle("hamberger_menuOpen");
          }}
        >
          <i className="material-icons-round"> arrow_back </i>
        </Link>
      </div>
      <div className="hamberger_menuInnr">
        <ul>
          <li>
            <Link to="/champions" onClick={toggleMenu}>
              Champions
            </Link>
          </li>
          <li>
            <Link to="/groups" onClick={toggleMenu}>
              Groups
            </Link>
          </li>
          <li>
            <Link to="/activity" onClick={toggleMenu}>
              Forum
            </Link>
          </li>
          <li>
            <Link to="/conversation" onClick={toggleMenu}>
              Chat
            </Link>
          </li>
          <li>
            <Link to="/events" onClick={toggleMenu}>
              Events
            </Link>
          </li>
          <li>
            <Link to="/course/details/0" onClick={toggleMenu}>
              Courses
            </Link>
          </li>
          <li>
            <Link to="/challenges" onClick={toggleMenu}>
              Challenges
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AppMenu;
