import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// importing custom components here
import Header from "common/Header";
import BreadCrumb from "components/CourseDetailsComponents/common/BreadCrumb";
import CourseDetailsTopSection from "./CourseDetailsTopSection";
import CourseDetailsBottomSection from "./CourseDetailsBottomSection";
import Footer from "common/Footer";

const CourseDetails = () => {
  useEffect(() => {
    document.title = "Course-Details";
  }, []);

  return (
    <>
      <Header />
      <BreadCrumb />
      <CourseDetailsTopSection />
      <CourseDetailsBottomSection />
      <Footer />
    </>
  );
};

export default CourseDetails;
