/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalIndraInfo } from "App";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const Step7 = (props) => {
  // for routing
  const history = useHistory();

  // Getting the course ID created in Step 1
  const { courseId } = useContext(GlobalIndraInfo);

  // toast property
  const toastOptions = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    // theme: "colored",
  };

  // accessing the user's token here
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // initial state for step seven data
  const [stepSevenData, setStepSevenData] = useState({
    paypalId: "",
  });

  /*---- function for go to next step -----*/
  const nextStepHandler = async (e) => {
    e.preventDefault();

    // console.log("step one data ----->", stepOneData);
    if (stepSevenData.paypalId == "") {
      alert("Fill all the data");
    } else {
      let requestURL = url.API_BaseUrl + url.DEMO_POST + `?token=${token}`;

      console.log("Step 7 sending request --------> ", requestURL);

      let stepSevenState = {
        stepSevenData,
        isStepSevenComplete: true,
      };

      try {
        const response = await postData(requestURL, stepSevenData);

        console.log("Step 7 receiving response --------> ", response);

        if (response.status) {
          console.log("Data submitted successfully", response);
          history.push("/course/add/step8");
          props.onConfirmStep7(stepSevenState);
        } else {
          console.log("Some error occurred while submitting the data!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  /*---- end of function for go to next step -----*/

  /* Function to redirect to the next page without calling the API */
  const goToNextPageHandler = (e) => {
    e.preventDefault();
    history.push("/course/add/step8");
  };

  /* Function to redirect to the previous page without calling the API */
  const goToPreviousPageHandler = (e) => {
    e.preventDefault();
    history.push("/course/add/Step6");
  };

  return (
    <>
      <div className="col-lg-9 col-md-8">
        {/* --------------- Unknown back arrow button starts here --------------- */}
        <div className="back_progress">
          <div className="page_back">
            <Link to="#">
              <span className="material-icons-outlined">arrow_back</span>
              Back
            </Link>
          </div>
          <div className="step_progress">
            <span className="progress_bar" style={{ width: "85%" }}></span>
          </div>
        </div>
        {/* --------------- Unknown back arrow button ends here --------------- */}

        {/* --------------- Form section starts here --------------- */}
        <div className="course_uploadrghtpnl">
          <div className="course_itemInnr">
            <div className="course_stepItem">
              <div className="coursestep_hdng">
                <h3>{t("Payment Settings")}</h3>
                <p>
                  <Trans i18nKey="paymentadd">
                    Please add your paypal id to where you want to receive
                    payment
                  </Trans>
                </p>
              </div>
              <div className="course_fillTop">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input_fill jobinput_fill">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("Paypal ID")}
                        value={stepSevenData.paypalId}
                        onChange={(e) =>
                          setStepSevenData({
                            ...stepSevenData,
                            paypalId: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ---- go to next & previous step button area ---- */}
          <div className="nxt_prvbtn_Outer">
            {/* ---- go to previous step button area ---- */}
            <div className="prev_Btn">
              <Link
                to="#"
                className="border_btn"
                onClick={(e) => {
                  goToPreviousPageHandler(e);
                }}
              >
                {t("Back to previous")}
              </Link>
            </div>

            {/* ---- 'Save & Continue' button area ---- */}
            <div className="next_Btn">
              <Link
                to="#"
                className="border_btn"
                role="button"
                onClick={(e) => {
                  nextStepHandler(e);
                }}
              >
                {t("Save & Continue")}
              </Link>
            </div>

            {/* ---- go to next step button area ---- */}
            <div className="next_Btn">
              <Link
                to="#"
                className="basic_btn"
                onClick={(e) => {
                  goToNextPageHandler(e);
                }}
              >
                {t("Go to Next Step")}
              </Link>
            </div>
          </div>
        </div>
        {/* --------------- Form section ends here --------------- */}
      </div>
      <ToastContainer />
    </>
  );
};

export default Step7;
