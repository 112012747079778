/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

// import images here
import docIcon from "assets/ChallegesDetailsAsstes/doc.svg";
import pdfIcon from "assets/ChallegesDetailsAsstes/pdf.svg";

const DocTab = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  // 👇️ get ID from url
  const params = useParams();

  // Initial useState to save all the incoming data from API.
  const [jobDetailsData, setJobDetailsData] = useState(null);

  // Function to call the API and get all the data
  const getJobDetailsData = async (id) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_CHALLENGES_DETAILS_DOCS_TAB +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      // console.log(
      //   "requesting URL to get all the job details Docs Tab ------> ",
      //   requestURL
      // );

      const response = await getData(requestURL);
      console.log(
        "Received Response in challenges details Doc tab --------> ",
        response
      );

      if (response.status) {
        setJobDetailsData(response.data);
      } else {
        console.log("Some error occurred ------> ", response);
      }
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
    }
  };

  // useEffect from here
  useEffect(() => {
    getJobDetailsData(params.id);
  }, []);

  return (
    <>
      {jobDetailsData == null ? (
        // ----------------------- Placeholder starts here -----------------------
        <div className="course_detailspnl">
          <div className="contnt_hdng">
            <span
              className="placeholder rounded-3 col-1"
              style={{ height: "22px" }}
            ></span>
          </div>

          <div className="docs_outer">
            <div className="docs_row">
              <div className="docs_left">
                <div className="doc_icon">
                  <span
                    className="placeholder rounded-3"
                    style={{ width: "25px", height: "25px" }}
                  ></span>
                </div>
                <div className="doc_text">
                  <span
                    className="placeholder placeholder-sm rounded-3"
                    style={{ width: "180px" }}
                  ></span>
                </div>
              </div>
              <div className="docs_right">
                <ul>
                  <li>
                    <span
                      className="placeholder placeholder-sm rounded-3"
                      style={{ width: "85px" }}
                    ></span>
                  </li>
                  <li>
                    <span
                      className="placeholder placeholder-sm rounded-3"
                      style={{ width: "35px" }}
                    ></span>
                  </li>
                  <li>
                    <span
                      className="placeholder rounded-3"
                      style={{ width: "20px", height: "20px" }}
                    ></span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="docs_row">
              <div className="docs_left">
                <div className="doc_icon">
                  <span
                    className="placeholder rounded-3"
                    style={{ width: "25px", height: "25px" }}
                  ></span>
                </div>
                <div className="doc_text">
                  <span
                    className="placeholder placeholder-sm rounded-3"
                    style={{ width: "180px" }}
                  ></span>
                </div>
              </div>
              <div className="docs_right">
                <ul>
                  <li>
                    <span
                      className="placeholder placeholder-sm rounded-3"
                      style={{ width: "85px" }}
                    ></span>
                  </li>
                  <li>
                    <span
                      className="placeholder placeholder-sm rounded-3"
                      style={{ width: "35px" }}
                    ></span>
                  </li>
                  <li>
                    <span
                      className="placeholder rounded-3"
                      style={{ width: "20px", height: "20px" }}
                    ></span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="docs_row">
              <div className="docs_left">
                <div className="doc_icon">
                  <span
                    className="placeholder rounded-3"
                    style={{ width: "25px", height: "25px" }}
                  ></span>
                </div>
                <div className="doc_text">
                  <span
                    className="placeholder placeholder-sm rounded-3"
                    style={{ width: "180px" }}
                  ></span>
                </div>
              </div>
              <div className="docs_right">
                <ul>
                  <li>
                    <span
                      className="placeholder placeholder-sm rounded-3"
                      style={{ width: "85px" }}
                    ></span>
                  </li>
                  <li>
                    <span
                      className="placeholder placeholder-sm rounded-3"
                      style={{ width: "35px" }}
                    ></span>
                  </li>
                  <li>
                    <span
                      className="placeholder rounded-3"
                      style={{ width: "20px", height: "20px" }}
                    ></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // ----------------------- Mapped data starts here -----------------------
        <div className="course_detailspnl">
          <div className="contnt_hdng">
            <h4>Docs</h4>
          </div>
          {jobDetailsData.docs != null && jobDetailsData.docs.length > 0 ? (
            <div className="docs_outer">
              {jobDetailsData.docs.map((doc, index) => {
                return (
                  <div className="docs_row" key={index}>
                    <div className="docs_left">
                      <div className="doc_icon">
                        {doc.type == "docx" ? (
                          <img src={docIcon} alt="" />
                        ) : (
                          <img src={pdfIcon} alt="" />
                        )}
                      </div>
                      <div className="doc_text">
                        <h5>{doc.title}</h5>
                      </div>
                    </div>
                    <div className="docs_right">
                      <ul>
                        <li>{doc.created}</li>
                        <li>{doc.size}</li>
                        <li>
                          <Link to="#">
                            <span className="material-icons-outlined">
                              file_upload
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            // ----------------------- Placeholder starts here -----------------------
            <div className="course_detailspnl">
              <div className="contnt_hdng">
                <span
                  className="placeholder rounded-3 col-1"
                  style={{ height: "22px" }}
                ></span>
              </div>

              <div className="docs_outer">
                <div className="docs_row">
                  <div className="docs_left">
                    <div className="doc_icon">
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "25px", height: "25px" }}
                      ></span>
                    </div>
                    <div className="doc_text">
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "180px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="docs_right">
                    <ul>
                      <li>
                        <span
                          className="placeholder placeholder-sm rounded-3"
                          style={{ width: "85px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder placeholder-sm rounded-3"
                          style={{ width: "35px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder rounded-3"
                          style={{ width: "20px", height: "20px" }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="docs_row">
                  <div className="docs_left">
                    <div className="doc_icon">
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "25px", height: "25px" }}
                      ></span>
                    </div>
                    <div className="doc_text">
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "180px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="docs_right">
                    <ul>
                      <li>
                        <span
                          className="placeholder placeholder-sm rounded-3"
                          style={{ width: "85px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder placeholder-sm rounded-3"
                          style={{ width: "35px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder rounded-3"
                          style={{ width: "20px", height: "20px" }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="docs_row">
                  <div className="docs_left">
                    <div className="doc_icon">
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "25px", height: "25px" }}
                      ></span>
                    </div>
                    <div className="doc_text">
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "180px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="docs_right">
                    <ul>
                      <li>
                        <span
                          className="placeholder placeholder-sm rounded-3"
                          style={{ width: "85px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder placeholder-sm rounded-3"
                          style={{ width: "35px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder rounded-3"
                          style={{ width: "20px", height: "20px" }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            // ----------------------- Placeholder ends here -----------------------
          )}
        </div>
      )}
    </>
  );
};

export default DocTab;
