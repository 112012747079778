/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// importing custom components here
import QuestionModal from "components/CourseDetailsComponents/Modals/QuestionModal";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormdata } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const LessonQuestions = (props) => {
  // accessing the user's token here
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // Initial useState to save the content recceived from the props.
  const [questionsData, setQuestionsData] = useState(null);

  // Initial useState to save the student/subscriber question.
  const [userQuestion, setUserQuestion] = useState("");

  // Initial useState to display the warning
  const [showWarning, setShowWarning] = useState(false);

  // Function to submit the student's/subscriber's question to the API.
  const questionSubmitHandler = async (e) => {
    e.preventDefault();

    if (userQuestion == "") {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      let requestURL =
        url.API_BaseUrl +
        url.API_SUBMIT_SUBSCRIBER_QUESTION +
        `?token=${token}` +
        `&lid=${props.lessonId}`;

      let questionData = {
        question: userQuestion,
      };

      try {
        const response = await postFormdata(requestURL, questionData);

        console.log("Review API receiving response --------> ", response);

        if (response.status) {
          // console.log("Data submitted successfully", response);
          let myModal = new bootstrap.Modal(
            document.getElementById("question_success_modal")
          );
          myModal.show();
          setUserQuestion("");
        } else {
          console.log("Some error occurred while submitting the data!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // UseEffect for lesson Id & question
  useEffect(() => {
    if (props.questions) {
      console.log(
        "Received Lesson Questions in Lesson Task Tab -----> ",
        props.questions
      );

      // assigning the data to the useState
      setQuestionsData(props.questions);
    }

    if (props.lessonId) {
      console.log("Received Lesson ID ----> ", props.lessonId);
    }
  }, [props.questions]);

  // return from here
  return (
    <div className="tab-pane fade active show" id="questions">
      {questionsData == null ? null : (
        <div className="question_pnl lesson_pnl">
          <div className="details_faqPnl">
            {/* --------- Accordion area starts here --------- */}
            {questionsData != null && questionsData.length > 0 ? (
              <div className="accordian_area">
                <div className="accordion accordion-flush" id="faq-accordian">
                  {questionsData.map((question, index) => {
                    return (
                      <div className="accordion-item" key={question.id}>
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + index}
                          >
                            <span>{question.question}</span>
                            <i className="material-icons-round">expand_more</i>
                          </button>
                        </h2>
                        <div
                          id={"collapse" + index}
                          className="accordion-collapse collapse"
                          data-bs-parent="#faq-accordian"
                        >
                          <div className="accordion-body">
                            <div className="faq_para">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: question.answer,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {/* --------- Accordion area ends here --------- */}
          </div>

          {/* --------- Question submit area starts here --------- */}
          <div className="question_submit_area">
            <div className="input_fill jobinput_fill description_fill">
              <label>{t("Your Questions")}</label>
              {showWarning == true ? (
                <p className="text-danger mt-2">
                  * {t("Please enter the question")}
                </p>
              ) : null}

              <textarea
                className="form-control"
                placeholder={t("Enter you question")}
                value={userQuestion != null ? userQuestion : ""}
                onChange={(e) => {
                  setUserQuestion(e.target.value);
                  setShowWarning(false);
                }}
              ></textarea>
            </div>
            <div className="question_submit">
              <button
                className="basic_btn"
                // data-bs-toggle="modal"
                // data-bs-target="#question_success_modal"
                onClick={(e) => questionSubmitHandler(e)}
              >
                {t("Submit Question")}
              </button>
            </div>
          </div>
          {/* --------- Question submit area ends here --------- */}
        </div>
      )}
      {/* // Importing Modal here */}
      <QuestionModal />
    </div>
  );
};

export default LessonQuestions;
