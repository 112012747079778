/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation, Trans } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { GlobalIndraInfo } from "App";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

// importing custom components here
import TaskpopUp from "../PopUpComponents/TaskPopUp";

const Task = (props) => {
  // accessing the user's token here
  const token = localStorage.getItem("token");
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // Getting the course ID created in Step 1
  const { courseId, lessonId, getTaskId, getTaskTitle, taskTitle, taskId } =
    useContext(GlobalIndraInfo);

  // toast property
  const toastOption = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  // initial state for step four task data
  const [stepFourTaskName, setStepFourTaskName] = useState("");

  // initial useState to save all tasks of a particular lesson
  const [tasks, setTasks] = useState([]);

  // intial useState to save details of selected task
  const [selectedTask, setSelectedTask] = useState(null);

  //initial state for display error message
  const [titleErrorMessage, setTitleErrorMessage] = useState(false);

  // initial state for display task details
  const [showWarning, setShowWarning] = useState(false);

  // task submit
  const lessonSubmitHandler = async (e) => {
    e.preventDefault();

    if (stepFourTaskName.title == "") {
      setTitleErrorMessage(true);
    } else {
      setTitleErrorMessage(false);
      // console.log("lesson ------>", val, index);

      let requestURL =
        url.API_BaseUrl +
        url.API_STEP_4_SAVE_LESSON_TASK_NAME +
        `?token=${token}` +
        `&cid=${props.courseid}` +
        `&lid=${props.lessonid}`;

      console.log("Step 4 Task sending request --------> ", requestURL);

      try {
        let titleData = {
          title: stepFourTaskName,
        };
        const response = await postData(requestURL, titleData);

        if (response.status) {
          console.log("Step 4 Task receiving response --------> ", response);
          setStepFourTaskName("");
          getAllTasksHandler();
        } else {
          console.log("Some error occurred while submitting the data!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  /* -------- function to call the call the Step 13 API -------- */
  const getAllTasksHandler = async () => {
    let requestURL =
      url.API_BaseUrl +
      url.API_STEP_4_VIEW_ALL_LESSON_TASKS +
      `?token=${token}` +
      `&lid=${props.lessonid}`;

    console.log("Requesting for the lessons tasks --------> ", requestURL);

    try {
      const response = await getData(requestURL);
      if (response.status) {
        console.log("Received Response -------->", response);
        setTasks(response.data);
      } else {
        console.log("Some error occurred while submitting the data!");
        // console.log("Received Response -------->", response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /* -------- end of function to call the call the Step 13 API -------- */

  const reloadTasks = () => {
    getAllTasksHandler();
  };

  useEffect(() => {
    if (props.lessonid) {
      getAllTasksHandler();
    }
  }, [props.lessonid]);

  // Return function from here...
  return (
    <>
      <div className="inner_tab_pane" id="task">
        <div className="course_stepItem">
          {showWarning && (
            <p className="text-danger">
              * {t("Select instructor with lesson time")}
            </p>
          )}

          {/* ----------- Section to display all tasks of the lesson starts here ----------- */}
          {tasks ? (
            <div className="course_lessonsOuter">
              {tasks.map((item, index) => {
                return (
                  <div className="course_lessons_row" key={index}>
                    <div className="course_lessons_lft">
                      <p>{item.title}</p>
                      <div className="input_fill jobinput_fill lesson_editfill">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={item.title}
                        />
                      </div>
                    </div>
                    <div className="course_lessons_right">
                      {/* <div className="lessns_edit">
                        <Link to="#">
                          <span className="material-icons-outlined">edit</span>
                        </Link>
                      </div> */}

                      {/* -------- Link to activate the Taskpopup component starts here -------- */}
                      <div className="lessns_details_btn taskpopup_btn">
                        <Link
                          to="#"
                          role="button"
                          onClick={(e) => {
                            e.preventDefault();
                            getTaskId(item.id);
                            getTaskTitle(item.title);
                            setSelectedTask(item);
                            document.body.classList.add("taskopen_popup");
                          }}
                        >
                          {t("Details")}
                          <span className="material-icons-outlined">
                            chevron_right
                          </span>
                        </Link>
                      </div>
                      {/* -------- Link to activate the Taskpopup component ends here -------- */}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
          {/* ----------- Section to display all tasks of the lesson ends here ----------- */}

          <div className="course_lessons_Innr">
            {/* ---- lesson block start ----- */}

            {/* --------- Task name input starts here --------- */}
            <div className="input_fill jobinput_fill mb-2">
              <input
                type="text"
                name="lesson"
                className="form-control"
                placeholder={t("Add Task Title")}
                value={stepFourTaskName}
                onChange={(e) => setStepFourTaskName(e.target.value)}
              />
              {titleErrorMessage && (
                <p className="text-danger mt-2">
                  * {t("Please Add a task Title")}
                </p>
              )}
              <Link
                to="#"
                className="material-icons-outlined fill_chk"
                role="button"
                onClick={(e) => {
                  lessonSubmitHandler(e);
                }}
              >
                send
              </Link>
            </div>
            {/* --------- Task name input ends here --------- */}
          </div>
          {/* ---- end of lession ----- */}
        </div>
      </div>

      {/* --------------- Task Popup component added here ----------- */}
      <TaskpopUp selectedTask={selectedTask} reloadTasks={reloadTasks} />
    </>
  );
};

export default Task;
