/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { GlobalIndraInfo } from "App";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// importing the images here
import Shopify from "assets/ChallengesAssets/svg/shopify.svg";

const ChallengesListArea = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  // getting search params from useContext
  const {
    searchJobTitle,
    searchJobCity,
    searchJobCategory,
    searchJobsQueryValues,
    resetJobsQuery,
    refreshChallengeList,
  } = useContext(GlobalIndraInfo);

  // for routing
  const history = useHistory();

  // Initial loading status
  const [isLoading, setIsLoading] = useState(false);

  // initial useState for static JSON data to display the Challenges
  const [jobsData, setJobsData] = useState([]);

  // Initial useState to set page numbers for pagination
  const [page, setPage] = useState(1);

  //glow number count
  const glowCount = [1, 2, 3, 4, 5, 6];

  // function to call the API and get all the courses
  const getChallengesListData = async () => {
    let categoryValue = "";

    setIsLoading(true);

    let requestURL =
      url.API_BaseUrl +
      url.API_VIEW_ALL_JOBS +
      `?token=${token}` +
      `&page=${page}`;

    if (searchJobTitle != "") {
      requestURL = requestURL + `&query=${searchJobTitle}`;
    }

    if (searchJobCategory.length > 0) {
      searchJobCategory.map((data) => {
        categoryValue += `&skill[]=${data}`;
      });
      requestURL = requestURL + `${categoryValue}`;
    }

    if (searchJobCity != "") {
      requestURL = requestURL + `&location=${searchJobCity}`;
    }

    try {
      console.log(
        "requesting URL to get the list of all the jobs ------>",
        requestURL
      );

      const response = await getData(requestURL);
      console.log("Received Response --------> ", response.data);

      if (response.status) {
        setJobsData(response.data);
      } else {
        console.log("Some error occurred ------> ", response);
      }
    } catch (error) {
      console.log("Error of indra activity post is ----->", error);
    }
    setIsLoading(false);
  };

  // function to go to the challenge details page
  const goToChallengeDetails = (e, id) => {
    e.preventDefault();
    // console.log("ID that we are changing is ----> ", id);

    // redirecting the user to the details page
    history.push(`/challenges/details/${id}`);
  };

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  // useEffect for page number
  useEffect(() => {
    getChallengesListData();
  }, [page]);

  // useEffect from here
  useEffect(() => {
    getChallengesListData();
  }, []);

  // useEffect for the useContext values
  useEffect(() => {
    console.log(searchJobCategory);
    if (
      searchJobTitle != "" ||
      searchJobCity != "" ||
      searchJobCategory.length > 0
    ) {
      getChallengesListData();
      searchJobsQueryValues(null);
    }
  }, [searchJobTitle, searchJobCity, searchJobCategory]);

  useEffect(() => {
    if (refreshChallengeList) {
      getChallengesListData();
      resetJobsQuery(false);
    }
  }, [refreshChallengeList]);

  // return starts here
  return (
    <section className="champions_area">
      <div className="container">
        {isLoading ? (
          <div className="champions_outer">
            {/* ------------- glow placeholder when data is being loaded ------------- */}
            <div className="row">
              {glowCount.map((count) => {
                return (
                  <div className="col-lg-4 col-6" key={count}>
                    <div className="challenges_item">
                      <div className="challenge_top_head">
                        <div className="challenge_iconOuter">
                          <span
                            className="placeholder rounded-circle me-2"
                            style={{ width: "22px", height: "22px" }}
                          ></span>
                          <span
                            className="placeholder rounded-2"
                            style={{ width: "50px" }}
                          ></span>
                        </div>
                        <div className="bookmark_iconOuter">
                          <div className="chappions_rating">
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "40px" }}
                            ></span>
                          </div>
                          <div className="bookmark_icon">
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "16px", height: "20px" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div className="club_nameLocation">
                        <span
                          className="placeholder d-block rounded-2 col-6 mb-2"
                          style={{ height: "20px" }}
                        ></span>
                        <span
                          className="placeholder d-block rounded-2 col-4 mb-2"
                          style={{ height: "18px" }}
                        ></span>
                        <span
                          className="placeholder d-block rounded-2 col-4"
                          style={{ height: "16px" }}
                        ></span>
                      </div>
                      <div className="experts_skill d-flex flex-wrap">
                        <span
                          className="placeholder d-block rounded-2 me-2 mb-2"
                          style={{ width: "80px", height: "30px" }}
                        ></span>
                        <span
                          className="placeholder d-block rounded-2 me-2 mb-2"
                          style={{ width: "80px", height: "30px" }}
                        ></span>
                        <span
                          className="placeholder d-block rounded-2 me-2 mb-2"
                          style={{ width: "80px", height: "30px" }}
                        ></span>
                        <span
                          className="placeholder d-block rounded-2 me-2 mb-2"
                          style={{ width: "80px", height: "30px" }}
                        ></span>
                        <span
                          className="placeholder d-block rounded-2 me-2 mb-2"
                          style={{ width: "80px", height: "30px" }}
                        ></span>
                      </div>
                      <div className="work_details">
                        <div className="d-flex justify-content-between">
                          <span
                            className="placeholder d-block rounded-2 col-3"
                            style={{ height: "20px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2 col-3"
                            style={{ height: "20px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2"
                            style={{ width: "20px", height: "20px" }}
                          ></span>
                        </div>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder d-block rounded-2 col-12"
                          style={{ height: "50px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* ------------- glow placeholder till here ------------- */}
          </div>
        ) : (
          <div className="champions_outer">
            {jobsData == null ? null : (
              <div className="row">
                {/* ------------ Mapping starts here ------------ */}
                {jobsData.map((job, index) => (
                  <div className="col-lg-4 col-6" key={job.id}>
                    <div className="challenges_item">
                      {/* ----------- Job Icon & Rating section ----------- */}
                      <div className="challenge_top_head">
                        <div className="challenge_iconOuter">
                          <i className="challenge_icon">
                            <img
                              src={
                                job.image && job.image != null
                                  ? job.image
                                  : Shopify
                              }
                              alt="companyLogo"
                            />
                          </i>
                          <span className="challenge_icon_text">
                            {job.company != null ? job.company : ""}
                          </span>
                        </div>
                        <div className="bookmark_iconOuter">
                          <div className="chappions_rating">
                            <Link to="#">
                              <i className="material-icons-outlined">star</i>
                              <span>
                                {job.rating && job.rating != null
                                  ? job.rating
                                  : "0.0"}
                              </span>
                            </Link>
                          </div>
                          <div className="bookmark_icon">
                            <Link to="#">
                              <i className="material-icons-outlined">
                                bookmark_border
                              </i>
                            </Link>
                          </div>
                        </div>
                      </div>

                      {/* ----------- Job title, Price range, Location details section ----------- */}
                      <div className="club_nameLocation">
                        <h3>{job.name != null ? job.name : ""}</h3>
                        <h4>€ {job.price != null ? job.price : " private"}</h4>
                        <div className="locate_text">
                          <h5>
                            <i className="material-icons-outlined"> place </i>
                            <span>
                              {job.location != null
                                ? job.location
                                : " undisclosed"}
                            </span>
                          </h5>
                        </div>
                      </div>

                      {/* ----------- Tags section ----------- */}
                      <div className="experts_skill">
                        {job.skills != null && job.skills.length > 0 ? (
                          <ul>
                            {job.skills.slice(0, 5).map((skill, index) => {
                              return (
                                <li key={skill.id}>
                                  <Link to="#">
                                    <span>{skill.label}</span>
                                  </Link>
                                </li>
                              );
                            })}
                            <p className="skills_count">
                              +{job.skills.length - 5} more
                            </p>
                          </ul>
                        ) : null}
                      </div>

                      {/* ----------- Job Type & pay details section ----------- */}
                      <div className="work_details">
                        <ul>
                          <li>{job.jobtype}</li>
                          <li>
                            Experience:{" "}
                            {job.experience != null
                              ? job.experience + "+ years"
                              : " undisclosed"}
                          </li>
                          <li>
                            <span className="material-icons-outlined">
                              signal_cellular_alt
                            </span>
                          </li>
                        </ul>
                      </div>

                      {/* ----------- See details button section ----------- */}
                      <div className="details_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn"
                          tabIndex="0"
                          onClick={(e) => goToChallengeDetails(e, job.id)}
                        >
                          See Details
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
                {/* ------------ Mapping ends here ------------ */}

                {/* ------------ Pagination starts here ------------ */}
                <div className="pagination_list">
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="material-icons-outlined">
                          arrow_back_ios
                        </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => nextPage(1)}>
                        1
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => nextPage(2)}>
                        2
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => nextPage(3)}>
                        3
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => nextPage(4)}>
                        4
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => nextPage(5)}>
                        5
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => nextPage(6)}>
                        6
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="material-icons-outlined">
                          arrow_forward_ios
                        </i>
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* ------------ Pagination ends here ------------ */}
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default ChallengesListArea;
