/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const DateTab = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  // 👇️ get ID from url
  const params = useParams();

  // Initial useState to save all the incoming data from API.
  const [jobDetailsData, setJobDetailsData] = useState(null);

  // Function to call the API and get all the data
  const getJobDetailsData = async (id) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_CHALLENGES_DETAILS_DATES_TAB +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      // console.log(
      //   "requesting URL to get all the job details Dates Tab ------> ",
      //   requestURL
      // );

      const response = await getData(requestURL);
      console.log(
        "Received Response in challenges details Dates tab --------> ",
        response
      );

      if (response.status) {
        setJobDetailsData(response.data);
      } else {
        console.log("Some error occurred ------> ", response);
      }
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
    }
  };

  // useEffect from here
  useEffect(() => {
    getJobDetailsData(params.id);
  }, []);

  // return from here
  return (
    <>
      {jobDetailsData == null ? (
        // ----------------------- Placeholder starts here -----------------------
        <div className="course_detailspnl">
          <div className="contnt_hdng">
            <span
              className="placeholder rounded-3 col-1"
              style={{ height: "22px" }}
            ></span>
          </div>

          <div className="dates_pnlOuter">
            <div className="dates_pnlItem complete_item">
              <span className="dates_nmbr">1</span>
              <div className="dates_hdng">
                <span
                  className="placeholder rounded-3 col-1"
                  style={{ height: "18px" }}
                ></span>
              </div>
              <div className="job_tags">
                <ul>
                  <li>
                    <i className="material-icons-outlined"> place </i>
                    <span>San Deago,CA USA</span>
                  </li>
                  <li>
                    <i className="material-icons-outlined"> event_note </i>
                    <span>01/09/2021 - 30/09/2021</span>
                  </li>
                  <li>
                    <i className="material-icons-outlined">person_outline</i>
                    <span>15 Participants</span>
                  </li>
                </ul>
              </div>
              <div className="dates_listing">
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2"></span>
              </div>
            </div>

            <div className="dates_pnlItem">
              <span className="dates_nmbr">2</span>
              <div className="dates_hdng">
                <span
                  className="placeholder rounded-3 col-1"
                  style={{ height: "18px" }}
                ></span>
              </div>
              <div className="job_tags">
                <ul>
                  <li>
                    <i className="material-icons-outlined"> place </i>
                    <span>San Deago,CA USA</span>
                  </li>
                  <li>
                    <i className="material-icons-outlined"> event_note </i>
                    <span>01/09/2021 - 30/09/2021</span>
                  </li>
                  <li>
                    <i className="material-icons-outlined">person_outline</i>
                    <span>15 Participants</span>
                  </li>
                </ul>
              </div>
              <div className="dates_listing">
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                <span className="placeholder placeholder-sm rounded-3 col-2"></span>
              </div>
            </div>

            <div className="dates_pnlItem">
              <span className="dates_nmbr">3</span>
              <div className="dates_hdng">
                <span
                  className="placeholder rounded-3 col-1"
                  style={{ height: "18px" }}
                ></span>
              </div>
              <div className="job_tags">
                <ul>
                  <li>
                    <i className="material-icons-outlined"> place </i>
                    <span>San Deago,CA USA</span>
                  </li>
                  <li>
                    <i className="material-icons-outlined"> event_note </i>
                    <span>01/09/2021 - 30/09/2021</span>
                  </li>
                  <li>
                    <i className="material-icons-outlined">person_outline</i>
                    <span>15 Participants</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="dates_pnlItem">
              <span className="dates_nmbr">4</span>
              <div className="dates_hdng">
                <span
                  className="placeholder rounded-3 col-1"
                  style={{ height: "18px" }}
                ></span>
              </div>
              <div className="job_tags">
                <ul>
                  <li>
                    <i className="material-icons-outlined"> place </i>
                    <span>San Deago,CA USA</span>
                  </li>
                  <li>
                    <i className="material-icons-outlined"> event_note </i>
                    <span>01/09/2021 - 30/09/2021</span>
                  </li>
                  <li>
                    <i className="material-icons-outlined">person_outline</i>
                    <span>15 Participants</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // ----------------------- Placeholder ends here -----------------------
        <div className="course_detailspnl">
          <div className="contnt_hdng">
            <h4>Dates</h4>
          </div>
          {jobDetailsData.phases != null && jobDetailsData.phases.length > 0 ? (
            <div className="dates_pnlOuter">
              {jobDetailsData.phases.map((phase, index) => {
                return (
                  <div
                    className={
                      index == 0
                        ? "dates_pnlItem complete_item"
                        : "dates_pnlItem"
                    }
                    key={phase.id}
                  >
                    <span className="dates_nmbr">{index + 1}</span>
                    {/* ------ Phase name ------ */}
                    <div className="dates_hdng">
                      <h4>{phase.name}</h4>
                    </div>

                    {/* ------ Phase details name ------ */}
                    <div className="job_tags">
                      <ul>
                        {/* Location section */}
                        <li style={{ color: "black" }}>
                          <i className="material-icons-outlined">place</i>
                          <span>
                            {phase.location != null ? phase.location : ""}
                          </span>
                        </li>

                        {/* Dates section */}
                        <li style={{ color: "black" }}>
                          <i className="material-icons-outlined">event_note</i>
                          <span>
                            {phase.fromdate != null ? phase.fromdate : ""} -{" "}
                            {phase.todate != null ? phase.todate : ""}
                          </span>
                        </li>

                        {/* Participants count */}
                        <li style={{ color: "black" }}>
                          <i className="material-icons-outlined">
                            person_outline
                          </i>
                          <span>
                            {phase.participants != null
                              ? phase.participants
                              : "0"}{" "}
                            Participants
                          </span>
                        </li>
                      </ul>
                    </div>

                    {/* ------ KPIS name ------ */}
                    {phase.kpis != null && phase.kpis.length > 0 ? (
                      <div className="dates_listing">
                        <h3>KPIs for evaluation</h3>
                        <ul>
                          {phase.kpis.map((kpi, index) => {
                            return <li key={kpi.id}>{kpi.label}</li>;
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          ) : (
            // ----------------------- Placeholder ends here -----------------------
            <div className="course_detailspnl">
              <div className="contnt_hdng">
                <span
                  className="placeholder rounded-3 col-1"
                  style={{ height: "22px" }}
                ></span>
              </div>

              <div className="dates_pnlOuter">
                <div className="dates_pnlItem complete_item">
                  <span className="dates_nmbr">1</span>
                  <div className="dates_hdng">
                    <span
                      className="placeholder rounded-3 col-1"
                      style={{ height: "18px" }}
                    ></span>
                  </div>
                  <div className="job_tags">
                    <ul>
                      <li>
                        <i className="material-icons-outlined"> place </i>
                        <span>San Deago,CA USA</span>
                      </li>
                      <li>
                        <i className="material-icons-outlined"> event_note </i>
                        <span>01/09/2021 - 30/09/2021</span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">
                          person_outline
                        </i>
                        <span>15 Participants</span>
                      </li>
                    </ul>
                  </div>
                  <div className="dates_listing">
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2"></span>
                  </div>
                </div>

                <div className="dates_pnlItem">
                  <span className="dates_nmbr">2</span>
                  <div className="dates_hdng">
                    <span
                      className="placeholder rounded-3 col-1"
                      style={{ height: "18px" }}
                    ></span>
                  </div>
                  <div className="job_tags">
                    <ul>
                      <li>
                        <i className="material-icons-outlined"> place </i>
                        <span>San Deago,CA USA</span>
                      </li>
                      <li>
                        <i className="material-icons-outlined"> event_note </i>
                        <span>01/09/2021 - 30/09/2021</span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">
                          person_outline
                        </i>
                        <span>15 Participants</span>
                      </li>
                    </ul>
                  </div>
                  <div className="dates_listing">
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2 mb-3"></span>
                    <span className="placeholder placeholder-sm rounded-3 col-2"></span>
                  </div>
                </div>

                <div className="dates_pnlItem">
                  <span className="dates_nmbr">3</span>
                  <div className="dates_hdng">
                    <span
                      className="placeholder rounded-3 col-1"
                      style={{ height: "18px" }}
                    ></span>
                  </div>
                  <div className="job_tags">
                    <ul>
                      <li>
                        <i className="material-icons-outlined"> place </i>
                        <span>San Deago,CA USA</span>
                      </li>
                      <li>
                        <i className="material-icons-outlined"> event_note </i>
                        <span>01/09/2021 - 30/09/2021</span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">
                          person_outline
                        </i>
                        <span>15 Participants</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="dates_pnlItem">
                  <span className="dates_nmbr">4</span>
                  <div className="dates_hdng">
                    <span
                      className="placeholder rounded-3 col-1"
                      style={{ height: "18px" }}
                    ></span>
                  </div>
                  <div className="job_tags">
                    <ul>
                      <li>
                        <i className="material-icons-outlined"> place </i>
                        <span>San Deago,CA USA</span>
                      </li>
                      <li>
                        <i className="material-icons-outlined"> event_note </i>
                        <span>01/09/2021 - 30/09/2021</span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">
                          person_outline
                        </i>
                        <span>15 Participants</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            // ----------------------- Placeholder ends here -----------------------
          )}
        </div>
      )}
    </>
  );
};

export default DateTab;
