/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import { postData } from "utils/Gateway";
import * as url from "helpers/url_helper";
import { useTranslation } from "react-i18next";

//import images
import loginPic from "assets/CourseAssets/images/login-pic.jpg";

// import custom component
import Header from "common/Header";
import Footer from "common/Footer";

const Login = () => {
  const token = localStorage.getItem("token");

  const history = useHistory();
  const { t } = useTranslation();

  // console.log(document.referrer.split("/").pop());

  const [showPassword, setShowPassword] = useState(true);
  // function for change text type
  const toggleText = () => {
    setShowPassword(!showPassword);
  };

  /*----- UseState to send username and password for Login ----*/
  const [data, setData] = useState({
    usemail: "",
    uspass: "",
  });

  const [loginFailedMessage, setLoginFailedMessage] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");

  // function to handle the logIn process
  const LogInHandler = async (e) => {
    e.preventDefault();

    let requestURL =
      url.API_BaseUrl +
      url.API_LoginUrl +
      "?usemail=" +
      data.usemail +
      "&uspass=" +
      data.uspass;

    //console.log("login url -----> ", requestURL);

    if (data.usemail == "" || data.uspass == "") {
      setFailedMessage(`${t("* Please fill up all data")}`);
    } else {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (data.usemail.match(mailformat)) {
        try {
          const response = await postData(requestURL, data);

          //console.log("Received response is ------> ", response);

          if (response.status) {
            // console.log("User Added successfully!");

            setLoginFailedMessage(false);

            // assigning the received values to the browser's local storage.
            localStorage.setItem("token", response.token);

            localStorage.setItem("userInfo", JSON.stringify(response));

            // assigning the loggedin user's id to the browser's local storage.
            localStorage.setItem("userID", JSON.stringify(response.id));

            resetState();
          } else {
            setLoginFailedMessage(true);
            resetState();
          }
        } catch (error) {
          console.log("error at login ---->", error);
        }
      } else {
        setFailedMessage("Please enter valid email id");
      }
    }
  };

  // function to reset the useState containing the email and password text fields
  const resetState = () => {
    setData({ usemail: "", uspass: "" });
  };

  // useEffect for cheking the login token available or not with refferel url
  useEffect(() => {
    // console.log(document.referrer);
    if (token) {
      if (document.referrer == "" || document.referrer == null) {
        history.push("/");
      } else {
        window.location.href = document.referrer;
      }
    }
  }, [token]);

  return (
    <>
      <Header />

      <section className="login_area">
        <div className="container">
          <div className="login_bxArea">
            <div className="signupleft_pnl">
              <div className="login_innr">
                <div className="login_hdng">
                  <h2>{t("Log in")}</h2>
                  <h5>{t("Please enter details to proceed")}</h5>
                </div>

                {loginFailedMessage && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <strong>{t("Login Failed")}</strong>{" "}
                    {t(" Email and Password are not matched")}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={() => {
                        setLoginFailedMessage(false);
                      }}
                    ></button>
                  </div>
                )}

                <p className="text-danger mb-3">{failedMessage}</p>

                <form onSubmit={LogInHandler}>
                  <div className="login_fillOuter">
                    <div className="input_fill jobinput_fill">
                      <label>{t("Email")}</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder={t("Enter email")}
                        value={data.usemail}
                        onChange={(e) => {
                          setData({ ...data, usemail: e.target.value });
                          setLoginFailedMessage(false);
                        }}
                      />
                    </div>
                    <div className="input_fill jobinput_fill">
                      <label>{t("Password")}</label>
                      <div className="eye_btnfill">
                        <input
                          type={showPassword ? "password" : "text"}
                          className="form-control"
                          placeholder={t("Enter Password")}
                          value={data.uspass}
                          onChange={(e) => {
                            setData({ ...data, uspass: e.target.value });
                            setLoginFailedMessage(false);
                          }}
                        />
                        <Link to="#" className="copy_link" onClick={toggleText}>
                          <span className="material-icons-outlined">
                            visibility
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="trms_check login_checkouter">
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>
                        {t("Agree with the")} <Link to="#">T&amp;C</Link>
                      </p>
                    </label>
                    <div className="forgot_links">
                      <Link to="#">{t("Forgot Password")}</Link>
                    </div>
                  </div>
                  <div className="login_submit">
                    <button type="submit" className="basic_btn ylw_btn">
                      {t("Sign in")}
                    </button>
                  </div>
                  <div className="create_userOuter">
                    <div className="create_text">
                      <p>
                        {t("Do not have an account?")}
                        <Link to="/signup">{t("Create one")}</Link>
                      </p>
                    </div>
                    <div className="or_text">
                      <span>{t("or")}</span>
                    </div>
                    <div className="linkedin_btn">
                      <Link to="#">
                        <span>
                          <img src="images/gmail.svg" alt="" />
                        </span>
                        {t("Login with Gmail")}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="signupright_pnl">
              <div className="signup_pic">
                <figure>
                  <img src={loginPic} alt="login" />
                </figure>
                <div className="login_caption">
                  <h2>
                    <span>{t("Discover Indra")} </span> ATM Ecosystem
                  </h2>
                  <p>
                    {t(
                      "More than 25% of the world s total airspace is managed with Indra s Automation Systems"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Login;
