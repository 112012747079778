import React from "react";

const EventProduct = () => {
  return (
    <div>
      <h1>Product here</h1>
    </div>
  );
};

export default EventProduct;
