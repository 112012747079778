/* eslint-disable */
import React from "react";
import Header from "common/Header";
import Footer from "common/Footer";
import BreadCrumb from "components/InvitationComponents/Common/BreadCumb";
import InvitationMain from "components/InvitationComponents/InvitationMain";

const Invitation = () => {
  return (
    <>
      <Header />
      <BreadCrumb />
      <InvitationMain />
      <Footer />
    </>
  );
};

export default Invitation;
