import React from "react";
import { Link } from "react-router-dom";

const EventBreadCumb = () => {
  return (
    <div className="bredcum_area">
      <div className="container">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="#">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="#">Air Traffic</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="#">Indra Air Automation</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Course
            </li>
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default EventBreadCumb;
