/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

import { useTranslation, Trans } from "react-i18next";
/* --------------------------------------------------------------*/

// import inages
import defaultUser from "assets/ActivityAssests/images/default-user.png";

const ActivityLeftpanel = (props) => {
  const token = localStorage.getItem("token");

  // saving loggedin user's Id
  const loggedInUserID = localStorage.getItem("userID");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  //console.log("search value in left panel", props.searchValue);

  const [firstPostId, setFirstPostId] = useState(null);
  const [activityPost, setActivityPost] = useState([]);
  const [loadTopics, setLoadTopics] = useState(false);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [postIsSeleceted, setPostIsSeleceted] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [hideLoadMore, setHideLoadMore] = useState(false);

  // the following useState is used to increment the page number and render it accordingly.
  const [page, setPage] = useState(1);

  /*--- function for get all post lists ---*/
  const listPosts = async () => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITIES_LIST +
      `?token=${token}` +
      `&page=${page}`;

    if (props.searchValue != "") {
      if (props.searchValue.length > 1) {
        requestURL = requestURL + `&query=${props.searchValue}`;
      }
    }

    if (props.title != "") {
      if (props.title.length > 1) {
        requestURL = requestURL + `&query=${props.title}`;
      }
    }

    if (props.contact.length > 0) {
      let contactQuery = "";
      props.contact.map((data) => {
        contactQuery += `&contact[]=${data}`;
      });
      requestURL = requestURL + `${contactQuery}`;
    }

    if (props.event.length > 0) {
      let eventQuery = "";
      props.event.map((data) => {
        eventQuery += `&event[]=${data}`;
      });
      requestURL = requestURL + `${eventQuery}`;
    }

    if (props.group.length > 0) {
      let groupQuery = "";
      props.group.map((data) => {
        groupQuery += `&group[]=${data}`;
      });
      requestURL = requestURL + `${groupQuery}`;
    }

    if (props.tags.length > 0) {
      let tagsQuery = "";
      props.tags.map((data) => {
        tagsQuery += `&tags[]=${data}`;
      });
      requestURL = requestURL + `${tagsQuery}`;
    }

    if (props.category.length > 0) {
      let categoryQuery = "";
      props.category.map((data) => {
        categoryQuery += `&category[]=${data}`;
      });
      requestURL = requestURL + `${categoryQuery}`;
    }

    try {
      // console.log("url in get all list of posts------>", requestURL);

      const response = await getData(requestURL);

      // console.log("Received response in left panel ------> ", response);

      if (response.status && response.data.length > 0) {
        if (page == 1) {
          setActivityPost(response.data);

          if (response.data.length < 7) {
            setHideLoadMore(true);
          } else {
            setHideLoadMore(false);
          }

          if (props.showListPost) {
            props.rightPanelHandler(response.data[0].id);
          }
          if (firstPostId == null) {
            setFirstPostId(response.data[0].id);
          }
          // first post display at right panel
        } else {
          response.data.map((item) => {
            setActivityPost((activityPost) => [...activityPost, item]);
          });
        }
        setIsMoreLoading(false);
      } else {
        setActivityPost(response.data);
        props.rightPanelHandler(null);
        setEmptyMessage("Sorry! No more posts are available");
        setIsMoreLoading(false);
      }
      setLoadTopics(false);
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
      scrollToTop();
      setLoadTopics(false);
    }
  };

  /*--- function for get more post details by increasing page number ---*/
  const loadMoreHandler = () => {
    if (emptyMessage == "") {
      setPage(page + 1);
      setIsMoreLoading(true);
    }
  };

  /*--- function for scroll to top when page reloads ---*/
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*--- function for send post id to main activity component ---*/
  const rightPanelHandler = (item) => {
    props.rightPanelHandler(item.id);
    document.body.classList.add("activity_open");
  };

  //function for delete forum
  const deleteForum = async (item) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_REMOVE_FORUM +
      `?token=${token}` +
      `&fid=${item.id}`;

    // console.log("remove comment url ---->", requestURL);

    try {
      const response = await getData(requestURL);

      // console.log("Remove response---> ", response);

      if (response.status) {
        props.refreshActivity();
      } else {
        console.log("error in response");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // list post runs by search query
  useEffect(() => {
    if (props.showListPost) {
      if (emptyMessage == "Sorry! No more posts are available") {
        setEmptyMessage("");
      }
      setLoadTopics(true);
      setPage(1);
      listPosts();
      props.hideListPost();
    }
  }, [props.showListPost]);

  // list post runs by page number
  useEffect(() => {
    if (isMoreLoading) {
      setLoadTopics(false);
    } else {
      setLoadTopics(true);
    }
    listPosts();
  }, [page]);

  // useEffect for set default post for right panel
  useEffect(() => {
    if (firstPostId != null) {
      props.rightPanelHandler(firstPostId);
    }
  }, [firstPostId]);

  return (
    <>
      {loadTopics ? (
        <div className="glow_area list_glowArea">
          <div className="plan p-3 border rounded-3 placeholder-glow">
            <span className="placeholder placeholder-sm col-12 rounded-3 mb-3"></span>
            <span className="placeholder placeholder-sm col-10 rounded-3 mb-3"></span>
            <div className="user_placeHoledr">
              <div className="default_usr">
                <img src={defaultUser} alt="default-user" />
              </div>
              <div className="default_usr_name">
                <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              </div>
            </div>
            <div className="data_btnOuter">
              <span className="placeholder placeholder-sm col-3 rounded-3"></span>
              <span className="placeholder placeholder-sm col-3 rounded-3"></span>
              <span className="placeholder placeholder-sm col-3 rounded-3"></span>
            </div>
            <span className="placeholder placeholder-sm col-2 rounded-3 mb-3"></span>
            <p className="data_btnOuter placeholder-glow">
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="activity_leftpnl">
            {activityPost.map((item, index) => {
              return (
                <div className="activity_lftItem" key={index}>
                  <div className="activty_lftHdng">
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        rightPanelHandler(item);
                      }}
                    >
                      {item.title}
                    </h3>
                  </div>
                  <div className="activity_userOuter">
                    <div className="activity_usrPnl">
                      <div className="user_activty">
                        <img src={item.moderator.thumbnail} alt="" />
                      </div>
                      <div className="activty_usrname">
                        <h4>{item.moderator.name}</h4>
                      </div>
                    </div>
                    <div className="activity_date">
                      <i className="material-icons-round">calendar_today</i>
                      <span>{item.created}</span>
                    </div>
                    {item.moderator.id == loggedInUserID ? (
                      <div
                        className="activity_date"
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteForum(item)}
                      >
                        <i className="material-icons-round">delete</i>
                        <span>{t("Remove")}</span>
                      </div>
                    ) : null}
                  </div>
                  <div className="winers_likeOuter">
                    <div className="likecmnts_pnl">
                      <div className="form_share">
                        <p>
                          {item.isglobal == "1" ? (
                            <i className="material-icons-outlined">public</i>
                          ) : (
                            <i className="material-icons-outlined">settings</i>
                          )}
                        </p>
                        <p>
                          <i className="material-icons-outlined">thumb_up</i>
                          <em>{t("Likes")}</em>
                          <span>{item.likes}</span>
                        </p>
                        <p>
                          <i className="material-icons-outlined">chat</i>
                          <em>{t("Comment")}</em>
                          <span>{item.comments.count}</span>
                        </p>
                      </div>
                    </div>
                    {item.winner.length == 0 || item.winner == null ? null : (
                      <div
                        className="winner_user"
                        tabIndex="0"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={item.winner.name}
                      >
                        <div className="activty_usrname">
                          <h5>Winner</h5>
                        </div>

                        <div className="user_activty">
                          <img
                            src={
                              item.winner.image
                                ? item.winner.image
                                : defaultUser
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="activity_tagsouter">
                    {item.tags.length == 0 ? null : (
                      <div className="activity_tagsRow">
                        <h4>{t("Tags")}</h4>
                        <div className="experts_skill activity_skill">
                          <ul>
                            {item.tags.slice(0, 4).map((item2, index2) => {
                              return (
                                <li key={index2}>
                                  <Link to="#">{item2.label}</Link>
                                </li>
                              );
                            })}
                          </ul>

                          {item.tags.length > 4 ? (
                            <div className="more_tags">
                              <Link to="#">
                                +{item.tags.length - 4}
                                {t("more")}
                              </Link>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                    {item.categories.length == 0 ? null : (
                      <div className="activity_tagsRow">
                        <h4>{t("Categories")}</h4>
                        <div className="experts_skill activity_skill">
                          <ul>
                            {item.categories
                              .slice(0, 4)
                              .map((item2, index2) => {
                                return (
                                  <li key={index2}>
                                    <Link to="#" key={index2}>
                                      {item2.label}
                                    </Link>
                                  </li>
                                );
                              })}
                          </ul>
                          {item.categories.length >= 4 ? (
                            <div className="more_tags">
                              <Link to="#">
                                +{item.categories.length} {t("more")}
                              </Link>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {emptyMessage == "" ? (
            <div className="loadmoreBtn text-center">
              {hideLoadMore ? null : (
                <Link
                  to="#"
                  className="basic_btn"
                  role="button"
                  onClick={loadMoreHandler}
                >
                  {t("Load More")}
                  {isMoreLoading == true ? (
                    <div
                      className="mx-2 spinner-border spinner-border-sm text-white"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : null}
                </Link>
              )}
            </div>
          ) : (
            <div className="loadmoreBtn text-center">
              <Link to="#" className="basic_btn">
                {emptyMessage}
              </Link>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ActivityLeftpanel;
