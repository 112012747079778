/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormdata } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */
// importing context API here
import { GlobalIndraInfo } from "App";
//import images
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import defaultUser from "assets/ConversationAssests/images/default-user.png";

const Review = () => {
  // accessing the user's token here
  const token = localStorage.getItem("token");

  // get info from context
  const { subscriberStatus, joinIsLoading, joinNowConfirm } =
    useContext(GlobalIndraInfo);

  // 👇️ get ID from url
  const params = useParams();

  // initial useState to save the comment
  const [comments, setComments] = useState("");

  // initial useState to save star rating
  const [starRating, setstarRating] = useState(1);

  /* ------------ Initial useStates for activating the starts starts here ------------ */
  const [activeFirstStar, setActiveFirstStar] = useState(true);
  const [activeSecondStar, setActiveSecondStar] = useState(false);
  const [activeThirdStar, setActiveThirdStar] = useState(false);
  const [activeFourthStar, setActiveFourthStar] = useState(false);
  const [activeFiveStar, setActiveFifthStar] = useState(false);

  // function to display the star ratings
  const highlightStars = (e, value) => {
    e.preventDefault();

    if (value == 1) {
      setActiveFirstStar(true);
      setActiveSecondStar(false);
      setActiveThirdStar(false);
      setActiveFourthStar(false);
      setActiveFifthStar(false);
    }

    if (value == 2) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(false);
      setActiveFourthStar(false);
      setActiveFifthStar(false);
    }

    if (value == 3) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(false);
      setActiveFifthStar(false);
    }

    if (value == 4) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
      setActiveFifthStar(false);
    }

    if (value == 5) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
      setActiveFifthStar(true);
    }
  };
  /* ------------ Initial useStates for activating the starts ends here ------------ */

  // Intitial useState to save all the review from the API
  const [reviewsList, setReviewsList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [ratingError, setRatingError] = useState(false);
  const [commentError, setCommentError] = useState(false);

  // Function to submit the review to the API.
  const reviewSubmitHandler = async (e) => {
    e.preventDefault();
    setCommentError(false);
    setRatingError(false);
    if (comments == "" || starRating == 0) {
      if (comments == "") {
        setCommentError(true);
      } else {
        setCommentError(false);
      }
      if (starRating == 0) {
        setRatingError(true);
      } else {
        setRatingError(false);
      }
    } else {
      let requestURL =
        url.API_BaseUrl + url.API_COURSE_RATING + `?token=${token}`;

      // console.log("Review API sending request --------> ", requestURL);

      let reviewData = {
        mod: "MOD_USER",
        com: params.id,
        comments: comments,
        ratings: starRating,
      };

      // console.log("Review API sending data --------> ", reviewData);

      try {
        const response = await postFormdata(requestURL, reviewData);

        // console.log(
        //   "Review API receiving review response --------> ",
        //   response
        // );

        if (response.status) {
          // console.log("Data submitted successfully", response);
          resetHandler();
          getReviewsLists(params.id);
        } else {
          console.log("Some error occurred while submitting the data!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Function to get all the review data from API
  const getReviewsLists = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_COURSE_VIEW_REVIEWS +
      `?token=${token}` +
      `&mod=MOD_USER` +
      `&com=${id}`;

    // console.log("View Review API sending request --------> ", requestURL);

    try {
      const response = await getData(requestURL);

      console.log("View Review API receiving response --------> ", response);

      if (response.status) {
        // console.log("Data submitted successfully", response);
        setReviewsList(response.data);
      } else {
        // console.log("Some error occurred while submitting the data!");
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error in get review---->", error);
    }
  };

  // Function to select the star ratings from the stars
  const selectedStar = (e, star) => {
    e.preventDefault();
    // console.log("Selected Star rating -----> ", star);
    setstarRating(star);
  };

  // function to reset the star and comments
  const resetHandler = () => {
    setActiveFirstStar(true);
    setActiveSecondStar(false);
    setActiveThirdStar(false);
    setActiveFourthStar(false);
    setActiveFifthStar(false);
    setComments("");
  };

  // useEffect starts here
  useEffect(() => {
    getReviewsLists(params.id);
  }, [params.id]);

  return (
    <>
      <div className="tab-pane fade show active" id="feedbacks">
        {isLoading ? (
          <div className="course_detailspnl">
            <div className="contnt_hdng">
              <span
                className="placeholder rounded-3 col-2"
                style={{ height: "22px" }}
              ></span>
            </div>
            <div className="customer_feedbacks">
              <div className="recomend_row">
                <div className="team_userOuter forum_usrOuter">
                  <div className="team_usr_pic">
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                    ></span>
                  </div>
                  <div className="team_usrTxt flex-fill">
                    <span className="placeholder placeholder-md rounded-2 col-1 mb-2"></span>
                    <span className="placeholder placeholder-sm rounded-2 col-1"></span>
                    <div className="rating_star">
                      <span
                        className="placeholder rounded-2 mt-3"
                        style={{ width: "88px", height: "22px" }}
                      ></span>
                    </div>
                    <div className="forum_para">
                      <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                      <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                    </div>
                    <div className="feedback_share">
                      <ul>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: " 35px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: " 35px" }}
                          ></span>
                        </li>
                      </ul>
                    </div>
                    <div className="post_time">
                      <span
                        className="placeholder placeholder-sm rounded-2"
                        style={{ width: "80px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recomend_row">
                <div className="team_userOuter forum_usrOuter">
                  <div className="team_usr_pic">
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                    ></span>
                  </div>
                  <div className="team_usrTxt flex-fill">
                    <span className="placeholder placeholder-md rounded-2 col-1 mb-2"></span>
                    <span className="placeholder placeholder-sm rounded-2 col-1"></span>
                    <div className="rating_star">
                      <span
                        className="placeholder rounded-2 mt-3"
                        style={{ width: "88px", height: "22px" }}
                      ></span>
                    </div>
                    <div className="forum_para">
                      <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                      <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                    </div>
                    <div className="feedback_share">
                      <ul>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                      </ul>
                    </div>
                    <div className="post_time">
                      <span
                        className="placeholder placeholder-sm rounded-2"
                        style={{ width: " 80px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recomend_row">
                <div className="team_userOuter forum_usrOuter">
                  <div className="team_usr_pic">
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                    ></span>
                  </div>
                  <div className="team_usrTxt flex-fill">
                    <span className="placeholder placeholder-md rounded-2 col-1 mb-2"></span>
                    <span className="placeholder placeholder-sm rounded-2 col-1"></span>
                    <div className="rating_star">
                      <span
                        className="placeholder rounded-2 mt-3"
                        style={{ width: "88px", height: "22px" }}
                      ></span>
                    </div>
                    <div className="forum_para">
                      <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                      <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                    </div>
                    <div className="feedback_share">
                      <ul>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "35px", height: "35px" }}
                          ></span>
                        </li>
                      </ul>
                    </div>
                    <div className="post_time">
                      <span
                        className="placeholder placeholder-sm rounded-2"
                        style={{ width: "80px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="course_detailspnl">
            <div className="contnt_hdng">
              <h4>Review</h4>
            </div>

            <div className="form_commnts_area commnts_newbx">
              <div className="course_rvw_row">
                <div className="rating_hdng">
                  <h5>Give Ratings</h5>
                </div>
                <ul>
                  {/* ----------- 1 Star ----------- */}
                  <li
                    className={activeFirstStar == true ? "active_star" : ""}
                    onClick={(e) => {
                      selectedStar(e, 1);
                      highlightStars(e, 1);
                    }}
                  >
                    <Link to="#">
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>

                  {/* ----------- 2 Star ----------- */}
                  <li
                    className={activeSecondStar == true ? "active_star" : ""}
                    onClick={(e) => {
                      selectedStar(e, 2);
                      highlightStars(e, 2);
                    }}
                  >
                    <Link to="#">
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>

                  {/* ----------- 3 Star ----------- */}
                  <li
                    className={activeThirdStar == true ? "active_star" : ""}
                    onClick={(e) => {
                      selectedStar(e, 3);
                      highlightStars(e, 3);
                    }}
                  >
                    <Link to="#">
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>

                  {/* ----------- 4 Star ----------- */}
                  <li
                    className={activeFourthStar == true ? "active_star" : ""}
                    onClick={(e) => {
                      selectedStar(e, 4);
                      highlightStars(e, 4);
                    }}
                  >
                    <Link to="#">
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>

                  {/* ----------- 5 Star ----------- */}
                  <li
                    className={activeFiveStar == true ? "active_star" : ""}
                    onClick={(e) => {
                      selectedStar(e, 5);
                      highlightStars(e, 5);
                    }}
                  >
                    <Link to="#">
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>
                </ul>

                {ratingError && (
                  <p className="text-danger mb-3">* Please give rating</p>
                )}
              </div>
              <div className="commnts_send_innr">
                <div className="rating_hdng">
                  <h5>Give Comments</h5>
                </div>
                <div className="commnts_sendArea">
                  <textarea
                    className="form-control"
                    placeholder="Write your comments here"
                    value={comments != "" ? comments : ""}
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                  <div className="furmcmnt_btn">
                    <button
                      className="cmnt_btn"
                      onClick={(e) => reviewSubmitHandler(e)}
                    >
                      <span className="material-icons-outlined">send</span>
                    </button>
                  </div>
                </div>
                {commentError && (
                  <p className="text-danger mt-2">* Please add comment</p>
                )}
              </div>
            </div>

            {/* ------- All reviews list section (course like) starts here ------- */}
            <div className="review_bxOuter">
              {reviewsList != null && reviewsList.length > 0 ? (
                <div className="row">
                  {reviewsList.map((review, index) => {
                    return (
                      <div className="col-lg-6" key={review.id}>
                        <div className="review_bxItem">
                          <div className="forum_cmntsRow">
                            <div className="team_userOuter forum_usrOuter">
                              {/* -------- User image section starts here -------- */}
                              <div className="team_usr_pic">
                                <img
                                  src={
                                    review.userimage != null
                                      ? review.userimage
                                      : defaultUser
                                  }
                                  alt="UserProfilePic"
                                />
                              </div>
                              {/* -------- User image section ends here -------- */}

                              {/* -------- User details & comments section starts here -------- */}
                              <div className="team_usrTxt">
                                <div className="time_text">
                                  <h5>{review.created}</h5>
                                </div>
                                <h3>{review.user}</h3>
                                <h6>{review.useremail}</h6>
                                <div className="comnt_rating cstomer_tvw">
                                  <ul>
                                    {_.times(review.globalrating, (i) => (
                                      <li className="active_star" key={i}>
                                        <Link to="#">
                                          <i className="material-icons-outlined">
                                            star
                                          </i>
                                        </Link>
                                      </li>
                                    ))}
                                    {/* {_.times(5 - review.globalrating, (i) => (
                                    <li key={i}>
                                      <Link to="#">
                                        <i className="material-icons-outlined">
                                          star
                                        </i>
                                      </Link>
                                    </li>
                                  ))} */}
                                  </ul>
                                </div>
                                <div className="forum_para">
                                  <p>{review.comment}</p>
                                </div>

                                {/* ------- Like & comment button section starts here ------- */}
                                {/* <div className="form_share">
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    thumb_up
                                  </i>
                                  <em>Likes</em>
                                  <span>2</span>
                                </Link>
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    {" "}
                                    chat{" "}
                                  </i>
                                  <em>Comment</em>
                                  <span>3</span>
                                </Link>
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    {" "}
                                    share{" "}
                                  </i>
                                </Link>
                              </div> */}
                                {/* ------- Like & comment button section endss here ------- */}
                              </div>
                              {/* -------- User details & comments section ends here -------- */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="row">
                  <div className="alert alert-info" role="alert">
                    No reviews available!
                  </div>
                </div>
              )}
            </div>
            {/* ------- All reviews list section (course like) ends here ------- */}
          </div>
        )}
      </div>
    </>
  );
};

export default Review;
