/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalIndraInfo } from "App";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

//import images
import defaultUser from "assets/ConversationAssests/images/default-user.png";
import blankInvite from "assets/ChampionAssets/images/people-collaborating-with-tech- 1.png";

import { useTranslation } from "react-i18next";

const Sent = (props) => {
  const token = localStorage.getItem("token");

  //initial for recieved lists
  const [sentLists, setSentLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // function for all recieved lists
  const getSentLists = async () => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_INVITE_LISTS +
      `?token=${token}` +
      `&state=sent`;

    // console.log("request url for champion lists----->", requestURL);
    try {
      const response = await getData(requestURL);
      console.log("Received Response in sent lists --------> ", response);

      if (response.status && response.data) {
        setSentLists(response.data);
      } else {
        console.log("Some error occurred ------> ", response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getSentLists();
  }, []);

  useEffect(() => {
    props.isLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <div className="invite_table_innr">
          <table>
            <tr>
              <td>
                <div className="user_placeHoledr">
                  <div className="default_usr invite_usr">
                    <img src={defaultUser} alt="user" />
                  </div>
                  <div className="tble_usrtext_glow">
                    <span className="placeholder placeholder-sm col-10 rounded-3"></span>{" "}
                    <br />
                    <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                  </div>
                </div>
              </td>
              <td>
                <div className="tble_usrtext_glow">
                  <span className="placeholder placeholder-sm col-10 rounded-3"></span>{" "}
                  <br />
                  <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                </div>
              </td>
              <td>
                <div className="tble_usrtext_glow">
                  <span className="placeholder placeholder-sm col-10 rounded-3"></span>{" "}
                  <br />
                  <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                </div>
              </td>
              <td>
                <div
                  className="tble_usrtext_glow"
                  style={{ display: "flex", gap: "10px" }}
                >
                  <span className="placeholder placeholder-sm col-5 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-5 rounded-3"></span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <div className="invite_table_innr">
          {sentLists.length == 0 ? (
            <div className="invite_blank_image">
              <img src={blankInvite} alt="" />
            </div>
          ) : (
            <>
              {sentLists.map((list, index) => {
                return (
                  <table key={index}>
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="invite_usrOuter">
                            <div className="invite_usr">
                              <img
                                src={list.image ? list.image : defaultUser}
                                alt=""
                              />
                            </div>
                            <div className="invite_text">
                              {list.name && <h4>{list.name}</h4>}
                              {list.position && <h5>{list.position}</h5>}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="invite_text">
                            {list.reason && <h4>{list.reason}</h4>}
                            {list.relation && <h5>{list.relation}</h5>}
                          </div>
                        </td>
                        <td>
                          <div className="invite_text">
                            <h4>Date</h4>
                            <h5>14 August,2022</h5>
                          </div>
                        </td>
                        <td>
                          <div className="action_btnOuter">
                            {list.approved ? (
                              <Link to="#" className="basic_btn grn_btn">
                                Accepted
                              </Link>
                            ) : (
                              <Link to="#" className="basic_btn pending_btn ">
                                Pending
                              </Link>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                );
              })}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Sent;
