import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const HomeActivityLists = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section className="activity_sec pd_30">
      <div className="container">
        <div className="slideHdng_outer">
          <div className="page_hdng">
            <h2>Best Activity </h2>
          </div>
          <div className="see_moreBtn">
            <Link to="/activity" className="border_btn ylwbrdr_btn">
              See All
            </Link>
          </div>
        </div>
        <div className="slide_Outer">
          <div className="activity_slider slider_innr">
            <Carousel ssr showDots responsive={responsive}>
              <div className="slide_Item mb-4">
                <div className="activity_slideItem">
                  <div className="activity_usrPnl">
                    <div className="user_activty">
                      <img src="images/expert1.png" alt="" className="" />
                    </div>
                    <div className="activty_usrname">
                      <h4>Gordon Ramsay</h4>
                      <h5>20/07/2021 17:31</h5>
                    </div>
                  </div>
                  <div className="activity_para">
                    <p>
                      I read on some website that the 1600 and 2600 was better,
                      but, they are both worse than the 9600k. And, anyways I
                      already have a B365 LGA 1151 Gen 2 motherboard, so, it's
                      the most logical upgrade....
                      <Link to="#">Read More</Link>
                    </p>
                  </div>
                  <div className="activty_comnts_likelist">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">thumb_up</i>
                          <span>2.5k</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">chat</i>
                          <span>3</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">share</i>
                          <span>3</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="activity_slideItem">
                  <div className="activity_usrPnl">
                    <div className="user_activty">
                      <img src="images/expert1.png" alt="" className="" />
                    </div>
                    <div className="activty_usrname">
                      <h4>Gordon Ramsay</h4>
                      <h5>20/07/2021 17:31</h5>
                    </div>
                  </div>
                  <div className="activity_para">
                    <p>
                      I read on some website that the 1600 and 2600 was better,
                      but, they are both worse than the 9600k. And, anyways I
                      already have a B365 LGA 1151 Gen 2 motherboard, so, it's
                      the most logical upgrade....
                      <Link to="#">Read More</Link>
                    </p>
                  </div>
                  <div className="activty_comnts_likelist">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">thumb_up</i>
                          <span>2.5k</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">chat</i>
                          <span>3</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">share</i>
                          <span>3</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="activity_slideItem">
                  <div className="activity_usrPnl">
                    <div className="user_activty">
                      <img src="images/expert1.png" alt="" className="" />
                    </div>
                    <div className="activty_usrname">
                      <h4>Gordon Ramsay</h4>
                      <h5>20/07/2021 17:31</h5>
                    </div>
                  </div>
                  <div className="activity_para">
                    <p>
                      I read on some website that the 1600 and 2600 was better,
                      but, they are both worse than the 9600k. And, anyways I
                      already have a B365 LGA 1151 Gen 2 motherboard, so, it's
                      the most logical upgrade....
                      <Link to="#">Read More</Link>
                    </p>
                  </div>
                  <div className="activty_comnts_likelist">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">thumb_up</i>
                          <span>2.5k</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">chat</i>
                          <span>3</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">share</i>
                          <span>3</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="activity_slideItem">
                  <div className="activity_usrPnl">
                    <div className="user_activty">
                      <img src="images/expert1.png" alt="" className="" />
                    </div>
                    <div className="activty_usrname">
                      <h4>Gordon Ramsay</h4>
                      <h5>20/07/2021 17:31</h5>
                    </div>
                  </div>
                  <div className="activity_para">
                    <p>
                      I read on some website that the 1600 and 2600 was better,
                      but, they are both worse than the 9600k. And, anyways I
                      already have a B365 LGA 1151 Gen 2 motherboard, so, it's
                      the most logical upgrade....
                      <Link to="#">Read More</Link>
                    </p>
                  </div>
                  <div className="activty_comnts_likelist">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">thumb_up</i>
                          <span>2.5k</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">chat</i>
                          <span>3</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">share</i>
                          <span>3</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="activity_slideItem">
                  <div className="activity_usrPnl">
                    <div className="user_activty">
                      <img src="images/expert1.png" alt="" className="" />
                    </div>
                    <div className="activty_usrname">
                      <h4>Gordon Ramsay</h4>
                      <h5>20/07/2021 17:31</h5>
                    </div>
                  </div>
                  <div className="activity_para">
                    <p>
                      I read on some website that the 1600 and 2600 was better,
                      but, they are both worse than the 9600k. And, anyways I
                      already have a B365 LGA 1151 Gen 2 motherboard, so, it's
                      the most logical upgrade....
                      <Link to="#">Read More</Link>
                    </p>
                  </div>
                  <div className="activty_comnts_likelist">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">thumb_up</i>
                          <span>2.5k</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">chat</i>
                          <span>3</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">share</i>
                          <span>3</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="activity_slideItem">
                  <div className="activity_usrPnl">
                    <div className="user_activty">
                      <img src="images/expert1.png" alt="" className="" />
                    </div>
                    <div className="activty_usrname">
                      <h4>Gordon Ramsay</h4>
                      <h5>20/07/2021 17:31</h5>
                    </div>
                  </div>
                  <div className="activity_para">
                    <p>
                      I read on some website that the 1600 and 2600 was better,
                      but, they are both worse than the 9600k. And, anyways I
                      already have a B365 LGA 1151 Gen 2 motherboard, so, it's
                      the most logical upgrade....
                      <Link to="#">Read More</Link>
                    </p>
                  </div>
                  <div className="activty_comnts_likelist">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">thumb_up</i>
                          <span>2.5k</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">chat</i>
                          <span>3</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">share</i>
                          <span>3</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeActivityLists;
