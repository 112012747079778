/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalIndraInfo } from "App";
import Select from "react-select";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormdata } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const Step2 = (props) => {
  // Getting the course ID created in Step 1
  const { courseId, onConfirmStep2, onConfirmStep1 } =
    useContext(GlobalIndraInfo);

  // Initial useState for Notification component
  const [isSubmittingData, setIsSubmittingData] = useState(false);

  // console.log("course id in step 2----->", courseId);

  const history = useHistory();

  // accessing the user's token here
  const token = localStorage.getItem("token");

  // Initial useState to save received instructor data from API
  const [featuresData, setFeaturesData] = useState([]);

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();
  /* ------------- Language translation imports ends here ------------- */

  const [features, setFeatures] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [skills, setSkills] = useState([]);

  /*----- feature repeater start -----*/
  const extraFeatureTemplate = {
    feature: "",
  };

  // initial state for feature block
  const [featuresBlock, setFeatureBlock] = useState([extraFeatureTemplate]);

  // add feature block
  const onAddFeatureBlock = () => {
    setFeatureBlock([...featuresBlock, extraFeatureTemplate]);
  };

  // insert value author block
  const featureBlockHandler = (e, index) => {
    const updateFeatureBlock = featuresBlock.map((item, i) =>
      index == i
        ? Object.assign(item, { [e.target.name]: e.target.value })
        : item
    );

    setFeatureBlock(updateFeatureBlock);

    const featureValue = updateFeatureBlock.map((item, number) => {
      return item;
    });
    setFeatures(featureValue);
  };

  // map features value to form repeater
  const updateFeatureBlock = (features) => {
    let arr = [...featuresData];

    features.map(function (item) {
      // restructing json object to map with form data
      let featureSavedBlockElement = {
        id: item.id,
        feature: item.label,
      };

      arr.push(featureSavedBlockElement);

      // console.log("arr", arr);
    });

    setFeatureBlock(arr);
  };

  // delete author block
  const onDeleteFeatureBlock = async (item, index) => {
    let featureId = {
      featureid: item.id,
    };

    let requestURL =
      url.API_BaseUrl +
      url.API_DELETE_FORM_REPEATER +
      `?token=${token}` +
      `&cid=${courseId}`;

    try {
      let response = await postFormdata(requestURL, featureId);
      if (response.status) {
        console.log(response.status);
      } else {
        console.log(response.status);
      }
      const filteredFeature = [...featuresBlock];
      filteredFeature.splice(index, 1);
      setFeatureBlock(filteredFeature);
      const featureValue = filteredFeature.map((item) => {
        return item;
      });
      setFeatures(featureValue);
    } catch (error) {}
  };
  /*----- feature repeater end -----*/

  /*----- requirement one repeater start -----*/
  // Initial useState to save received instructor data from API
  const [requirementsData, setRequirementsData] = useState([]);

  const requirementsTemplate = {
    requirement: "",
  };

  // initial state for requirement one block
  const [requirementsBlock, setRequirementsBlock] = useState([
    requirementsTemplate,
  ]);

  // add requirement one block
  const onAddRequirementsBlock = () => {
    setRequirementsBlock([...requirementsBlock, requirementsTemplate]);
  };

  // insert value requirement one block
  const requirementsBlockHandler = (e, index) => {
    const updateRequirementOneBlock = requirementsBlock.map((item, i) =>
      index == i
        ? Object.assign(item, { [e.target.name]: e.target.value })
        : item
    );

    setRequirementsBlock(updateRequirementOneBlock);

    const requirementValue = updateRequirementOneBlock.map((item) => {
      return item;
    });

    setRequirements(requirementValue);
  };

  // map requirements value to form repeater
  const updateRequirementsBlock = (requirements) => {
    let arr = [...requirementsData];

    requirements.map(function (item) {
      // restructing json object to map with form data
      let requirementSavedBlockElement = {
        id: item.id,
        requirement: item.label,
      };

      arr.push(requirementSavedBlockElement);

      // console.log("arr", arr);
    });

    setRequirementsBlock(arr);
  };

  // delete requirement one block
  const onDeleteRequirementsBlock = async (item, index) => {
    let requirementId = {
      requirementid: item.id,
    };

    let requestURL =
      url.API_BaseUrl +
      url.API_DELETE_FORM_REPEATER +
      `?token=${token}` +
      `&cid=${courseId}`;

    try {
      let response = await postFormdata(requestURL, requirementId);

      const filteredRequirementOne = [...requirementsBlock];
      filteredRequirementOne.splice(index, 1);
      setRequirementsBlock(filteredRequirementOne);
      const requirementValue = filteredRequirementOne.map((item, number) => {
        return item;
      });

      setFeatures(requirementValue);
    } catch (error) {
      console.log(error);
    }
  };
  /*----- requirement one repeater end -----*/

  /* ----------------- Skills multi select requirements starts here ----------------- */
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState(null);

  // function to get dropdown options from API
  const getSkills = async () => {
    const requestURL =
      url.API_BaseUrl + url.API_CATEGORIES_DROPDOWN_OPTIONS + `?token=${token}`;

    //console.log(requestURL);
    try {
      const response = await getData(requestURL);

      //console.log("Received response in category ------> ", response);

      if (response.status) {
        //console.log("Category response is----->", response.data.options);
        setSkillsOptions(response.data);
      } else {
        // console.log("Some error occurred!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // custom style for category
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "5px",
      fontFamily: "sans-serif",
      border: "none",
      backgroundColor: "#F0F0F0",
      height: 50,
      borderRadius: "8px",
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8E8E",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  const skillsSelectionHandler = (val) => {
    let skillsVal = val.map((item) => {
      let skillsValObject = {
        label: item.label,
        id: item.id,
      };
      return skillsValObject;
    });

    if (skillsVal.length != 0) {
      setSelectedSkills();
      setSkills(skillsVal);
    }
  };
  /* ----------------- Skills multi select requirements ends here ----------------- */

  /*---- function for go to next step -----*/
  const nextStepHandler = async (e) => {
    e.preventDefault();
    setIsSubmittingData(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_STEP_2_COURSE_CREATE +
      `?token=${token}` +
      `&cid=${courseId}`;

    console.log("Step 2 sending request --------> ", requestURL);

    let stepTwoData = {
      features: features,
      requirements: requirements,
      skills: skills,
    };

    let stepTwoState = true;

    try {
      const response = await postData(requestURL, stepTwoData);

      console.log("Step 2 sending response --------> ", response);

      if (response.status) {
        console.log("Data submitted successfully", response);
        setIsSubmittingData(false);
        history.push("/course/add/step3");
        onConfirmStep2(stepTwoState);
      } else {
        console.log("Some error occurred while submitting the data!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*---- end of function for go to next step -----*/

  /*---- function to fetch the course details saved in step 1 -----*/
  const viewCourseDetailsHandler = async (id) => {
    const requestURL =
      url.API_BaseUrl +
      url.API_STEP_2_COURSE_VIEW +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      const response = await getData(requestURL);
      // console.log("step two view details response----->", response);
      if (response.status) {
        let skillsArr = [];
        response.data.skills.map((item) => {
          // console.log("categories in view", item);
          let skillsObject = {
            id: item.id,
            value: item.id,
            name: item.label,
            label: item.label,
            slug: item.label,
          };

          skillsArr.push(skillsObject);
        });
        skillsSelectionHandler(skillsArr);
        setSelectedSkills(skillsArr);
        // setSavedData(response.data);
        if (response.data.features.length > 0) {
          updateFeatureBlock(response.data.features);
        }

        if (response.data.requirements.length > 0) {
          updateRequirementsBlock(response.data.requirements);
        }
        setSkills(response.data.skills);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*---- end of function to fetch the course details saved in step 1 -----*/

  /* Function to redirect to the next page without calling the API */
  const goToNextPageHandler = (e) => {
    e.preventDefault();
    history.push("/course/add/step3");

    // set false condition if user redirect to previous page
    let stepTwoState = true;
    onConfirmStep2(stepTwoState);
  };

  /* Function to redirect to the previous page without calling the API */
  const goToPreviousPageHandler = (e) => {
    e.preventDefault();
    history.push("/course/add/step1");

    // set false condition if user redirect to previous page
    let stepTwoState = false;
    onConfirmStep2(stepTwoState);
  };

  /* useEffect function from here */
  useEffect(() => {
    let stepTwoState = false;
    // fetching the category options
    getSkills();

    // check whether course id available or not
    if (courseId != null) {
      stepTwoState = true;
      viewCourseDetailsHandler(courseId);
    }
    onConfirmStep2(stepTwoState);
  }, [courseId]);

  return (
    <>
      <div className="col-lg-9">
        {/* ------------- Mobile View back button starts here ------------- */}
        <div className="back_progress">
          <div className="page_back">
            <Link to="#">
              <span className="material-icons-outlined"> arrow_back </span>
              Back
            </Link>
          </div>
          <div className="step_progress">
            <span className="progress_bar" style={{ width: "35%" }}></span>
          </div>
        </div>
        {/* ------------- Mobile View back button starts here ------------- */}

        {/* ------------- Form section starts here ------------- */}
        <div className="course_uploadrghtpnl">
          <div className="course_itemInnr">
            <div className="course_stepItem">
              <div className="coursestep_hdng">
                <h3>
                  <Trans i18nKey="What will students learn in your course">
                    What will students learn in your course
                  </Trans>
                  ?
                </h3>
                <p>
                  <Trans i18nKey="Add_5">
                    Add 5 most importants features about your course that will
                    benifit every student
                  </Trans>
                </p>
              </div>
              <div className="course_fillTop">
                {/* ---------- Display the Features section starts here ---------- */}

                {/* ---------- Display the Features section starts here ---------- */}

                {/* ---- repeater feature start ---- */}
                {featuresBlock.map((item, index) => {
                  return (
                    <div
                      className="input_fill jobinput_fill d-flex align-items-center "
                      key={index}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Feature"
                        name="feature"
                        value={item.feature}
                        onChange={(e) => featureBlockHandler(e, index)}
                      />

                      {/* --------- delete button starts here --------- */}
                      {index == 0 ? null : (
                        <div>
                          <Link
                            to="#"
                            role="button"
                            onClick={() => onDeleteFeatureBlock(item, index)}
                          >
                            <span className="material-icons-outlined">
                              clear
                            </span>
                          </Link>
                        </div>
                      )}

                      {/* --------- delete button ends here --------- */}
                    </div>
                  );
                })}
                {/* ---- repeater feature end ---- */}
              </div>

              {/* ---- add button for repeater feature ---- */}
              <div className="addfill_btn">
                <Link
                  to="#"
                  className="add_plusBtn"
                  role="button"
                  onClick={onAddFeatureBlock}
                >
                  <i className="material-icons-outlined">add_circle_outline</i>
                  {t("Add Another")}
                </Link>
              </div>
              {/* ---- add button for repeater feature end ---- */}
            </div>
            <div className="course_stepItem">
              <div className="coursestep_hdng">
                <h3>
                  <Trans i18nKey="course_prerequisites">
                    What are the requirements or prerequisites for taking your
                    course
                  </Trans>
                  ?
                </h3>
                <p>
                  <Trans i18nKey="Add 1 or more requirements">
                    Add 1 or more requirements
                  </Trans>
                </p>
              </div>
              <div className="course_fillTop">
                {/* ---------- Display the Features section starts here ---------- */}

                {/* ---------- Display the Features section starts here ---------- */}

                {/* ---- requirements 1 repeater start ----- */}
                {requirementsBlock.map((item, index) => {
                  return (
                    <div className="input_fill jobinput_fill" key={index}>
                      <input
                        type="text"
                        name="requirement"
                        className="form-control"
                        placeholder={t("Enter requirements")}
                        value={item.requirement}
                        onChange={(e) => requirementsBlockHandler(e, index)}
                      />

                      {/* ---- delete button for requirements 1 repeater ----- */}
                      {index == 0 ? null : (
                        <div className="dlete_btn">
                          <Link
                            to="#"
                            role="button"
                            onClick={() =>
                              onDeleteRequirementsBlock(item, index)
                            }
                          >
                            <span className="material-icons-outlined">
                              delete
                            </span>
                          </Link>
                        </div>
                      )}
                      {/* ---- delete button for requirements 1 repeater end----- */}
                    </div>
                  );
                })}

                {/* ---- requirements 1 repeater end ----- */}
              </div>

              {/* ---- Add button for requirements 1 repeater ----- */}
              <div className="addfill_btn">
                <Link
                  to="#"
                  className="add_plusBtn"
                  role="button"
                  onClick={onAddRequirementsBlock}
                >
                  <i className="material-icons-outlined">add_circle_outline</i>
                  {t("Add Another")}
                </Link>
              </div>
              {/* ---- end of Add button for requirements 1 repeater ----- */}
            </div>

            <div className="course_stepItem">
              <div className="coursestep_hdng">
                <h3>
                  <Trans i18nKey="course_for">Skills Required</Trans>
                </h3>
                <p>{t("Select 1 or more skills")}</p>
              </div>

              {/* ---------- Display the Features section starts here ---------- */}

              {/* ---------- Display the Features section starts here ---------- */}

              <div className="course_fillTop">
                <Select
                  placeholder={t("Select the required Skills")}
                  options={skillsOptions}
                  value={selectedSkills}
                  isMulti={true}
                  onChange={(val) => {
                    skillsSelectionHandler(val);
                  }}
                  classNamePrefix="select2-selection"
                  styles={customSelectStyle}
                />
              </div>
            </div>
          </div>

          {isSubmittingData && (
            <div className="rounded">
              <div className="alert alert-info">
                {t("Submitting data please wait")} ....
              </div>
            </div>
          )}

          {/* ---- Next button section starts here ---- */}
          <div className="nxt_prvbtn_Outer">
            <div className="prev_Btn">
              <Link
                to="#"
                role="button"
                className="border_btn"
                onClick={(e) => {
                  goToPreviousPageHandler(e);
                }}
              >
                {t("Back to previous")}
              </Link>
            </div>

            {/* Save and Continue button */}
            <div className="next_Btn">
              <Link
                to="#"
                role="button"
                className="border_btn"
                onClick={(e) => {
                  nextStepHandler(e);
                }}
              >
                {t("Save & Continue")}
              </Link>
            </div>

            {/* Next Step button */}
            <div className="next_Btn">
              <Link
                to="#"
                role="button"
                className="basic_btn"
                onClick={(e) => {
                  goToNextPageHandler(e);
                }}
              >
                {t("Go to Next Step")}
              </Link>
            </div>
          </div>
          {/* ---- Next button section ends here ---- */}
        </div>
        {/* ------------- Form section ends here ------------- */}
      </div>
    </>
  );
};

export default Step2;
