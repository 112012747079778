/* eslint-disable */
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import custom components here
import SubscribedCourses from "./Tabs/SubscribedCourses";
import PublishedCourses from "./Tabs/PublishedCourses";

const MyCoursesListArea = () => {
  const { t } = useTranslation();
  /*----- initial state for toggling tabs ------*/
  const [toggleSubscribedCoursesTab, setToggleSubscribedCoursesTab] =
    useState(true);
  const [togglePublishedCoursesTab, setTogglePublishedCoursesTab] =
    useState(false);
  /*----- end of initial state for toggling tabs ------*/

  // funtion to display subscribed courses tab
  const showSubscribedCoursesTab = (e) => {
    e.preventDefault();
    setToggleSubscribedCoursesTab(true);
    setTogglePublishedCoursesTab(false);
  };

  // function to display published course tab
  const showPublishedCourseTab = (e) => {
    e.preventDefault();
    setToggleSubscribedCoursesTab(false);
    setTogglePublishedCoursesTab(true);
  };

  return (
    <>
      {/* -------------- Tab Links Section starts here -------------- */}
      <div className="course_tablinks_outer learning_coursetablinks">
        <div className="container">
          <div className="tab_links">
            <ul className="nav nav-tabs">
              {/* ------------ Subscribed course tab link starts here ------------ */}
              <li className="nav-item">
                <Link
                  className={
                    toggleSubscribedCoursesTab == true
                      ? "nav-link active show"
                      : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showSubscribedCoursesTab(e)}
                >
                  {t("Subscribed Courses")}
                </Link>
              </li>
              {/* ------------ Subscribed course tab link ends here ------------ */}

              {/* ------------ Published course tab link starts here ------------ */}
              <li className="nav-item">
                <Link
                  className={
                    togglePublishedCoursesTab == true
                      ? "nav-link active show"
                      : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showPublishedCourseTab(e)}
                >
                  {t("Published Courses")}
                </Link>
              </li>
              {/* ------------ Published course tab link ends here ------------ */}
            </ul>
          </div>
        </div>
      </div>
      {/* -------------- Tab Links Section ends here -------------- */}

      {/* -------------- Tab Content Section starts here -------------- */}
      <div className="learning_course_area">
        <div className="container">
          <div className="tab-content">
            {/* --------------- Subscribed Courses Body starts here --------------- */}
            {toggleSubscribedCoursesTab && <SubscribedCourses />}
            {/* --------------- Subscribed Courses Body ends here --------------- */}

            {/* --------------- Published Courses Body starts here --------------- */}
            {togglePublishedCoursesTab && <PublishedCourses />}
            {/* --------------- Published Courses Body ends here --------------- */}
          </div>
        </div>
      </div>
      {/* -------------- Tab Content Section ends here -------------- */}
    </>
  );
};

export default MyCoursesListArea;
