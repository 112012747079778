/* eslint-disable */
import React, { useState, useEffect, createContext } from "react";
import { Switch } from "react-router-dom";

// import route lists
import { publicRoutes, protectedRoutes } from "./routes/index";

// import middlewire for authenticated routing
import Authmiddleware from "./routes/Authmiddleware";

// Creating the context API here in the parent
export const GlobalIndraInfo = createContext();

const App = () => {
  // Initial useState to save the Id of the newly created course
  const [receivedCourseId, setReceivedCourseId] = useState(null);

  // Initial useState to save the Lesson Id of the lesson created in Step 4
  const [receivedLessonId, setReceivedLessonId] = useState(null);

  // Initial useState to save the Task Id of the lesson created in Step 4
  const [receivedTaskId, setReceivedTaskId] = useState(null);

  const [lessonTitle, setLessonTitle] = useState("");

  const [recievedTaskTitle, setRecievedTaskTitle] = useState("");

  /* -------- Course Details Requirements starts here ---------- */
  // Initial useState to save the Course Name from Course Details
  const [receivedCourseName, setReceivedCourseName] = useState("");

  // Initial useState to save the Course Features from Course Details
  const [receivedFeatures, setReceivedFeatures] = useState(null);

  // Initial useState to save the Course Skills from Course Details
  const [receivedSkills, setReceivedSkills] = useState(null);

  //Initial for recieved category
  const [recievedCategory, setRecievedCategory] = useState(null);

  // Initial useState to save the Course Skills from Course Details
  const [receivedAbout, setReceivedAbout] = useState("");

  // Initial useState to save the Course Requirements from Course Details
  const [receivedRequirements, setReceivedRequirements] = useState(null);

  // Initial useState to save the Course Promotional Text from Course Details
  const [receivedPromotionalText, setReceivedPromotionalText] = useState(null);

  // Initial useState to save the Course Lesson Data from Course Details
  const [receivedLessons, setReceivedLessons] = useState(null);

  // Initial useState to save the Course Team from Course Details
  const [receivedTeam, setReceivedTeam] = useState(null);

  // Initial useState to save the Course Stories from Course Details
  const [receivedStories, setReceivedStories] = useState(null);

  // Initial useState to save the Course Promo Video URL from Course Details
  const [receivedPromoVideo, setReceivedPromovideo] = useState("");

  /* ----------------- Course Details Requirements ends here ----------------- */

  // Other useStates
  const [stepOneComplete, setStepOneComplete] = useState(false); // for step one complete
  const [stepTwoComplete, setStepTwoComplete] = useState(false); // for step two complete
  const [stepThreeComplete, setStepThreeComplete] = useState(false); // for step three complete
  const [stepFourComplete, setStepFourComplete] = useState(false); //for step four complete
  const [stepFiveComplete, setStepFiveComplete] = useState(false); //for step five complete
  const [stepSixComplete, setStepSixComplete] = useState(false); //for step six complete
  const [stepSevenComplete, setStepSevenComplete] = useState(false); //for step seven complete
  const [stepEightComplete, setStepEightComplete] = useState(false); //for step eight complete
  const [joinNow, setJoinNow] = useState(false);
  const [joinIsLoading, setJoinIsLoading] = useState(false);
  const [lessonComplete, setLessonComplete] = useState(false);
  const [subscriberStatus, setSubscriberStatus] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchSkills, setSearchSkills] = useState([]);
  const [searchCategory, setSearchCategory] = useState([]);
  const [refreshCourseList, setRefreshCourseList] = useState(false);

  /* ------------------ Challenges Search Query ---------------- */
  const [searchJobTitle, setSearchJobTitle] = useState("");
  const [searchJobCity, setSearchJobCity] = useState("");
  const [searchJobCategory, setSearchJobCategory] = useState([]);
  const [refreshChallengeList, setRefreshChallengeList] = useState(false);

  /* ------------------ champions use states ------------------ */
  const [champName, setChampName] = useState("");
  const [champCategory, setChampCategory] = useState([]);
  const [champCity, setChampCity] = useState("");
  const [refreshChampLists, setRefreshChampLists] = useState(false);
  const [championName, setChampionName] = useState("");

  // function to receive created course id from the child or grand-child component
  const getCourseId = (data) => {
    console.log(
      "Course ID received in the Parent Component via useContext ------> ",
      data
    );
    if (data == null || data == undefined) {
      setReceivedCourseId(null);
      setStepOneComplete(false);
      setStepTwoComplete(false);
      setStepThreeComplete(false);
      setStepFourComplete(false);
      setStepFiveComplete(false);
      setStepSixComplete(false);
      setStepSevenComplete(false);
      setStepEightComplete(false);
    } else {
      setReceivedCourseId(data);
    }
  };

  // function to receive created lesson id from the child or grand-child component
  const getLessonId = (data) => {
    // console.log(
    //   "Lesson ID received in the Parent Component via useContext ------> ",
    //   data
    // );
    setReceivedLessonId(data);
  };

  // function to receive created lesson title from the child or grand-child component
  const getLessonTitle = (data) => {
    // console.log(
    //   "Lesson title received in the Parent Component via useContext ------> ",
    //   data
    // );
    setLessonTitle(data);
  };

  // function to receive created task id from the child or grand-child component
  const getTaskId = (data) => {
    // console.log(
    //   "Task ID received in the Parent Component via useContext ------> ",
    //   data
    // );
    setReceivedTaskId(data);
  };

  // function to receive created lesson title from the child or grand-child component
  const getTaskTitle = (data) => {
    // console.log(
    //   "Task title received in the Parent Component via useContext ------> ",
    //   data
    // );
    setRecievedTaskTitle(data);
  };

  /* ----------------- Course Details Requirements starts here ----------------- */
  const getCourseName = (data) => {
    // console.log(
    //   "Course name received in the Parent Component via useContext ------> ",
    //   data
    // );
    setReceivedCourseName(data);
  };

  const getCourseFeatures = (data) => {
    // console.log(
    //   "Course features received in the Parent Component via useContext ------> ",
    //   data
    // );
    setReceivedFeatures(data);
  };

  const getCourseSkills = (data) => {
    // console.log(
    //   "Course skills received in the Parent Component via useContext ------> ",
    //   data
    // );
    setReceivedSkills(data);
  };

  const getCourseCategories = (data) => {
    setRecievedCategory(data);
  };

  const getCourseAbout = (data) => {
    // console.log(
    //   "Course About received in the Parent Component via useContext ------> ",
    //   data
    // );
    setReceivedAbout(data);
  };

  const getCourseRequirements = (data) => {
    // console.log(
    //   "Course requirements received in the Parent Component via useContext ------> ",
    //   data
    // );
    setReceivedRequirements(data);
  };

  const getCoursePromotionalText = (data) => {
    // console.log(
    //   "Course promotional text received in the Parent Component via useContext ------> ",
    //   data
    // );
    setReceivedPromotionalText(data);
  };

  const getCourseLessons = (data) => {
    // console.log(
    //   "Course lessons received in the Parent Component via useContext ------> ",
    //   data
    // );
    setReceivedLessons(data);
  };

  const getCourseTeams = (data) => {
    // console.log(
    //   "Course team received in the Parent Component via useContext ------> ",
    //   data
    // );
    setReceivedTeam(data);
  };

  const getCourseStories = (data) => {
    // console.log(
    //   "Course stories received in the Parent Component via useContext ------> ",
    //   data
    // );
    setReceivedStories(data);
  };

  const getCoursePromoVideo = (data) => {
    // console.log(
    //   "Course promo video received in the Parent Component via useContext ------> ",
    //   data
    // );
    setReceivedPromovideo(data);
  };

  /* ----------------- Course Details Requirements ends here ----------------- */

  // function for get state from step one right panel
  const onConfirmStep1 = (isStepOneComplete) => {
    if (isStepOneComplete) {
      setStepOneComplete(true);
    } else {
      setStepOneComplete(false);
    }
  };

  // function for get state from step two right panel
  const onConfirmStep2 = (isStepTwoComplete) => {
    if (isStepTwoComplete) {
      setStepTwoComplete(true);
    } else {
      setStepTwoComplete(false);
    }
  };

  // function for get state from step three right panel
  const onConfirmStep3 = (isStepThreeComplete) => {
    if (isStepThreeComplete) {
      setStepThreeComplete(true);
    } else {
      setStepThreeComplete(false);
    }
  };

  // function for get state from step three right panel
  const onConfirmStep4 = (isStepFourComplete) => {
    if (isStepFourComplete) {
      setStepFourComplete(true);
    } else {
      setStepFourComplete(false);
    }
  };

  // function for get state from step five right panel
  const onConfirmStep5 = (isStepFiveComplete) => {
    if (isStepFiveComplete) {
      setStepFiveComplete(true);
    } else {
      setStepFiveComplete(false);
    }
  };

  // function for get state from step six right panel
  const onConfirmStep6 = (isStepSixComplete) => {
    if (isStepSixComplete) {
      setStepSixComplete(true);
    } else {
      setStepSixComplete(false);
    }
  };

  // function for get state from step seven right panel
  const onConfirmStep7 = (isStepSevenComplete) => {
    if (isStepSevenComplete) {
      setStepSevenComplete(true);
    } else {
      setStepSevenComplete(false);
    }
  };

  // function for get state from step eight right panel
  const onConfirmStep8 = (isStepEightComplete) => {
    if (isStepEightComplete) {
      setStepEightComplete(true);
    } else {
      setStepEightComplete(false);
    }
  };

  //function for join course
  const joinNowConfirm = (data) => {
    if (data) {
      setJoinNow(true);
    } else {
      setJoinNow(false);
    }
  };

  //function for join loading
  const joinNowLoading = (data) => {
    if (data) {
      setJoinIsLoading(true);
    } else {
      setJoinIsLoading(false);
    }
  };

  //function for load lesson
  const completeLesson = (data) => {
    if (data) {
      setLessonComplete(true);
    } else {
      setLessonComplete(false);
    }
  };

  // function for get subscriber status
  const subscriberDetails = (data) => {
    if (data) {
      setSubscriberStatus(true);
    } else {
      setSubscriberStatus(false);
    }
  };

  //function for get search course queries paramters
  const searchQueryValues = (data) => {
    if (data) {
      setSearchTitle(data.searchTitle);
      setSearchSkills(data.skills);
      setSearchCategory(data.category);
    }
  };

  // function for reset all queries for courselists components
  const resetCourseQuery = (data) => {
    if (data) {
      setRefreshCourseList(true);
    } else {
      setRefreshCourseList(false);
    }
    setSearchTitle("");
    setSearchSkills([]);
    setSearchCategory([]);
  };

  // function for get search challenges parameters
  const searchJobsQueryValues = (data) => {
    // assign to the useStates
    if (data) {
      setSearchJobTitle(data.jobTitle);
      setSearchJobCity(data.city);
      setSearchJobCategory(data.selectedCategory);
    } else {
      setSearchJobTitle("");
      setSearchJobCity("");
      setSearchJobCategory([]);
    }
  };

  // function to reset the challenges filter query
  const resetJobsQuery = (data) => {
    if (data) {
      setRefreshChallengeList(true);
    } else {
      setRefreshChallengeList(false);
    }
    setSearchJobTitle("");
    setSearchJobCity("");
    setSearchJobCategory([]);
  };

  //functions for search champions
  const searchChampions = (data) => {
    if (data) {
      setChampName(data.champName);
      setChampCategory(data.champCategory);
      setChampCity(data.championCity);
    }
  };

  //function for reset champions search
  const refreshChampionLists = (data) => {
    if (data) {
      setRefreshChampLists(true);
    } else {
      setRefreshChampLists(false);
    }
    setChampName("");
    setChampCategory([]);
    setChampCity("");
  };

  // function to assign champion name for the breadcrumb
  const getChampionName = (data) => {
    // console.log(
    //   "Championname received in the Parent Component via useContext ------> ",
    //   data
    // );

    setChampionName(data);
  };

  return (
    <>
      <GlobalIndraInfo.Provider
        value={{
          courseId: receivedCourseId,
          lessonId: receivedLessonId,
          lessonTitle: lessonTitle,
          taskId: receivedTaskId,
          taskTitle: recievedTaskTitle,

          // ------- Course Details requirements starts here -------

          courseName: receivedCourseName,
          getCourseName: getCourseName,
          courseFeatures: receivedFeatures,
          getCourseFeatures: getCourseFeatures,
          courseSkills: receivedSkills,
          getCourseSkills: getCourseSkills,
          courseCategory: recievedCategory,
          getCourseCategories: getCourseCategories,
          courseAbout: receivedAbout,
          getCourseAbout: getCourseAbout,
          courseRequirements: receivedRequirements,
          getCourseRequirements: getCourseRequirements,
          coursePromotionalText: receivedPromotionalText,
          getCoursePromotionalText: getCoursePromotionalText,
          courseLessons: receivedLessons,
          getCourseLessons: getCourseLessons,
          courseTeams: receivedTeam,
          getCourseTeams: getCourseTeams,
          courseStories: receivedStories,
          getCourseStories: getCourseStories,
          coursePromoVideo: receivedPromoVideo,
          getCoursePromoVideo: getCoursePromoVideo,
          // ------- Course Details requirements ends here -------

          getCourseId: getCourseId,
          getLessonId: getLessonId,
          getLessonTitle: getLessonTitle,
          getTaskId: getTaskId,
          getTaskTitle: getTaskTitle,

          onConfirmStep1: onConfirmStep1,
          stepOneComplete: stepOneComplete,
          onConfirmStep2: onConfirmStep2,
          stepTwoComplete: stepTwoComplete,
          onConfirmStep3: onConfirmStep3,
          stepThreeComplete: stepThreeComplete,
          onConfirmStep4: onConfirmStep4,
          stepFourComplete: stepFourComplete,
          onConfirmStep5: onConfirmStep5,
          stepFiveComplete: stepFiveComplete,
          stepSixComplete: stepSixComplete,
          onConfirmStep6: onConfirmStep6,
          stepSixComplete: stepSixComplete,
          onConfirmStep7: onConfirmStep7,
          stepSevenComplete: stepSevenComplete,
          onConfirmStep8: onConfirmStep8,
          stepEightComplete: stepEightComplete,

          //lesson join
          joinNowConfirm: joinNowConfirm,
          joinNow: joinNow,
          joinNowLoading: joinNowLoading,
          joinIsLoading: joinIsLoading,
          completeLesson,
          lessonComplete,
          subscriberDetails,
          subscriberStatus,

          // Course filter requirements
          searchQueryValues,
          searchTitle,
          searchSkills,
          searchCategory,
          resetCourseQuery,
          refreshCourseList,

          // challenges filter requirements
          searchJobsQueryValues: searchJobsQueryValues,
          searchJobTitle,
          searchJobCity,
          searchJobCategory,
          resetJobsQuery,
          refreshChallengeList,

          //champions filter requirements
          searchChampions,
          champName,
          champCategory,
          champCity,
          refreshChampLists,
          refreshChampionLists,
          championName,
          getChampionName,
        }}
      >
        <Switch>
          {/* ---- non authenticated routes ---- */}
          {publicRoutes.map((routename, index) => (
            <Authmiddleware
              path={routename.path}
              component={routename.component}
              key={index}
              exact
              isAuthProtected={false}
            />
          ))}
          {/* ----end of  non authenticated routes ---- */}

          {/* ---- authenticated routes ---- */}
          {protectedRoutes.map((routename, index) => (
            <Authmiddleware
              path={routename.path}
              component={routename.component}
              key={index}
              exact
              isAuthProtected={true}
            />
          ))}
          {/* ---- end of authenticated routes ---- */}
        </Switch>
      </GlobalIndraInfo.Provider>
    </>
  );
};

export default App;
