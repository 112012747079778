/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// importing context provider here
import { GlobalIndraInfo } from "App";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormdata, uploadFile } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const Step3 = (props) => {
  const token = localStorage.getItem("token");
  // const courseIdFromLocal = sessionStorage.getItem("courseid");
  const { courseId, onConfirmStep3 } = useContext(GlobalIndraInfo);

  // Initial useState for Notification component
  const [isSubmittingData, setIsSubmittingData] = useState(false);

  const history = useHistory();

  // toast property
  const toastOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    // theme: "colored",
  };

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const [stepThreePromotionalVideo, setStepThreePromotionalVideo] =
    useState("");
  const [stepThreeBanner, setStepThreeBanner] = useState("");
  const [features, setfeatures] = useState([]);
  const [stories, setStories] = useState([]);

  // initial for uploaded video
  const [uploadedVideo, setUploadedVideo] = useState("");
  const [storyUploading, setStoryUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [storyBlockNo, setStoryBlockNo] = useState(null);
  const [promoVideoWarning, setPromoVideoWarning] = useState(false);
  const [bannerWarning, setBannerWarning] = useState(false);
  const [videoIsNotEmpty, setVideoIsNotEmpty] = useState(false);
  const [bannerIsNotEmpty, setBannerIsNotEmpty] = useState(false);
  const [unSupportWarning, setUnSupportWarning] = useState(false);
  const [unSupportStory, setUnSupportStory] = useState(false);

  // count number for spinner
  const loadingCircle = [1, 2, 3, 4, 5, 6];

  // initial for upload banner
  const [uploadedBanner, setUploadedBanner] = useState("");

  /*---- video upload section -----*/
  const videouploadHandler = async (e) => {
    e.preventDefault();
    if (
      e.target.files[0].type == "video/mp4" ||
      e.target.files[0].type == "video/avi" ||
      e.target.files[0].type == "video/quicktime" ||
      e.target.files[0].type == "video/x-ms-wmv"
    ) {
      setUnSupportWarning(false);
      setUploadComplete(true);
      let videoFile = e.target.files[0];

      let apiUrl = url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

      try {
        let response = await uploadFile(apiUrl, videoFile);
        // console.log("response video----->", response);
        if (response.status) {
          setStepThreePromotionalVideo(response.data.id);
          setUploadComplete(false);
        }
      } catch (error) {
        console.log("error----->", error);
      }

      let videoUrl = URL.createObjectURL(e.target.files[0]);

      // setup preview of video
      let preview = document.getElementById("samplepreview");
      preview.setAttribute("src", `${videoUrl}`);
    } else {
      setUnSupportWarning(true);
    }
  };
  /*---- video upload section end -----*/

  /*----- banner upload section -----*/
  const bannerUploadHandler = async (e) => {
    let imageFile = e.target.files[0];

    let apiUrl = url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

    try {
      let response = await uploadFile(apiUrl, imageFile);
      // console.log("response video----->", response);
      if (response.status) {
        setStepThreeBanner(response.data.id);
      }
    } catch (error) {
      console.log("error----->", error);
    }
    setUploadedBanner(URL.createObjectURL(e.target.files[0]));
  };
  /*----- banner upload section end -----*/

  /*----- feature repeater start -----*/
  const promotionalTemplate = {
    title: "",
    description: "",
  };

  // initial state for feature block
  const [promotionalBlock, setPromotionalBlock] = useState([]);

  // add feature block
  const onAddPromotionalBlock = () => {
    setPromotionalBlock([...promotionalBlock, promotionalTemplate]);
  };

  // insert value author block
  const promotionalBlockHandler = (e, index) => {
    const updatePromoBlock = promotionalBlock.map((item, i) =>
      index == i
        ? Object.assign(item, { [e.target.name]: e.target.value })
        : item
    );

    setPromotionalBlock(updatePromoBlock);

    const featureValue = updatePromoBlock.map((item, number) => {
      return item;
    });

    setfeatures(featureValue);
  };

  // map features value to form repeater
  const updatePromotionalBlock = (promotional) => {
    let arr = [];

    promotional.map(function (item) {
      // restructing json object to map with form data
      let promotionalSavedBlockElement = {
        id: item.id,
        title: item.title,
        description: item.description,
      };

      arr.push(promotionalSavedBlockElement);
    });

    setPromotionalBlock(arr);
  };

  // delete author block
  const onDeletePromotionalBlock = async (item, index) => {
    let contentId = {
      promotionalcontentid: item.id,
    };

    let requestURL =
      url.API_BaseUrl +
      url.API_DELETE_FORM_REPEATER +
      `?token=${token}` +
      `&cid=${courseId}`;

    try {
      let response = await postFormdata(requestURL, contentId);
      const filteredPromotional = [...promotionalBlock];
      filteredPromotional.splice(index, 1);

      setPromotionalBlock(filteredPromotional);

      const featureValue = filteredPromotional.map((item, number) => {
        return item;
      });

      setfeatures(featureValue);
    } catch (error) {
      console.log("error in delete repeater", error);
    }
  };
  /*----- feature repeater end -----*/

  /*---- story repeater start */
  const storyBlockTamplate = {
    video: "",
    videoid: "",
    storyid: "",
    title: "",
    article: "",
  };

  const [storyBlock, setStoryBlock] = useState([storyBlockTamplate]);

  // add author block
  const onAddStoryBlock = () => {
    setStoryBlock([...storyBlock, storyBlockTamplate]);
  };

  // image repeater
  const onUploadStory = async (e, index) => {
    if (
      e.target.files[0].type == "video/mp4" ||
      e.target.files[0].type == "video/avi" ||
      e.target.files[0].type == "video/quicktime" ||
      e.target.files[0].type == "video/x-ms-wmv"
    ) {
      setStoryUploading(true);
      setStoryBlockNo(index);

      let fileData = e.target.files[0];

      let apiUrl = url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

      try {
        let response = await uploadFile(apiUrl, fileData);
        // console.log("response in video story---->", response);
        if (response.status) {
          const updateStatus = storyBlock.map((item, i) =>
            index == i
              ? Object.assign(item, {
                  ["video"]: response.data.url,
                  ["videoid"]: response.data.id,
                })
              : item
          );

          setStoryUploading(false);
          setStoryBlock(updateStatus);
          const storyValue = updateStatus.map((item, number) => {
            return item;
          });
          setStories(storyValue);
        } else {
          console.log("Cannot upload picture");
        }
      } catch (error) {
        console.log("error in file upload ----->", error);
      }
    } else {
      console.log("file is not supported");
    }
  };

  // insert value author block
  const storyFormHandler = (e, index) => {
    const updateStatus = storyBlock.map((item, i) =>
      index == i
        ? Object.assign(item, { [e.target.name]: e.target.value })
        : item
    );

    setStoryBlock(updateStatus);
    const storyValue = updateStatus.map((item, number) => {
      return item;
    });
    setStories(storyValue);
  };

  // map stories value to form repeater
  const updateStoryBlock = (stories) => {
    let arr = [];

    stories.map(function (item) {
      // restructing json object to map with form data
      let storySavedBlockElement = {
        storyid: item.id,
        video: item.video,
        videoid: item.videoid,
        title: item.title,
        article: item.article,
      };

      arr.push(storySavedBlockElement);

      // console.log("arr", arr);
    });

    setStoryBlock(arr);
  };

  // delete author block
  const onDeleteStoryBlock = async (item, index) => {
    console.log(item);
    let promotionalStoriesId = {
      promotionalstoriesid: item.storyid,
    };

    let requestURL =
      url.API_BaseUrl +
      url.API_DELETE_FORM_REPEATER +
      `?token=${token}` +
      `&cid=${courseId}`;

    try {
      let response = await postFormdata(requestURL, promotionalStoriesId);

      console.log(response);
      const filteredStory = [...storyBlock];
      filteredStory.splice(index, 1);

      setStoryBlock(filteredStory);
      const storyValue = filteredStory.map((item, number) => {
        return item;
      });
      setStories(storyValue);
    } catch (error) {
      console.log(error);
    }
  };
  /*---- story repeater end */

  /*---- function for go to next step -----*/
  const nextStepHandler = async (e) => {
    setPromoVideoWarning(false);
    setBannerWarning(false);
    e.preventDefault();

    if (
      (stepThreePromotionalVideo == "" && videoIsNotEmpty == false) ||
      (stepThreeBanner == "" && bannerIsNotEmpty == false)
    ) {
      if (stepThreePromotionalVideo == "" && videoIsNotEmpty == false) {
        setPromoVideoWarning(true);
      } else {
        setPromoVideoWarning(false);
      }
      if (stepThreeBanner == "" && bannerIsNotEmpty == false) {
        setBannerWarning(true);
      } else {
        setBannerWarning(false);
      }
    } else {
      setIsSubmittingData(true);
      setPromoVideoWarning(false);
      setBannerWarning(false);
      let stepThreeData = {
        promotionalvideoid: stepThreePromotionalVideo,
        promotionalbannerid: stepThreeBanner,
        promotionaltext: features,
        stories: stories,
      };

      console.log("step three data ----->", stepThreeData);

      const requestURL =
        url.API_BaseUrl +
        url.API_STEP_3_COURSE_CREATE +
        `?token=${token}` +
        `&cid=${courseId}`;

      try {
        const response = await postData(requestURL, stepThreeData);
        console.log("step three response----->", response);
        if (response.status) {
          let stepThreeState = true;
          setIsSubmittingData(false);
          onConfirmStep3(stepThreeState);
          history.push("/course/add/step4");
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  /*---- function for go to next step ends here -----*/

  /*---- function to fetch the course details saved in step 1 -----*/
  const viewCourseDetailsHandler = async (id) => {
    const requestURL =
      url.API_BaseUrl +
      url.API_STEP_3_COURSE_VIEW +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      const response = await getData(requestURL);
      console.log("step three view response----->", response);
      if (response.status) {
        if (response.data.stories.length > 0) {
          updateStoryBlock(response.data.stories);
        }

        if (response.data.promotionaltext.length > 0) {
          updatePromotionalBlock(response.data.promotionaltext);
        }

        if (response.data.promotionalvideo) {
          setVideoIsNotEmpty(true);
          setTimeout(() => {
            //console.log(response.data.promotionalvideo);

            let video = document.getElementById("samplepreview");
            let videoSource = document.getElementById("sampleVideoUrl");
            videoSource.setAttribute(
              "src",
              `${response.data.promotionalvideo}`
            );

            video.load();
          }, 2000);
        }

        if (
          response.data.promotionalbanner != "" ||
          response.data.promotionalbanner != null
        ) {
          setBannerIsNotEmpty(true);
          setUploadedBanner(response.data.promotionalbanner);
        }
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*---- end of function to fetch the course details saved in step 1 -----*/

  /* Function to redirect to the next page without calling the API */
  const goToNextPageHandler = (e) => {
    e.preventDefault();
    history.push("/course/add/step4");
    let stepThreeState = true;
    onConfirmStep3(stepThreeState);
  };

  /* Function to redirect to the previous page without calling the API */
  const goToPreviousPageHandler = (e) => {
    e.preventDefault();
    history.push("/course/add/Step2");
    let stepThreeState = false;
    onConfirmStep3(stepThreeState);
  };

  /* useEffect function from here */
  useEffect(() => {
    let stepThreeState = false;

    if (courseId != null) {
      stepThreeState = true;
      // console.log("Step 1 ------> Course ID is empty");
      viewCourseDetailsHandler(courseId);
    }
    onConfirmStep3(stepThreeState);
  }, [courseId]);

  return (
    <>
      <div className="col-lg-9">
        {/* --------------- Unknown back arrow button starts here --------------- */}
        <div className="back_progress">
          <div className="page_back">
            <Link to="#">
              <span className="material-icons-outlined">arrow_back</span>
              {t("Back")}
            </Link>
          </div>
          <div className="step_progress">
            <span className="progress_bar" style={{ width: "45%" }}></span>
          </div>
        </div>
        {/* --------------- Unknown back arrow button ends here --------------- */}

        {/* --------------- Form section starts here --------------- */}
        <div className="course_uploadrghtpnl">
          <div className="course_itemInnr">
            {/* ---------- Video upload and preview section starts here ---------- */}
            <div className="course_stepItem">
              <div className="coursestep_hdng">
                <h3>{t("Promotional Video")}</h3>
                <p>
                  <Trans i18nKey="Upload_trailer">
                    Please upload a promotional video that will help you to gain
                    more audience
                  </Trans>
                </p>
              </div>

              {/* ---- video upload area ----- */}
              <div className="upload_vdoOuter">
                <div className="upload_area">
                  <div className="upload_item">
                    {/* ------------ Video preview starts here ------------ */}
                    <div className="uploaded_innrBx uploaded_vdobx course_uploadBx">
                      <video
                        id="samplepreview"
                        controls
                        height="155px"
                        width="155px"
                        fullscreen="true"
                      >
                        <source id="sampleVideoUrl" />
                      </video>
                      {uploadComplete && (
                        <div className="vdoplay_Btn">
                          <p className="text-white">{t("Uploading")}</p>
                          {loadingCircle.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="spinner-grow mx-1 text-white"
                                style={{ height: "3px", width: "3px" }}
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    {unSupportWarning && (
                      <p className="text-danger mt-2">
                        * Unsupported File Format
                      </p>
                    )}
                    {/* ------------ Video preview ends here ------------ */}
                  </div>
                  {/* ------------ Video uploader starts here ------------ */}
                  <div className="upload_item">
                    <div className="upload_btn course_uploadBx">
                      <input
                        name="story"
                        type="file"
                        className="file_uoload"
                        onChange={(e) => videouploadHandler(e)}
                      />
                      <div className="upload_icon">
                        <span className="material-icons-outlined">
                          file_upload
                        </span>
                      </div>
                      <div className="upload_txt">
                        <h4>{t("Upload")}</h4>
                        <h5>{t("Max 10mb")}</h5>
                      </div>
                    </div>
                  </div>
                  {/* ------------ Video uploader endss here ------------ */}
                  {/* ------------ Delete video button starts here ------------ */}
                  <div className="dlete_btn">
                    <Link
                      to="#"
                      role="button"
                      onClick={() => setUploadedVideo("")}
                    >
                      <span className="material-icons-outlined">delete</span>
                    </Link>
                  </div>
                  {/* ------------ Delete video button ends here ------------ */}
                </div>
              </div>
              {/* ---- video upload area end here ----- */}
            </div>
            {/* ---------- Video upload and preview section ends here ---------- */}

            {/* ----- banner upload area ----- */}
            <div className="course_stepItem">
              <div className="coursestep_hdng">
                <h3>{t("Promotional Banner")}</h3>
                <p>
                  <Trans i18nKey="Upload_trailer">
                    Please upload a Banner that will help you to gain more
                    audience
                  </Trans>
                </p>
              </div>

              {/* ---- image upload area ----- */}
              <div className="upload_vdoOuter">
                <div className="upload_area">
                  <div className="upload_item">
                    {/* ------------ image preview starts here ------------ */}
                    <div className="uploaded_innrBx uploaded_vdobx course_uploadBx">
                      <img
                        height="155px"
                        width="155px"
                        src={
                          uploadedBanner
                            ? uploadedBanner
                            : "https://i.stack.imgur.com/Vkq2a.png"
                        }
                      />
                    </div>
                    {/* ------------ image preview ends here ------------ */}
                  </div>
                  {/* ------------ image uploader starts here ------------ */}
                  <div className="upload_item">
                    <div className="upload_btn course_uploadBx">
                      <input
                        name="story"
                        type="file"
                        className="file_uoload"
                        onChange={(e) => bannerUploadHandler(e)}
                      />

                      <div className="upload_icon">
                        <span className="material-icons-outlined">
                          file_upload
                        </span>
                      </div>
                      <div className="upload_txt">
                        <h4>{t("Upload")}</h4>
                        <h5>{t("Max 10mb")}</h5>
                      </div>
                    </div>
                  </div>
                  {/* ------------ image uploader endss here ------------ */}
                  {/* ------------ Delete image button starts here ------------ */}
                  <div className="dlete_btn">
                    <Link to="#" role="button">
                      <span className="material-icons-outlined">delete</span>
                    </Link>
                  </div>
                  {/* ------------ Delete image button ends here ------------ */}
                </div>
              </div>
              {/* ---- image upload area end here ----- */}
            </div>
            {/* ----- banner upload area end here ----- */}

            {/* ----- features block start ---- */}
            <div className="course_stepItem">
              <div className="coursestep_hdng">
                <h3>{t("Promotional Contents")}</h3>
                <p>
                  <Trans i18nKey="Upload_trailer">
                    Please add Feature that will help you to gain more audience
                  </Trans>
                </p>
              </div>
              {/* ---- repeater feature start ---- */}
              {promotionalBlock.map((item, index) => {
                return (
                  <div className="feature_block" key={index}>
                    <div className="input_fill jobinput_fill">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("Enter Feature title")}
                        name="title"
                        value={item.title}
                        onChange={(e) => promotionalBlockHandler(e, index)}
                      />
                    </div>
                    <div className="input_fill jobinput_fill description_fill">
                      <textarea
                        className="form-control"
                        placeholder={t("Feature description")}
                        name="description"
                        value={item.description}
                        onChange={(e) => promotionalBlockHandler(e, index)}
                      ></textarea>
                    </div>
                    {/* --------- delete button starts here --------- */}

                    <div className="dlete_btn">
                      <Link
                        to="#"
                        role="button"
                        onClick={() => onDeletePromotionalBlock(item, index)}
                      >
                        <span className="material-icons-outlined">delete</span>
                      </Link>
                    </div>

                    {/* --------- delete button ends here --------- */}
                  </div>
                );
              })}
              {/* ---- repeater feature end ---- */}
              {/* ---- add button for repeater feature ---- */}
              <div className="addfill_btn">
                <Link
                  to="#"
                  className="add_plusBtn"
                  role="button"
                  onClick={onAddPromotionalBlock}
                >
                  <i className="material-icons-outlined">add_circle_outline</i>
                  {t("Add Content")}
                </Link>
              </div>
              {/* ---- add button for repeater feature end ---- */}
            </div>
            {/* ----- features block end ----- */}

            {/* ------------------- Story section starts here ------------------- */}
            <div className="course_stepItem">
              {/* --------- heading section starts here --------- */}
              <div className="coursestep_hdng">
                <h3>{t("Add Stories")}</h3>
                <p>
                  <Trans i18nKey="Give_attention">
                    Give some interesting stories that grab attention
                  </Trans>
                </p>
              </div>
              {/* --------- heading section ends here --------- */}

              {/* ------------------- Story section form repeater starts here ------------------- */}
              <div className="intrector_fillOuter ">
                {storyBlock.map((item, index) => {
                  return (
                    <div
                      className="intrector_row intrector_profile_row"
                      key={index}
                    >
                      <div className="left_intrector">
                        {/* ------------ Video preview starts here ------------ */}
                        <div className="uploaded_innrBx uploaded_vdobx course_uploadBx mr-3">
                          <figure>
                            {/* <img src="images/upload-vdo.jpg" alt="" /> */}
                            <video
                              id={`samplestorypreview` + index}
                              src={item.video}
                              controls
                              height="155px"
                              width="155px"
                              fullscreen="true"
                              className="rounded hidden video_media"
                            >
                              {/* <source src={item.video} type="video/mp4" /> */}
                            </video>

                            {storyUploading && storyBlockNo == index && (
                              <div className="vdoplay_Btn">
                                <p className="text-white">{t("Uploading")}</p>
                                {loadingCircle.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="spinner-grow mx-1 text-white"
                                      style={{ height: "3px", width: "3px" }}
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </figure>

                          <div className="dlete_btn">
                            <Link to="#" role="button">
                              <span className="material-icons-outlined">
                                delete
                              </span>
                            </Link>
                          </div>
                        </div>
                        {/* ------------ Video preview ends here ------------ */}

                        {/* ---------- Video uploader starts here ---------- */}
                        <div className="upload_btn course_uploadBx">
                          <input
                            name="storydataurl"
                            type="file"
                            className="file_uoload"
                            onChange={(e) => onUploadStory(e, index)}
                          />
                          <div className="upload_icon">
                            <span className="material-icons-outlined">
                              file_upload
                            </span>
                          </div>
                          <div className="upload_txt">
                            <h4>{t("Upload")}</h4>
                            <h5>{t("Max 10mb")}</h5>
                          </div>
                        </div>
                        {/* ---------- Video uploader ends here ---------- */}

                        {/* -------- Textboxes start here -------- */}
                        <div className="upload_filOuter">
                          <div className="input_fill jobinput_fill">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Enter a title")}
                              name="title"
                              value={item.title}
                              onChange={(e) => storyFormHandler(e, index)}
                            />
                          </div>
                          <div className="input_fill jobinput_fill description_fill">
                            <textarea
                              className="form-control"
                              placeholder={t("Type your article")}
                              name="article"
                              value={item.article}
                              onChange={(e) => storyFormHandler(e, index)}
                            ></textarea>
                          </div>
                        </div>
                        {/* -------- Textboxes end here -------- */}
                      </div>

                      {/* ---------- Delete button section starts here ---------- */}
                      <div className="right_intrector">
                        {index == 0 ? null : (
                          <div className="dlete_btn">
                            <Link
                              to="#"
                              role="button"
                              onClick={() => onDeleteStoryBlock(item, index)}
                            >
                              <span className="material-icons-outlined">
                                delete
                              </span>
                            </Link>
                          </div>
                        )}
                      </div>
                      {/* ---------- Delete button section ends here ---------- */}
                    </div>
                  );
                })}
              </div>
              {/* ------------------- Story section form repeater ends here ------------------- */}

              {/* ------------------- Add Story section button starts here ------------------- */}
              <div className="addfill_btn">
                <Link
                  to="#"
                  className="add_plusBtn"
                  role="button"
                  onClick={onAddStoryBlock}
                >
                  <i className="material-icons-outlined">add_circle_outline</i>
                  {t("Add Another")}
                </Link>
              </div>
              {/* ------------------- Add Story section button ends here ------------------- */}
            </div>
            {/* ------------------- Story section ends here ------------------- */}
          </div>

          {isSubmittingData && (
            <div className="rounded">
              <div className="alert alert-info">
                Submitting data
                {t("please wait")} ....
              </div>
            </div>
          )}

          {/* ---- go to next & previous step button area starts here ---- */}
          <div className="nxt_prvbtn_Outer">
            <div className="prev_Btn">
              <Link
                to="#"
                className="border_btn"
                onClick={(e) => {
                  goToPreviousPageHandler(e);
                }}
              >
                {t("Back to previous")}
              </Link>
            </div>

            <div className="next_Btn">
              <Link
                to="#"
                className="border_btn"
                role="button"
                onClick={(e) => {
                  if (token == null) {
                    toast.warn(
                      `${t("please login to continue")}`,
                      toastOptions
                    );
                  } else {
                    nextStepHandler(e);
                  }
                }}
              >
                {t("Save & Continue")}
              </Link>
            </div>

            <div className="next_Btn">
              <Link
                to="#"
                className="basic_btn"
                onClick={(e) => {
                  goToNextPageHandler(e);
                }}
              >
                {t("Go to Next Step")}
              </Link>
            </div>
          </div>
          {promoVideoWarning && (
            <h5 className="text-warning mt-3">
              * Please Upload a Promotional Video
            </h5>
          )}
          {bannerWarning && (
            <h5 className="text-warning mt-3">* Please Upload a Banner</h5>
          )}
          {/* ---- go to next & previous step button area ends here ---- */}
        </div>
        {/* --------------- Form section ends here --------------- */}
      </div>

      <ToastContainer />
    </>
  );
};

export default Step3;
