import React from "react";

const WinnerTab = () => {
  return (
    <div className="course_detailspnl">
      <div className="contnt_hdng">
        <h4>Winner</h4>
      </div>
      <div className="team_outer">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="expertbx_item members_teamItem">
              <div className="membrs_teamOuter">
                <div className="team_membrs_pic">
                  <img src="images/expert4.png" alt="" />
                </div>
                <div className="membrs_team_details">
                  <div className="team_name">
                    <h3>Bertha Sandoval</h3>
                    <h5>UX Designer, Google</h5>
                  </div>
                  <div className="team_rating star_color ">
                    <h3>
                      {" "}
                      <i className="material-icons-outlined">star</i>{" "}
                      <span>4.5</span>
                    </h3>

                    <h6>
                      32<span>(valoraciones)</span>
                    </h6>
                  </div>
                  <div className="team_address_value">
                    <h3>
                      Madrid,Spain <span>€12,000</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="expertbx_item members_teamItem">
              <div className="membrs_teamOuter">
                <div className="team_membrs_pic">
                  <img src="images/expert4.png" alt="" />
                </div>
                <div className="membrs_team_details">
                  <div className="team_name">
                    <h3>Bertha Sandoval</h3>
                    <h5>UX Designer, Google</h5>
                  </div>
                  <div className="team_rating star_color ">
                    <h3>
                      {" "}
                      <i className="material-icons-outlined">star</i>{" "}
                      <span>4.5</span>
                    </h3>

                    <h6>
                      32<span>(valoraciones)</span>
                    </h6>
                  </div>
                  <div className="team_address_value">
                    <h3>
                      Madrid,Spain <span>€12,000</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="expertbx_item members_teamItem">
              <div className="membrs_teamOuter">
                <div className="team_membrs_pic">
                  <img src="images/expert4.png" alt="" />
                </div>
                <div className="membrs_team_details">
                  <div className="team_name">
                    <h3>Bertha Sandoval</h3>
                    <h5>UX Designer, Google</h5>
                  </div>
                  <div className="team_rating star_color ">
                    <h3>
                      {" "}
                      <i className="material-icons-outlined">star</i>{" "}
                      <span>4.5</span>
                    </h3>

                    <h6>
                      32<span>(valoraciones)</span>
                    </h6>
                  </div>
                  <div className="team_address_value">
                    <h3>
                      Madrid,Spain <span>€12,000</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="expertbx_item members_teamItem">
              <div className="membrs_teamOuter">
                <div className="team_membrs_pic">
                  <img src="images/expert4.png" alt="" />
                </div>
                <div className="membrs_team_details">
                  <div className="team_name">
                    <h3>Bertha Sandoval</h3>
                    <h5>UX Designer, Google</h5>
                  </div>
                  <div className="team_rating star_color ">
                    <h3>
                      {" "}
                      <i className="material-icons-outlined">star</i>{" "}
                      <span>4.5</span>
                    </h3>

                    <h6>
                      32<span>(valoraciones)</span>
                    </h6>
                  </div>
                  <div className="team_address_value">
                    <h3>
                      Madrid,Spain <span>€12,000</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="expertbx_item members_teamItem">
              <div className="membrs_teamOuter">
                <div className="team_membrs_pic">
                  <img src="images/expert4.png" alt="" />
                </div>
                <div className="membrs_team_details">
                  <div className="team_name">
                    <h3>Bertha Sandoval</h3>
                    <h5>UX Designer, Google</h5>
                  </div>
                  <div className="team_rating star_color ">
                    <h3>
                      {" "}
                      <i className="material-icons-outlined">star</i>{" "}
                      <span>4.5</span>
                    </h3>

                    <h6>
                      32<span>(valoraciones)</span>
                    </h6>
                  </div>
                  <div className="team_address_value">
                    <h3>
                      Madrid,Spain <span>€12,000</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="expertbx_item members_teamItem">
              <div className="membrs_teamOuter">
                <div className="team_membrs_pic">
                  <img src="images/expert4.png" alt="" />
                </div>
                <div className="membrs_team_details">
                  <div className="team_name">
                    <h3>Bertha Sandoval</h3>
                    <h5>UX Designer, Google</h5>
                  </div>
                  <div className="team_rating star_color ">
                    <h3>
                      {" "}
                      <i className="material-icons-outlined">star</i>{" "}
                      <span>4.5</span>
                    </h3>

                    <h6>
                      32<span>(valoraciones)</span>
                    </h6>
                  </div>
                  <div className="team_address_value">
                    <h3>
                      Madrid,Spain <span>€12,000</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinnerTab;
