/* eslint-disable */
import React, { useEffect } from "react";

// import custom component
import Header from "common/Header";
import Footer from "common/Footer";
import Banner from "components/HomeComponents/Banner";
import TabArea from "common/tabarea/TabArea";
import HomeCourseLists from "components/HomeComponents/HomeCourseLists";
import SystemArea from "components/HomeComponents/SystemArea";
import HomeChampionLists from "components/HomeComponents/HomeChampionLists";
import HomeChallengeLists from "components/HomeComponents/HomeChallengeLists";
import HomeChampionRequire from "components/HomeComponents/HomeChampionRequire";
import HomeActivityLists from "components/HomeComponents/HomeActivityLists";
import HomeEventLists from "components/HomeComponents/HomeEventLists";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Banner />
      <TabArea module={""} />
      <HomeCourseLists />
      <SystemArea />
      <HomeChampionLists />
      <HomeChallengeLists />
      <HomeChampionRequire />
      <HomeActivityLists />
      <HomeEventLists />
      <Footer />
    </>
  );
};

export default Home;
