/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GlobalIndraInfo } from "App";

// import Gateway for API fetch call
import { getData, postData, postFormData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

// importing custom components here
import QuestionPopUp from "../PopUpComponents/QuestionPopUp";

const Questions = (props) => {
  // accessing the user's token here
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // Getting the course ID, Lesson ID created in Step 1
  const { courseId, lessonId } = useContext(GlobalIndraInfo);

  // toast property
  const toastOption = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  // initial state for step four question data
  const [stepFourQuestionData, setStepFourQuestionData] = useState({
    question: "",
  });

  // Initial useState to save all the submitted Questions
  const [receivedQuestions, setReceivedQuestions] = useState();

  // Initial useState to save the selected Question's ID
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  //initial state for display error message
  const [titleErrorMessage, setTitleErrorMessage] = useState(false);

  // initial state for display Questions details
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  // lesson submit
  const lessonSubmitHandler = async (e) => {
    // prevent page reload
    e.preventDefault();

    // console.log("lesson ------>", val, index);
    if (stepFourQuestionData.question == "") {
      setTitleErrorMessage(true);
    } else {
      setTitleErrorMessage(false);

      let requestURL =
        url.API_BaseUrl +
        url.API_STEP_4_ADD_LESSON_QUESTION +
        `?token=${token}` +
        `&cid=${courseId}` +
        `&lid=${lessonId}`;
      // console.log("Step 4 Question sending request --------> ", requestURL);

      try {
        const response = await postData(requestURL, stepFourQuestionData);

        if (response.status) {
          // console.log("Data submitted successfully", response);
          getAllQuestionsHandler();
          setStepFourQuestionData({ ...stepFourQuestionData, question: "" });
        } else {
          console.log("Some error occurred while submitting the data!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onConfirmSubmit = () => {
    // setStepFourQuestionData({ ...stepFourQuestionData, question: "" });
    // setShowQuestionsDetails(false);
  };

  /* -------- function to call the call the Step 13 API -------- */
  const getAllQuestionsHandler = async () => {
    let requestURL =
      url.API_BaseUrl +
      url.API_STEP_4_VIEW_ALL_LESSON_QUESTIONS +
      `?token=${token}` +
      `&lid=${lessonId}`;

    // console.log("Requesting for the lessons tasks --------> ", requestURL);

    try {
      const response = await getData(requestURL);
      if (response.status) {
        // console.log("Received Response -------->", response);
        setReceivedQuestions(response.data);
      } else {
        console.log("Some error occurred while submitting the data!");
        // console.log("Received Response -------->", response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /* -------- end of function to call the call the Step 13 API -------- */

  useEffect(() => {
    if (props.lessonid) {
      getAllQuestionsHandler();
    }
  }, [props.lessonid]);

  return (
    <>
      <div className="inner_tab_pane" id="questions">
        <div className="course_stepItem">
          <div className="course_lessons_Innr">
            {/* ---- question block ----- */}
            {receivedQuestions ? (
              <div className="course_lessonsOuter">
                {receivedQuestions.map((item, index) => {
                  return (
                    <div className="course_lessons_row" key={index}>
                      <div className="course_lessons_lft">
                        <p>{item.question}</p>
                        <div className="input_fill jobinput_fill lesson_editfill">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={item.question}
                          />
                        </div>
                      </div>
                      <div className="course_lessons_right">
                        <div className="lessns_details_btn formulapopup_btn">
                          <Link
                            to="#"
                            role="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedQuestion(item);
                              document.body.classList.add("formula_openPopup");
                            }}
                          >
                            {t("Details")}
                            <span className="material-icons-outlined">
                              chevron_right
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}

            {/* -------------- Enter Question section starts here -------------- */}
            <div className="input_fill jobinput_fill">
              <input
                type="text"
                name="lesson"
                className="form-control"
                placeholder={t("Enter question")}
                value={stepFourQuestionData.question}
                onChange={(e) =>
                  setStepFourQuestionData({
                    ...stepFourQuestionData,
                    question: e.target.value,
                  })
                }
              />
              {titleErrorMessage && (
                <p className="text-danger mt-2">
                  * {t("Please Add a Question")}
                </p>
              )}
              <Link
                to="#"
                className="material-icons-outlined fill_chk"
                role="button"
                onClick={(e) => {
                  lessonSubmitHandler(e);
                }}
              >
                send
              </Link>
            </div>

            {showWarning && (
              <p className="text-danger">
                * {t("Select instructor with lesson time")}
              </p>
            )}
            {/* -------------- Enter Question section ends here -------------- */}
          </div>
        </div>
      </div>

      {/* --------------- Formula Popup component added here ----------- */}
      <QuestionPopUp
        selectedQuestion={selectedQuestion}
        onConfirmSubmit={onConfirmSubmit}
      />
    </>
  );
};

export default Questions;
