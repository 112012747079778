/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// import images here
import exportLogo1 from "assets/ChampionsDetailsAssets/images/exprt-logo1.png";
import exportLogo2 from "assets/ChampionsDetailsAssets/images/exprt-logo2.png";
import exportLogo3 from "assets/ChampionsDetailsAssets/images/exprt-logo3.png";

const Overview = () => {
  const token = localStorage.getItem("token");
  const params = useParams();

  //initial useState for JSON
  // const [overviewData, setOverviewData] = useState({
  //   id: 1,
  //   name: "",
  //   detailsPara1:
  //     "I love coding and problem solving. I have 3+ years of experience in the web development sector. Love working on both server and client side code. I like to get to know my clients closely to facilitate better communication.",
  //   detailsPara2:
  //     "Superb developer, he goes over and above in most cases to make sure the job is done and done well. He has the right skill set for a developer and more importantly he aligns himself with the vision of the product and provides great insights on how to build a product the right way. Will look forward to work with him on more projects.",
  //   experience: [
  //     {
  //       id: 1,
  //       position: "Business Manager",
  //       companyName: "Airbnb Inc.",
  //       image: exportLogo1,
  //       startDate: "Oct 2015",
  //       endDate: "Present",
  //       location: "San Franchisco, CA",
  //     },
  //     {
  //       id: 2,
  //       position: "Channel Sales Director",
  //       companyName: "Dropbox Inc.",
  //       image: exportLogo2,
  //       startDate: "Apr 2010",
  //       endDate: "Sep 2015",
  //       location: "Newark, NJ",
  //     },
  //     {
  //       id: 3,
  //       position: "Product Director",
  //       companyName: "Spotify Inc.",
  //       image: exportLogo3,
  //       startDate: "Jan 2008",
  //       endDate: "Mar 2010",
  //       location: "San Franchisco, CA",
  //     },
  //   ],
  //   skills: [
  //     {
  //       id: 1,
  //       name: "Marketing",
  //     },
  //     {
  //       id: 2,
  //       name: "Growth Management",
  //     },
  //     {
  //       id: 3,
  //       name: "Sales Growth",
  //     },
  //     {
  //       id: 4,
  //       name: "Analytics",
  //     },
  //     {
  //       id: 5,
  //       name: "Technology",
  //     },
  //     {
  //       id: 6,
  //       name: "SEO",
  //     },
  //     {
  //       id: 7,
  //       name: "Content Writer",
  //     },
  //     {
  //       id: 8,
  //       name: "Retail Management",
  //     },
  //     {
  //       id: 9,
  //       name: "Growth Analysis",
  //     },
  //   ],
  //   email: "Pierre.gagnaire@examaile.com",
  //   dateOfBirth: "15th January, 1986",
  //   language: "English, Polish, Spanish",
  //   education: "Bsc from Stanford University",
  // });
  const [overviewData, setOverviewData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // function for get about details
  const getAboutDetails = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_CHAMPION_ABOUT +
      `?token=${token}` +
      `&uid=${id}`;

    try {
      // console.log(
      //   "requesting URL to get the champion  about ------>",
      //   requestURL
      // );

      const response = await getData(requestURL);

      console.log(
        "Received Response in champion details overview --------> ",
        response
      );

      if (response.status) {
        setOverviewData(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect from here
  useEffect(() => {
    getAboutDetails(params.id);
  }, [params.id]);

  // return function from here
  return (
    <>
      {isLoading ? (
        <div className="tab-pane fade show active" id="overview">
          <div className="exprt_overviewOuter">
            <div className="course_detailspnl">
              <div className="contnt_hdng">
                <span
                  className="placeholder rounded-3 col-2"
                  style={{ height: "22px" }}
                ></span>
              </div>
              <div className="results_para">
                <p>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                </p>
                <p>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                </p>
              </div>
            </div>
            <div className="course_detailspnl">
              <div className="contnt_hdng">
                <span className="placeholder placeholder-md rounded-3 col-1"></span>
              </div>
              <div className="experience_pnl">
                <div className="dates_pnlOuter">
                  <div className="dates_pnlItem experice_item">
                    <span className="dates_nmbr exprince_logo">
                      <span
                        className="placeholder d-block rounded-2"
                        style={{ width: "100%", height: "100%" }}
                      ></span>
                    </span>
                    <div className="experience_hdng">
                      <h3>
                        <span className="placeholder placeholder-md rounded-3 col-2 ms-0"></span>{" "}
                        <span className="placeholder placeholder-sm rounded-3 col-1"></span>
                      </h3>
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "134px" }}
                      ></span>
                    </div>
                    <div className="job_tags">
                      <ul>
                        <li>
                          <span>San Franchisco, CA</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="dates_pnlItem experice_item">
                    <span className="dates_nmbr exprince_logo">
                      <span
                        className="placeholder d-block rounded-2"
                        style={{ width: "100%", height: "100%" }}
                      ></span>
                    </span>
                    <div className="experience_hdng">
                      <h3>
                        <span className="placeholder placeholder-md rounded-3 col-2 ms-0"></span>{" "}
                        <span className="placeholder placeholder-sm rounded-3 col-1"></span>
                      </h3>
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "134px" }}
                      ></span>
                    </div>
                    <div className="job_tags">
                      <ul>
                        <li>
                          <span>Newark, NJ</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="dates_pnlItem experice_item">
                    <span className="dates_nmbr exprince_logo">
                      <span
                        className="placeholder d-block rounded-2"
                        style={{ width: "100%", height: "100%" }}
                      ></span>
                    </span>
                    <div className="experience_hdng">
                      <h3>
                        <span className="placeholder placeholder-md rounded-3 col-2 ms-0"></span>{" "}
                        <span className="placeholder placeholder-sm rounded-3 col-1"></span>
                      </h3>
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "134px" }}
                      ></span>
                    </div>
                    <div className="job_tags">
                      <ul>
                        <li>
                          <span>San Franchisco, CA</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="tab-pane fade show active" id="overview">
          {overviewData == null ? null : (
            <div className="exprt_overviewOuter">
              {/* ---------------- Details section starts here ---------------- */}
              <div className="course_detailspnl">
                <div className="contnt_hdng">
                  <h4>Details</h4>
                </div>
                <div className="results_para">
                  <p
                    dangerouslySetInnerHTML={{ __html: overviewData.about }}
                  ></p>
                </div>
              </div>
              {/* ---------------- Details section starts here ---------------- */}

              {/* ---------------- Experience section starts here ---------------- */}
              <div className="course_detailspnl">
                <div className="contnt_hdng">
                  <h4>Experience</h4>
                </div>
                <div className="experience_pnl">
                  <div className="dates_pnlOuter">
                    {overviewData.experience.map((item, index) => (
                      <div className="dates_pnlItem experice_item" key={index}>
                        <span className="dates_nmbr exprince_logo">
                          <img src={exportLogo2} alt="CompanyLogo" />
                        </span>
                        <div className="experience_hdng">
                          <h3>
                            {item.designation} <span>at {item.company}</span>
                          </h3>
                          {item.datefrom || item.dateto ? (
                            <h5>
                              {item.datefrom} to {item.dateto}
                            </h5>
                          ) : null}
                        </div>
                        <div className="job_tags">
                          <ul>
                            <li>
                              <span>{item.location}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* ---------------- Experience section ends here ---------------- */}

              {/* ---------------- Skills section starts here ---------------- */}
              <div className="course_detailspnl">
                <div className="contnt_hdng">
                  <h4>Skills</h4>
                </div>
                {overviewData.skills.length == 0 ? null : (
                  <div className="experts_skill">
                    <ul>
                      {overviewData.skills.map((item, index) => (
                        <li key={index}>
                          <Link to="#">{item.label}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {/* ---------------- Skills section ends here ---------------- */}

              {/* ---------------- Additional Information section starts here ---------------- */}
              <div className="course_detailspnl">
                <div className="contnt_hdng">
                  <h4>Additional Information</h4>
                </div>
                <div className="expert_aditonal_outer">
                  <div className="row">
                    {/* --------- Email --------- */}
                    <div className="col-lg-3 col-md-6">
                      <div className="additional_info">
                        <h4>
                          <i className="material-icons-outlined">email</i> Email
                        </h4>
                        <Link to={`${overviewData.email}`}>
                          {overviewData.email}
                        </Link>
                      </div>
                    </div>

                    {/* --------- Date Of Birth --------- */}
                    <div className="col-lg-3 col-md-6">
                      <div className="additional_info">
                        <h4>
                          <i className="material-icons-outlined">event_note</i>
                          Date of Birth
                        </h4>
                        <h3>{overviewData.dateOfBirth}</h3>
                      </div>
                    </div>

                    {/* --------- Language --------- */}
                    <div className="col-lg-3 col-md-6">
                      <div className="additional_info">
                        <h4>
                          <i className="material-icons-outlined">language</i>
                          Language
                        </h4>
                        <h3>{overviewData.language}</h3>
                      </div>
                    </div>

                    {/* --------- Education --------- */}
                    <div className="col-lg-3 col-md-6">
                      <div className="additional_info">
                        <h4>
                          <i className="material-icons-outlined">school</i>{" "}
                          Education
                        </h4>
                        <h3>{overviewData.education}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ---------------- Additional Information section ends here ---------------- */}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Overview;
