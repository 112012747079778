/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// import custom componenets here
import Overview from "components/ChampionsDetailsComponents/Tabs/Overview";
import Portfolio from "components/ChampionsDetailsComponents/Tabs/Portfolio";
import Contribution from "components/ChampionsDetailsComponents/Tabs/Contribution";
import Survey from "components/ChampionsDetailsComponents/Tabs/Survey";
import Schedule from "components/ChampionsDetailsComponents/Tabs/Schedule";
import Review from "components/ChampionsDetailsComponents/Tabs/Review";

const ChampionDetailsBottomSection = () => {
  // accessing the user's token here
  const token = localStorage.getItem("token");
  const params = useParams();

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // initial for loading glow effect
  const [isLoading, setIsLoading] = useState(false);

  /*----- initial state for toggling tabs ------*/
  const [overviewTab, setOverviewTab] = useState(true);
  const [portfolioTab, setPortfolioTab] = useState(false);
  const [contributionTab, setContributionTab] = useState(false);
  const [surveyTab, setSurveyTab] = useState(false);
  const [scheduleTab, setScheduleTab] = useState(false);
  const [reviewTab, setReviewTab] = useState(false);
  /*----- end of initial state for toggling tabs ------*/

  // funtion for display contents tab
  const showOverviewTab = () => {
    setOverviewTab(true);
    setPortfolioTab(false);
    setContributionTab(false);
    setSurveyTab(false);
    setScheduleTab(false);
    setReviewTab(false);
  };

  const showPortfolioTab = () => {
    setOverviewTab(false);
    setPortfolioTab(true);
    setContributionTab(false);
    setSurveyTab(false);
    setScheduleTab(false);
    setReviewTab(false);
  };

  const showContributionTab = () => {
    setOverviewTab(false);
    setPortfolioTab(false);
    setContributionTab(true);
    setSurveyTab(false);
    setScheduleTab(false);
    setReviewTab(false);
  };

  const showSurveyTab = () => {
    setOverviewTab(false);
    setPortfolioTab(false);
    setContributionTab(false);
    setSurveyTab(true);
    setScheduleTab(false);
    setReviewTab(false);
  };

  const showScheduleTab = () => {
    setOverviewTab(false);
    setPortfolioTab(false);
    setContributionTab(false);
    setSurveyTab(false);
    setScheduleTab(true);
    setReviewTab(false);
  };

  const showReviewTab = () => {
    setOverviewTab(false);
    setPortfolioTab(false);
    setContributionTab(false);
    setSurveyTab(false);
    setScheduleTab(false);
    setReviewTab(true);
  };

  // function for get champion details
  const getChampionsData = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_CHAMPION_BANNER +
      `?token=${token}` +
      `&uid=${id}`;

    try {
      const response = await getData(requestURL);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect from here
  useEffect(() => {
    getChampionsData(params.id);
  }, [params.id]);

  return (
    <>
      {isLoading ? (
        <section className="indra_course_tabArea placeholder-glow">
          <div className="course_tablinks_outer">
            <div className="container">
              <div className="tab_links">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <Link
                      className="nav-link active show"
                      data-bs-toggle="tab"
                      to="#overview"
                    >
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "42px" }}
                      ></span>
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link" data-bs-toggle="tab" to="#portfolio">
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "42px" }}
                      ></span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      data-bs-toggle="tab"
                      to="#contribution"
                    >
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "42px" }}
                      ></span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link " data-bs-toggle="tab" to="#survey">
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "42px" }}
                      ></span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" data-bs-toggle="tab" to="#schedule">
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "42px" }}
                      ></span>
                    </Link>
                  </li> */}

                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      data-bs-toggle="tab"
                      to="#feedbacks"
                    >
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "42px" }}
                      ></span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="indra_course_tabArea">
          <div className="course_tablinks_outer">
            <div className="container">
              <div className="tab_links">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <Link
                      className={
                        overviewTab ? "nav-link active show" : "nav-link"
                      }
                      data-bs-toggle="tab"
                      to="#"
                      onClick={showOverviewTab}
                    >
                      Overview
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      className={
                        portfolioTab ? "nav-link active show" : "nav-link"
                      }
                      data-bs-toggle="tab"
                      to="#"
                      onClick={showPortfolioTab}
                    >
                      Portfolio
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        contributionTab ? "nav-link active show" : "nav-link"
                      }
                      data-bs-toggle="tab"
                      to="#"
                      onClick={showContributionTab}
                    >
                      contribution
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        surveyTab ? "nav-link active show" : "nav-link"
                      }
                      data-bs-toggle="tab"
                      to="#"
                      onClick={showSurveyTab}
                    >
                      survey
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        scheduleTab ? "nav-link active show" : "nav-link"
                      }
                      data-bs-toggle="tab"
                      to="#"
                      onClick={showScheduleTab}
                    >
                      Schedule
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link
                      className={
                        reviewTab ? "nav-link active show" : "nav-link"
                      }
                      data-bs-toggle="tab"
                      to="#"
                      onClick={showReviewTab}
                    >
                      Review
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="tab_bodyArea">
            <div className="container">
              <div className="tab-content">
                {overviewTab && <Overview />}
                {/* {portfolioTab && <Portfolio />}
                {contributionTab && <Contribution />}
                {surveyTab && <Survey />}
                {scheduleTab && <Schedule />} */}
                {reviewTab && <Review />}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ChampionDetailsBottomSection;
