import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// importing icons here
import facebook from "assets/CourseDetailsAssests/svgs/fb.svg";
import twitter from "assets/CourseDetailsAssests/svgs/twitter.svg";
import linkedin from "assets/CourseDetailsAssests/svgs/linkedin.svg";

const ShareModal = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  return (
    <div className="share_modal_bx">
      <div
        className="modal fade"
        id="share_modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* ------------ Modal Header ------------ */}
            <div className="modal-header">
              <h5 className="modal-title">{t("Share")}</h5>
              <button
                type="button"
                className="close_btn ylw_closeBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close </span>
              </button>
            </div>

            {/* ------------ Modal Body ------------ */}
            <div className="modal-body">
              {/* --------- Social component starts here --------- */}
              <div className="share_social_outer">
                {/* ----------- facebook ----------- */}
                <div className="share_social_row">
                  <Link to="#">
                    <div className="social_icon_text">
                      <i>
                        <img src={facebook} alt="facebookIcon" />
                      </i>
                      <span>facebook</span>
                    </div>
                    <div className="social_send">
                      <span className="material-icons"> send </span>
                    </div>
                  </Link>
                </div>

                {/* ----------- twitter ----------- */}
                <div className="share_social_row">
                  <Link to="#">
                    <div className="social_icon_text">
                      <i>
                        <img src={twitter} alt="twitterIcon" />
                      </i>
                      <span>twitter</span>
                    </div>
                    <div className="social_send">
                      <span className="material-icons"> send </span>
                    </div>
                  </Link>
                </div>

                {/* ----------- linkedin ----------- */}
                <div className="share_social_row">
                  <Link to="#">
                    <div className="social_icon_text">
                      <i>
                        <img src={linkedin} alt="" />
                      </i>
                      <span>linkedin</span>
                    </div>
                    <div className="social_send">
                      <span className="material-icons"> send </span>
                    </div>
                  </Link>
                </div>
              </div>

              {/* --------- Link component starts here --------- */}
              <div className="share_copylink_row">
                <label>{t("Copy link to share")}</label>
                <div className="share_copybx">
                  <span>
                    https://indra.com/course/details/id2893250course/details/id2893250course/details/id2893250
                  </span>
                  <Link to="#">
                    <i className="material-icons">content_copy</i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
