/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const Applied = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  // 👇️ get ID from url
  const params = useParams();

  // Initial useState to save all the incoming data from API.
  const [jobDetailsData, setJobDetailsData] = useState(null);

  // Function to call the API and get all the data
  const getJobDetailsData = async (id) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_CHALLENGES_DETAILS_APPLIED_TAB +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      // console.log(
      //   "requesting URL to get all the job details applied tab ------> ",
      //   requestURL
      // );

      const response = await getData(requestURL);
      console.log(
        "Received Response in challenge details applied tab --------> ",
        response
      );

      if (response.status) {
        setJobDetailsData(response.data);
      } else {
        console.log("Some error occurred ------> ", response);
      }
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
    }
  };

  // useEffect from here
  useEffect(() => {
    getJobDetailsData(params.id);
  }, []);

  return (
    <div className="course_detailspnl">
      <div className="member_box_outer">
        <div className="contnt_hdng tab_Hdng">
          <h4>Applied</h4>
        </div>
        <div className="member_box_outer">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <img src="images/expert4.png" alt="" />
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>Bertha Sandoval</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <span className="material-icons-outlined">person</span>
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>CAN00KLPES</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <img src="images/expert4.png" alt="" />
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>Bertha Sandoval</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <span className="material-icons-outlined">person</span>
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>CAN00KLPES</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <img src="images/expert4.png" alt="" />
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>Bertha Sandoval</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <span className="material-icons-outlined">person</span>
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>CAN00KLPES</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <img src="images/expert4.png" alt="" />
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>Bertha Sandoval</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <span className="material-icons-outlined">person</span>
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>CAN00KLPES</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <img src="images/expert4.png" alt="" />
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>Bertha Sandoval</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <span className="material-icons-outlined">person</span>
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>CAN00KLPES</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <img src="images/expert4.png" alt="" />
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>Bertha Sandoval</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <span className="material-icons-outlined">person</span>
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>CAN00KLPES</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <img src="images/expert4.png" alt="" />
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>Bertha Sandoval</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <span className="material-icons-outlined">person</span>
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>CAN00KLPES</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <img src="images/expert4.png" alt="" />
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>Bertha Sandoval</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="expertbx_item members_teamItem">
                <div className="membrs_teamOuter">
                  <div className="team_membrs_pic">
                    <span className="material-icons-outlined">person</span>
                  </div>
                  <div className="membrs_team_details">
                    <div className="team_name">
                      <h3>CAN00KLPES</h3>
                      <h5>UX Designer, Google</h5>
                    </div>
                    <div className="team_rating star_color ">
                      <h3>
                        {" "}
                        <i className="material-icons-outlined">star</i>{" "}
                        <span>4.5</span>
                      </h3>

                      <h6>
                        32<span>(valoraciones)</span>
                      </h6>
                    </div>
                    <div className="team_address_value">
                      <h3>
                        Madrid,Spain <span>€12,000</span>
                      </h3>
                    </div>
                    =
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applied;
