import React from "react";
import { useTranslation } from "react-i18next";

//import images
import welcomeGraphics from "assets/ConversationAssests/images/welcome_graphics.png";

const Welcome = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  return (
    <div className="welcome_screnBx">
      <div className="welcome_innrPnl">
        <div className="welcome_graphics">
          <img src={welcomeGraphics} alt="welcome" />
        </div>
        <div className="welcome_txtpnl">
          <h2>{t("Welcome to Indra Chat")}</h2>
          <p>
            {t(
              "Start messaging and send email to your connections in a single platform"
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
