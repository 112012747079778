import React, { useEffect, useState } from "react";

//import custom components
import Header from "common/Header";
import Banner from "components/EventsComponents/Banner";
import TabArea from "common/tabarea/TabArea";
import EventsListArea from "components/EventsComponents/EventsListArea";
import Footer from "common/Footer";

const Events = () => {
  useEffect(() => {
    document.title = "Events";
  }, []);

  return (
    <>
      <Header />
      <Banner />
      <TabArea module={"event"} />
      <EventsListArea />
      <Footer />
    </>
  );
};

export default Events;
