import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const HomeChallengeLists = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section className="challenges_sec pd_30">
      <div className="container">
        <div className="slideHdng_outer">
          <div className="page_hdng">
            <h2>Best Challenges</h2>
          </div>
          <div className="see_moreBtn">
            <Link to="#" className="border_btn ylwbrdr_btn">
              See All
            </Link>
          </div>
        </div>
        <div className="slide_Outer">
          <div className="challenges_slider slider_innr">
            <Carousel ssr showDots responsive={responsive}>
              <div className="slide_Item mb-4">
                <div className="challenges_item">
                  <div className="challenge_top_head">
                    <div className="challenge_iconOuter">
                      <i className="challenge_icon">
                        <img src="images/shopify.svg" alt="" />
                      </i>
                      <span className="challenge_icon_text">Shopify</span>
                    </div>
                    <div className="bookmark_iconOuter">
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                        </Link>
                      </div>
                      <div className="bookmark_icon">
                        <Link to="#">
                          <i className="material-icons-outlined">
                            bookmark_border
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="club_nameLocation">
                    <h3>Website Developer</h3>
                    <h4>$50k - $150K</h4>
                    <div className="locate_text">
                      <h5>
                        <i className="material-icons-outlined">place</i>
                        <span>NewYork, USA</span>
                      </h5>
                    </div>
                  </div>
                  <div className="experts_skill">
                    <ul>
                      <li>
                        <Link to="#">
                          <span>Backend</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data analyst</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>analyst</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data science</span>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="work_details">
                    <ul>
                      <li>Full Time</li>
                      <li>$60k - $100k</li>
                      <li>
                        <span className="material-icons-outlined">
                          signal_cellular_alt
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="challenges_item">
                  <div className="challenge_top_head">
                    <div className="challenge_iconOuter">
                      <i className="challenge_icon">
                        <img src="images/shopify.svg" alt="" />
                      </i>
                      <span className="challenge_icon_text">Shopify</span>
                    </div>
                    <div className="bookmark_iconOuter">
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                        </Link>
                      </div>
                      <div className="bookmark_icon">
                        <Link to="#">
                          <i className="material-icons-outlined">
                            bookmark_border
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="club_nameLocation">
                    <h3>Website Developer</h3>
                    <h4>$50k - $150K</h4>
                    <div className="locate_text">
                      <h5>
                        <i className="material-icons-outlined">place</i>
                        <span>NewYork, USA</span>
                      </h5>
                    </div>
                  </div>
                  <div className="experts_skill">
                    <ul>
                      <li>
                        <Link to="#">
                          <span>Backend</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data analyst</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>analyst</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data science</span>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="work_details">
                    <ul>
                      <li>Full Time</li>
                      <li>$60k - $100k</li>
                      <li>
                        <span className="material-icons-outlined">
                          signal_cellular_alt
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="challenges_item">
                  <div className="challenge_top_head">
                    <div className="challenge_iconOuter">
                      <i className="challenge_icon">
                        <img src="images/shopify.svg" alt="" />
                      </i>
                      <span className="challenge_icon_text">Shopify</span>
                    </div>
                    <div className="bookmark_iconOuter">
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                        </Link>
                      </div>
                      <div className="bookmark_icon">
                        <Link to="#">
                          <i className="material-icons-outlined">
                            bookmark_border
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="club_nameLocation">
                    <h3>Website Developer</h3>
                    <h4>$50k - $150K</h4>
                    <div className="locate_text">
                      <h5>
                        <i className="material-icons-outlined">place</i>
                        <span>NewYork, USA</span>
                      </h5>
                    </div>
                  </div>
                  <div className="experts_skill">
                    <ul>
                      <li>
                        <Link to="#">
                          <span>Backend</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data analyst</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>analyst</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data science</span>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="work_details">
                    <ul>
                      <li>Full Time</li>
                      <li>$60k - $100k</li>
                      <li>
                        <span className="material-icons-outlined">
                          signal_cellular_alt
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="challenges_item">
                  <div className="challenge_top_head">
                    <div className="challenge_iconOuter">
                      <i className="challenge_icon">
                        <img src="images/shopify.svg" alt="" />
                      </i>
                      <span className="challenge_icon_text">Shopify</span>
                    </div>
                    <div className="bookmark_iconOuter">
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                        </Link>
                      </div>
                      <div className="bookmark_icon">
                        <Link to="#">
                          <i className="material-icons-outlined">
                            bookmark_border
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="club_nameLocation">
                    <h3>Website Developer</h3>
                    <h4>$50k - $150K</h4>
                    <div className="locate_text">
                      <h5>
                        <i className="material-icons-outlined">place</i>
                        <span>NewYork, USA</span>
                      </h5>
                    </div>
                  </div>
                  <div className="experts_skill">
                    <ul>
                      <li>
                        <Link to="#">
                          <span>Backend</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data analyst</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>analyst</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data science</span>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="work_details">
                    <ul>
                      <li>Full Time</li>
                      <li>$60k - $100k</li>
                      <li>
                        <span className="material-icons-outlined">
                          signal_cellular_alt
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="challenges_item">
                  <div className="challenge_top_head">
                    <div className="challenge_iconOuter">
                      <i className="challenge_icon">
                        <img src="images/shopify.svg" alt="" />
                      </i>
                      <span className="challenge_icon_text">Shopify</span>
                    </div>
                    <div className="bookmark_iconOuter">
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                        </Link>
                      </div>
                      <div className="bookmark_icon">
                        <Link to="#">
                          <i className="material-icons-outlined">
                            bookmark_border
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="club_nameLocation">
                    <h3>Website Developer</h3>
                    <h4>$50k - $150K</h4>
                    <div className="locate_text">
                      <h5>
                        <i className="material-icons-outlined">place</i>
                        <span>NewYork, USA</span>
                      </h5>
                    </div>
                  </div>
                  <div className="experts_skill">
                    <ul>
                      <li>
                        <Link to="#">
                          <span>Backend</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data analyst</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>analyst</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Data science</span>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="work_details">
                    <ul>
                      <li>Full Time</li>
                      <li>$60k - $100k</li>
                      <li>
                        <span className="material-icons-outlined">
                          signal_cellular_alt
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeChallengeLists;
