/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// importing images here.

const RightProfilePanel = (props) => {
  // get loggedin User information from local storage
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // console.log("Right profile panel--->", props.contactInfo);

  return (
    <>
      {props.contactInfo == undefined ? null : (
        <div className="profile_pnlArea">
          {/* ---------- Profile heading section ---------- */}
          <div className="profile_hdngTop">
            <h3>Profile</h3>
            <span
              className="profile_crossbtn"
              onClick={() => {
                document.body.classList.toggle("profile_menuPnl_open");
              }}
            >
              <i className="material-icons-round"> close </i>
            </span>
          </div>
          {/* ---------- end of Profile heading section ---------- */}

          {/* ---------- Profile body section ---------- */}
          <div className="profile_innr_body">
            <div className="profile_innrScroll">
              {/* ---------- User Image && name section ---------- */}
              {props.contactInfo == null ||
              props.contactInfo == undefined ? null : (
                <div className="profile_info_row">
                  <div className="profile_picPnl">
                    <div className="profile_img">
                      <figure>
                        {props.contactInfo.image == null ? (
                          <div className="chatting_user">
                            <span className="material-icons-round">person</span>
                          </div>
                        ) : (
                          <img
                            src={props.contactInfo.image}
                            alt="userProfilePic"
                          />
                        )}
                      </figure>
                    </div>
                    <div className="profile_textPnl">
                      <h4>
                        {props.contactInfo.name != null
                          ? props.contactInfo.name
                          : null}
                        {props.contactInfo.surname != null
                          ? props.contactInfo.surname
                          : null}
                      </h4>
                      <h5>Last Online:{props.contactInfo.lastcommtime}</h5>
                    </div>
                  </div>

                  {props.contactInfo.tags != null ? (
                    <div className="tag_msg">
                      {props.contactInfo.tags.length > 0 ? (
                        <Link to="#">
                          <i className="material-icons">label</i>
                          {props.contactInfo.tags.map((item2, index2) => {
                            return <span key={index2}>{item2.label},</span>;
                          })}
                        </Link>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              )}
              {/* ---------- end of User Image section ---------- */}

              {/* ---------- User email section ---------- */}
              {props.contactInfo == null ? null : (
                <div className="profile_info_row">
                  <div className="profile_details_row">
                    <div className="info_icon">
                      <i className="material-icons-round">email </i>
                      <span>Mail</span>
                    </div>
                    <p>
                      <Link to="#">
                        {props.contactInfo.email != null
                          ? props.contactInfo.email
                          : null}
                      </Link>
                    </p>
                  </div>
                </div>
              )}
              {/* ---------- end of User email section ---------- */}

              {/* ---------- User phone section ---------- */}
              <div className="profile_info_row">
                <div className="profile_details_row">
                  <div className="info_icon">
                    <i className="material-icons-round">phone_in_talk </i>
                    <span>Phone</span>
                  </div>
                  <p>
                    <Link to="#">
                      {props.contactInfo.phone != null
                        ? props.contactInfo.phone
                        : null}
                    </Link>
                  </p>
                </div>
              </div>
              {/* ---------- end of User phone section ---------- */}

              {/* ---------- User address section ---------- */}
              <div className="profile_info_row">
                <div className="profile_details_row">
                  <div className="info_icon">
                    <i className="material-icons-round">place </i>
                    <span>Address</span>
                  </div>
                  <p>
                    <Link to="#">
                      {props.contactInfo.address != null
                        ? props.contactInfo.address
                        : null}
                    </Link>
                  </p>
                </div>
              </div>
              {/* ---------- end of User address section ---------- */}

              {/* ---------- User work information section ---------- */}
              <div className="profile_info_row">
                <div className="profile_details_row">
                  <div className="info_icon">
                    <i className="material-icons-round">location_city </i>
                    <span>Position</span>
                  </div>
                  <p>
                    <Link to="#">
                      {props.contactInfo.position != null
                        ? props.contactInfo.position
                        : null}
                    </Link>
                  </p>
                </div>
              </div>
              {/* ---------- end of User work information section ---------- */}

              {/* ---------- Delete chat section ---------- */}
              {/* <div className="profile_info_row">
                <div className="profile_details_row">
                  <div className="info_icon">
                    <i className="material-icons-round">delete </i>
                    <span>Delete Chat</span>
                  </div>
                </div>
              </div> */}
              {/* ---------- end of Delete chat section ---------- */}

              {/* ---------- Toggle 'mute' switch section ---------- */}
              {/* <div className="profile_info_row">
                <div className="profile_details_row mute_row">
                  <div className="info_icon">
                    <i className="material-icons-round">
                      notifications_active{" "}
                    </i>
                    <span>Mute Notification</span>
                  </div>
                  <div className="toggle_switch">
                    <div className="switch_box box_1">
                      <input type="checkbox" className="switch_1" />
                    </div>
                  </div>
                </div>
              </div> */}
              {/* ---------- end of Toggle 'mute' switch section ---------- */}

              {/* ---------- User attachments section ---------- */}
              {/* <div className="profile_info_row">
                <div className="profile_details_row">
                  <div className="info_icon">
                    <i className="material-icons-round">collections </i>
                    <span>Attachments</span>
                  </div>
                  <div className="profile_attachedment_outer">
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached1} alt="attached1" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached2} alt="attached2" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached3} alt="attached3" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached4} alt="attached4" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached5} alt="attached5" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached6} alt="attached6" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached2} alt="attached2" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={attached4} data-fancybox="fancyBig">
                        <img src={attached4} alt="attached4" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={attached6} data-fancybox="fancyBig">
                        <img src={attached6} alt="attached6" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={attached1} data-fancybox="fancyBig">
                        <img src={attached1} alt="attached1" />
                      </Link>
                    </div>

                    <div className="attachedment_item">
                      <Link to={attached3} data-fancybox="fancyBig">
                        <img src={attached3} alt="attached3" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={attached5} data-fancybox="fancyBig">
                        <img src={attached5} alt="attached5" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* ---------- end of User attachments section ---------- */}

              {/* ---------- Signout section ---------- */}
              {/* <div className="profile_info_row">
                <div className="profile_details_row signout_fill">
                  <Link
                    to="#"
                    className="info_icon"
                    onClick={() => {
                      localStorage.clear(); // clear the local storage after log out
                      window.location.reload(); // for reloading page after log out
                    }}
                  >
                    <i className="material-icons-round">account_circle </i>
                    <span>Sign Out</span>
                  </Link>
                </div>
              </div> */}
              {/* ---------- end of Signout section ---------- */}
            </div>
          </div>
          {/* ---------- end of Profile heading section ---------- */}
        </div>
      )}
    </>
  );
};

export default RightProfilePanel;
