/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { GlobalIndraInfo } from "App";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const QuestionPopUp = (props) => {
  // accessing the user's token here
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // Getting the course ID, Lesson ID created in Step 1
  const { courseId, lessonId } = useContext(GlobalIndraInfo);

  const [qustionId, setQustionId] = useState("");

  // initial state for step four question popup data
  const [qustionPopUpAnswer, setQuestionPopUpAnswer] = useState("");

  //initial state for display error message
  const [titleErrorMessage, setTitleErrorMessage] = useState(false);

  /*---- CKEditor classic editor configuration----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      uploadUrl: "https://dev.lobees.com/web/uploads/reports/",
    },
    placeholder: "Type here...",
  };
  /*----- end of CKEditor classic editor part----- */

  // lesson submit
  const lessonAnswerSubmitHandler = async (e) => {
    // prevent page reload
    e.preventDefault();

    // console.log("lesson ------>", val, index);
    if (qustionPopUpAnswer == "") {
      setTitleErrorMessage(true);
    } else {
      setTitleErrorMessage(false);

      let requestURL =
        url.API_BaseUrl +
        url.API_STEP_4_ADD_LESSON_ANSWER +
        `?token=${token}` +
        `&cid=${courseId}`;

      if (qustionId != "") {
        requestURL = requestURL + `&qid=${qustionId}`;
      }

      // console.log(
      //   "Step 4 Question Popup sending answer --------> ",
      //   requestURL
      // );

      let answerOfQuestion = {
        answer: qustionPopUpAnswer,
      };

      try {
        const response = await postData(requestURL, answerOfQuestion);

        if (response.status) {
          // console.log(
          //   "Step 4 Question popup receiving response --------> ",
          //   response
          // );
          document.body.classList.remove("formula_openPopup");
          props.onConfirmSubmit();
        } else {
          console.log("Some error occurred while submitting the data!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (props.selectedQuestion) {
      console.log(props.selectedQuestion);
      setQuestionPopUpAnswer(props.selectedQuestion.answer);
      setQustionId(props.selectedQuestion.id);
    }
  }, [props.selectedQuestion]);

  return (
    <>
      <div className="ux_details_popupArea formula_popup_area">
        <div className="ux_popupHdng">
          <h3>
            <span
              className="formula_cross"
              onClick={() => {
                props.onConfirmSubmit();
                document.body.classList.remove("formula_openPopup");
              }}
            >
              <i className="material-icons-outlined">arrow_back</i>
            </span>
            {props.question}
          </h3>
        </div>
        <div className="uxfill_innrBody">
          <div className="taskpnl_outer">
            <div className="course_stepItem">
              <div className="coursestep_hdng">
                <h3>{t("Answers")}</h3>
              </div>

              {/* ------------------ Text Editor Starts here ------------------ */}
              <div className="text_editor">
                <div className="text_msg">
                  <CKEditor
                    className="form-control"
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={qustionPopUpAnswer ? qustionPopUpAnswer : ""}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "250px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      let editContent = editor.getData();
                      //console.log(JSON.stringify(editContent));
                      setQuestionPopUpAnswer(editContent);
                    }}
                  />
                  {titleErrorMessage && (
                    <p className="text-danger mt-2">
                      * {t("Please Answer the given question")}
                    </p>
                  )}
                </div>

                <div className="update_btn">
                  <button
                    type="submit"
                    className="basic_btn"
                    onClick={(e) => {
                      lessonAnswerSubmitHandler(e);
                    }}
                  >
                    {t("Answer")}
                  </button>
                </div>
              </div>
              {/* ------------------ Text Editor Starts here ------------------ */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionPopUp;
