/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

import blankVideo from "assets/CourseDetailsAssests/images/course-vdo-right-blank.jpg";

const About = () => {
  const token = localStorage.getItem("token");
  const params = useParams();

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [features, setFeatures] = useState(null);
  const [skills, setSkills] = useState(null);
  const [about, setAbout] = useState("");
  const [requirements, setRequirements] = useState(null);
  const [promotionalText, setPromotionalText] = useState(null);
  const [stories, setStories] = useState(null);
  const [promoVideoURL, setpromoVideoURL] = useState("");
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // function for get course about
  const getCourseAbout = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_VIEW_COURSE_DETAILS +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      // console.log("requesting URL to get lesson details ------>", requestURL);
      const response = await getData(requestURL);

      if (response.status) {
        setFeatures(response.data.features);
        setSkills(response.data.skills);
        setAbout(response.data.about);
        setRequirements(response.data.requirements);
        setPromotionalText(response.data.promotionaltext);
        setStories(response.data.stories);
        setpromoVideoURL(response.data.promotionalvideo);
        setCategories(response.data.categories);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
    }
  };

  useEffect(() => {
    getCourseAbout(params.id);
  }, [params.id]);

  // return from here
  return (
    <>
      {/* --------------- Course (About) Body starts here --------------- */}
      <div className="tab-pane show active fade" id="course_about">
        <div className="training_outer">
          <div className="row">
            {/* ---------- Left side component starts here ---------- */}
            <div className="col-lg-8">
              {isLoading ? (
                <div className="exprt_overviewOuter">
                  <div className="course_detailspnl">
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-3 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                  </div>
                  <div className="course_detailspnl">
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-3 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                    </p>
                  </div>
                  <div className="course_detailspnl">
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-4 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-12 rounded-3 mb-10"></span>
                      <span className="placeholder placeholder-sm col-11 rounded-3 mb-10"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-10"></span>
                      <span className="placeholder placeholder-sm col-9 rounded-3 mb-10"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-12 rounded-3 mb-10"></span>
                      <span className="placeholder placeholder-sm col-11 rounded-3 mb-10"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-10"></span>
                      <span className="placeholder placeholder-sm col-9 rounded-3 mb-10"></span>
                    </p>
                  </div>
                  {/* <div className="course_detailspnl">
                    <div className="course_vdopnlOuter">
                      <div className="course_vdo_row">
                        <div className="course_vdopnl">
                          <figure>
                            <img src="images/course-vdo1.jpg" alt="" />
                          </figure>
                          <div className="vdoplay_btn">
                            <Link to="#">
                              <span className="material-icons-round">
                                play_arrow
                              </span>
                            </Link>
                          </div>
                        </div>
                        <div className="vdo_textpnl">
                          <div className="time_clock">
                            <i className="material-icons-round">schedule </i>
                            <span>3.40</span>
                          </div>
                          <div className="vdo_hdng">
                            <h3>
                              Desktop publishing software like Aldus PageMaker
                              including versions
                            </h3>
                          </div>
                          <div className="vdo_para">
                            <p>
                              I love coding and problem solving. I have 3+ years
                              of experience in the web development sector. Love
                              working on both server and client side code...
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="course_vdo_row">
                        <div className="course_vdopnl">
                          <figure>
                            <img src="images/course-vdo2.jpg" alt="" />
                          </figure>
                          <div className="vdoplay_btn">
                            <Link to="#">
                              <span className="material-icons-round">
                                play_arrow
                              </span>
                            </Link>
                          </div>
                        </div>
                        <div className="vdo_textpnl">
                          <div className="time_clock">
                            <i className="material-icons-round">schedule </i>
                            <span>3.40</span>
                          </div>
                          <div className="vdo_hdng">
                            <h3>
                              Desktop publishing software like Aldus PageMaker
                              including versions
                            </h3>
                          </div>
                          <div className="vdo_para">
                            <p>
                              I love coding and problem solving. I have 3+ years
                              of experience in the web development sector. Love
                              working on both server and client side code...
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="course_vdo_row">
                        <div className="course_vdopnl">
                          <figure>
                            <img src="images/course-vdo1.jpg" alt="" />
                          </figure>
                          <div className="vdoplay_btn">
                            <Link to="#">
                              <span className="material-icons-round">
                                play_arrow
                              </span>
                            </Link>
                          </div>
                        </div>
                        <div className="vdo_textpnl">
                          <div className="time_clock">
                            <i className="material-icons-round">schedule </i>
                            <span>3.40</span>
                          </div>
                          <div className="vdo_hdng">
                            <h3>
                              Desktop publishing software like Aldus PageMaker
                              including versions
                            </h3>
                          </div>
                          <div className="vdo_para">
                            <p>
                              I love coding and problem solving. I have 3+ years
                              of experience in the web development sector. Love
                              working on both server and client side code...
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              ) : (
                <div className="exprt_overviewOuter">
                  {/* --------- Features section starts here --------- */}
                  {features != null && features.length > 0 ? (
                    <div className="course_detailspnl">
                      <div className="contnt_hdng">
                        <h4>{t("What you'll learn?")}</h4>
                      </div>
                      <div className="training_ticklisting">
                        {features.map((feature, index) => {
                          return (
                            <p key={feature.id}>
                              <i className="material-icons-outlined">
                                check_circle
                              </i>
                              <span>{feature.label}</span>
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  {/* --------- Features section ends here --------- */}

                  {/* --------- Skills section starts here --------- */}
                  {skills != null && skills.length > 0 ? (
                    <div className="course_detailspnl">
                      <div className="contnt_hdng">
                        <h4>{t("Skills you will gain")}</h4>
                      </div>
                      <div className="experts_skill">
                        <ul>
                          {skills.map((skill, index) => {
                            return (
                              <li key={skill.id}>
                                <Link to="#">{skill.label}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  ) : null}
                  {/* --------- Skills section ends here --------- */}

                  {/* --------- Author section starts here --------- */}
                  {about != "" ? (
                    <div className="course_detailspnl">
                      <div className="contnt_hdng">
                        <h4>{t("About this course")}</h4>
                      </div>
                      <div className="results_para">
                        <form>
                          <p dangerouslySetInnerHTML={{ __html: about }}></p>
                        </form>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* --------- Author insight section ends here --------- */}

                  {/* --------- Stories section starts here --------- */}
                  {stories != null && stories.length > 0 ? (
                    <div className="course_detailspnl">
                      <div className="contnt_hdng">
                        <h4>{t("Stories")}</h4>
                      </div>
                      <div className="course_vdopnlOuter">
                        {stories.map((story, index) => {
                          return (
                            <div className="course_vdo_row" key={story.id}>
                              {/* ------- Video section starts here ------- */}
                              <div className="course_vdopnl">
                                <div className="embed-responsive embed-responsive-16by9 course_vdoBx">
                                  <video
                                    className="embed-responsive-item"
                                    style={{ borderRadius: "5px" }}
                                    src={story.video != null ? story.video : ""}
                                    id="samplepreview"
                                    controls
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                    fullscreen="true"
                                  ></video>
                                </div>
                              </div>
                              {/* ------- Video section ends here ------- */}

                              {/* ------- Details section starts here ------- */}
                              <div className="vdo_textpnl">
                                {/* ------ Time section ------ */}
                                <div className="time_clock">
                                  <i className="material-icons-round">
                                    schedule{" "}
                                  </i>
                                  <span>3.40</span>
                                </div>

                                {/* ------ Heading section ------ */}
                                <div className="vdo_hdng">
                                  <h3>{story.title}</h3>
                                </div>

                                {/* ------ Body section ------ */}
                                <div className="vdo_para">
                                  <p>{story.article}</p>
                                </div>
                              </div>
                              {/* ------- Details section ends here ------- */}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  {/* --------- Stories section ends here --------- */}

                  {/* --------- Requirements section starts here --------- */}
                  {requirements != null && requirements.length > 0 ? (
                    <div className="course_detailspnl">
                      <div className="contnt_hdng">
                        <h4>{t("Requirements / Pre-requisits")}</h4>
                      </div>
                      <div className="training_ticklisting">
                        {requirements.map((requirement, index) => {
                          return (
                            <p key={requirement.id}>
                              <i className="material-icons-outlined">
                                check_circle
                              </i>
                              <span>{requirement.label}</span>
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  {/* --------- Requirements section ends here --------- */}
                </div>
              )}
            </div>
            {/* ---------- Left side component ends here ---------- */}

            {/* ---------- Right side component starts here ---------- */}
            <div className="col-lg-4">
              {features == null ||
              skills == null ||
              about == "" ||
              requirements == null ||
              promotionalText == null ||
              stories == null ||
              promoVideoURL == "" ? (
                <div className="traning_pnlOuter">
                  <div className="course_rightPnl placeholder-glow">
                    <div className="course_vdopnl">
                      <figure>
                        <img src={blankVideo} alt="video" />
                      </figure>
                    </div>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                    <p className="data_btnOuter mb-20">
                      <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                      <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="traning_pnlOuter">
                  <div className="course_rightPnl">
                    {/* ------- Promotional Video Section starts here ------- */}
                    <div className="course_vdopnl">
                      <div className="embed-responsive embed-responsive-16by9 course_vdoBx">
                        <video
                          className="embed-responsive-item"
                          style={{ borderRadius: "5px" }}
                          src={promoVideoURL != "" ? promoVideoURL : ""}
                          id="samplepreview"
                          controls
                          allowFullScreen
                          width="100%"
                          height="100%"
                          fullscreen="true"
                        ></video>
                      </div>
                    </div>
                    {/* ------- Promotional Video Section ends here ------- */}

                    {/* ------- Promotional Text Points Section starts here ------- */}
                    <div className="course_puterpnl">
                      {promotionalText != null && promotionalText.length > 0 ? (
                        <>
                          {promotionalText.map((text, index) => {
                            return (
                              <div className="course_row" key={text.id}>
                                <div className="course_icon">
                                  <i className="material-icons-outlined">
                                    fact_check
                                  </i>
                                </div>
                                <div className="course_text">
                                  <h3>{text.title}</h3>
                                  <p>{text.description}</p>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                    {/* ------- Promotional Text Points Section ends here ------- */}
                  </div>
                </div>
              )}
            </div>
            {/* ---------- Right side component ends here ---------- */}
          </div>
        </div>
      </div>
      {/* --------------- Course (About) Body ends here --------------- */}
    </>
  );
};

export default About;
