/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";

import user1 from "assets/ChallegesDetailsAsstes/user1.png";

// importing images here
import PDF from "assets/ConversationAssests/svg/pdf.svg";
import XLS from "assets/ConversationAssests/svg/xls.svg";
import DOCS from "assets/ConversationAssests/svg/docs.svg";
import VIDEO from "assets/ConversationAssests/svg/video.svg";
import ZIP from "assets/ConversationAssests/svg/zip.svg";

// import custom images here
import Google from "assets/ChallegesDetailsAsstes/google.png";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { postFormdata, uploadFile } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const ApplyNowModal = (props) => {
  // accessing the user's token here
  const token = localStorage.getItem("token");

  // Initial useStates to save data
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [resumeTitle, setResumeTitle] = useState("");
  const [resumeDetails, setResumeDetails] = useState("");
  const [ctcMin, setCtcMin] = useState("");
  const [ctcMax, setCtcMax] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [comments, setComments] = useState("");
  const [privacy, setPrivacy] = useState(0);

  // Initial useState for warnings
  const [firstNameWarning, setFirstNameWarning] = useState(false);
  const [lastNameWarning, setLastNameWarning] = useState(false);
  const [emailWarning, setEmailWarning] = useState(false);
  const [resumeTitleWarning, setResumeTitleWarning] = useState(false);
  const [resumeWarning, setResumeWarning] = useState(false);

  // initial for uploading images
  const [uploadedResumeWithData, setUploadedResumeWithData] = useState("");
  const [uploadedResumeWithDataId, setUploadedResumeWithDataId] = useState("");
  const [uploadedResumeWithoutData, setUploadedResumeWithoutData] =
    useState("");
  const [uploadedResumeWithoutDataId, setUploadedResumeWithoutDataId] =
    useState("");

  // useState to check the checkbox
  const [checkval, setCheckval] = useState("");

  // Function to toggle the checkbox
  const checkBoxToggler = (value) => {
    if (value) {
      setPrivacy(1);
    } else {
      setPrivacy(0);
    }
  };

  /*----- upload resume with data section start -----*/
  const resumeWithDataHandler = async (e) => {
    let imageFile = e.target.files[0];
    console.log(e.target.files[0].type);

    let apiUrl = url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

    try {
      let response = await uploadFile(apiUrl, imageFile);
      // console.log("response video----->", response);
      if (response.status) {
        console.log("Resume with data ------> ", response.data.id);
        setUploadedResumeWithDataId(response.data.id);
      }
    } catch (error) {
      console.log("error----->", error);
    }
    setUploadedResumeWithData(e.target.files[0]);
  };
  /*----- upload resume with data section end -----*/

  /*----- upload resume without data section start -----*/
  const resumeWithoutDataHandler = async (e) => {
    let imageFile = e.target.files[0];

    let apiUrl = url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

    try {
      let response = await uploadFile(apiUrl, imageFile);
      // console.log("response video----->", response);
      if (response.status) {
        console.log("Resume without data ------> ", response.data.id);
        setUploadedResumeWithoutDataId(response.data.id);
      }
    } catch (error) {
      console.log("error----->", error);
    }
    setUploadedResumeWithoutData(e.target.files[0]);
  };
  /*----- upload resume without data section end -----*/

  /* ----------- video upload section ------------ */
  // count number for spinner
  const loadingCircle = [1, 2, 3, 4, 5, 6];
  const [unSupportWarning, setUnSupportWarning] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadedVideoId, setUploadedVideoId] = useState("");

  const videouploadHandler = async (e) => {
    e.preventDefault();
    if (
      e.target.files[0].type == "video/mp4" ||
      e.target.files[0].type == "video/avi" ||
      e.target.files[0].type == "video/quicktime" ||
      e.target.files[0].type == "video/x-ms-wmv"
    ) {
      setUnSupportWarning(false);
      setUploadComplete(true);
      let videoFile = e.target.files[0];

      let apiUrl = url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

      try {
        let response = await uploadFile(apiUrl, videoFile);
        // console.log("response video----->", response);
        if (response.status) {
          // console.log("Apply now modal video id ---> ", response.data.id);
          setUploadedVideoId(response.data.id);
          setUploadComplete(false);
        }
      } catch (error) {
        console.log("error----->", error);
      }

      let videoUrl = URL.createObjectURL(e.target.files[0]);

      // setup preview of video
      let preview = document.getElementById("samplepreview");
      preview.setAttribute("src", `${videoUrl}`);
    } else {
      setUnSupportWarning(true);
    }
  };
  /*---- video upload section end -----*/

  // Function to submit the applicant's application to the API.
  const applicationSubmitHandler = async (e) => {
    e.preventDefault();

    if (
      firstName == "" ||
      lastName == "" ||
      email == "" ||
      resumeTitle == "" ||
      uploadedResumeWithData == ""
    ) {
      if (firstName == "") {
        setFirstNameWarning(true);
      } else {
        setFirstNameWarning(false);
      }
      if (lastName == "") {
        setLastNameWarning(true);
      } else {
        setLastNameWarning(false);
      }
      if (email == "") {
        setEmailWarning(true);
      } else {
        setEmailWarning(false);
      }
      if (resumeTitle == "") {
        setResumeTitleWarning(true);
      } else {
        setResumeTitleWarning(false);
      }
      if (uploadedResumeWithData == "") {
        setResumeWarning(true);
      } else {
        setResumeWarning(false);
      }
    } else {
      setFirstNameWarning(false);
      setLastNameWarning(false);
      setEmailWarning(false);
      setResumeTitleWarning(false);
      setResumeWarning(false);
      let requestURL = url.API_BaseUrl + url.DEMO_POST + `?token=${token}`;

      let applicationData = {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: phone,
        resumetitle: resumeTitle,
        resumedetails: resumeDetails,
        minctc: ctcMin,
        maxctc: ctcMax,
        url: videoLink,
        comments: comments,
        privacy: privacy,
        attachmentwithdata: uploadedResumeWithDataId,
        attachmentwithoutdata: uploadedResumeWithoutDataId,
        videoresume: uploadedVideoId,
      };

      try {
        const response = await postFormdata(requestURL, applicationData);

        console.log("Review API receiving response --------> ", response);

        if (response.status) {
          console.log("Data submitted successfully", response);
          resetHandler();
        } else {
          console.log("Some error occurred while submitting the data!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // function to reset all useStates after submission
  const resetHandler = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setResumeTitle("");
    setResumeDetails("");
    setCtcMin("");
    setCtcMax("");
    setVideoLink("");
    setComments("");
    setPrivacy("");
    setUploadedResumeWithData("");
    setUploadedResumeWithDataId("");
    setUploadedResumeWithoutData("");
    setUploadedResumeWithoutDataId("");
    setCheckval("");
    setUnSupportWarning(false);
    setUploadComplete(false);
    setUploadedVideoId("");

    // Resetting the uploaded video preview
    let videoUrl = "";
    // setup preview of video
    let preview = document.getElementById("samplepreview");
    preview.setAttribute("src", `${videoUrl}`);
  };

  // return statement from here
  return (
    <div className="job_modal">
      <div
        className="modal fade"
        id="apply_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* ----------- Modal header starts here ----------- */}
            <div className="modal-header">
              <div className="company_logotxt">
                <span className="logo_circle">
                  <img src={Google} alt="" />
                </span>
                <span>
                  {props.companyname == null ? "" : props.companyname}
                </span>
              </div>
              <div className="modal_hdng">
                <h3>Apply for {props.jobname != null ? props.jobname : ""}</h3>
              </div>
              <div className="job_tags">
                <ul>
                  <li>
                    <i className="material-icons-outlined"> place </i>
                    <span>
                      {props.joblocation != null ? props.joblocation : ""}
                    </span>
                  </li>
                  <li>
                    <i className="material-icons-outlined"> event_note </i>
                    <span>{props.jobdate != null ? props.jobdate : ""}</span>
                  </li>
                  <li>
                    <i className="material-icons-outlined"> schedule </i>
                    <span>{props.jobtype != null ? props.jobtype : ""}</span>
                  </li>
                </ul>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined"> close </i>
              </button>
            </div>
            {/* ----------- Modal header ends here ----------- */}

            {/* ----------- Modal body starts here ----------- */}
            <div className="modal-body">
              {/* -------- Personal details row starts here -------- */}
              <div className="prsnl_detailsArea">
                <div className="row">
                  {/* -------- First name here -------- */}
                  <div className="col-lg-4 col-sm-6">
                    <div className="input_fill jobinput_fill">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter first name"
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          setFirstNameWarning(false);
                        }}
                      />

                      {firstNameWarning && (
                        <p className="text-danger mt-2">
                          * please enter first name
                        </p>
                      )}
                    </div>
                  </div>

                  {/* -------- Last name here -------- */}
                  <div className="col-lg-4 col-sm-6">
                    <div className="input_fill jobinput_fill">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter last name"
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                          setLastNameWarning(false);
                        }}
                      />

                      {lastNameWarning && (
                        <p className="text-danger mt-2">
                          * please enter last name
                        </p>
                      )}
                    </div>
                  </div>

                  {/* -------- Email name here -------- */}
                  <div className="col-lg-4 col-sm-6">
                    <div className="input_fill jobinput_fill">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailWarning(false);
                        }}
                      />

                      {emailWarning && (
                        <p className="text-danger mt-2">* please enter email</p>
                      )}
                    </div>
                  </div>

                  {/* -------- Phone number here -------- */}
                  <div className="col-lg-4 col-sm-6">
                    <div className="input_fill jobinput_fill">
                      <label>Phone No.</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter phone no"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* -------- Other details row starts here -------- */}
              <div className="others_detailsArea">
                {/* -------- Resume title starts here -------- */}
                <div className="details_row">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="details_input_txt">
                        <label>Resume Title</label>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="input_fill jobinput_fill">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter title"
                          value={resumeTitle}
                          onChange={(e) => {
                            setResumeTitle(e.target.value);
                            setResumeTitleWarning(false);
                          }}
                        />

                        {resumeTitleWarning && (
                          <p className="text-danger mt-2">
                            * please enter resume title
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* -------- Resume details starts here -------- */}
                <div className="details_row">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="details_input_txt">
                        <label>Resume Details</label>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="input_fill jobinput_fill description_fill">
                        <textarea
                          className="form-control"
                          placeholder="Enter details"
                          value={resumeDetails}
                          onChange={(e) => setResumeDetails(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                {/* -------- CTC Details section starts here -------- */}
                <div className="details_row">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="details_input_txt">
                        <label>Expected CTC</label>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="job_fillOuter">
                        <div className="input_fill jobinput_fill">
                          <input
                            type="text"
                            className="form-control uro_sign"
                            placeholder="Maximum expectation"
                            value={ctcMax}
                            onChange={(e) => setCtcMax(e.target.value)}
                          />
                        </div>
                        <div className="input_fill jobinput_fill">
                          <input
                            type="text"
                            className="form-control uro_sign"
                            placeholder="Minimum expectation"
                            value={ctcMin}
                            onChange={(e) => setCtcMin(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* -------- Upload attachment with data section starts here -------- */}
                <div className="details_row attached_row">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="details_input_txt">
                        <label>Attach Resume</label>
                        <p>with data</p>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="upload_area">
                        <div className="upload_item">
                          {/* ------------ image preview starts here ------------ */}
                          <div className="uploaded_innrBx uploaded_vdobx course_uploadBx">
                            {uploadedResumeWithData.type ==
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                            uploadedResumeWithData.type ==
                              "application/msword" ||
                            uploadedResumeWithData.type ==
                              "application/vnd.oasis.opendocument.text" ||
                            uploadedResumeWithData.type ==
                              "application/vnd.ms-word.document.macroEnabled.12" ||
                            uploadedResumeWithData.type ==
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.template" ||
                            uploadedResumeWithData.type ==
                              "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                              <>
                                <img
                                  src={DOCS}
                                  alt="docs"
                                  style={{ width: "215px", height: "215px" }}
                                />
                                <label>{uploadedResumeWithData.name}</label>
                              </>
                            ) : null}
                            {uploadedResumeWithData.type ==
                            "application/pdf" ? (
                              <>
                                <img
                                  src={PDF}
                                  alt="pdf"
                                  style={{ width: "215px", height: "215px" }}
                                />
                                <label>{uploadedResumeWithData.name}</label>
                              </>
                            ) : null}
                            {uploadedResumeWithData.type ==
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                            uploadedResumeWithData.type ==
                              "application/vnd.ms-excel" ? (
                              <>
                                <img
                                  src={XLS}
                                  alt="docs"
                                  style={{ width: "215px", height: "215px" }}
                                />
                                <label>{uploadedResumeWithData.name}</label>
                              </>
                            ) : null}
                            {uploadedResumeWithData.type == "video/mp4" ? (
                              <>
                                <img
                                  src={VIDEO}
                                  alt="video"
                                  style={{ width: "215px", height: "215px" }}
                                />
                                <label>{uploadedResumeWithData.name}</label>
                              </>
                            ) : null}
                            {uploadedResumeWithData.type ==
                            "application/x-zip-compressed" ? (
                              <>
                                <img
                                  src={ZIP}
                                  alt="video"
                                  style={{ width: "215px", height: "215px" }}
                                />
                                <label>{uploadedResumeWithData.name}</label>
                              </>
                            ) : null}
                          </div>
                          {/* ------------ image preview ends here ------------ */}
                        </div>
                        <div className="upload_item">
                          <div className="upload_btn course_uploadBx">
                            <input
                              name="story"
                              type="file"
                              className="file_uoload"
                              onChange={(e) => {
                                resumeWithDataHandler(e);
                                setResumeWarning(false);
                              }}
                            />

                            <div className="upload_icon">
                              <span className="material-icons-outlined">
                                file_upload
                              </span>
                            </div>
                            <div className="upload_txt">
                              <h4>Upload</h4>
                              <h5>Max 10mb Doc/PDF</h5>
                            </div>
                          </div>
                        </div>

                        {resumeWarning && (
                          <p className="text-danger mt-2">
                            * please upload atleast one resume atleast
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* -------- Upload attachment without data section starts here -------- */}
                <div className="details_row attached_row">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="details_input_txt">
                        <label>Attach Resume</label>
                        <p>without data</p>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="upload_area">
                        <div className="upload_item">
                          {/* ------------ image preview starts here ------------ */}
                          <div className="uploaded_innrBx uploaded_vdobx course_uploadBx">
                            {uploadedResumeWithoutData.type ==
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                            uploadedResumeWithoutData.type ==
                              "application/msword" ||
                            uploadedResumeWithoutData.type ==
                              "application/vnd.oasis.opendocument.text" ||
                            uploadedResumeWithoutData.type ==
                              "application/vnd.ms-word.document.macroEnabled.12" ||
                            uploadedResumeWithoutData.type ==
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.template" ||
                            uploadedResumeWithoutData.type ==
                              "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                              <>
                                <img
                                  src={DOCS}
                                  alt="docs"
                                  style={{ width: "215px", height: "215px" }}
                                />
                                <label>{uploadedResumeWithoutData.name}</label>
                              </>
                            ) : null}
                            {uploadedResumeWithoutData.type ==
                            "application/pdf" ? (
                              <>
                                <img
                                  src={PDF}
                                  alt="pdf"
                                  style={{ width: "215px", height: "215px" }}
                                />
                                <label>{uploadedResumeWithoutData.name}</label>
                              </>
                            ) : null}
                            {uploadedResumeWithoutData.type ==
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                            uploadedResumeWithoutData.type ==
                              "application/vnd.ms-excel" ? (
                              <>
                                <img
                                  src={XLS}
                                  alt="docs"
                                  style={{ width: "215px", height: "215px" }}
                                />
                                <label>{uploadedResumeWithoutData.name}</label>
                              </>
                            ) : null}
                            {uploadedResumeWithoutData.type == "video/mp4" ? (
                              <>
                                <img
                                  src={VIDEO}
                                  alt="video"
                                  style={{ width: "215px", height: "215px" }}
                                />
                                <label>{uploadedResumeWithoutData.name}</label>
                              </>
                            ) : null}
                            {uploadedResumeWithData.type ==
                            "application/x-zip-compressed" ? (
                              <>
                                <img
                                  src={ZIP}
                                  alt="video"
                                  style={{ width: "215px", height: "215px" }}
                                />
                                <label>{uploadedResumeWithoutData.name}</label>
                              </>
                            ) : null}
                          </div>
                          {/* ------------ image preview ends here ------------ */}
                        </div>
                        <div className="upload_item">
                          <div className="upload_btn course_uploadBx">
                            <input
                              name="story"
                              type="file"
                              className="file_uoload"
                              onChange={(e) => resumeWithoutDataHandler(e)}
                            />

                            <div className="upload_icon">
                              <span className="material-icons-outlined">
                                file_upload
                              </span>
                            </div>
                            <div className="upload_txt">
                              <h4>Upload</h4>
                              <h5>Max 10mb Doc/PDF</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* -------- Upload attachment video section starts here -------- */}
                <div className="details_row attached_row">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="details_input_txt">
                        <label>Attach Resume</label>
                        <p>video</p>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="upload_area">
                        {/* ------------ Video preview starts here ------------ */}
                        <div className="upload_item">
                          <div className="uploaded_innrBx uploaded_vdobx course_uploadBx">
                            <video
                              id="samplepreview"
                              controls
                              height="155px"
                              width="155px"
                              fullscreen="true"
                            >
                              <source id="sampleVideoUrl" />
                            </video>
                            {uploadComplete && (
                              <div className="vdoplay_Btn">
                                <p className="text-white">Uploading</p>
                                {loadingCircle.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="spinner-grow mx-1 text-white"
                                      style={{ height: "3px", width: "3px" }}
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                          {unSupportWarning && (
                            <p className="text-danger mt-2">
                              * Unsupported File Format
                            </p>
                          )}
                        </div>
                        {/* ------------ Video preview ends here ------------ */}

                        {/* ------------ Video uploader starts here ------------ */}
                        <div className="upload_item">
                          <div className="upload_btn course_uploadBx">
                            <input
                              name="story"
                              type="file"
                              className="file_uoload"
                              onChange={(e) => videouploadHandler(e)}
                            />
                            <div className="upload_icon">
                              <span className="material-icons-outlined">
                                file_upload
                              </span>
                            </div>
                            <div className="upload_txt">
                              <h4>Upload</h4>
                              <h5>Max 10mb</h5>
                              <h5>Video</h5>
                            </div>
                          </div>
                        </div>
                        {/* ------------ Video uploader endss here ------------ */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* -------- Video link section starts here -------- */}
                <div className="details_row">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="details_input_txt">
                        <label>Video Link</label>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="input_fill jobinput_fill">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter URL"
                          value={videoLink}
                          onChange={(e) => setVideoLink(e.target.value)}
                        />
                        <Link to="#" className="copy_link">
                          <span className="material-icons-outlined"> link</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                {/* -------- Comments section starts here -------- */}
                <div className="details_row">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="details_input_txt">
                        <label>Comments</label>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="input_fill jobinput_fill description_fill">
                        <textarea
                          className="form-control"
                          placeholder="Enter comments"
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                {/* -------- Checkbox starts here -------- */}
                <div className="details_row">
                  <div className="row">
                    <div className="col-lg-3 hidden-sm"></div>
                    <div className="col-lg-9">
                      <div className="chek_row">
                        <label className="radio-check">
                          <input
                            type="checkbox"
                            defaultChecked={checkval}
                            onChange={(e) => checkBoxToggler(e.target.checked)}
                          />
                          <span></span>
                          <p>I don't want to present my details publicly</p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* -------- Apply now button section starts here -------- */}
                <div className="details_row">
                  <div className="row">
                    <div className="col-lg-3 hidden-sm"></div>
                    <div className="col-lg-9">
                      <div className="apply_btn">
                        <button
                          className="basic_btn"
                          onClick={(e) => applicationSubmitHandler(e)}
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ----------- Modal body ends here ----------- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyNowModal;
