import React from "react";
import { Link } from "react-router-dom";

// import images
import graphBanner from "assets/ActivityAssests/images/graph-bnr.jpg";
import homeBanner1 from "assets/HomeAssets/Images/home-bnr1.jpg";
import homeBanner2 from "assets/HomeAssets/Images/home-bnr2.jpg";

const Banner = () => {
  return (
    // <section
    //   className="graph_bnr_area bg_area home_bnrArea"
    //   style={{ backgroundImage: `url(${graphBanner})` }}
    // >
    //   <div className="container">
    //     <div className="bnr_graph_textPnl">
    //       <h1>
    //         <span>Discover Indra </span>ATM Ecosystem
    //       </h1>
    //       <p>
    //         More than 25% of the world's total airspace is managed with Indra's
    //         Automation Systems
    //       </p>
    //     </div>
    //   </div>
    // </section>

    <section className="home_bnr_area">
      <div className="home_bnrslide_Outer">
        <div
          className="home_bnrSlider carousel slide"
          id="carouselExampleInterval"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div
              className="homeslide_item carousel-item active"
              style={{ backgroundImage: `url(${homeBanner1})` }}
              data-bs-interval="10000"
            >
              <div className="bnr_text_content">
                <div className="container">
                  <div className="bnr_graph_textPnl">
                    <h2>
                      <span>Professional Portal </span>Indra Champions ATM
                    </h2>
                    <p>
                      Indra Air - Automation: More than 25% of the world's
                      airspace is managed with our systems
                    </p>
                    <div className="bnr_btnOuter">
                      <Link to="#" className="basic_btn ylw_btn">
                        Contact Us
                      </Link>
                      <Link to="#" className="basic_btn ylw_btn">
                        Portfolio
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="homeslide_item carousel-item"
              style={{ backgroundImage: `url(${homeBanner2})` }}
              data-bs-interval="2000"
            >
              <div className="bnr_text_content">
                <div className="container">
                  <div className="bnr_graph_textPnl">
                    <h2>
                      <span>Professional Portal </span>Indra Champions ATM
                    </h2>
                    <p>
                      Indra Air - Automation: More than 25% of the world's
                      airspace is managed with our systems
                    </p>
                    <div className="bnr_btnOuter">
                      <Link to="#" className="basic_btn ylw_btn">
                        Contact Us
                      </Link>
                      <Link to="#" className="basic_btn ylw_btn">
                        Portfolio
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Banner;
