/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

const TabArea = (props) => {
  let { t } = useTranslation();
  const {
    searchQueryValues,
    resetCourseQuery,
    searchJobsQueryValues,
    resetJobsQuery,
    searchChampions,
    refreshChampionLists,
  } = useContext(GlobalIndraInfo);

  // for routing
  const history = useHistory();

  /*----- initial state for toggling tabs ------*/
  const [courseTab, setCourseTab] = useState(false);
  const [eventTab, setEventTab] = useState(false);
  const [challengesTab, setChallengesTab] = useState(false);
  const [championsTab, setChampionsTab] = useState(false);
  const [groupTab, setGroupTab] = useState(false);
  /*----- end of initial state for toggling tabs ------*/

  /* ----------- function to display the tabs ----------- */
  const showCourseTab = (e) => {
    e.preventDefault();
    setCourseTab(true);
    setEventTab(false);
    setChallengesTab(false);
    setChampionsTab(false);
    setGroupTab(false);
  };

  const showEventTab = (e) => {
    e.preventDefault();
    setCourseTab(false);
    setEventTab(true);
    setChallengesTab(false);
    setChampionsTab(false);
    setGroupTab(false);
  };

  const showChallengesTab = (e) => {
    e.preventDefault();
    setCourseTab(false);
    setEventTab(false);
    setChallengesTab(true);
    setChampionsTab(false);
    setGroupTab(false);
  };

  const showChampionsTab = (e) => {
    e.preventDefault();
    setCourseTab(false);
    setEventTab(false);
    setChallengesTab(false);
    setChampionsTab(true);
    setGroupTab(false);
  };

  const showGroupTab = (e) => {
    e.preventDefault();
    setCourseTab(false);
    setEventTab(false);
    setChallengesTab(false);
    setChampionsTab(false);
    setGroupTab(true);
  };
  /* ----------- function to display the tabs ----------- */

  /* ----------- React select requirements starts here ----------- */
  const [courseSkillsSelectedStatus, setSelectedStatus] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [courseTitle, setCourseTitle] = useState("");
  const [skills, setSkills] = useState([]);
  const [category, setCategory] = useState([]);
  const [courseCategoriesValue, setCategoriesValue] = useState([]);

  const token = localStorage.getItem("token");

  /* ----------- Challenges Tab requirements starts here ----------- */
  // Initial useState to save the job title
  const [jobTitle, setJobTitle] = useState("");

  // Initial useState to save the city
  const [jobCity, setJobCity] = useState("");

  // Initial useState to save the selection from the React Select Element
  const [selectedJobsCategory, setSelectedJobsCategory] = useState([]);

  // Initial useState to get all the options from API
  const [categoriesJobsValue, setJobsCategoriesValue] = useState([]);

  // Initial useState to display the selected items
  const [selectedChallengesCategory, setSelectedChallengesCategory] =
    useState(null);

  const challengesCategorySelectionHandler = (val) => {
    let challengeCategoryVal = val.map((item) => {
      return item.value;
    });
    setSelectedChallengesCategory();
    if (challengeCategoryVal.length == 0) {
      setSelectedJobsCategory([]);
    } else {
      setSelectedJobsCategory(challengeCategoryVal);
    }
  };

  // function for searching jobs in challenges
  const searchJobsHandler = (e) => {
    e.preventDefault();

    history.push("/challenges");

    let data = {
      jobTitle: jobTitle,
      city: jobCity,
      selectedCategory: selectedJobsCategory,
    };

    searchJobsQueryValues(data);
    setJobTitle("");
    setJobCity("");
    setSelectedChallengesCategory(null);
  };

  // function for resetting the filter in challenges
  const resetJobsHandler = (e) => {
    e.preventDefault();

    // do something here
    setJobTitle("");
    setJobCity("");
    setSelectedChallengesCategory(null);
    resetJobsQuery(true);
  };

  /* ----------- Challenges Tab requirements ends here ----------- */

  /* ----------- Events Tab requirements starts here ----------- */
  // Initial useState to save the event title
  const [eventTitle, setEventTitle] = useState("");

  // Initial useState to save the event city
  const [eventCity, setEventCity] = useState("");

  // Initial useState to save the selection from the React Select Element
  const [selectedEventsItemsCategory, setSelectedEventsItemsCategory] =
    useState();

  // Initial useState to get all the options from API
  const [categoriesEventsValue, setEventsCategoriesValue] = useState([]);

  // Initial useState to display the selected items
  const [selectedEventsCategory, setSelectedEventsCategory] = useState(null);

  const eventsCategorySelectionHandler = (val) => {
    let challengeCategoryVal = val.map((item) => {
      return item.value;
    });

    if (challengeCategoryVal.length != 0) {
      setSelectedEventsCategory(); // only for display purpose
      setSelectedEventsItemsCategory(challengeCategoryVal);
    }
  };
  /* ----------- Events Tab requirements ends here ----------- */

  /* ----------- Champions Tab requirements starts here ----------- */
  const [champName, setChampName] = useState("");
  const [champCategoryLists, setChampCategoryLists] = useState([]);
  const [championCity, setChampionCity] = useState("");
  const [selectedChampCategory, setSelectedChampCategory] = useState();
  const [champCategory, setChampCategory] = useState([]);

  // function for select champ category
  const champCategoryHandler = (val) => {
    let categoryVal = val.map((item) => {
      let categoryObject = {
        label: item.label,
        id: item.id,
      };
      return categoryObject.id;
    });

    if (categoryVal.length != 0) {
      setSelectedChampCategory();
      setChampCategory(categoryVal); //set multiselect values in array
    }
  };

  // function for search course
  const searchChampHandler = (e) => {
    e.preventDefault();

    history.push("/champions");

    let data = {
      champName,
      championCity,
      champCategory,
    };
    searchChampions(data);
    setChampName("");
    setSelectedChampCategory(null);
    setChampionCity("");
    setChampCategory([]);
  };

  //function for refresh
  const refreshChampHandler = (e) => {
    e.preventDefault();
    setChampName("");
    setSelectedChampCategory(null);
    setChampionCity("");
    refreshChampionLists(true);
  };

  /* ----------- Champions Tab requirements ends here ----------- */

  /* ----------- Group Tab requirements starts here ----------- */
  // Initial useState to save the event title
  const [groupTitle, setGroupTitle] = useState("");

  // Initial useState to save the event city
  const [groupCity, setGroupCity] = useState("");

  // Initial useState to get all the options from API
  const [categoriesGroupValue, setGroupsCategoriesValue] = useState([]);

  // Initial useState to save the selection from the React Select Element
  const [selectedGroupItemsCategory, setSelectedGroupsItemsCategory] =
    useState();

  // Initial useState to display the selected items
  const [selectedGroupCategory, setSelectedGroupCategory] = useState(null);

  const groupCategorySelectionHandler = (val) => {
    let challengeCategoryVal = val.map((item) => {
      return item.value;
    });

    if (challengeCategoryVal.length != 0) {
      setSelectedGroupCategory(); // only for display purpose
      setSelectedGroupsItemsCategory(challengeCategoryVal);
    }
  };
  /* ----------- Group Tab requirements ends here ----------- */

  /* ----------- Course Tab requirements starts here ----------- */
  // function for select status
  const courseSkillsStatusSelectionHandler = (val) => {
    let skillVal = val.map((item) => {
      let skillsObject = {
        label: item.label,
        id: item.id,
      };
      return skillsObject.id;
    });

    if (skillVal.length != 0) {
      setSelectedStatus();
      setSkills(skillVal); //set multiselect values in array
    }
  };

  // function for select category
  const courseCategoryHandler = (val) => {
    let categoryVal = val.map((item) => {
      let categoryObject = {
        label: item.label,
        id: item.id,
      };
      return categoryObject.id;
    });

    if (categoryVal.length != 0) {
      setSelectedCategory();
      setCategory(categoryVal); //set multiselect values in array
    }
  };

  // Required for single select
  const statusList = [
    { label: "Publish", value: 1 },
    { label: "Draft", value: 2 },
  ];

  // function for search course
  const searchCourseHandler = (e) => {
    e.preventDefault();

    history.push("/courses");

    let data = {
      searchTitle: courseTitle,
      skills,
      category,
    };

    setCourseTitle("");
    setSelectedCategory(null);
    setSelectedStatus(null);
    searchQueryValues(data);
  };

  //function for refresh
  const refreshCourseHandler = (e) => {
    e.preventDefault();

    setCourseTitle("");
    setSelectedCategory(null);
    setSelectedStatus(null);
    resetCourseQuery(true);
  };
  /* ----------- Course Tab requirements ends here ----------- */

  // custom style for category
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "5px",
      fontFamily: "sans-serif",
      border: "none",
      backgroundColor: "#F0F0F0",
      height: 50,
      borderRadius: "8px",
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8E8E",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  // function to get categories details from API
  const getCategories = async () => {
    const requestURL =
      url.API_BaseUrl + url.API_ACTIVITY_FILTER_CATEGORIES + `?token=${token}`;

    //console.log(requestURL);
    try {
      const response = await getData(requestURL);

      //console.log("Received response in category ------> ", response);

      if (response.status) {
        //console.log("Category response is----->", response.data.options);
        setCategoriesValue(response.data);
        setJobsCategoriesValue(response.data);
        setEventsCategoriesValue(response.data);
        setChampCategoryLists(response.data);
        setGroupsCategoriesValue(response.data);
      } else {
        // console.log("Some error occurred!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useeffect for get category lists
  useEffect(() => {
    getCategories();
  }, []);

  // useEffect based on props
  useEffect(() => {
    // do something here
    if (props.module == "course") {
      setCourseTab(true);
    } else if (props.module == "event") {
      setEventTab(true);
    } else if (props.module == "groups") {
      setGroupTab(true);
    } else if (props.module == "champions") {
      setChampionsTab(true);
    } else if (props.module == "challenges") {
      setChallengesTab(true);
    } else {
      setCourseTab(true);
    }
  }, [props.module]);

  return (
    <section className="sports_tabArea">
      <div className="container">
        <div className="sports_tabinnr">
          {/* -------------- Tab Activation links starts here -------------- */}
          <div className="sport_tabLinks">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {/* --------- Course Link --------- */}
              <li className="nav-item">
                <Link
                  className={courseTab == true ? "nav-link active" : "nav-link"}
                  to="#"
                  onClick={(e) => {
                    showCourseTab(e);
                  }}
                >
                  {t("Course")}
                </Link>
              </li>

              {/* --------- Event Link --------- */}
              <li className="nav-item">
                <Link
                  className={eventTab == true ? "nav-link active" : "nav-link"}
                  to="#"
                  onClick={(e) => {
                    showEventTab(e);
                  }}
                >
                  {t("Event")}
                </Link>
              </li>

              {/* --------- Group Link --------- */}
              <li className="nav-item">
                <Link
                  className={groupTab == true ? "nav-link active" : "nav-link"}
                  to="#"
                  onClick={(e) => {
                    showGroupTab(e);
                  }}
                >
                  {t("Group")}
                </Link>
              </li>

              {/* --------- Champions Link --------- */}
              <li className="nav-item">
                <Link
                  className={
                    championsTab == true ? "nav-link active" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => {
                    showChampionsTab(e);
                  }}
                >
                  {t("Champions")}
                </Link>
              </li>

              {/* --------- Challenges Link --------- */}
              <li className="nav-item">
                <Link
                  className={
                    challengesTab == true ? "nav-link active" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => {
                    showChallengesTab(e);
                  }}
                >
                  {t("Challenges")}
                </Link>
              </li>
            </ul>
          </div>
          {/* -------------- Tab Activation links ends here -------------- */}

          {/* -------------- Tab Section starts here -------------- */}
          <div className="tab-content">
            {/* --------------- Course section starts here --------------- */}
            <div className="tab-pane fade show active" id="course_tab">
              {courseTab && (
                <div className="tab_formOuter">
                  {/* --------- Course Title --------- */}
                  <div className="tab_fill model_fill">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Title / Course Code")}
                      value={courseTitle}
                      onChange={(e) => setCourseTitle(e.target.value)}
                    />
                  </div>

                  {/* ------ Courses Skills multi-select ------ */}
                  <div className="tab_fill locate_fill">
                    <div className="adress_fill">
                      <Select
                        isMulti={true}
                        options={courseCategoriesValue}
                        classNamePrefix="select2-selection"
                        placeholder={t("skills")}
                        value={courseSkillsSelectedStatus}
                        onChange={(val) => {
                          courseSkillsStatusSelectionHandler(val);
                        }}
                      />
                    </div>
                  </div>

                  {/* ------ Champions Categories multi-select ------ */}
                  <div className="tab_fill locate_fill">
                    <div className="adress_fill">
                      <Select
                        isMulti={true}
                        classNamePrefix="select2-selection"
                        placeholder={t("category")}
                        options={courseCategoriesValue}
                        value={selectedCategory}
                        onChange={(val) => courseCategoryHandler(val)}
                      />
                    </div>
                  </div>

                  {/* --------- Courses Button --------- */}
                  <div className="submit_fill submit_rfreshOuter">
                    <button
                      type="submit"
                      className="submit_btn form_submit"
                      onClick={(e) => searchCourseHandler(e)}
                    >
                      <i className="material-icons-round">search</i>
                      <span>Search</span>
                    </button>

                    <button
                      className="submit_btn form_submit refresh_btn"
                      style={{ marginLeft: "10px" }}
                      onClick={(e) => refreshCourseHandler(e)}
                    >
                      <i className="material-icons-round">refresh</i>
                    </button>
                  </div>
                </div>
              )}
            </div>
            {/* --------------- Course section ends here --------------- */}

            {/* --------------- Event section starts here --------------- */}
            <div className="tab-pane fade show active" id="event_tab">
              {eventTab && (
                <div className="tab_formOuter">
                  {/* --------- Event Title --------- */}
                  <div className="tab_fill model_fill">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title / Event Code"
                      value={eventTitle}
                      onChange={(e) => setEventTitle(e.target.value)}
                    />
                  </div>

                  {/* ------ Event Categories multi-select ------ */}
                  <div className="tab_fill select_fill">
                    <Select
                      isMulti={true}
                      placeholder={t("Select Events Category")}
                      options={categoriesEventsValue} // show all options
                      value={selectedEventsCategory} // show selected values
                      onChange={(val) => {
                        eventsCategorySelectionHandler(val);
                      }} // handler function
                      classNamePrefix="select2-selection"
                      styles={customSelectStyle}
                    />
                  </div>

                  {/* --------- Event City --------- */}
                  <div className="tab_fill locate_fill">
                    <div className="adress_fill">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Event City"
                        value={eventCity}
                        onChange={(e) => setEventCity(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* --------- Event Button --------- */}
                  <div className="submit_fill submit_rfreshOuter">
                    {/* ---------- Search button starts here ---------- */}
                    <button
                      type="submit"
                      className="submit_btn form_submit"
                      // onClick={}
                    >
                      <i className="material-icons-round">search</i>
                      <span>Search</span>
                    </button>

                    {/* ---------- Reset button starts here ---------- */}
                    <button
                      style={{ marginLeft: "10px" }}
                      type="submit"
                      className="submit_btn form_submit refresh_btn"
                      // onClick={resetHandler}
                    >
                      <i className="material-icons-round">refresh</i>
                    </button>
                  </div>
                </div>
              )}
            </div>
            {/* --------------- Event section ends here --------------- */}

            {/* --------------- Group section starts here --------------- */}
            <div className="tab-pane fade show active" id="group_tab">
              {groupTab && (
                <div className="tab_formOuter">
                  {/* --------- Group Title --------- */}
                  <div className="tab_fill model_fill">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title / Group Code"
                      value={groupTitle}
                      onChange={(e) => setGroupTitle(e.target.value)}
                    />
                  </div>

                  {/* ------ Event Categories multi-select ------ */}
                  <div className="tab_fill select_fill">
                    <Select
                      isMulti={true}
                      placeholder={t("Select Group Category")}
                      options={categoriesGroupValue} // show all options
                      value={selectedGroupCategory} // show selected values
                      onChange={(val) => {
                        groupCategorySelectionHandler(val);
                      }} // handler function
                      classNamePrefix="select2-selection"
                      styles={customSelectStyle}
                    />
                  </div>

                  {/* --------- Group City --------- */}
                  <div className="tab_fill locate_fill">
                    <div className="adress_fill">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Group City"
                        value={groupCity}
                        onChange={(e) => setGroupCity(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* --------- Group Button --------- */}
                  <div className="submit_fill submit_rfreshOuter">
                    {/* ---------- Search button starts here ---------- */}
                    <button
                      type="submit"
                      className="submit_btn form_submit"
                      // onClick={searchHandler}
                    >
                      <i className="material-icons-round">search</i>
                      <span>Search</span>
                    </button>

                    {/* ---------- Reset button starts here ---------- */}
                    <button
                      style={{ marginLeft: "10px" }}
                      type="submit"
                      className="submit_btn form_submit refresh_btn"
                      // onClick={resetHandler}
                    >
                      <i className="material-icons-round">refresh</i>
                    </button>
                  </div>
                </div>
              )}
            </div>
            {/* --------------- Group section ends here --------------- */}

            {/* --------------- Champions section starts here --------------- */}
            <div className="tab-pane fade show active" id="champions_tab">
              {championsTab && (
                <div className="tab_formOuter">
                  {/* --------- Champions Title --------- */}
                  <div className="tab_fill model_fill">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name/Surname"
                      value={champName}
                      onChange={(e) => setChampName(e.target.value)}
                    />
                  </div>

                  {/* ------ Champions Categories multi-select ------ */}
                  <div className="tab_fill select_fill">
                    <Select
                      options={champCategoryLists}
                      value={selectedChampCategory}
                      isMulti={true}
                      onChange={(val) => champCategoryHandler(val)}
                      classNamePrefix="select2-selection"
                      placeholder="Select Skill"
                      styles={customSelectStyle}
                    />
                  </div>

                  {/* --------- Champions City --------- */}
                  <div className="tab_fill locate_fill">
                    <div className="adress_fill">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Location"
                        value={championCity}
                        onChange={(e) => setChampionCity(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* --------- Champions Button --------- */}
                  <div className="submit_fill submit_rfreshOuter">
                    {/* ---------- Search button starts here ---------- */}
                    <button
                      type="submit"
                      className="submit_btn form_submit"
                      onClick={(e) => searchChampHandler(e)}
                    >
                      <i className="material-icons-round">search</i>
                      <span>Search</span>
                    </button>

                    {/* ---------- Reset button starts here ---------- */}
                    <button
                      style={{ marginLeft: "10px" }}
                      type="submit"
                      className="submit_btn form_submit refresh_btn"
                      onClick={(e) => refreshChampHandler(e)}
                    >
                      <i className="material-icons-round">refresh</i>
                    </button>
                  </div>
                </div>
              )}
            </div>
            {/* --------------- Champions section ends here --------------- */}

            {/* --------------- Challenges section starts here --------------- */}
            <div className="tab-pane fade show active" id="challenges_tab">
              {challengesTab && (
                <div className="tab_formOuter">
                  {/* --------- Job Title --------- */}
                  <div className="tab_fill model_fill">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title / Challenge Code"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                    />
                  </div>

                  {/* ------ Job Categories multi-select ------ */}
                  <div className="tab_fill select_fill">
                    <Select
                      isMulti={true}
                      placeholder={t("Select Challenges Category")}
                      options={categoriesJobsValue} // show all options
                      value={selectedChallengesCategory} // show selected values
                      onChange={(val) => {
                        challengesCategorySelectionHandler(val);
                      }} // handler function
                      classNamePrefix="select2-selection"
                      styles={customSelectStyle}
                    />
                  </div>

                  {/* --------- City --------- */}
                  <div className="tab_fill locate_fill">
                    <div className="adress_fill">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Challenge City"
                        value={jobCity}
                        onChange={(e) => setJobCity(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* --------- Button --------- */}
                  <div className="submit_fill submit_rfreshOuter">
                    {/* ---------- Search button starts here ---------- */}
                    <button
                      type="submit"
                      className="submit_btn form_submit"
                      onClick={(e) => searchJobsHandler(e)}
                    >
                      <i className="material-icons-round">search</i>
                      <span>Search</span>
                    </button>

                    {/* ---------- Reset button starts here ---------- */}
                    <button
                      style={{ marginLeft: "10px" }}
                      type="submit"
                      className="submit_btn form_submit refresh_btn"
                      onClick={(e) => resetJobsHandler(e)}
                    >
                      <i className="material-icons-round">refresh</i>
                    </button>
                  </div>
                </div>
              )}
            </div>
            {/* --------------- Challenges section ends here --------------- */}
          </div>
          {/* -------------- Tab Section starts here -------------- */}
        </div>
      </div>
    </section>
  );
};

export default TabArea;
