/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";

// importing context API here
import { GlobalIndraInfo } from "App";

// importing custom components here
import AddTagsModal from "components/ChallengesDetailsComponents/Modals/AddTagsModal";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

// importing custom components here
import ApplyNowModal from "./Modals/ApplyNowModal";

// import custom images here
import Google from "assets/ChallegesDetailsAsstes/google.png";

const ChallengesDetailsTopSection = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  // 👇️ get ID from url
  const params = useParams();

  // Initial useState to save all the incoming data from API.
  const [jobDetailsData, setJobDetailsData] = useState(null);

  // Function to call the API and get all the data
  const getJobDetailsData = async (id) => {
    let requestURL =
      url.API_BaseUrl + url.API_VIEW_JOB_DETAILS + id + `?token=${token}`;

    // setIsLoading(true);

    try {
      console.log(
        "requesting URL to get all the job details ------> ",
        requestURL
      );

      const response = await getData(requestURL);
      console.log(
        "Received Response in course details top --------> ",
        response
      );

      if (response.status) {
        setJobDetailsData(response.data);
      } else {
        console.log("Some error occurred ------> ", response);
      }
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
    }
  };

  // function for calling API after closing add tag modal
  const afterModalClose = () => {
    getJobDetailsData(params.id);
  };

  // useEffect from here
  useEffect(() => {
    getJobDetailsData(params.id);
  }, []);

  return (
    <>
      {jobDetailsData == null ? (
        <section className="course_details_sec placeholder-glow">
          <div className="container">
            <div className="course_detailsBx">
              {/* --------------------------- Left Section --------------------------- */}
              <div className="topdetails_left">
                <div className="global_details_outer">
                  <div className="glbl_detailstext">
                    <div className="challenge_iconOuter mb-3">
                      <span
                        className="placeholder rounded-circle me-2"
                        style={{ width: "20px", height: "20px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "45px" }}
                      ></span>
                    </div>
                    <div className="details_usrName_hdng mb-3">
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "330px", height: "26px" }}
                      ></span>
                    </div>
                    <span
                      className="placeholder rounded-3 mb-3"
                      style={{ width: "170px", height: "38px" }}
                    ></span>
                    <div className="d-flex">
                      <span
                        className="placeholder placeholder-sm rounded-3 me-3"
                        style={{ width: "142px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-sm rounded-3 me-3"
                        style={{ width: "75px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "95px" }}
                      ></span>
                    </div>
                    <div className="hire_btn">
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "132px", height: "50px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>

              {/* --------------------------- Right Section --------------------------- */}
              <div className="topdetails_right">
                <div className="details_shareOuter">
                  <div className="d-flex align-items-center">
                    <span
                      className="placeholder placeholder-sm rounded-3 me-3"
                      style={{ width: "60px" }}
                    ></span>
                    <span
                      className="placeholder placeholder-sm rounded-3 me-3"
                      style={{ width: "110px" }}
                    ></span>
                    <span
                      className="placeholder placeholder-sm rounded-3 me-3"
                      style={{ width: "100px" }}
                    ></span>
                    <span
                      className="placeholder rounded-3"
                      style={{ width: "40px", height: "40px" }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="course_details_sec">
          <div className="container">
            <div className="course_detailsBx">
              {/* --------------------------- Left Section --------------------------- */}
              <div className="topdetails_left">
                <div className="global_details_outer">
                  <div className="glbl_detailstext">
                    {/* ---------- Comapny name & logo section ---------- */}
                    <div className="challenge_iconOuter">
                      <i className="challenge_icon">
                        <img src={Google} alt="" />
                      </i>{" "}
                      <span className="challenge_icon_text">
                        {" "}
                        {jobDetailsData.company != null
                          ? jobDetailsData.company
                          : ""}
                      </span>
                    </div>

                    {/* ---------- Job Type section ---------- */}
                    <div className="details_usrName_hdng">
                      <h3>
                        {jobDetailsData.name != null ? jobDetailsData.name : ""}
                      </h3>
                    </div>

                    {/* ---------- Price section ---------- */}
                    <div className="details_value">
                      <h4>
                        €{" "}
                        {jobDetailsData.price != null
                          ? jobDetailsData.price
                          : "private"}
                      </h4>
                    </div>

                    {/* ---------- Star rating, Location, Enrolled section ---------- */}
                    <div className="details_tags expert_details">
                      {/* ------- Ratings & Reviews ------- */}
                      <div className="jb_rating star_color">
                        <i className="material-icons-outlined">star</i>
                        <span>4.5</span>
                        <h6>
                          (32<em>valoraciones</em>)
                        </h6>
                      </div>

                      {/* ------- Location ------- */}
                      <div className="course_text">
                        <i className="material-icons-outlined"> place </i>
                        <h4>
                          {jobDetailsData.location != null
                            ? jobDetailsData.location
                            : "undisclosed"}
                        </h4>
                      </div>

                      {/* ------- Experience ------- */}
                      <div className="course_text">
                        <i className="material-icons-outlined"> people</i>
                        <h4>
                          {jobDetailsData.experience != null
                            ? jobDetailsData.experience + "+ years"
                            : " undisclosed"}
                        </h4>
                      </div>
                    </div>

                    {/* ---------- 'Apply Now' button section ---------- */}
                    <div className="hire_btn">
                      <Link
                        to="#"
                        className="basic_btn ylw_btn"
                        data-bs-toggle="modal"
                        data-bs-target="#apply_modal"
                        onClick={document.body.classList.add("modal-open")}
                      >
                        Apply Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* --------------------------- Right Section --------------------------- */}
              <div className="topdetails_right">
                <div className="details_shareOuter">
                  {/* ------- Job Type & Response Count ------- */}
                  <div className="details_prgrss">
                    <h4>
                      {jobDetailsData.jobtype != null
                        ? jobDetailsData.jobtype
                        : " undisclosed"}
                    </h4>
                    <div className="tags_prgrsspnl">
                      <h5>
                        {jobDetailsData.responsecount != null
                          ? jobDetailsData.responsecount
                          : " undisclosed"}{" "}
                        applied <span>(40%)</span>
                      </h5>
                      <div className="circle_progess_outer">
                        <span
                          className="circle_progress_bar"
                          style={{ width: "40%" }}
                        ></span>
                      </div>
                    </div>
                  </div>

                  {/* ------- Share Button ------- */}
                  <div className="exprt_share social_share">
                    <ul>
                      {/* ------------ Tags Modal link from here ------------ */}
                      <li>
                        {/* <Link to="#" onClick={(e) => triggerAddTagsModal(e)}> */}
                        <Link
                          to="#"
                          className="basic_btn activty_filter"
                          data-bs-toggle="modal"
                          data-bs-target="#addtag_mdl"
                        >
                          <i className="material-icons-outlined">more</i>
                        </Link>
                      </li>
                      {/* ------------ Tags Modal link till here ------------ */}

                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">share</i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* importing the Add Tags Modal here */}
      <AddTagsModal afterModalClose={afterModalClose} />

      {/* Apply now modal is added here */}
      {jobDetailsData != null ? (
        <ApplyNowModal
          companyname={jobDetailsData.company}
          jobname={jobDetailsData.name}
          joblocation={jobDetailsData.location}
          jobdate={jobDetailsData.created}
          jobtype={jobDetailsData.jobtype}
        />
      ) : null}
    </>
  );
};

export default ChallengesDetailsTopSection;
