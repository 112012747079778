import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import images here
import coursePic1 from "assets/EventsAssets/images/course-pic1.jpg";
import coursePic2 from "assets/EventsAssets/images/course-pic2.jpg";
import coursePic3 from "assets/EventsAssets/images/course-pic3.jpg";

const EventsListArea = () => {
  // Initial useState to set page numbers for pagination
  const [page, setPage] = useState(1);

  // initial useState for JSON data
  const [eventsData, setEventsData] = useState([
    {
      id: 1,
      image: coursePic1,
      day: 6,
      month: "FEB",
      year: 2022,
      location: "Madrid,Spain",
      enrollCount: "25k",
      courseName: "Full Stack MERN Development",
      tags: [
        {
          id: 1,
          name: "MongoDB",
        },
        {
          id: 2,
          name: "ExpressJS",
        },
        {
          id: 3,
          name: "React JS",
        },
        {
          id: 4,
          name: "Node JS",
        },
      ],
    },
    {
      id: 2,
      image: coursePic2,
      day: 7,
      month: "FEB",
      year: 2022,
      location: "Madrid,Spain",
      enrollCount: "25k",
      courseName: "Full Stack MEAN Development",
      tags: [
        {
          id: 1,
          name: "MongoDB",
        },
        {
          id: 2,
          name: "ExpressJS",
        },
        {
          id: 3,
          name: "Angular JS",
        },
        {
          id: 4,
          name: "Node JS",
        },
      ],
    },
    {
      id: 3,
      image: coursePic3,
      day: 9,
      month: "MAR",
      year: 2022,
      location: "Madrid,Spain",
      enrollCount: "25k",
      courseName: "Full Stack MEVN Development",
      tags: [
        {
          id: 1,
          name: "MongoDB",
        },
        {
          id: 2,
          name: "ExpressJS",
        },
        {
          id: 3,
          name: "Vue JS",
        },
        {
          id: 4,
          name: "Node JS",
        },
      ],
    },
  ]);

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  // useEffect for page number
  useEffect(() => {
    // getChallengesListData();
  }, [page]);

  return (
    <>
      <section className="champions_area">
        <div className="container">
          <div className="champions_outer">
            <div className="row">
              {/* ------------ Mapping starts here ------------ */}
              {eventsData.map((item, index) => (
                <div className="col-lg-4 col-sm-6" key={item.id}>
                  <div className="events_itemBx">
                    <div className="champions_pic">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="champions_textpnl">
                      <div className="events_dateBx">
                        <h3>{item.day}</h3>
                        <h5>
                          {item.month} <span>{item.year}</span>
                        </h5>
                      </div>
                      <div className="events_hdngauthor">
                        <h4>{item.location}</h4>
                        <div className="evnts_enroll">
                          <i className="material-icons-outlined"> people </i>
                          <span>{item.enrollCount} Enrolled</span>
                        </div>
                      </div>
                      <div className="leftchampion_text">
                        <h3>{item.courseName}</h3>
                      </div>
                      <div className="experts_skill">
                        <ul>
                          {item.tags.map((tag, index) => (
                            <li key={tag.id}>
                              <Link to="#">
                                <span>{tag.name}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="details_btn">
                        <Link
                          to={`/events/details/${item.id}`}
                          className="basic_btn ylw_btn"
                        >
                          See Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* ------------ Mapping ends here ------------ */}

              {/* ------------ Pagination starts here ------------ */}
              <div className="pagination_list">
                <ul>
                  <li>
                    <Link to="#">
                      <i className="material-icons-outlined">arrow_back_ios</i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => nextPage(1)}>
                      1
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => nextPage(2)}>
                      2
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => nextPage(3)}>
                      3
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => nextPage(4)}>
                      4
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => nextPage(5)}>
                      5
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => nextPage(6)}>
                      6
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="material-icons-outlined">
                        arrow_forward_ios
                      </i>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* ------------ Pagination ends here ------------ */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventsListArea;
