import React, { useEffect, useState } from "react";

//import custom components
import Header from "common/Header";
import Banner from "components/ChampionsComponents/Banner";
import TabArea from "common/tabarea/TabArea";
import ChampionListArea from "components/ChampionsComponents/ChampionListArea";
import Footer from "common/Footer";

const Champions = () => {
  useEffect(() => {
    document.title = "Champions";
  }, []);
  return (
    <>
      <Header />
      <Banner />
      <TabArea module={"champions"} />
      <ChampionListArea />
      <Footer />
    </>
  );
};

export default Champions;
