import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import sample video here
import blankImage from "assets/CourseDetailsAssests/images/blank-img.jpg";
import blankUser from "assets/CourseDetailsAssests/images/default-user.png";

/* -------------------------- required imports for API Call -------------------------- */

import MarkCompleteModal from "components/CourseDetailsComponents/Modals/MarkCompleteModal";
/* ----------------------------------------------------------------------------------- */

const LessonContent = (props) => {
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // Initial useState to save the content recceived from the props.
  const [contentData, setContentData] = useState(null);
  const [lessonId, setLessonId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // UseEffect starts from here
  useEffect(() => {
    if (props.content) {
      console.log(
        "Received Lesson Content in Lesson Content Tab -----> ",
        props.content
      );

      // assigning the data to the useState
      setContentData(props.content);
    }
  }, [props.content]);

  useEffect(() => {
    if (props.lessonContentLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [props.lessonContentLoading]);

  return (
    <div className="tab-pane fade active show" id="content">
      {contentData == null ? null : (
        <>
          {isLoading ? (
            <div className="content_pnl lesson_pnl">
              <p className="data_btnOuter mb-20">
                <span className="placeholder placeholder-sm col-12 rounded-3 mb-10"></span>
                <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
              </p>
              <p className="data_btnOuter mb-20">
                <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                <span className="placeholder placeholder-sm col-2 rounded-3 mb-0"></span>
                <span className="placeholder placeholder-sm col-3 rounded-3 mb-0"></span>
              </p>
              <div className="course_vdopnl">
                <img src={blankImage} alt="" />
              </div>
            </div>
          ) : (
            <div className="content_pnl lesson_pnl">
              <div className="lesson_detailsHdng">
                <h2>{contentData.title}</h2>
              </div>

              <div className="lesson_info">
                {contentData.instructors != null &&
                contentData.instructors.length > 0 ? (
                  <div className="userexprt_pnltxt">
                    {contentData.instructors.map((instructor, index) => {
                      return (
                        <div className="userexprt_pnltxt" key={index}>
                          <div className="expert_usr">
                            {instructor.image || instructor.image == "" ? (
                              <img src={instructor.image} alt="user" />
                            ) : (
                              <img src={blankUser} alt="user" />
                            )}
                          </div>
                          <div className="exprt_text">
                            <h5>{instructor.label ? instructor.label : ""}</h5>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="userexprt_pnltxt">
                    {contentData.members.map((instructor, index) => {
                      return (
                        <div className="userexprt_pnltxt" key={index}>
                          <div className="expert_usr">
                            {instructor.image || instructor.image == "" ? (
                              <img src={instructor.image} alt="user" />
                            ) : (
                              <img src={blankUser} alt="user" />
                            )}
                          </div>
                          <div className="exprt_text">
                            <h5>{instructor.label ? instructor.label : ""}</h5>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {/* ---------- Lesson time duration starts here ---------- */}
                <div className="info_rating">
                  <ul>
                    <li>
                      <i className="material-icons-outlined">schedule</i>
                      <span>{contentData.duration} mins</span>
                    </li>
                  </ul>
                </div>
                {/* ---------- Lesson time duration ends here ---------- */}
              </div>

              {/* -------- Lesson video section starts here -------- */}
              <div className="course_vdopnl">
                <div className="embed-responsive embed-responsive-16by9 course_vdoBx">
                  <video
                    className="embed-responsive-item"
                    style={{ borderRadius: "5px" }}
                    src={contentData.video != null ? contentData.video : ""}
                    id="samplepreview"
                    controls
                    allowFullScreen
                    width="100%"
                    height="100%"
                    fullscreen="true"
                  ></video>
                </div>
              </div>
              {/* -------- Lesson video section ends here -------- */}

              <div className="course_contentOuter">
                <div className="course_contentBx">
                  <div className="contnt_hdng">
                    <h4>{contentData.title}</h4>
                  </div>
                  {contentData.content != null ? (
                    <div className="results_para">
                      <form>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: contentData.content,
                          }}
                        ></p>
                      </form>
                    </div>
                  ) : null}
                </div>
              </div>

              {/* -------- Mark lesson complete button also trigger complete modal -------- */}
              {contentData.hascompleted ? null : (
                <div className="lesson_btn">
                  <Link
                    to="#"
                    className="basic_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#complete_success_modal"
                    onClick={() => setLessonId(contentData.id)}
                  >
                    {t("Mark Lesson Complete")}
                  </Link>
                </div>
              )}
            </div>
          )}
        </>
      )}
      <MarkCompleteModal lessonId={lessonId} />
    </div>
  );
};

export default LessonContent;
