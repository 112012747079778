import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { GlobalIndraInfo } from "App";

const CourseLeftPanel = (props) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const {
    stepOneComplete,
    stepTwoComplete,
    stepThreeComplete,
    stepFourComplete,
    stepFiveComplete,
    stepSixComplete,
    stepSevenComplete,
    stepEightComplete,
  } = useContext(GlobalIndraInfo);

  return (
    <>
      <div className="col-lg-3">
        <div className="course_uploadLftpnl">
          <div className="upload_hdng">
            <h2>{t("Add Course")}</h2>
          </div>

          <div className="couse_rdoinnr">
            {/* --------------- Step 1, 2 & 3 begins here --------------- */}
            <div className="course_rdoItem">
              <div className="rdo_row">
                <label
                  className={
                    stepThreeComplete
                      ? "radio_circle selected_rdo"
                      : "radio_circle"
                  }
                >
                  {stepThreeComplete ? (
                    <i className="material-icons-round sucess_tick">
                      check_circle
                    </i>
                  ) : null}
                  <input type="radio" name="rdo1" />
                  <span></span>
                  <p>{t("Plan Your Course")}</p>
                </label>
              </div>
              <div className="rdo_menulist">
                <ul>
                  <li className={stepOneComplete ? "compelete_grn_tick" : ""}>
                    <Link to="#">
                      <span>{t("Introduction")}</span>
                      <i className="material-icons-outlined">check_circle</i>
                    </Link>
                  </li>

                  <li className={stepTwoComplete ? "compelete_grn_tick" : ""}>
                    <Link to="#">
                      <span>{t("Intended Learners")}</span>
                      <i className="material-icons-outlined">check_circle</i>
                    </Link>
                  </li>

                  <li className={stepThreeComplete ? "compelete_grn_tick" : ""}>
                    <Link to="#">
                      <span>{t("Promotional Content")}</span>
                      <i className="material-icons-outlined">check_circle</i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* --------------- Step 1, 2 & 3 ends here --------------- */}

            {/* --------------- Step 4 begins here --------------- */}
            <div className="course_rdoItem">
              <div className="rdo_row">
                <label
                  className={
                    stepFourComplete
                      ? "radio_circle selected_rdo"
                      : "radio_circle"
                  }
                >
                  {stepFourComplete ? (
                    <i className="material-icons-round sucess_tick">
                      check_circle
                    </i>
                  ) : null}
                  <input type="radio" name="rdo1" />
                  <span></span>
                  <p>{t("Create Your Content")}</p>
                </label>
              </div>
              <div className="rdo_menulist">
                <ul>
                  <li className={stepFourComplete ? "compelete_grn_tick" : ""}>
                    <Link to="#">
                      <span>{t("Add Lessons")}</span>
                      <i className="material-icons-outlined">check_circle</i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* --------------- Step 4 ends here --------------- */}

            {/* --------------- Step 5, 6, 7 & 8 begins here --------------- */}
            <div className="course_rdoItem">
              <div className="rdo_row">
                <label
                  className={
                    stepEightComplete
                      ? "radio_circle selected_rdo"
                      : "radio_circle"
                  }
                >
                  {stepEightComplete ? (
                    <i className="material-icons-round sucess_tick">
                      check_circle
                    </i>
                  ) : null}
                  <input type="radio" name="rdo1" />
                  <span></span>
                  <p>{t("Publish Your Content")}</p>
                </label>
              </div>
              <div className="rdo_menulist">
                <ul>
                  {/* ----------- Step 5 ----------- */}
                  <li className={stepFiveComplete ? "compelete_grn_tick" : ""}>
                    <Link to="#">
                      <span>{t("Course Message")}</span>
                      <i className="material-icons-outlined">check_circle</i>
                    </Link>
                  </li>

                  {/* ----------- Step 6 ----------- */}
                  <li className={stepSixComplete ? "compelete_grn_tick" : ""}>
                    <Link to="#">
                      <span>{t("Coupons and Referrals")}</span>
                      <i className="material-icons-outlined">check_circle</i>
                    </Link>
                  </li>

                  {/* ----------- Step 7 ----------- */}
                  {/* <li className={stepSevenComplete ? "compelete_grn_tick" : ""}>
                    <Link to="#">
                      <span>{t("Payment Settings")}</span>
                      <i className="material-icons-outlined">check_circle</i>
                    </Link>
                  </li> */}

                  {/* ----------- Step 8 ----------- */}
                  <li className={stepEightComplete ? "compelete_grn_tick" : ""}>
                    <Link to="#">
                      <span>{t("Publish & Congrats")}</span>
                      <i className="material-icons-outlined">check_circle</i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* --------------- Step 5, 6, 7 & 8 ends here --------------- */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseLeftPanel;
