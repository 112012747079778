import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

// importing custom components here
import Step1 from "components/CourseComponents/step-1/Step1";
import Step2 from "components/CourseComponents/step-2/Step2";
import Step3 from "components/CourseComponents/step-3/Step3";
import Step4 from "components/CourseComponents/step-4/Step4";
import Step5 from "components/CourseComponents/step-5/Step5";
import Step6 from "components/CourseComponents/step-6/Step6";
import Step7 from "components/CourseComponents/step-7/Step7";
import Step8 from "components/CourseComponents/step-8/Step8";

const CourseRightPanel = (props) => {
  // getting the user token here.
  const token = localStorage.getItem("token");

  // console.log("URL ----> ", url);
  // console.log("PATH ----> ", path);

  // Return statement from here
  return (
    <>
      <Switch>
        {/* -------- Step 1 -------- */}
        <Route
          exact
          path="/course/add/step1"
          render={(props) => <Step1 {...props} />}
        />

        {/* -------- Step 2 -------- */}
        <Route
          exact
          path="/course/add/step2"
          render={(props) => <Step2 {...props} />}
        />

        {/* -------- Step 3 -------- */}
        <Route
          exact
          path="/course/add/step3"
          render={(props) => <Step3 {...props} />}
        />

        {/* -------- Step 4 -------- */}
        <Route
          exact
          path="/course/add/step4"
          render={(props) => <Step4 {...props} />}
        />

        {/* -------- Step 5 -------- */}
        <Route
          exact
          path="/course/add/step5"
          render={(props) => <Step5 {...props} />}
        />

        {/* -------- Step 6 -------- */}
        <Route
          exact
          path="/course/add/step6"
          render={(props) => <Step6 {...props} />}
        />

        {/* -------- Step 7 -------- */}
        <Route
          exact
          path="/course/add/step7"
          render={(props) => <Step7 {...props} />}
        />

        {/* -------- Step 8 -------- */}
        <Route
          exact
          path="/course/add/step8"
          render={(props) => <Step8 {...props} />}
        />
      </Switch>
    </>
  );
};

export default CourseRightPanel;
