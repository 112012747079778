/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import Gateway for API fetch call
import {
  getData,
  postData,
  postActivityFormData,
  uploadMultipleFile,
  postFormdata,
} from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

const Events = () => {
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // Getting the course ID created in Step 1
  const params = useParams();

  const glowCount = [1, 2, 3];
  const spanCount = [1, 2, 3, 4, 5];

  const [eventLists, setEventLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getEventLists = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_VIEW_EVENT_DETAILS +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      const response = await getData(requestURL);

      console.log(response);

      if (response.status && response.data.length > 0) {
        setEventLists(response.data);
      }
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getEventLists(params.id);
  }, []);

  return (
    <>
      {/* --------------- Events Body starts here --------------- */}
      <div className="tab-pane show active fade" id="events">
        {isLoading ? (
          <div className="course_detailspnl">
            <div className="contnt_hdng tab_Hdng placeholder-glow">
              <span className="placeholder placeholder-sm rounded-3 col-2"></span>
            </div>
            <div className="evntbx_outer">
              <div className="row">
                {glowCount.map((glowElem) => {
                  return (
                    <div className="col-lg-4 col-sm-6" key={glowElem}>
                      <div className="job_bxItem event_bxItem placeholder-glow">
                        <div className="event_hdng">
                          <h3>
                            <span
                              className="placeholder rounded-3 col-8 mb-3"
                              style={{ height: "22px" }}
                            ></span>
                          </h3>
                          <div className="date_ratingOuter">
                            <span
                              className="placeholder rounded-3 col-6 me-3"
                              style={{ height: "18px" }}
                            ></span>
                            <span
                              className="placeholder rounded-3 col-2"
                              style={{ height: "18px" }}
                            ></span>
                          </div>
                        </div>
                        <div className="experts_skill">
                          <div className="d-flex">
                            <span
                              className="placeholder rounded-3 col-3 me-3"
                              style={{ height: "30px" }}
                            ></span>
                            <span
                              className="placeholder rounded-3 col-3"
                              style={{ height: "30px" }}
                            ></span>
                          </div>
                        </div>
                        <div className="ambassadors_users event_user">
                          <div className="d-flex">
                            {spanCount.map((spanElem) => {
                              return (
                                <span
                                  key={spanElem}
                                  className="placeholder me-1 rounded-circle"
                                  style={{ width: "40px", height: "40px" }}
                                ></span>
                              );
                            })}
                          </div>
                        </div>
                        <div className="details_btn">
                          <span
                            className="placeholder rounded-3 col-12"
                            style={{ height: "50px" }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div className="course_detailspnl">
            <div className="contnt_hdng tab_Hdng">
              <h4>{t("Events")}</h4>
            </div>

            {/* ------------ Cards section starts here ------------ */}
            <div className="evntbx_outer">
              {eventLists.length > 0 && (
                <div className="row">
                  {/* --------------- Card 1 --------------- */}
                  {eventLists.map((events, index) => {
                    return (
                      <div className="col-lg-4 col-sm-6" key={index}>
                        <div className="job_bxItem event_bxItem">
                          <div className="event_hdng">
                            <h3>{events.title}</h3>
                            <div className="date_ratingOuter">
                              <h5>
                                <i className="material-icons-outlined">
                                  event_note
                                </i>
                                <span>{events.created}</span>
                              </h5>
                              <div className="team_rating star_color">
                                <h3>
                                  <i className="material-icons-outlined">
                                    star
                                  </i>
                                  <span>{events.rating}</span>
                                </h3>
                                <h6>({events.reviews})</h6>
                              </div>
                            </div>
                          </div>
                          <div className="experts_skill">
                            <ul>
                              {events.category.map((catElem, index) => {
                                <li key={index}>
                                  <Link to="#">{catElem}</Link>
                                </li>;
                              })}
                              <li>
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    {" "}
                                    place{" "}
                                  </i>
                                  {events.address}
                                </Link>
                              </li>
                            </ul>
                          </div>

                          <div className="ambassadors_users event_user">
                            <ul>
                              {events.members.map((member, index) => {
                                return (
                                  <li key={index}>
                                    <Link to="#">
                                      <img src={member.image} alt="" />
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          <div className="details_btn">
                            <Link
                              to="#"
                              className="basic_btn ylw_btn jbdetails_btn"
                            >
                              View Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {/* ------------ Cards section ends here ------------ */}
          </div>
        )}
      </div>
      {/* --------------- Events Body ends here --------------- */}
    </>
  );
};

export default Events;
