/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ------------------------------------------------------------------------*/

const AddTagsModal = (props) => {
  // getting logged in user's token here
  const token = localStorage.getItem("token");
  const params = useParams();
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [tags, setTags] = useState([]);
  // Initial useState to store the entered tags name
  const [newTag, setNewTag] = useState("");

  //initial useState to hold all the selected users
  const [selectedTags, setSelectedTags] = useState([]);
  const [newTagWarining, setNewTagWarining] = useState(false);
  const [tagWarning, setTagWarning] = useState(false);
  const [isAddTagLoading, setIsAddTagLoading] = useState(false);
  const [isRemoveTagLoading, setIsRemoveTagLoading] = useState(false);

  // function for get chat details from API
  const getTagsData = async () => {
    let requestURL =
      url.API_BaseUrl + url.API_CONVERSATION_TAGS + `?token=${token}`;

    // console.log("Tags url -----> ", requestURL);

    try {
      const response = await getData(requestURL);

      // console.log("Received Tags response is ------> ", response);

      if (response.status) {
        //console.log("response is----->", response.data);
        setTags(response.data);
      } else {
        //console.log("Some error occurred!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function for add new tag for tag contact/group
  const addNewTagHandler = async (event) => {
    event.preventDefault();
    //console.log("New tag added -------> ", newTag);

    if (newTag == "") {
      setNewTagWarining(true);
    } else {
      let tagData = {
        name: newTag,
      };
      setNewTagWarining(false);
      let requestURL =
        url.API_BaseUrl + url.API_CREATE_NEW_TAG + `?token=${token}`;

      //console.log("Add Tags URL -------> ", requestURL);

      try {
        const response = await postData(requestURL, tagData);

        if (response.status) {
          //console.log("Received tag response -------> ", response);

          // call the get function again to update the dom
          getTagsData();
        } else {
          //console.log("Message sending failed : " + response);
        }
      } catch (error) {
        //console.log('add new tag error------>',error);
      }
    }
    // resetting the useState to clear the text field.
    resetTagFieldHandler();
  };

  // reset all tag fields
  const resetTagFieldHandler = () => {
    setNewTag("");
    setNewTagWarining(false);
  };

  // function for set tags
  const tagselectedHandler = (data) => {
    // console.log("data---->", data);

    let prevTags = selectedTags;

    if (prevTags.includes(data.id)) {
      let selectedTagItem = document.getElementById("tagselected-" + data.id);
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.id);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagselected-" + data.id);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.id);
    }
    setSelectedTags(prevTags);
  };

  // function for add tags to chat/contact/group
  const saveTagsHandler = async () => {
    let taglists = "";

    // console.log("selecetd tags----->", selectedTags);

    selectedTags.map((data, index) => {
      taglists += `&tags[]=${data}`;
    });

    if (selectedTags.length >= 1) {
      setIsAddTagLoading(true);
      setTagWarning(false);
      let requestURL =
        url.API_BaseUrl +
        url.ADD_TAG_CHAMPION +
        `?token=${token}` +
        `&uid=${params.id}` +
        `${taglists}`;

      console.log("Add tag Modal URL --------> ", requestURL);

      try {
        const response = await getData(requestURL);

        console.log("Add tag modal response is ------> ", response);

        if (response.status) {
          props.afterModalClose();
          //console.log("successfully submitted");
        } else {
          //console.log("Some error occurred!");
        }

        let addTagModal = document.querySelector("#champtag_mdl");
        let modal = bootstrap.Modal.getInstance(addTagModal);
        modal.hide();
        closeModalHandler();
        setIsAddTagLoading(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      setTagWarning(true);
    }
  };

  // function for remove tag from contact/group
  const removeTagsHandler = async () => {
    let taglists = "";

    // console.log("selecetd tags----->", selectedTags);

    selectedTags.map((data, index) => {
      taglists += `&tags[]=${data}`;
    });

    //console.log("tags--->", tid);

    if (selectedTags.length >= 1) {
      setIsRemoveTagLoading(true);
      setTagWarning(false);
      let requestURL =
        url.API_BaseUrl +
        url.REMOVE_TAG_CHAMPION +
        `?token=${token}` +
        `&uid=${params.id}` +
        `${taglists}`;

      console.log("Remove tag Modal URL --------> ", requestURL);

      try {
        const response = await getData(requestURL);

        console.log("Remove tag modal response is ------> ", response);

        if (response.status) {
          props.afterModalClose();
          //console.log("successfully submitted");
        } else {
          //console.log("Some error occurred!");
        }

        let addTagModal = document.querySelector("#champtag_mdl");
        let modal = bootstrap.Modal.getInstance(addTagModal);
        modal.hide();
        closeModalHandler();
        setIsRemoveTagLoading(false);
      } catch (error) {
        //console.log(error);
      }
    } else {
      setTagWarning(true);
    }
  };

  // function for reset all data after close modal
  const closeModalHandler = () => {
    resetTagFieldHandler();
    setSelectedTags([]);
    resetSelectTags();
    setTagWarning(false);
  };

  // remove active class after close modal
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("displayTags");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  useEffect(() => {
    // API call to get all the Tag data here
    getTagsData();
  }, []);

  return (
    <div className="chat_modalbx share_modal_bx tag_modalBx">
      <div
        className="modal fade"
        id="champtag_mdl"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header mdl_hdng">
              <h5 className="modal-title">Add Tag</h5>
              <button
                type="button"
                className="close ylw_closeBtn bg-warning"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModalHandler}
              >
                <span className="material-icons-round">close </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="group_srchfill add_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Enter to create new label")}
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                />
                <Link
                  to="#"
                  className="add_icon"
                  onClick={(e) => addNewTagHandler(e)}
                >
                  <span className="material-icons-round">add_circle </span>
                </Link>
              </div>
              {tags.length > 0 && (
                <div className="group_innrScroll">
                  <div className="tag_links">
                    <ul>
                      {tags.map((data, index) => (
                        <li key={data.id}>
                          <Link
                            to="#"
                            className="displayTags"
                            onClick={() => tagselectedHandler(data)}
                            id={"tagselected-" + data.id}
                          >
                            {data.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {/* -------------------- Button section starts here -------------------- */}
              <div className="group_btnOuter">
                {tagWarning && (
                  <p className="text-danger mb-2">
                    * {t("Select atleast one tag")}
                  </p>
                )}
                <ul>
                  <li className="w_100">
                    <Link
                      to="#"
                      className="ornage_btn"
                      role="button"
                      onClick={saveTagsHandler}
                    >
                      {isAddTagLoading ? (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <i className="material-icons-round"> check_circle </i>
                      )}
                      <span>{t("Add")}</span>
                    </Link>
                  </li>
                  <li className="w_100">
                    <Link
                      to="#"
                      className="red_btn"
                      role="button"
                      onClick={removeTagsHandler}
                    >
                      {isRemoveTagLoading ? (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <i className="material-icons-round"> delete </i>
                      )}
                      <span>{t("Delete")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* -------------------- Button section ends here -------------------- */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTagsModal;
