import React from "react";
import { Link } from "react-router-dom";

const GroupDetailsTopSection = () => {
  return (
    <>
      <section className="course_details_sec">
        <div className="container">
          <div className="course_detailsBx">
            <div className="topdetails_left">
              <div className="expertholder_outer">
                <div className="experts_holdertext">
                  <h5>ATM Traffic Automation</h5>
                  <h3>Indra ATM Ecosystem Champions (2022) Group</h3>
                  <div className="details_tags expert_details">
                    <div className="jb_rating star_color">
                      <i className="material-icons-outlined"> star </i>
                      <span>4.5</span>
                      <h6>
                        (32<em>valoraciones</em>)
                      </h6>
                    </div>
                  </div>

                  <div className="experts_skill">
                    <ul>
                      <li>
                        <Link to="#">
                          <span>Big Tech</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>IT Gients</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Technology</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="ambassadors_users grps_user">
                    <ul>
                      <li>
                        <Link to="#">
                          <img src="images/grp1.jpg" alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img src="images/grp2.jpg" alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img src="images/grp3.jpg" alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img src="images/grp4.jpg" alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img src="images/grp5.jpg" alt="" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="training_para">
                    <p>
                      At Indra we have developed air traffic management systems
                      that are deployed across the world, with over 4,000
                      installations in 160 countries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="topdetails_right">
              <div className="asign_top">
                <div className="asign_outer">
                  <div className="asign_item">
                    <h4>
                      Offered By <span>Indra</span>
                    </h4>
                  </div>
                </div>

                <div className="exprt_share traning_share">
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="material-icons-outlined">more</i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="material-icons-outlined">share</i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GroupDetailsTopSection;
