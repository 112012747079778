/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

//importing images here

const ChatInfo = (props) => {
  // function for refresh chatboard/groupchatboard via props
  const refreshHandler = () => {
    props.refreshChatMail();
  };

  // function for open add tag modal and send group/contact via props
  const openAddTag = () => {
    props.sendTag();
  };

  const token = localStorage.getItem("token");

  return (
    <>
      {/* -------- top menu bar containing the user profile image and other links -------- */}
      <div className="info_icons">
        <ul>
          {/* -------------- Search Bar -------------- */}
          <li>
            <Link
              to="#"
              onClick={() => {
                toggleBlockById("chatsearch_pnl");
                props.resetChatQuery();
              }}
            >
              <i className="material-icons-round">search</i>
            </Link>
          </li>

          <li>
            <Link to="#" role="button" onClick={refreshHandler}>
              <i className="material-icons-round">refresh</i>
            </Link>
          </li>

          {/* -------------- Group Modal button -------------- */}
          {/* <li>
            <Link to="#" data-bs-toggle="modal" data-bs-target="#group_modal">
              <i className="material-icons-round">groups</i>
            </Link>
          </li> */}

          {/* -------------- 'Add Tag' Modal button here --------------- */}
          <li>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#addtag_mdl"
              role="button"
              onClick={openAddTag}
            >
              <i className="material-icons-round">loupe</i>
            </Link>
          </li>

          {/* -------------- Video call button here --------------- */}
          {/* <li>
            <Link to="#">
              <i className="material-icons-round">video_call</i>
            </Link>
          </li> */}

          {/* -------------- More button here --------------- */}
          {/* <li>
            <Link to="#">
              <i className="material-icons-round">more_vert</i>
            </Link>
          </li> */}

          {/* -------------- Login modal button here --------------- */}
          {/* {token == null ? (
            <li>
              <Link
                to="#"
                role="button"
                data-bs-toggle="modal"
                data-bs-target="#loginModal"
              >
                <i className="material-icons-round">login</i>
              </Link>
            </li>
          ) : null} */}
        </ul>
      </div>
    </>
  );
};

export default ChatInfo;
