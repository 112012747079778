/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { useTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalIndraInfo } from "App";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//import api files
import * as url from "helpers/url_helper";

import blankImage from "assets/CourseAssets/images/nophotoloading-images-vector.jpg";

import { getData, postData, postFormdata, uploadFile } from "utils/Gateway";

const Step1 = (props) => {
  const token = localStorage.getItem("token");

  const { courseId, getCourseId, onConfirmStep1 } = useContext(GlobalIndraInfo);

  // console.log("course id step one---->", courseId);

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // initial state for step one data
  const [courseCode, setCourseCode] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [sendLanguage, setSendLanguage] = useState("");
  const [stepOneTitle, setStepOneTitle] = useState("");
  const [stepOneSubtitle, setStepOneSubtitle] = useState("");
  const [stepOneCategories, setStepOneCategories] = useState([]);
  const [stepOneCompanyLogo, setStepOneCompanyLogo] = useState("");
  const [stepOneCompanyName, setStepOneCompanyName] = useState("");
  const [stepOneInstructorForm, setStepOneInstructorForm] = useState([]);
  const [stepOneAbout, setStepOneAbout] = useState("");
  const [instructorBlockNo, setInstructorBlockNo] = useState(null);
  // initial state for saving returned data from API
  const [instructorUploading, setInstructorUploading] = useState(false);

  //initial state for display error message
  const [titleErrorMessage, setTitleErrorMessage] = useState(false);
  const [subtitleErrorMessage, setSubtitleErrorMessage] = useState(false);
  const [categoryErrorMessage, setCategoryErrorMessage] = useState(false);
  const [courseCodeError, setCourseCodeError] = useState(false);
  const [langError, setLangError] = useState(false);
  const [instructorError, setInstructorError] = useState(false);
  const [instIsNotEmpty, setInstIsNotEmpty] = useState(false);

  /*---- CKEditor classic editor configuration----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      uploadUrl: "https://dev.lobees.com/web/uploads/reports/",
    },
    placeholder: "About course...",
  };
  /*----- end of CKEditor classic editor part----- */

  // Initial useState for Notification component
  const [isUploading, setIsUploading] = useState(false);

  //initial state for display publisher block or not
  const [showPublisherSection, setShowPublisherSection] = useState(false);

  //inital state for company image
  const [companyLogo, setCompanyLogo] = useState(blankImage);

  // for routing
  const history = useHistory();

  // custom style for category
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "5px",
      fontFamily: "sans-serif",
      border: "1px solid #e4e4e4",
      backgroundColor: "#fff",
      height: 50,
      borderRadius: "8px",
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8E8E",
        backgroundColor: "#fff",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    // dropdownIndicator: (base, state) => {
    //   let changes = { color: "#505050" };
    //   return Object.assign(base, changes);
    // },
  };

  /*---------- Category selection start -----------*/
  const [categoriesValue, setCategoriesValue] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // count number for spinner
  const loadingCircle = [1, 2, 3, 4, 5, 6];

  let languageOptions = [
    { value: "en", label: "English - en" },
    { value: "es", label: "Spanish - es" },
  ];

  const languageSelectionHandler = (val) => {
    setSelectedLanguage(val);
    setSendLanguage(val.value);
  };

  // function to get categories details from API
  const getCategories = async () => {
    const requestURL =
      url.API_BaseUrl + url.API_CATEGORIES_DROPDOWN_OPTIONS + `?token=${token}`;

    //console.log(requestURL);
    try {
      const response = await getData(requestURL);

      //console.log("Received response in category ------> ", response);

      if (response.status) {
        //console.log("Category response is----->", response.data.options);
        setCategoriesValue(response.data);
      } else {
        // console.log("Some error occurred!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const categorySelectionHandler = (val) => {
    let categoryVal = val.map((item) => {
      let categoryObject = {
        label: item.label,
        id: item.id,
      };
      return categoryObject;
    });

    if (categoryVal.length != 0) {
      setSelectedCategory();
      setStepOneCategories(categoryVal); //set multiselect values in array
    }
  };

  /*---------- Category selection end ----------*/

  // function for company image upload
  const companyImageUploadHandler = async (e) => {
    let fileData = e.target.files[0];

    let apiUrl = url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

    // calling the promise notification here
    setIsUploading(true);
    try {
      let response = await uploadFile(apiUrl, fileData);
      // console.log("response file upload------>", response.data);

      if (response.status) {
        setCompanyLogo(response.data.url);
        setStepOneCompanyLogo(response.data.id);

        // calling the notification useState here
        setIsUploading(false);
      } else {
        console.log("Cannot upload picture");
      }
    } catch (error) {
      console.log("error in file upload ----->", error);
    }
  };

  /*----- Instructor block repeater ------ */
  const instructorBlockTemplate = {
    instructorimage: "",
    instructorimageid: "",
    instructorname: "",
    instructorposition: "",
    instructoremail: "",
  };

  const [instructorBlock, setInstructorBlock] = useState([
    instructorBlockTemplate,
  ]);

  // add Instructor block
  const onAddInstructorBlock = () => {
    setInstructorBlock([...instructorBlock, instructorBlockTemplate]);
  };

  // image repeater
  const onUploadImage = async (e, index) => {
    setInstructorBlockNo(index);
    setInstructorUploading(true);
    let fileData = e.target.files[0];

    let apiUrl = url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

    try {
      let response = await uploadFile(apiUrl, fileData);
      // console.log("response file upload------>", response.data);

      if (response.status) {
        const updateImageStatus = instructorBlock.map((item, i) =>
          index == i
            ? Object.assign(item, {
                ["instructorimage"]: response.data.url,
                ["instructorimageid"]: response.data.id,
              })
            : item
        );

        //console.log("update image status ----->", updateImageStatus);

        // let iamgeUrl = URL.createObjectURL(e.target.files[0]);
        let iamgeUrl = response.data.url;

        // setup preview of video
        let preview = document.getElementById(`instructorimagepreview` + index);
        preview.setAttribute("src", `${iamgeUrl}`);

        setInstructorBlock(updateImageStatus);
        const imageValue = updateImageStatus.map((item, number) => {
          return item;
        });

        setStepOneInstructorForm(imageValue);
        setInstructorUploading(false);
      } else {
        console.log("Cannot upload picture");
      }
    } catch (error) {
      console.log("error in file upload ----->", error);
    }
  };

  // insert value Instructor block
  const instructorFormHandler = (e, index) => {
    // console.log("value", e.target.value);

    const updateStatus = instructorBlock.map((item, i) =>
      index == i
        ? Object.assign(item, { [e.target.name]: e.target.value })
        : item
    );

    setInstructorBlock(updateStatus);

    const docValue = updateStatus.map((item, number) => {
      return item;
    });

    setStepOneInstructorForm(docValue);
  };

  // update instructor value
  const updateInstructorBlock = (instructors) => {
    let arr = [];

    instructors.map(function (instructor) {
      // restructing json object to map with form data
      let instructorSavedBlockElement = {
        membershipid: instructor.membershipid,
        instructorimage: instructor.image,
        instructorimageid: instructor.imageid,
        instructorname: instructor.name,
        instructorposition: instructor.position,
        instructoremail: instructor.email,
      };

      arr.push(instructorSavedBlockElement);

      // console.log("arr", arr);
    });

    setInstructorBlock(arr);
  };

  // delete Instructor block
  const onDeleteInstructorBlock = async (item, index) => {
    let memberId = {
      membershipid: item.membershipid,
    };
    let requestURL =
      url.API_BaseUrl +
      url.API_DELETE_FORM_REPEATER +
      `?token=${token}` +
      `&cid=${courseId}`;

    try {
      let response = await postFormdata(requestURL, memberId);

      const filteredBlock = [...instructorBlock];
      filteredBlock.splice(index, 1);

      setInstructorBlock(filteredBlock);
      const docValue = filteredBlock.map((item, number) => {
        return item;
      });
      setStepOneInstructorForm(docValue);
    } catch (error) {
      console.log(error);
    }
  };
  /*----- end of Instructor block repeater ------ */

  /*---- function for go to next step -----*/
  const nextStepHandler = async (e) => {
    e.preventDefault();
    console.log("step one data ----->", stepOneInstructorForm);
    setTitleErrorMessage(false);
    setSubtitleErrorMessage(false);
    setCategoryErrorMessage(false);
    setLangError(false);
    setCourseCodeError(false);
    setInstructorError(false);
    if (
      courseCode == "" ||
      sendLanguage == "" ||
      stepOneTitle == "" ||
      stepOneSubtitle == "" ||
      stepOneCategories.length == 0 ||
      (stepOneInstructorForm.length == 0 && !instIsNotEmpty)
    ) {
      if (sendLanguage == "") {
        setLangError(true);
      } else {
        setLangError(false);
      }
      if (courseCode == "") {
        setCourseCodeError(true);
      } else {
        setCourseCodeError(false);
      }
      if (stepOneTitle == "") {
        setTitleErrorMessage(true);
      } else {
        setTitleErrorMessage(false);
      }
      if (stepOneSubtitle == "") {
        setSubtitleErrorMessage(true);
      } else {
        setSubtitleErrorMessage(false);
      }
      if (stepOneCategories.length == 0) {
        setCategoryErrorMessage(true);
      } else {
        setCategoryErrorMessage(false);
      }
      if (stepOneInstructorForm.length == 0 && !instIsNotEmpty) {
        setInstructorError(true);
      } else {
        setInstructorError(false);
      }
    } else {
      let stepOneData = {
        code: courseCode,
        language: sendLanguage,
        title: stepOneTitle,
        subtitle: stepOneSubtitle,
        categories: stepOneCategories,
        companylogo: stepOneCompanyLogo,
        companyname: stepOneCompanyName,
        instructorform: stepOneInstructorForm,
        about: stepOneAbout,
      };
      // console.log("step one sending data ----->", stepOneData);

      let requestURL = "";

      if (courseId != "") {
        requestURL =
          url.API_BaseUrl +
          url.API_STEP_1_COURSE_CREATE +
          `?token=${token}` +
          `&cid=${courseId}`;
      } else {
        requestURL =
          url.API_BaseUrl + url.API_STEP_1_COURSE_CREATE + `?token=${token}`;
      }

      setIsUploading(true);

      try {
        const response = await postData(requestURL, stepOneData);
        // console.log("step one sending response----->", response);
        if (response.status) {
          let stepOneState = true;
          setIsUploading(false);
          onConfirmStep1(stepOneState);
          getCourseId(response.courseid);
          history.push("/course/add/step2");
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  /*---- end of function for go to next step -----*/

  /*---- function to fetch the course details saved in step 1 -----*/
  const viewCourseDetailsHandler = async (id) => {
    const requestURL =
      url.API_BaseUrl +
      url.API_STEP_1_COURSE_VIEW +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      const response = await getData(requestURL);
      console.log("step one view course response category----->", response);
      if (response.status) {
        let categoryArr = [];
        response.data.categories.map((item) => {
          // console.log("categories in view", item);
          let categoryObject = {
            id: item.id,
            value: item.id,
            name: item.label,
            label: item.label,
            slug: item.label,
          };

          categoryArr.push(categoryObject);
        });

        categorySelectionHandler(categoryArr);
        setSelectedCategory(categoryArr);

        if (response.data.members.length > 0) {
          updateInstructorBlock(response.data.members);
          setInstIsNotEmpty(true);
        } else {
          setInstIsNotEmpty(false);
        }

        setStepOneTitle(response.data.label);
        setStepOneSubtitle(response.data.subtitle);
        setStepOneAbout(response.data.about);
        setCourseCode(response.data.code);
        if (response.data.language == "en") {
          setSelectedLanguage({ value: "en", label: "English - en" });
        } else {
          setSelectedLanguage({ value: "es", label: "Spanish - es" });
        }
        setSendLanguage(response.data.language);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*---- end of function to fetch the course details saved in step 1 -----*/

  /* Function to redirect to the next page without calling the API */
  const goToNextPageHandler = (e) => {
    e.preventDefault();
    history.push("/course/add/step2");
    let stepOneState = true;
    onConfirmStep1(stepOneState);
  };

  useEffect(() => {
    let stepOneState = false;
    if (courseId != null) {
      stepOneState = true;
      viewCourseDetailsHandler(courseId);
    }
    onConfirmStep1(stepOneState);
  }, [courseId]);

  useEffect(() => {
    // fetching the category options
    getCategories();
  }, []);

  return (
    <>
      <div className="col-lg-9">
        {/* ------------- Mobile View back button starts here ------------- */}
        <div className="back_progress">
          <div className="page_back">
            <Link to="#">
              <span className="material-icons-outlined">arrow_back</span>
              Back
            </Link>
          </div>
          <div className="step_progress">
            <span className="progress_bar" style={{ width: "20%" }}></span>
          </div>
        </div>
        {/* ------------- Mobile View back button starts here ------------- */}

        {/* ------------- Form section starts here ------------- */}
        <div className="course_uploadrghtpnl">
          <div className="course_fillTop">
            {/* ---- step one language ---- */}
            <div className="input_fill jobinput_fill">
              <label>{t("Language")}</label>
              <Select
                placeholder={t("Select Language")}
                options={languageOptions}
                value={selectedLanguage}
                onChange={(val) => {
                  languageSelectionHandler(val);
                }}
                classNamePrefix="select2-selection"
                styles={customSelectStyle}
              />
              {langError && (
                <p className="text-danger mt-1">* Please select language</p>
              )}
            </div>
            {/* ---- step one language end ---- */}

            {/* ----- step one course code ---- */}
            <div className="input_fill jobinput_fill">
              <label>{t("Course Code")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter Course Code")}
                value={courseCode}
                onChange={(e) => setCourseCode(e.target.value)}
              />
              {courseCodeError && (
                <p className="text-danger mt-2">
                  * {t("Please Enter Course Code")}
                </p>
              )}
            </div>
            {/* ----- step one course code end---- */}

            {/* ----- step one title ---- */}
            <div className="input_fill jobinput_fill">
              <label>{t("Course Title")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter Course Title")}
                value={stepOneTitle}
                onChange={(e) => setStepOneTitle(e.target.value)}
              />
              {titleErrorMessage && (
                <p className="text-danger mt-2">
                  *
                  <Trans i18nKey="Please Enter Course Title">
                    Please Enter Course Title
                  </Trans>
                </p>
              )}
            </div>
            {/* ----- step one title end ---- */}
            {/* ----- subtitle input section start ----- */}
            <div className="input_fill jobinput_fill">
              <label>{t("Subtitle")}</label>
              <textarea
                type="text"
                rows={3}
                className="form-control msg_bxArea p-3"
                placeholder={t("Enter Course Subtitle")}
                value={stepOneSubtitle}
                onChange={(e) => setStepOneSubtitle(e.target.value)}
              ></textarea>
              {subtitleErrorMessage && (
                <p className="text-danger mt-2">
                  *
                  <Trans i18nKey="Please fill up subtitle">
                    Please fill up subtitle
                  </Trans>
                </p>
              )}
            </div>
            {/* ----- subtitle input section end ----- */}

            {/* ----- category selecetion section start ----- */}
            <div className="input_fill jobinput_fill">
              <label>{t("Category")}</label>
              <Select
                placeholder={t("Select Category")}
                options={categoriesValue}
                value={selectedCategory}
                isMulti={true}
                onChange={(val) => {
                  categorySelectionHandler(val);
                }}
                classNamePrefix="select2-selection"
                styles={customSelectStyle}
              />
              {categoryErrorMessage && (
                <p className="text-danger mt-2">
                  *
                  <Trans i18nKey="Please select Category">
                    Please select Category
                  </Trans>
                </p>
              )}
            </div>
            {/* ----- category selecetion section end ----- */}

            {/* ----- TextEditor section start ----- */}
            <div className="input_fill jobinput_fill">
              <label>{t("About Course")}</label>
              <CKEditor
                className="form-control"
                editor={ClassicEditor}
                config={editorConfig}
                data={stepOneAbout ? stepOneAbout : ""}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "250px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  let editContent = editor.getData();
                  //console.log(JSON.stringify(editContent));
                  setStepOneAbout(editContent);
                }}
              />
            </div>
            {/* ----- TextEditor section end ----- */}
          </div>

          {/* ---- instructor section start ---- */}
          <div className="instrector_area">
            <div className="course_fillHdng">
              <h3>{t("Instructors")}</h3>
            </div>
            <div className="institute_check">
              <label className="radio_circle">
                <input
                  type="checkbox"
                  onChange={() =>
                    setShowPublisherSection(!showPublisherSection)
                  }
                />
                <span></span>
                <i>
                  <Trans i18nKey="Offered by an Institution">
                    Offered by an Institution
                  </Trans>
                </i>
              </label>
            </div>

            <div className="intrector_fillOuter">
              {/* ---- display company section conditionally ------ */}
              {showPublisherSection && (
                <>
                  {isUploading == false ? null : (
                    <div className="rounded">
                      <div className="alert alert-info">
                        {t("Uploading")}
                        <span style={{ fontWeight: "bold" }}>
                          {t("Company logo")}
                        </span>
                        {t("please wait")} ....
                      </div>
                    </div>
                  )}

                  <div className="intrector_row">
                    {/* ---- company logo and name area -----*/}
                    <div className="left_intrector">
                      <div className="gogle_icon">
                        <img src={companyLogo} alt="logo" />
                        <input
                          type="file"
                          className="file_btn"
                          onChange={(e) => companyImageUploadHandler(e)}
                        />
                      </div>
                      <div className="input_fill jobinput_fill">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("Enter Company Name")}
                          value={stepOneCompanyName}
                          onChange={(e) =>
                            setStepOneCompanyName(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    {/* ---- end of company logo and name area -----*/}

                    {/* ---- delete button for company name and logo -----*/}
                    <div className="right_intrector">
                      <div className="dlete_btn">
                        <Link
                          to="#"
                          role="button"
                          onClick={() => {
                            setCompanyLogo(blankImage);
                            setStepOneCompanyName("");
                            setStepOneCompanyLogo("");
                          }}
                        >
                          <span className="material-icons-outlined">
                            delete
                          </span>
                        </Link>
                      </div>
                    </div>
                    {/* ---- end of delete button for company name and logo -----*/}
                  </div>
                </>
              )}
              {/* ---- display company section conditionally end------ */}

              {/* ---- Instructor block repeater -----*/}
              {instructorBlock.map((item, index) => {
                return (
                  <div
                    className="intrector_row intrector_profile_row"
                    key={index}
                  >
                    {/* <div>
                      membership id :{JSON.stringify(item.membershipid)} <br />
                      block index : {JSON.stringify(index)}
                    </div> */}

                    <div className="left_intrector">
                      {/* ---- Instructor block image area -----*/}
                      <div className="user_uplodPic">
                        <img
                          id={`instructorimagepreview` + index}
                          alt="instructorimage"
                          src={
                            item.instructorimage
                              ? item.instructorimage
                              : blankImage
                          }
                        />
                        <input
                          type="file"
                          name="instructorimage"
                          className="file_btn"
                          onChange={(e) => onUploadImage(e, index)}
                        />
                        {instructorUploading && instructorBlockNo == index && (
                          <div className="vdoplay_Btn">
                            <p className="text-dark">{t("Uploading")}</p>
                            {loadingCircle.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="spinner-grow mx-1 text-dark"
                                  style={{ height: "3px", width: "3px" }}
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                      {/* ---- end of Instructor block image area -----*/}

                      <div className="upload_filOuter">
                        {/* ---- Instructor block Instructor name area -----*/}
                        <div className="input_fill jobinput_fill">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Enter Instructor Name")}
                            name="instructorname"
                            value={item.instructorname}
                            onChange={(e) => instructorFormHandler(e, index)}
                          />
                        </div>
                        {/* ---- end of Instructor block Instructor name area -----*/}

                        {/* ---- Instructor block Instructor position area -----*/}
                        <div className="input_fill jobinput_fill">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Enter Instructor Position")}
                            name="instructorposition"
                            value={item.instructorposition}
                            onChange={(e) => instructorFormHandler(e, index)}
                          />
                        </div>
                        {/* ---- end of Instructor block Instructor position area -----*/}

                        {/* ---- Instructor block Instructor email area -----*/}
                        <div className="input_fill jobinput_fill">
                          <input
                            type="email"
                            className="form-control"
                            placeholder={t("Enter Instructor Email")}
                            name="instructoremail"
                            value={item.instructoremail}
                            onChange={(e) => instructorFormHandler(e, index)}
                          />
                        </div>
                        {/* ---- end of Instructor block Instructor email area -----*/}
                      </div>
                    </div>

                    {/* ---- Instructor block delete button -----*/}

                    {index == 0 ? null : (
                      <div className="right_intrector">
                        <div className="dlete_btn">
                          <Link
                            to="#"
                            role="button"
                            onClick={() => onDeleteInstructorBlock(item, index)}
                          >
                            <span className="material-icons-outlined">
                              clear
                            </span>
                          </Link>
                        </div>
                      </div>
                    )}

                    {/* ---- end of Instructor block delete button -----*/}
                  </div>
                );
              })}
            </div>
            {/* ---- end of Instructor block repeater -----*/}

            {/* ---- add another Instructor block outside repeater -----*/}
            <div className="addfill_btn">
              <Link
                to="#"
                className="add_plusBtn"
                role="button"
                onClick={onAddInstructorBlock}
              >
                <i className="material-icons-outlined">add_circle_outline</i>
                {t("Add Another")}
              </Link>
            </div>
            {/* ----end of add another Instructor block outside repeater -----*/}
          </div>

          {instructorError && (
            <p className="text-danger mt-2">
              * {t("Please add one Instructor")}
            </p>
          )}

          {isUploading && (
            <div className="rounded">
              <div className="alert alert-info">
                {t("Submitting data please wait")} ....
              </div>
            </div>
          )}

          {/* ---- Next button section start ---- */}
          <div className="nxt_prvbtn_Outer">
            {courseId != null ? (
              <>
                {/* Save and Continue */}
                <div className="next_Btn">
                  <Link
                    to="#"
                    role="button"
                    className="border_btn"
                    onClick={(e) => {
                      nextStepHandler(e);
                    }}
                  >
                    {t("Save & Continue")}
                  </Link>
                </div>

                {/* Go to next step without saving */}
                <div className="next_Btn">
                  <Link
                    to="#"
                    role="button"
                    className="basic_btn"
                    onClick={(e) => {
                      goToNextPageHandler(e);
                    }}
                  >
                    {t("Go to Next Step")}
                  </Link>
                </div>
              </>
            ) : (
              // Save and go to next step
              <div className="next_Btn">
                <Link
                  to="#"
                  className="basic_btn"
                  role="button"
                  onClick={(e) => {
                    nextStepHandler(e);
                  }}
                >
                  {t("Go to Next Step")}
                </Link>
              </div>
            )}

            {/* ----end of  go to next step button area ---- */}
          </div>
          {/* ---- Next button section end ---- */}
        </div>
        {/* ------------- Form section ends here ------------- */}
      </div>

      <ToastContainer />
    </>
  );
};

export default Step1;
