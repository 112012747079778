import React from "react";
import { Link } from "react-router-dom";

const SimilarGroups = () => {
  return (
    <div className="groups_rightpnl">
      <div className="details_hdng">
        <h3>Similar Groups</h3>
      </div>
      <div className="group_rightinnr">
        <div className="grp_innr_row">
          <div className="leftchampion_text">
            <h3>ATM Ecosystem Group</h3>
          </div>
          <div className="experts_skill">
            <ul>
              <li>
                <Link to="#">
                  <span>Big Tech</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <span>IT Gients</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <span>Technology</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="groups_pic">
            <img src="images/indra_grps1.jpg" alt="" />
          </div>
        </div>
        <div className="grp_innr_row">
          <div className="leftchampion_text">
            <h3>ATM Ecosystem Group</h3>
          </div>
          <div className="experts_skill">
            <ul>
              <li>
                <Link to="#">
                  <span>Big Tech</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <span>IT Gients</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <span>Technology</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="groups_pic">
            <img src="images/indra_grps2.jpg" alt="" />
          </div>
        </div>
        <div className="grp_innr_row">
          <div className="leftchampion_text">
            <h3>ATM Ecosystem Group</h3>
          </div>
          <div className="experts_skill">
            <ul>
              <li>
                <Link to="#">
                  <span>Big Tech</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <span>IT Gients</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <span>Technology</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="groups_pic">
            <img src="images/indra_grps3.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimilarGroups;
