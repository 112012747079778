import React from "react";
import { Link } from "react-router-dom";
import SimilarGroups from "../common/SimilarGroups";

const Gallery = () => {
  return (
    <div className="tab-pane fade show active" id="evnt_gallery">
      <div className="training_outer">
        <div className="row">
          <div className="col-lg-8">
            <div className="course_detailspnl">
              <div className="contnt_hdng tab_Hdng">
                <h4>Gallery</h4>
              </div>
              <div className="portfolio_outer gallery_outer group_gallery_outer">
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery1.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery2.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery3.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery4.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery5.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery6.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery7.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery8.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery9.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery10.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery1.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery2.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery3.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery4.jpg" alt="" />
                  </Link>
                </div>
                <div className="portfolio_gallery_item">
                  <Link to="#">
                    <img src="images/gallery5.jpg" alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <SimilarGroups />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
