/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation, Trans } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import {
  getData,
  postData,
  postActivityFormData,
  uploadMultipleFile,
  postFormdata,
} from "utils/Gateway";

// importing images here
import PDF from "assets/ActivityAssests/svg/pdf.svg";
import XLS from "assets/ActivityAssests/svg/xls.svg";
import DOCS from "assets/ActivityAssests/svg/docs.svg";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ---------------------------------------------------------------------------------- */

const ActivityFilter = (props) => {
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  //initial state for question panel
  const [showQuestionPanel, setShowQuestionPanel] = useState(false);
  // ----------- this useState is for receiving the JSON API data ---------------------
  const [categoriesValue, setCategoriesValue] = useState([]);
  const [tagsValue, setTagsValue] = useState([]);
  const [contact, setContact] = useState([]); // contact
  const [groupLists, setGroupList] = useState([]); // grp
  const [event, setEvent] = useState([]); // event

  const [selectedContacts, setSelectedContacts] = useState();
  const [selectedGroups, setSelectedGroups] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [userSelectedCategories, setUserSelectedCategories] = useState();
  const [userSelectedTags, setUserSelectedTags] = useState();

  const [title, setTitle] = useState("");
  const [editorValue, setEditorValue] = useState("");

  const [contactData, setContactData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  // initial state for uploaded multiple files
  const [media, setMedia] = useState([]);

  //initial state for uploaded image id
  const [fileIds, setFileIds] = useState([]);

  //initial state for search query
  const [searchPostQuery, setSearchPostQuery] = useState("");

  // initial state for posting load
  const [isSubmiting, setIsSubmiting] = useState(false);

  //initial for validation
  const [titleError, setTitleError] = useState(false);
  const [detailsError, setDetailsError] = useState(false);

  //initial for visible optional block
  const [optionalBlock, setOptionalBlock] = useState(false);

  //initial for upload bigger file
  const [fileIsBig, setFileIsBig] = useState(false);

  // initial for uploading
  const [isUploading, setIsUploading] = useState(false);

  // function for display question panel
  const togglePost = () => {
    var x = document.getElementById("activity_question_panel1");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
    resetPost();
  };

  /*---- CKEditor classic editor configuration----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type here..."),
  };
  /*----- end of CKEditor classic editor part----- */

  // custom style for select
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "5px",
      margin: "2px",
      fontFamily: "sans-serif",
      border: "none",
      backgroundColor: "#Fff",
      height: 50,
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8E8E",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  // function for get all contact
  const fetchContact = async () => {
    const requestURL =
      url.API_BaseUrl + url.API_ACTIVITY_FILTER_CONTACTS + `?token=${token}`;

    try {
      const response = await getData(requestURL);

      //console.log("Received response is ------> ", response);

      if (response.status) {
        //console.log("response in contact----->", response.data);
        setContact(response.data);
      } else {
        // console.log("Some error occurred!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function for get all groups
  const fetchGroups = async () => {
    const requestURL =
      url.API_BaseUrl + url.API_ACTIVITY_FILTER_GROUPS + `?token=${token}`;
    try {
      const response = await getData(requestURL);

      //console.log("Received response in fetchGroups ------> ", response);

      if (response.status) {
        //console.log("response is----->", response.data);
        setGroupList(response.data);
      } else {
        // console.log("Some error occurred!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function for get all events
  const fetchEvents = async () => {
    const requestURL =
      url.API_BaseUrl + url.API_ACTIVITY_FILTER_EVENTS + `?token=${token}`;

    try {
      const response = await getData(requestURL);

      //console.log("Received response in fetchEvents ------> ", response);

      if (response.status) {
        //console.log("response is----->", response.data);
        setEvent(response.data);
      } else {
        // console.log("Some error occurred!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function to get tags details from API
  const getTagsData = async () => {
    let requestURL =
      url.API_BaseUrl + url.API_ACTIVITY_FILTER_TAGS + `?token=${token}`;

    // console.log("Tags url -----> ", requestURL);

    try {
      const response = await getData(requestURL);

      //console.log("Received Tags response is ------> ", response);

      if (response.status) {
        //console.log("Tag list is ----->", response.data);
        setTagsValue(response.data);
      } else {
        //console.log("Some error occurred!");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // function to get categories details from API
  const getCategories = async () => {
    const requestURL =
      url.API_BaseUrl + url.API_ACTIVITY_FILTER_CATEGORIES + `?token=${token}`;

    //console.log(requestURL);
    try {
      const response = await getData(requestURL);

      //console.log("Received response in category ------> ", response);

      if (response.status) {
        //console.log("Category response is----->", response.data.options);
        setCategoriesValue(response.data);
      } else {
        // console.log("Some error occurred!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function for select contacts
  const contactHandler = (val) => {
    setSelectedContacts(val);
    let contactItem = val.map((item) => {
      return item.id;
    });
    setContactData(contactItem);
  };

  // function for select groups
  const groupHandler = (val) => {
    setSelectedGroups(val);
    let groupItem = val.map((item) => {
      return item.id;
    });
    setGroupData(groupItem);
  };

  // function for select events
  const eventHandler = (val) => {
    setSelectedEvent(val);
    let eventItem = val.map((item) => {
      return item.id;
    });
    setEventData(eventItem);
  };

  // function for select tags
  const tagHandler = (val) => {
    setUserSelectedTags(val);
    let tagItem = val.map((item) => {
      return item.id;
    });
    setTagData(tagItem);
  };

  // function for select categories
  const categoryHandler = (val) => {
    let categoryItem = val.map((item) => {
      return item.value;
    });
    setUserSelectedCategories(val);
    setCategoryData(categoryItem);
  };

  // function for multiple file upload
  const multipleFileUploadHandler = async (e) => {
    setFileIsBig(false);

    const selectedFIles = []; // initial empty values for file array

    const targetFiles = e.target.files; // store file information

    let filesLength = targetFiles.length;

    let proceed = false;

    if (filesLength > 0) {
      for (let i = 0; i < filesLength; i++) {
        let f = targetFiles[i];

        if (f.size <= 12500000) {
          proceed = true;
        } else {
          proceed = false;
        }
      }

      // console.log(proceed);

      if (proceed) {
        setIsUploading(true);
        let requestURL =
          url.API_BaseUrl + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

        try {
          const response = await uploadMultipleFile(requestURL, targetFiles);
          //console.log("response file multiple---->", response);

          if (response.status == true) {
            let fileResponseId = response.data.map((data) => {
              return data.id;
            });
            setFileIds(fileResponseId);
          } else {
            console.log("Error in file response");
          }

          const targetFilesObject = [...targetFiles]; // store array of those files

          // check condition whether file array is empty or not
          if (media.length == 0) {
            targetFilesObject.map((file) => {
              return selectedFIles.push(file);
            });

            setMedia(selectedFIles); // changing state of files array
          } else {
            targetFilesObject.map((file) => {
              return selectedFIles.push(file);
            });

            // changing state of files array with previous file array values
            setMedia((media) => [...media, ...selectedFIles]);
          }
          setIsUploading(false);
        } catch (error) {
          console.log("Error in file upload----->", error);
        }
      } else {
        // console.log("Image must be lower then 10mb");
        setFileIsBig(true);
      }
    } else {
      return;
    }
  };

  // function for removing files from upload section
  const closeFileHandler = (index) => {
    const closedFile = [...media];
    closedFile.splice(index, 1);
    setMedia(closedFile);
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("inputGroupFile02");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //---------------------- Function to submit the question --------------------
  const saveQuestion = async () => {
    if (title == "" || editorValue == "") {
      if (title == "") {
        setTitleError(true);
      } else {
        setTitleError(false);
      }
      if (editorValue == "") {
        setDetailsError(true);
      } else {
        setDetailsError(false);
      }
    } else {
      setTitleError(false);
      setDetailsError(false);
      setIsSubmiting(true);
      let requestURL =
        url.API_BaseUrl + url.API_ACTIVITY_CREATE_POST + `?token=${token}`;

      let questionData = {
        token,
        message: editorValue,
        title,
        contacts: contactData,
        groups: groupData,
        events: eventData,
        tags: tagData,
        categories: categoryData,
        media: fileIds,
      };

      // console.log("question data before post------>", questionData);

      try {
        const response = await postFormdata(requestURL, questionData);
        // console.log("response ask question details----->", response);

        if (response.status == true) {
          props.loadList();
        } else {
          console.log("response error in ask question");
        }
        togglePost();
        resetPost();

        setIsSubmiting(false);
      } catch (error) {
        console.log("details page error", error);
        setIsSubmiting(false);
      }
    }
  };

  // reset function for reset all fields
  const resetPost = () => {
    setTitle("");
    setEditorValue("");
    optionalBlockReset();
  };

  // function for reset optional block
  const optionalBlockReset = () => {
    setContactData([]);
    setGroupData([]);
    setEventData([]);
    setTagData([]);
    setCategoryData([]);
    setFileIsBig(false);
    resetFile();
    setMedia([]);
    setSelectedContacts(null);
    setSelectedGroups(null);
    setSelectedEvent(null);
    setUserSelectedCategories(null);
    setUserSelectedTags(null);
    setTitleError(false);
    setDetailsError(false);
  };

  // function for search post queries
  const searchPostHandler = () => {
    props.searchPostHandler(searchPostQuery);
    setSearchPostQuery("");
  };

  // function for refresh again
  const refreshActivity = () => {
    props.refreshActivity();
  };

  useEffect(() => {
    getTagsData();
    getCategories();
    fetchContact();
    fetchGroups();
    fetchEvents();
  }, []);

  return (
    <>
      {/*----- search top bar start -----*/}
      <div className="activity_srchTop">
        <div className="filter_activity">
          {/* ---- ask toggle button for toggling question block ----- */}
          <div className="share_quaetion_btn">
            <Link
              to="#"
              className="basic_btn"
              role="button"
              onClick={togglePost}
            >
              {t("Ask or Share Question")}
            </Link>
          </div>
          {/* ---- ask toggle button for toggling question block end ----- */}

          {/* ---- toggle button for toggling filter modal ----- */}
          <div className="actvty_filter_btn">
            <Link
              to="#"
              className="basic_btn activty_filter"
              data-bs-toggle="modal"
              data-bs-target="#filter_Mdl"
            >
              <i className="material-icons-round">filter_alt</i>
              <span>{t("Filters")}</span>
            </Link>
          </div>
          {/* ---- end of toggle button filter modal ----- */}

          <button
            className="basic_btn activity_refresh"
            onClick={refreshActivity}
          >
            <i className="material-icons-round">refresh</i>
            <span>{t("Refresh")}</span>
          </button>
        </div>

        {/* ---- search block for post queries ----- */}
        <div className="activity_srch">
          <input
            type="text"
            value={searchPostQuery}
            className="form-control"
            placeholder={t("Search by title or description")}
            onChange={(e) => setSearchPostQuery(e.target.value)}
          />
          <button
            type="submit"
            className="filtr_srchBtn"
            onClick={searchPostHandler}
          >
            <i className="material-icons-round">search</i>
          </button>
        </div>
        {/* ---- search block for post queries end ----- */}
      </div>
      {/*----- seact top bar end -----*/}

      {/*----- question panel start -----*/}

      <div className="activity_questionPnl" id="activity_question_panel1">
        <div className="activity_tagsouter">
          {/* ---- title section ---- */}
          <div className="activity_tagsRow">
            <h4>{t("Post Your Reply")} *</h4>
            <div className="reply_fill">
              <input
                type="text"
                className="form-control"
                placeholder={t(
                  "Be specific and imagine you are asking a question to another person"
                )}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
          {titleError && (
            <p className="text-danger mb-2">* {t("Please Fill up Title")}</p>
          )}
          {/* ---- title section end ---- */}

          {/* ----- details section ---- */}
          <div className="activity_tagsRow">
            <h4>{t("Details")} *</h4>
            <div className="editor_area">
              <div className="editor">
                {/* <img src="images/editor.png" alt="" /> */}
              </div>
              <div className="editor_msgArea">
                <CKEditor
                  className="form-control"
                  editor={ClassicEditor}
                  config={editorConfig}
                  data={editorValue ? editorValue : ""}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log("Editor is ready to use!", editor)
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "height",
                        "200px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  onChange={(event, editor) => {
                    let editContent = editor.getData();
                    //console.log(JSON.stringify(editContent));
                    setEditorValue(editContent);
                  }}
                />
              </div>
            </div>
          </div>
          {detailsError && (
            <p className="text-danger mb-3">* {t("Please Fill up Details")}</p>
          )}

          <h6
            className="text-primary mb-2"
            onClick={() => {
              setOptionalBlock(!optionalBlock);
              optionalBlockReset();
            }}
            style={{ cursor: "pointer" }}
          >
            {t("Add more info")} ...
          </h6>
          {optionalBlock && (
            <div>
              {/* ---- contact section ---- */}
              <div className="activity_tagsRow">
                <h4>{t("Contact")}</h4>
                <div className="select_bx">
                  <Select
                    styles={customSelectStyle}
                    isMulti={true}
                    value={selectedContacts}
                    onChange={(val) => {
                      contactHandler(val);
                    }}
                    options={contact}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </div>
              {/* ---- contact section end ---- */}

              {/* ---- group section ---- */}
              <div className="activity_tagsRow">
                <h4>{t("Group")}</h4>
                <div className="select_bx">
                  <Select
                    styles={customSelectStyle}
                    isMulti={true}
                    value={selectedGroups}
                    onChange={(val) => {
                      groupHandler(val);
                    }}
                    options={groupLists}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </div>
              {/* ---- group section end ---- */}

              {/* ---- Event section ---- */}
              <div className="activity_tagsRow">
                <h4>{t("Event")}</h4>
                <div className="select_bx">
                  <Select
                    styles={customSelectStyle}
                    isMulti={true}
                    value={selectedEvent}
                    onChange={(val) => {
                      eventHandler(val);
                    }}
                    options={event}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </div>
              {/* ---- Event section end ---- */}

              {/* ---- tag section ---- */}
              <div className="activity_tagsRow">
                <h4>{t("Tags")}</h4>
                <div className="select_bx">
                  <Select
                    styles={customSelectStyle}
                    isMulti={true}
                    value={userSelectedTags}
                    onChange={(val) => {
                      tagHandler(val);
                    }}
                    options={tagsValue}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </div>
              {/* ---- tag section end ---- */}

              {/* ---- category section ---- */}
              <div className="activity_tagsRow">
                <h4>{t("Categories")}</h4>
                <div className="select_bx">
                  <Select
                    styles={customSelectStyle}
                    isMulti={true}
                    value={userSelectedCategories}
                    onChange={(val) => {
                      categoryHandler(val);
                    }}
                    options={categoriesValue}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </div>
              {/* ---- category section end ---- */}

              {/* ---- file preview section ---- */}

              <div className="activity_tagsRow d-flex mb-5">
                {/* ----- this style is for temporary purpose please change it in CSS   ----- */}
                {media.map((files, index) => {
                  return (
                    <div key={index} className="uploaded-media">
                      {/* <button
                        type="button"
                        className="close close-btn"
                        aria-label="Close"
                        onClick={() => closeFileHandler(index)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button> */}
                      {files.type == "image/jpeg" ||
                      files.type == "image/png" ? (
                        <img
                          src={URL.createObjectURL(files)}
                          alt="ImageFile"
                          className="rounded"
                          height="150"
                          width="150"
                        />
                      ) : null}
                      {files.type == "text/plain" ||
                      files.type ==
                        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                        <img
                          src={DOCS}
                          alt="docs"
                          className="rounded"
                          height="150"
                          width="150"
                        />
                      ) : null}
                      {files.type ==
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                      files.type == "application/msword" ? (
                        <img
                          src={DOCS}
                          alt="docs"
                          className="rounded"
                          height="150"
                          width="150"
                        />
                      ) : null}
                      {files.type == "application/pdf" ? (
                        <img
                          src={PDF}
                          alt="docs"
                          className="rounded"
                          height="150"
                          width="150"
                        />
                      ) : null}
                      {files.type ==
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                      files.type == "application/vnd.ms-excel" ? (
                        <img
                          src={XLS}
                          alt="docs"
                          className="rounded"
                          height="150"
                          width="150"
                        />
                      ) : null}
                      <p className="text-center">
                        <span>{files.name}</span>
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="activity_tagsRow">
                {/* ----- loading area for file uploading ----- */}
                {isUploading && (
                  <div
                    className="d-flex align-items-center"
                    style={{ backgroundColor: "#044954" }}
                  >
                    <h7 className="text-white fw-bold">
                      {t("Uploading Files")}
                    </h7>
                    <div
                      className="spinner-grow mx-1 text-white"
                      style={{ height: "6px", width: "6px" }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div
                      className="spinner-grow mx-1 text-white"
                      style={{ height: "6px", width: "6px" }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div
                      className="spinner-grow mx-1 text-white"
                      style={{ height: "6px", width: "6px" }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div
                      className="spinner-grow mx-1 text-white"
                      style={{ height: "6px", width: "6px" }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div
                      className="spinner-grow mx-1 text-white"
                      style={{ height: "6px", width: "6px" }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div
                      className="spinner-grow mx-1 text-white"
                      style={{ height: "6px", width: "6px" }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div
                      className="spinner-grow mx-1 text-white"
                      style={{ height: "6px", width: "6px" }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
                {/* ----- loading area for file uploading end ----- */}
              </div>
              {/* ---- file preview section end ---- */}
              {fileIsBig ? (
                <div className="activity_tagsRow">
                  <h6 className="text-white bg-danger">
                    * {t("Uploaded File Size must be lower than 10 mb")}
                  </h6>
                </div>
              ) : null}

              {/* ----- file upload ------ */}
              <div className="activity_tagsRow">
                <h4>{t("File Upload")}</h4>
                <div className="reply_fill">
                  <input
                    type="file"
                    className="form-control"
                    id="inputGroupFile02"
                    onChange={multipleFileUploadHandler}
                    multiple
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {isSubmiting && (
          <div className="rounded mt-2">
            <div className="alert alert-info">
              {t("Submiting")}
              <span style={{ fontWeight: "bold" }}> {t("Your Question")} </span>
              {t("please wait")} ....
            </div>
          </div>
        )}

        <div className="question_btnArea">
          <button type="submit" className="basic_btn" onClick={saveQuestion}>
            {t("Submit your question")}
          </button>

          <button
            type="submit"
            className="basic_btn cancel_whiteBtn"
            onClick={togglePost}
          >
            {t("Cancel")}
          </button>
        </div>
      </div>

      {/*----- question panel end -----*/}
    </>
  );
};

export default ActivityFilter;
