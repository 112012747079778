/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";
/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// import images here
import blankImage from "assets/CourseListAssets/images/blank-img.jpg";

const CourseListArea = () => {
  const { t } = useTranslation();
  // saving user token here
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(1);

  const {
    searchQueryValues,
    searchTitle,
    searchSkills,
    searchCategory,
    resetCourseQuery,
    refreshCourseList,
  } = useContext(GlobalIndraInfo);

  // for routing
  const history = useHistory();

  // initial useState to save demo JSON data
  const [courseListData, setCourseListData] = useState(null);

  // Initial loading status
  const [isLoading, setIsLoading] = useState(false);

  // function to call the API and get all the courses
  const getPublishedCourseListData = async () => {
    let skillsValue = "";
    let categoryValue = "";

    setIsLoading(true);

    let requestURL =
      url.API_BaseUrl +
      url.API_COURSE_LIST +
      `?token=${token}` +
      `&page=${page}`;

    if (searchTitle != "") {
      requestURL = requestURL + `&query=${searchTitle}`;
    }

    if (searchSkills.length > 0) {
      searchSkills.map((data) => {
        skillsValue += `&skill[]=${data}`;
      });
      requestURL = requestURL + `${skillsValue}`;
    }

    if (searchCategory.length > 0) {
      searchCategory.map((data) => {
        categoryValue += `&category[]=${data}`;
        requestURL = requestURL + `${categoryValue}`;
      });
    }

    try {
      console.log(
        "requesting URL to get the list of all the courses ------>",
        requestURL
      );

      const response = await getData(requestURL);
      console.log("Received Response --------> ", response);

      if (response.status) {
        setCourseListData(response.data);
      } else {
        console.log("Some error occurred ------> ", response);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error of indra activity post is ----->", error);
    }
  };

  // function to go to the details page
  const goToCourseDetails = (e, id) => {
    e.preventDefault();
    // console.log("ID that we are changing is ----> ", id);

    // redirecting the user to the details page (Step 1)
    history.push(`/course/details/${id}`);
  };

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  // useEffect from here
  useEffect(() => {
    getPublishedCourseListData();
  }, [page]);

  // useeffect for get course lists using search query
  useEffect(() => {
    if (
      searchTitle != "" ||
      searchSkills.length > 0 ||
      searchCategory.length > 0
    ) {
      getPublishedCourseListData();
      searchQueryValues(null);
    }
  }, [searchTitle, searchSkills, searchCategory]);

  // useeffect for get course lists using refresh button
  useEffect(() => {
    if (refreshCourseList) {
      setPage(1);
      getPublishedCourseListData();
      resetCourseQuery(false);
    }
  }, [refreshCourseList]);

  return (
    <>
      <section className="champions_area">
        <div className="container">
          {isLoading ? (
            <div className="champions_outer">
              <div className="row">
                {/* --------- Glow placeholder component starts here --------- */}
                {[1, 2, 3].map((i) => (
                  <div className="col-lg-4 col-sm-6" key={i}>
                    <div className="course_item">
                      <div className="champions_pic">
                        <img src={blankImage} alt="" />
                      </div>
                      <div className="plan p-3 border rounded-3 placeholder-glow">
                        <div className="user_placeHoledr">
                          <div className="default_usr_name">
                            <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                            <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                          </div>
                        </div>
                        <div className="data_btnOuter">
                          <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                        </div>
                        <p className="data_btnOuter placeholder-glow">
                          <span className="placeholder placeholder-sm col-3 rounded-3 ml-0"></span>
                          <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                          <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                        </p>
                        <div className="data_btnOuter">
                          <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* --------- Glow placeholder component ends here --------- */}
              </div>
            </div>
          ) : (
            <div className="champions_outer">
              {courseListData && (
                <div className="row">
                  {/* ------------ Mapping starts here ------------ */}
                  {courseListData.map((course) => (
                    <div className="col-lg-4 col-sm-6" key={course.id}>
                      <div className="course_item">
                        {/* ------- Course image section ------ */}
                        <div className="champions_pic">
                          <img src={course.image} alt="courseBanner" />
                        </div>
                        <div className="champions_textpnl">
                          {/* ------- Course author & rating section ------ */}
                          <div className="course_hdngauthor">
                            <h4>{course.moderator}</h4>
                            <div className="chappions_rating">
                              <Link to="#">
                                <i className="material-icons-outlined">star</i>
                                <span>4</span>
                                <em>(2,149)</em>
                              </Link>
                            </div>
                          </div>

                          {/* ------- Course name section ------ */}
                          <div className="leftchampion_text">
                            <h3>{course.label}</h3>
                          </div>

                          {/* ------- Course Categories list section ------ */}
                          <div className="experts_skill">
                            <ul>
                              {course.categories.map((item) => (
                                <li key={item.id}>
                                  <Link to="#">
                                    <span>{item.label}</span>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>

                          {/* ------- Course details section ------ */}
                          <div className="details_btn">
                            <Link
                              to="#"
                              className="basic_btn ylw_btn"
                              onClick={(e) => goToCourseDetails(e, course.id)}
                            >
                              {t("See Details")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* ------------ Mapping ends here ------------ */}

                  {/* pagination */}
                  <div className="pagination_list">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">
                            arrow_back_ios
                          </i>
                        </Link>
                      </li>
                      <li className={page == 1 ? "active" : ""}>
                        <Link to="#" onClick={() => nextPage(1)}>
                          1
                        </Link>
                      </li>
                      <li className={page == 2 ? "active" : ""}>
                        <Link to="#" onClick={() => nextPage(2)}>
                          2
                        </Link>
                      </li>
                      <li className={page == 3 ? "active" : ""}>
                        <Link to="#" onClick={() => nextPage(3)}>
                          3
                        </Link>
                      </li>
                      <li className={page == 4 ? "active" : ""}>
                        <Link to="#" onClick={() => nextPage(4)}>
                          4
                        </Link>
                      </li>
                      <li className={page == 5 ? "active" : ""}>
                        <Link to="#" onClick={() => nextPage(5)}>
                          5
                        </Link>
                      </li>
                      <li className={page == 6 ? "active" : ""}>
                        <Link to="#" onClick={() => nextPage(6)}>
                          6
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">
                            arrow_forward_ios
                          </i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default CourseListArea;
