import React, { useEffect } from "react";

//import custom components
import Header from "common/Header";
import Banner from "components/CourseListComponents/Banner";
import TabArea from "common/tabarea/TabArea";
import CourseListArea from "components/CourseListComponents/CourseListArea";
import Footer from "common/Footer";

const CourseList = () => {
  useEffect(() => {
    document.title = "Courses";
  }, []);

  return (
    <>
      <Header />
      <Banner />
      <TabArea module={"course"} />
      <CourseListArea />
      <Footer />
    </>
  );
};

export default CourseList;
