/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalIndraInfo } from "App";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const Step5 = (props) => {
  // for routing
  const history = useHistory();

  // Getting the course ID created in Step 1
  const { courseId, onConfirmStep5 } = useContext(GlobalIndraInfo);

  // Initial useState for Notification component
  const [isSubmittingData, setIsSubmittingData] = useState(false);

  // accessing the user's token here
  const token = localStorage.getItem("token");

  const [checked, setChecked] = useState(false);
  const [checkval, setCheckval] = useState("");

  // toast property
  const toastOptions = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    // theme: "colored",
  };

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // initial state for step six data
  const [stepFiveData, setStepFiveData] = useState({
    welcomemsg: "",
    thanksmsg: "",
    subscribermail: 0,
  });

  // initial state for saving returned data from API
  const [savedData, setSavedData] = useState();

  // Function to toggle subscribe mail checkbox
  const checkBoxToggler = (value) => {
    if (value) {
      setStepFiveData({ ...stepFiveData, subscribermail: 1 });
    } else {
      setStepFiveData({ ...stepFiveData, subscribermail: 0 });
    }
  };

  /*---- function for go to next step -----*/
  const nextStepHandler = async (e) => {
    e.preventDefault();

    // console.log("step one data ----->", stepOneData);
    if (stepFiveData.welcomemsg == "" || stepFiveData.thanksmsg == "") {
      toast.warn(`${t("Fill all the data")}`, toastOptions);
    } else {
      setIsSubmittingData(true);
      let requestURL =
        url.API_BaseUrl +
        url.API_STEP_5_COURSE_CREATE +
        `?token=${token}` +
        `&cid=${courseId}`;

      //console.log("Step 5 sending request --------> ", requestURL);

      let stepFiveState = true;

      try {
        const response = await postData(requestURL, stepFiveData);

        // console.log("Step 5 receiving response --------> ", response);

        if (response.status) {
          console.log("Data submitted successfully", response);
          onConfirmStep5(stepFiveState);
          setIsSubmittingData(false);
          history.push("/course/add/step6");
        } else {
          console.log("Some error occurred while submitting the data!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  /*---- end of function for go to next step -----*/

  /*---- function to fetch the course details saved in step 1 -----*/
  const viewCourseDetailsHandler = async (id) => {
    const requestURL =
      url.API_BaseUrl +
      url.API_STEP_5_COURSE_VIEW +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      const response = await getData(requestURL);
      // console.log("step one response----->", response);
      if (response.status) {
        setSavedData(response.data);

        if (response.data.subscribermail) {
          setChecked(true);
          setStepFiveData({
            ...stepFiveData,
            welcomemsg: response.data.welcomemsg,
            thanksmsg: response.data.thanksmsg,
            subscribermail: 1,
          });
        } else {
          setChecked(false);
          setStepFiveData({
            ...stepFiveData,
            welcomemsg: response.data.welcomemsg,
            thanksmsg: response.data.thanksmsg,
            subscribermail: 0,
          });
        }
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }

    // setup preview of video
  };
  /*---- end of function to fetch the course details saved in step 1 -----*/

  /* Function to redirect to the next page without calling the API */
  const goToNextPageHandler = (e) => {
    e.preventDefault();
    history.push("/course/add/step6");
    let stepFiveState = true;
    onConfirmStep5(stepFiveState);
  };

  /* Function to redirect to the previous page without calling the API */
  const goToPreviousPageHandler = (e) => {
    e.preventDefault();
    history.push("/course/add/Step4");
    let stepFiveState = false;
    onConfirmStep5(stepFiveState);
  };

  /* useEffect function from here */
  useEffect(() => {
    let stepFiveState = false;
    if (courseId != null) {
      stepFiveState = true;
      // console.log("Step 1 ------> Course ID is empty");
      viewCourseDetailsHandler(courseId);
    }
    onConfirmStep5(stepFiveState);
  }, [courseId]);

  return (
    <>
      <div className="col-lg-9">
        {/* --------------- Unknown back arrow button starts here --------------- */}
        <div className="back_progress">
          <div className="page_back">
            <Link to="#">
              <span className="material-icons-outlined">arrow_back</span>
              {t("Back")}
            </Link>
          </div>
          <div className="step_progress">
            <span className="progress_bar" style={{ width: "65%" }}></span>
          </div>
        </div>
        {/* --------------- Unknown back arrow button ends here --------------- */}

        {/* --------------- Form section starts here --------------- */}
        <div className="course_uploadrghtpnl">
          <div className="course_itemInnr">
            <div className="course_stepItem">
              <div className="coursestep_hdng msg_hdng">
                <h3>{t("Welcome Message")}</h3>
              </div>

              {/* ------------ Welcome message text area starts here ------------ */}
              <div className="">
                <textarea
                  className="form-control msg_bxArea"
                  rows={5}
                  placeholder={t("Enter welcome message")}
                  name="welcomemsg"
                  value={stepFiveData.welcomemsg ? stepFiveData.welcomemsg : ""}
                  onChange={(e) =>
                    setStepFiveData({
                      ...stepFiveData,
                      welcomemsg: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              {/* ------------ Welcome message text area ends here ------------ */}
            </div>

            {/* ------------ Thankyou message text area starts here ------------ */}
            <div className="course_stepItem">
              <div className="coursestep_hdng msg_hdng">
                <h3>{t("Congratulations Message")}</h3>
              </div>
              <div className="">
                <textarea
                  className="form-control msg_bxArea"
                  rows={5}
                  placeholder={t("Enter congratulations message")}
                  value={stepFiveData.thanksmsg ? stepFiveData.thanksmsg : ""}
                  onChange={(e) =>
                    setStepFiveData({
                      ...stepFiveData,
                      thanksmsg: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
            {/* ------------ Thankyou message text area ends here ------------ */}
          </div>

          {/* ------------ Checkbox check area starts here ------------ */}
          <div className="subscribe_chk">
            <label className="radio_circle">
              <input
                type="checkbox"
                // checked={checked}
                defaultChecked={checkval}
                onChange={(e) => checkBoxToggler(e.target.checked)}
              />
              <span></span>
              <i>{t("Also send a mail to the subscriber")}</i>
            </label>
          </div>
          {/* ------------ Checkbox check area ends here ------------ */}

          {isSubmittingData && (
            <div className="rounded">
              <div className="alert alert-info">
                Submitting data
                {t("please wait")} ....
              </div>
            </div>
          )}

          {/* ---- go to next & previous step button area ---- */}
          <div className="nxt_prvbtn_Outer">
            {/* ---- go to previous step button area ---- */}
            <div className="prev_Btn">
              <Link
                to="#"
                className="border_btn"
                onClick={(e) => {
                  goToPreviousPageHandler(e);
                }}
              >
                {t("Back to previous")}
              </Link>
            </div>

            {/* ---- go to next step button area ---- */}
            <div className="next_Btn">
              <Link
                to="#"
                className="border_btn"
                role="button"
                onClick={(e) => {
                  nextStepHandler(e);
                }}
              >
                {t("Save & Continue")}
              </Link>
            </div>

            <div className="next_Btn">
              <Link
                to="#"
                className="basic_btn"
                onClick={(e) => {
                  goToNextPageHandler(e);
                }}
              >
                {t("Go to Next Step")}
              </Link>
            </div>
          </div>
        </div>
        {/* --------------- Form section ends here --------------- */}
      </div>
      <ToastContainer />
    </>
  );
};

export default Step5;
