/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const ViewEmailPopupModal = (props) => {
  // accessing the user's token here
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // initial useState to store email details from API
  const [emailDetails, setEmailDetails] = useState({});

  // API call to fetch conversations list
  const getEmailDetails = async (id) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_VIEW_EMAIL_DETAILS +
      `?token=${token}` +
      `&mid=${id}`;

    //console.log("ViewEmailPopupModal request url -----> ", requestURL);

    try {
      const response = await getData(requestURL);

      //console.log("View email pop up response----->", response);

      if (response.status) {
        //console.log("response is----->", response.data);
        setEmailDetails(response.data);
      } else {
        // console.log("Some error occurred!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Console logging the received mail id via props
    //console.log("View Email Id -------> ", props.assignedMailId);

    // calling the API to get the details of the mail
    getEmailDetails(props.assignedMailId);
  }, [props.assignedMailId]);

  return (
    <>
      {/* -------------------------- Modal starts here -------------------------- */}
      <div className="chat_modalbx email_detailsmdl_Bx">
        <div className="modal fade" id="emaildetails_mdl">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header mdl_hdng">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="material-icons-round">close </span>
                </button>
              </div>
              <div className="modal-body email_details_body">
                <div className="details_mdlHdr">
                  <div className="mdl_logo">
                    <Link to="#">
                      <img src="images/logo.svg" />
                    </Link>
                  </div>
                  <div className="mdl_logo_right">
                    <ul>
                      <li>
                        <Link to="#">{emailDetails.created}</Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">language</i>
                          <span>{emailDetails.senderemail}</span>
                          {/* <div>
                            {emailDetails.senderimage != null ? (
                              <img
                                src={emailDetails.senderimage}
                                className="rounded-circle"
                                alt="SenderPic"
                                height="50"
                                width="50"
                              />
                            ) : null}
                          </div> */}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="email_details_bx">
                  <h3>{emailDetails.subject}</h3>
                  {/* <p>Greetings from indra.com!</p> */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: emailDetails.description,
                    }}
                  ></p>
                  {/* <p>Please find the activation code below:</p>
                  <h4>
                    Activation Code :{" "}
                    <span>{Math.floor(Math.random() * 10000)}</span>
                  </h4> */}

                  <div className="goodluck_text">
                    <h5>
                      <span>{emailDetails.sender}</span>
                    </h5>
                  </div>
                </div>
                <div className="details_footer">
                  <div className="mdl_footer_top">
                    <Link to="#">www.indra.com</Link>
                  </div>
                  <div className="mdl_footer_bttm">
                    {/* <p>Mail Date: {emailDetails.created}</p> */}
                    <p>24x7 Customer Support | Flexible Payment Options</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------- Modal ends here -------------------------- */}
    </>
  );
};

export default ViewEmailPopupModal;
