import React from "react";
import { Link } from "react-router-dom";
import SimilarGroups from "../common/SimilarGroups";

const Members = () => {
  return (
    <div className="tab-pane fade show active" id="evnt_members">
      <div className="training_outer">
        <div className="row">
          <div className="col-lg-8">
            <div className="course_detailspnl">
              <div className="contnt_hdng tab_Hdng">
                <h4>Members</h4>
              </div>
              <div className="champions_outer">
                <div className="row">
                  <div className="col-xl-4 col-6">
                    <div className="champions_item">
                      <div className="champions_pic">
                        <img src="images/champions1.jpg" alt="" />
                      </div>
                      <div className="champions_textpnl">
                        <div className="champions_txtOuter">
                          <div className="leftchampion_text">
                            <h3>Pierre Gagnaire</h3>
                            <h5>Sales Manager</h5>
                          </div>
                          <div className="chappions_rating">
                            <Link to="#">
                              <i className="material-icons-outlined">star</i>
                              <span>4.8</span>
                            </Link>
                          </div>
                        </div>
                        <div className="details_btn">
                          <Link
                            to="champions_details.html"
                            className="basic_btn ylw_btn"
                          >
                            See Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-6">
                    <div className="champions_item">
                      <div className="champions_pic">
                        <img src="images/champions2.jpg" alt="" />
                      </div>
                      <div className="champions_textpnl">
                        <div className="champions_txtOuter">
                          <div className="leftchampion_text">
                            <h3>Pierre Gagnaire</h3>
                            <h5>Sales Manager</h5>
                          </div>
                          <div className="chappions_rating">
                            <Link to="#">
                              <i className="material-icons-outlined">star</i>
                              <span>4.8</span>
                            </Link>
                          </div>
                        </div>
                        <div className="details_btn">
                          <Link
                            to="champions_details.html"
                            className="basic_btn ylw_btn"
                          >
                            See Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-6">
                    <div className="champions_item">
                      <div className="champions_pic">
                        <img src="images/champions3.jpg" alt="" />
                      </div>
                      <div className="champions_textpnl">
                        <div className="champions_txtOuter">
                          <div className="leftchampion_text">
                            <h3>Pierre Gagnaire</h3>
                            <h5>Sales Manager</h5>
                          </div>
                          <div className="chappions_rating">
                            <Link to="#">
                              <i className="material-icons-outlined">star</i>
                              <span>4.8</span>
                            </Link>
                          </div>
                        </div>
                        <div className="details_btn">
                          <Link
                            to="champions_details.html"
                            className="basic_btn ylw_btn"
                          >
                            See Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-6">
                    <div className="champions_item">
                      <div className="champions_pic">
                        <img src="images/champions4.jpg" alt="" />
                      </div>
                      <div className="champions_textpnl">
                        <div className="champions_txtOuter">
                          <div className="leftchampion_text">
                            <h3>Pierre Gagnaire</h3>
                            <h5>Sales Manager</h5>
                          </div>
                          <div className="chappions_rating">
                            <Link to="#">
                              <i className="material-icons-outlined">star</i>
                              <span>4.8</span>
                            </Link>
                          </div>
                        </div>
                        <div className="details_btn">
                          <Link
                            to="champions_details.html"
                            className="basic_btn ylw_btn"
                          >
                            See Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-6">
                    <div className="champions_item">
                      <div className="champions_pic">
                        <img src="images/champions3.jpg" alt="" />
                      </div>
                      <div className="champions_textpnl">
                        <div className="champions_txtOuter">
                          <div className="leftchampion_text">
                            <h3>Pierre Gagnaire</h3>
                            <h5>Sales Manager</h5>
                          </div>
                          <div className="chappions_rating">
                            <Link to="#">
                              <i className="material-icons-outlined">star</i>
                              <span>4.8</span>
                            </Link>
                          </div>
                        </div>
                        <div className="details_btn">
                          <Link to="#" className="basic_btn ylw_btn">
                            See Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-6">
                    <div className="champions_item">
                      <div className="champions_pic">
                        <img src="images/champions4.jpg" alt="" />
                      </div>
                      <div className="champions_textpnl">
                        <div className="champions_txtOuter">
                          <div className="leftchampion_text">
                            <h3>Pierre Gagnaire</h3>
                            <h5>Sales Manager</h5>
                          </div>
                          <div className="chappions_rating">
                            <Link to="#">
                              <i className="material-icons-outlined">star</i>
                              <span>4.8</span>
                            </Link>
                          </div>
                        </div>
                        <div className="details_btn">
                          <Link to="#" className="basic_btn ylw_btn">
                            See Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-6">
                    <div className="champions_item">
                      <div className="champions_pic">
                        <img src="images/champions1.jpg" alt="" />
                      </div>
                      <div className="champions_textpnl">
                        <div className="champions_txtOuter">
                          <div className="leftchampion_text">
                            <h3>Pierre Gagnaire</h3>
                            <h5>Sales Manager</h5>
                          </div>
                          <div className="chappions_rating">
                            <Link to="#">
                              <i className="material-icons-outlined">star</i>
                              <span>4.8</span>
                            </Link>
                          </div>
                        </div>
                        <div className="details_btn">
                          <Link to="#" className="basic_btn ylw_btn">
                            See Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-6">
                    <div className="champions_item">
                      <div className="champions_pic">
                        <img src="images/champions2.jpg" alt="" />
                      </div>
                      <div className="champions_textpnl">
                        <div className="champions_txtOuter">
                          <div className="leftchampion_text">
                            <h3>Pierre Gagnaire</h3>
                            <h5>Sales Manager</h5>
                          </div>
                          <div className="chappions_rating">
                            <Link to="#">
                              <i className="material-icons-outlined">star</i>
                              <span>4.8</span>
                            </Link>
                          </div>
                        </div>
                        <div className="details_btn">
                          <Link to="#" className="basic_btn ylw_btn">
                            See Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-6">
                    <div className="champions_item">
                      <div className="champions_pic">
                        <img src="images/champions1.jpg" alt="" />
                      </div>
                      <div className="champions_textpnl">
                        <div className="champions_txtOuter">
                          <div className="leftchampion_text">
                            <h3>Pierre Gagnaire</h3>
                            <h5>Sales Manager</h5>
                          </div>
                          <div className="chappions_rating">
                            <Link to="#">
                              <i className="material-icons-outlined">star</i>
                              <span>4.8</span>
                            </Link>
                          </div>
                        </div>
                        <div className="details_btn">
                          <Link to="#" className="basic_btn ylw_btn">
                            See Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <SimilarGroups />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Members;
