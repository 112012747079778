/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const Details = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  // 👇️ get ID from url
  const params = useParams();

  // Initial useState to save all the incoming data from API.
  const [jobDetailsData, setJobDetailsData] = useState(null);

  // Function to call the API and get all the data
  const getJobDetailsData = async (id) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_CHALLENGES_DETAILS_DETAILS_TAB +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      // console.log(
      //   "requesting URL to get all the job details ------> ",
      //   requestURL
      // );

      const response = await getData(requestURL);
      console.log(
        "Received Response in challenge details tab --------> ",
        response
      );

      if (response.status) {
        setJobDetailsData(response.data);
      } else {
        console.log("Some error occurred ------> ", response);
      }
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
    }
  };

  // useEffect from here
  useEffect(() => {
    getJobDetailsData(params.id);
  }, []);

  return (
    <>
      {jobDetailsData == null ? (
        <div className="course_detailspnl">
          <div className="results_innr">
            <div className="results_item">
              <div className="results_para">
                <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
                <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
              </div>
            </div>
            <div className="results_item">
              <span
                className="placeholder d-block rounded-2 col-1 my-3"
                style={{ height: "20px" }}
              ></span>
              <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
              <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
              <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
            </div>
            <div className="results_item">
              <span
                className="placeholder d-block rounded-2 col-1 my-3"
                style={{ height: "20px" }}
              ></span>
              <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
              <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
              <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
            </div>
          </div>
        </div>
      ) : (
        <div className="course_detailspnl">
          <div className="results_innr">
            <div className="results_item">
              {jobDetailsData.details == "" ? (
                <div className="course_detailspnl">
                  <div className="results_innr">
                    <div className="results_item">
                      <div className="results_para">
                        <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                        <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
                        <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                      </div>
                    </div>
                    <div className="results_item">
                      <span
                        className="placeholder d-block rounded-2 col-1 my-3"
                        style={{ height: "20px" }}
                      ></span>
                      <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                      <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
                      <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                    </div>
                    <div className="results_item">
                      <span
                        className="placeholder d-block rounded-2 col-1 my-3"
                        style={{ height: "20px" }}
                      ></span>
                      <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                      <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
                      <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="results_para"
                  dangerouslySetInnerHTML={{
                    __html: jobDetailsData.details,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Details;
