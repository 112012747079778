import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SubscribedCourses = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="tab-pane show active fade" id="my_course">
        <div className="learning_coursePnl">
          <div className="contnt_hdng tab_Hdng">
            <h4>{t("Subscribed Courses")}</h4>
          </div>
          <div className="learning_course_outer">
            <div className="learn_cpurse_row">
              <div className="learn_courseleft_pnl">
                <div className="course_item">
                  <div className="champions_pic">
                    <img src="images/course-pic1.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="course_hdngauthor">
                      <h4>Harry McLaren</h4>
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                          <em>(3,940)</em>
                        </Link>
                      </div>
                    </div>
                    <div className="leftchampion_text">
                      <h3>System Administrator User Manual</h3>
                    </div>
                    <div className="experts_skill">
                      <ul>
                        <li>
                          <Link to="#">
                            <span>Big Tech</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>IT Gients</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>Technology</span>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="details_btn">
                      <Link to="#" className="basic_btn ylw_btn">
                        {t("See Details")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="learn_courseright_pnl">
                <div className="top_lesson_row">
                  <div className="course_lessonsbx">
                    <div className="lessons_hdng">
                      <h3>
                        Subscribed on: <span>15/17/2022</span>
                      </h3>
                    </div>
                    <div className="lessons_progress">
                      <div className="progres_hdng">
                        <h4>Completed:</h4>
                        <h5>48%</h5>
                      </div>
                      <div className="progess_outer">
                        <span
                          className="progress_bar"
                          style={{ width: "48%" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div className="course_lessonsbx">
                    <div className="lessons_task_hdng">
                      <h3>
                        <i className="material-icons-round">description</i>
                        Lessons:
                      </h3>
                      <h5>14</h5>
                    </div>
                    <div className="lessons_task_list">
                      <li>
                        Completed: <span>04</span>
                      </li>
                      <li>
                        Pending: <span>10</span>
                      </li>
                    </div>
                  </div>
                  <div className="course_lessonsbx">
                    <div className="lessons_task_hdng">
                      <h3>
                        <i className="material-icons-round">task</i> Tasks:
                      </h3>
                      <h5>64</h5>
                    </div>
                    <div className="lessons_task_list">
                      <li>
                        Completed: <span>34</span>
                      </li>
                      <li>
                        Pending: <span>30</span>
                      </li>
                    </div>
                  </div>
                  <div className="course_lessonsbx">
                    <div className="lessons_task_hdng">
                      <h3>
                        <i className="material-icons-round">help</i> Questions:
                      </h3>
                      <h5>44</h5>
                    </div>
                    <div className="lessons_task_list">
                      <li>
                        Pending: <span>24</span>
                      </li>
                      <li>
                        Answered: <span>20</span>
                      </li>
                    </div>
                  </div>
                </div>
                <div className="bttm_lesson_row">
                  <div className="lessons_accor_hdng">
                    <h3>Lesson: 02</h3>
                    <span className="material-icons-round"> expand_more</span>
                  </div>

                  <div className="top_lesson_row">
                    <div className="lessons_feedbackleftpnl">
                      <div className="course_rvw_row">
                        <div className="rating_hdng">
                          <h5>Give Ratings</h5>
                        </div>
                        <ul>
                          <li className="active_star">
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="commnts_sendArea">
                        <textarea
                          className="form-control"
                          placeholder="Write your query here"
                        ></textarea>
                        <div className="furmcmnt_btn">
                          <button className="cmnt_btn">
                            <span className="material-icons-outlined">
                              image
                            </span>
                          </button>
                          <button className="cmnt_btn">
                            <span className="material-icons-outlined">
                              send
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="lessons_certificate_pnl">
                      <div className="lessons_task_list status_list">
                        <ul>
                          <li>
                            Total Score: <span>500</span>
                          </li>
                          <li>
                            Obtained Score: <span>380</span>
                          </li>
                          <li>
                            Passing Marks: <span>260</span>
                          </li>
                        </ul>
                      </div>
                      <div className="certificate_btn">
                        <Link to="#" className="basic_btn">
                          Download Certificate
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="learn_cpurse_row">
              <div className="learn_courseleft_pnl">
                <div className="course_item">
                  <div className="champions_pic">
                    <img src="images/course-pic2.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="course_hdngauthor">
                      <h4>Harry McLaren</h4>
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                          <em>(3,940)</em>
                        </Link>
                      </div>
                    </div>
                    <div className="leftchampion_text">
                      <h3>System Administrator User Manual</h3>
                    </div>
                    <div className="experts_skill">
                      <ul>
                        <li>
                          <Link to="#">
                            <span>Big Tech</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>IT Gients</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>Technology</span>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="details_btn">
                      <Link to="#" className="basic_btn ylw_btn">
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="learn_courseright_pnl">
                <div className="top_lesson_row">
                  <div className="course_lessonsbx">
                    <div className="lessons_hdng">
                      <h3>
                        Subscribed on: <span>15/17/2022</span>
                      </h3>
                    </div>
                    <div className="lessons_progress">
                      <div className="progres_hdng">
                        <h4>Completed:</h4>
                        <h5>48%</h5>
                      </div>
                      <div className="progess_outer">
                        <span
                          className="progress_bar"
                          style={{ width: "48%" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div className="course_lessonsbx">
                    <div className="lessons_task_hdng">
                      <h3>
                        <i className="material-icons-round">description</i>
                        Lessons:
                      </h3>
                      <h5>14</h5>
                    </div>
                    <div className="lessons_task_list">
                      <li>
                        Completed: <span>04</span>
                      </li>
                      <li>
                        Pending: <span>10</span>
                      </li>
                    </div>
                  </div>
                  <div className="course_lessonsbx">
                    <div className="lessons_task_hdng">
                      <h3>
                        <i className="material-icons-round">task</i> Tasks:
                      </h3>
                      <h5>64</h5>
                    </div>
                    <div className="lessons_task_list">
                      <li>
                        Completed: <span>34</span>
                      </li>
                      <li>
                        Pending: <span>30</span>
                      </li>
                    </div>
                  </div>
                  <div className="course_lessonsbx">
                    <div className="lessons_task_hdng">
                      <h3>
                        <i className="material-icons-round">help</i> Questions:
                      </h3>
                      <h5>44</h5>
                    </div>
                    <div className="lessons_task_list">
                      <li>
                        Pending: <span>24</span>
                      </li>
                      <li>
                        Answered: <span>20</span>
                      </li>
                    </div>
                  </div>
                </div>
                <div className="bttm_lesson_row">
                  <div className="lessons_accor_hdng">
                    <h3>Lesson: 02</h3>
                    <span className="material-icons-round"> expand_more</span>
                  </div>

                  <div className="top_lesson_row">
                    <div className="lessons_feedbackleftpnl">
                      <div className="course_rvw_row">
                        <div className="rating_hdng">
                          <h5>Give Ratings</h5>
                        </div>
                        <ul>
                          <li className="active_star">
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="commnts_sendArea">
                        <textarea
                          className="form-control"
                          placeholder="Write your query here"
                        ></textarea>
                        <div className="furmcmnt_btn">
                          <button className="cmnt_btn">
                            <span className="material-icons-outlined">
                              image
                            </span>
                          </button>
                          <button className="cmnt_btn">
                            <span className="material-icons-outlined">
                              send
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="lessons_certificate_pnl">
                      <div className="lessons_task_list status_list">
                        <ul>
                          <li>
                            Total Score: <span>500</span>
                          </li>
                          <li>
                            Obtained Score: <span>380</span>
                          </li>
                          <li>
                            Passing Marks: <span>260</span>
                          </li>
                        </ul>
                      </div>
                      <div className="certificate_btn">
                        <Link to="#" className="basic_btn">
                          Download Certificate
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="learn_cpurse_row">
              <div className="learn_courseleft_pnl">
                <div className="course_item">
                  <div className="champions_pic">
                    <img src="images/course-pic3.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="course_hdngauthor">
                      <h4>Harry McLaren</h4>
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                          <em>(3,940)</em>
                        </Link>
                      </div>
                    </div>
                    <div className="leftchampion_text">
                      <h3>System Administrator User Manual</h3>
                    </div>
                    <div className="experts_skill">
                      <ul>
                        <li>
                          <Link to="#">
                            <span>Big Tech</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>IT Gients</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>Technology</span>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="details_btn">
                      <Link to="#" className="basic_btn ylw_btn">
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="learn_courseright_pnl">
                <div className="top_lesson_row">
                  <div className="course_lessonsbx">
                    <div className="lessons_hdng">
                      <h3>
                        Subscribed on: <span>15/17/2022</span>
                      </h3>
                    </div>
                    <div className="lessons_progress">
                      <div className="progres_hdng">
                        <h4>Completed:</h4>
                        <h5>48%</h5>
                      </div>
                      <div className="progess_outer">
                        <span
                          className="progress_bar"
                          style={{ width: "48%" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div className="course_lessonsbx">
                    <div className="lessons_task_hdng">
                      <h3>
                        <i className="material-icons-round">description</i>
                        Lessons:
                      </h3>
                      <h5>14</h5>
                    </div>
                    <div className="lessons_task_list">
                      <li>
                        Completed: <span>04</span>
                      </li>
                      <li>
                        Pending: <span>10</span>
                      </li>
                    </div>
                  </div>
                  <div className="course_lessonsbx">
                    <div className="lessons_task_hdng">
                      <h3>
                        <i className="material-icons-round">task</i> Tasks:
                      </h3>
                      <h5>64</h5>
                    </div>
                    <div className="lessons_task_list">
                      <li>
                        Completed: <span>34</span>
                      </li>
                      <li>
                        Pending: <span>30</span>
                      </li>
                    </div>
                  </div>
                  <div className="course_lessonsbx">
                    <div className="lessons_task_hdng">
                      <h3>
                        <i className="material-icons-round">help</i> Questions:
                      </h3>
                      <h5>44</h5>
                    </div>
                    <div className="lessons_task_list">
                      <li>
                        Pending: <span>24</span>
                      </li>
                      <li>
                        Answered: <span>20</span>
                      </li>
                    </div>
                  </div>
                </div>
                <div className="bttm_lesson_row">
                  <div className="lessons_accor_hdng">
                    <h3>Lesson: 02</h3>
                    <span className="material-icons-round"> expand_more</span>
                  </div>

                  <div className="top_lesson_row">
                    <div className="lessons_feedbackleftpnl">
                      <div className="course_rvw_row">
                        <div className="rating_hdng">
                          <h5>Give Ratings</h5>
                        </div>
                        <ul>
                          <li className="active_star">
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="commnts_sendArea">
                        <textarea
                          className="form-control"
                          placeholder="Write your query here"
                        ></textarea>
                        <div className="furmcmnt_btn">
                          <button className="cmnt_btn">
                            <span className="material-icons-outlined">
                              image
                            </span>
                          </button>
                          <button className="cmnt_btn">
                            <span className="material-icons-outlined">
                              send
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="lessons_certificate_pnl">
                      <div className="lessons_task_list status_list">
                        <ul>
                          <li>
                            Total Score: <span>500</span>
                          </li>
                          <li>
                            Obtained Score: <span>380</span>
                          </li>
                          <li>
                            Passing Marks: <span>260</span>
                          </li>
                        </ul>
                      </div>
                      <div className="certificate_btn">
                        <Link to="#" className="basic_btn">
                          Download Certificate
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="learn_cpurse_row">
              <div className="learn_courseleft_pnl">
                <div className="course_item">
                  <div className="champions_pic">
                    <img src="images/course-pic2.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="course_hdngauthor">
                      <h4>Harry McLaren</h4>
                      <div className="chappions_rating">
                        <Link to="#">
                          <i className="material-icons-outlined">star</i>
                          <span>4.8</span>
                          <em>(3,940)</em>
                        </Link>
                      </div>
                    </div>
                    <div className="leftchampion_text">
                      <h3>System Administrator User Manual</h3>
                    </div>
                    <div className="experts_skill">
                      <ul>
                        <li>
                          <Link to="#">
                            <span>Big Tech</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>IT Gients</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>Technology</span>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="details_btn">
                      <Link to="#" className="basic_btn ylw_btn">
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="learn_courseright_pnl">
                <div className="top_lesson_row">
                  <div className="course_lessonsbx">
                    <div className="lessons_hdng">
                      <h3>
                        Subscribed on: <span>15/17/2022</span>
                      </h3>
                    </div>
                    <div className="lessons_progress">
                      <div className="progres_hdng">
                        <h4>Completed:</h4>
                        <h5>48%</h5>
                      </div>
                      <div className="progess_outer">
                        <span
                          className="progress_bar"
                          style={{ width: "48%" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div className="course_lessonsbx">
                    <div className="lessons_task_hdng">
                      <h3>
                        <i className="material-icons-round">description</i>
                        Lessons:
                      </h3>
                      <h5>14</h5>
                    </div>
                    <div className="lessons_task_list">
                      <li>
                        Completed: <span>04</span>
                      </li>
                      <li>
                        Pending: <span>10</span>
                      </li>
                    </div>
                  </div>
                  <div className="course_lessonsbx">
                    <div className="lessons_task_hdng">
                      <h3>
                        <i className="material-icons-round">task</i> Tasks:
                      </h3>
                      <h5>64</h5>
                    </div>
                    <div className="lessons_task_list">
                      <li>
                        Completed: <span>34</span>
                      </li>
                      <li>
                        Pending: <span>30</span>
                      </li>
                    </div>
                  </div>
                  <div className="course_lessonsbx">
                    <div className="lessons_task_hdng">
                      <h3>
                        <i className="material-icons-round">help</i> Questions:
                      </h3>
                      <h5>44</h5>
                    </div>
                    <div className="lessons_task_list">
                      <li>
                        Pending: <span>24</span>
                      </li>
                      <li>
                        Answered: <span>20</span>
                      </li>
                    </div>
                  </div>
                </div>
                <div className="bttm_lesson_row">
                  <div className="lessons_accor_hdng">
                    <h3>Lesson: 02</h3>
                    <span className="material-icons-round"> expand_more</span>
                  </div>

                  <div className="top_lesson_row">
                    <div className="lessons_feedbackleftpnl">
                      <div className="course_rvw_row">
                        <div className="rating_hdng">
                          <h5>Give Ratings</h5>
                        </div>
                        <ul>
                          <li className="active_star">
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span className="material-icons-outlined">
                                star
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="commnts_sendArea">
                        <textarea
                          className="form-control"
                          placeholder="Write your query here"
                        ></textarea>
                        <div className="furmcmnt_btn">
                          <button className="cmnt_btn">
                            <span className="material-icons-outlined">
                              image
                            </span>
                          </button>
                          <button className="cmnt_btn">
                            <span className="material-icons-outlined">
                              send
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="lessons_certificate_pnl">
                      <div className="lessons_task_list status_list">
                        <ul>
                          <li>
                            Total Score: <span>500</span>
                          </li>
                          <li>
                            Obtained Score: <span>380</span>
                          </li>
                          <li>
                            Passing Marks: <span>260</span>
                          </li>
                        </ul>
                      </div>
                      <div className="certificate_btn">
                        <Link to="#" className="basic_btn">
                          Download Certificate
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="load_moreBtn">
            <Link to="#" className="basic_btn ylw_btn">
              {t("Load More")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscribedCourses;
