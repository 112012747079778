/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { useTranslation, Trans } from "react-i18next";
import { GlobalIndraInfo } from "App";

//import images
import defaultUser from "assets/HomeAssets/Images/default-user.png";

const UserMenu = () => {
  const history = useHistory();

  const { getCourseId } = useContext(GlobalIndraInfo);

  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  //console.log("user info in header user menu------>", userInfo);

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // Function for changing language
  function handleSelectedLanguage(val) {
    i18n.changeLanguage(val);
  }

  // function for log out
  const logoutHandler = () => {
    try {
      localStorage.clear();
      window.location.reload();
    } catch (error) {
      console.log("Could not clear data ", error);
    }
  };

  return (
    <div className="hdr_top">
      <div className="container">
        <div className="top_outer">
          {/* ----- menu link ----- */}
          <div className="top_menu">
            <ul>
              <li>
                <Link to="#">{t("Investor relations")}</Link>
              </li>
              <li>
                <Link to="#">{t("Press Room")}</Link>
              </li>
              <li>
                <Link to="#">{t("Where we are")}</Link>
              </li>
            </ul>
          </div>
          {/* ----- menu link end ----- */}

          {/* ----- language section ----- */}
          <div className="lang_slect_bx">
            <select
              className="select"
              name="languageSelector"
              onChange={(e) => handleSelectedLanguage(e.target.value)}
            >
              <option value="en">English</option>
              <option value="es">Spain</option>
            </select>
          </div>
          {/* ----- language section end ----- */}

          {/* ---- search section ----- */}
          <div className="hdr_srch">
            <input
              type="text"
              className="form-control"
              placeholder={t("Search")}
            />
            <button type="submit" className="srchsbmit_btn">
              <span className="material-icons-round">search</span>
            </button>
          </div>
          {/* ---- search section end ----- */}
          <div className="login_menuicon-Outer">
            {token == null ? (
              <div className="loginuser_area">
                <div className="login_menu">
                  <Link to="/login">
                    <i className="material-icons-outlined">account_circle</i>
                  </Link>
                </div>
              </div>
            ) : (
              <div className="loginuser_area">
                <ul>
                  <li className="dropdown">
                    <Link
                      to="#"
                      data-bs-toggle="dropdown"
                      className="usr_pnlBtn"
                    >
                      <div className="usr_text">
                        {t("Hello")}
                        {userInfo == null ? null : (
                          <span className="text-white"> {userInfo.name}</span>
                        )}
                      </div>
                      <div className="usr_pic">
                        {userInfo == null ? null : (
                          <img
                            src={userInfo.image ? userInfo.image : defaultUser}
                            alt="user"
                          />
                        )}
                      </div>
                    </Link>
                    <div className="menurightPnl dropdown-menu">
                      <div className="account_topUsr">
                        <div className="account_user_outer">
                          <div className="accont_usrPic">
                            {userInfo == null ? null : (
                              <img
                                src={
                                  userInfo.image ? userInfo.image : defaultUser
                                }
                                alt="user"
                              />
                            )}
                          </div>
                          <div className="accont_owner_name">
                            {userInfo == null ? null : <h3>{userInfo.name}</h3>}
                          </div>
                        </div>
                        <div className="logout_btn">
                          <Link to="#" role="button" onClick={logoutHandler}>
                            <span className="material-icons-outlined text-dark">
                              logout
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="afterlogin_menu">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="material-icons-outlined">
                                space_dashboard
                              </i>
                              <span>{t("Dashboard")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="material-icons-outlined">
                                account_circle
                              </i>
                              <span>{t("My profile")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/mycourses"
                              onClick={() => {
                                getCourseId(null);
                              }}
                            >
                              <i className="material-icons-outlined">
                                history_edu
                              </i>
                              <span>{t("My Courses")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/course/add/step1"
                              onClick={() => {
                                getCourseId(null);
                              }}
                            >
                              <i className="material-icons-outlined">book</i>
                              <span>{t("Add New Course")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/myinvitations">
                              <i className="material-icons-outlined">
                                connect_without_contact
                              </i>
                              <span>{t("My Connect Invites")}</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
