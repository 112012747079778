/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { GlobalIndraInfo } from "App";

//import components
import ContactModal from "components/ChampionsDetailsComponents/modals/ContactModal";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// import image assests here
import expert1 from "assets/ChampionsDetailsAssets/images/expert9.png";
import social1 from "assets/ChampionsDetailsAssets/svg/social1.svg";
import social2 from "assets/ChampionsDetailsAssets/svg/social2.svg";
import social3 from "assets/ChampionsDetailsAssets/svg/social3.svg";
import social4 from "assets/ChampionsDetailsAssets/svg/social4.svg";
import AddTagsModal from "components/ChampionsDetailsComponents/modals/AddTagModal";

const ChampionsDetailsTopSection = () => {
  const token = localStorage.getItem("token");
  const params = useParams();

  // getting search params from useContext
  const { getChampionName } = useContext(GlobalIndraInfo);

  //initial for champion data
  const [championData, setChampionData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // function for get champion details
  const getChampionsData = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_CHAMPION_BANNER +
      `?token=${token}` +
      `&uid=${id}`;

    try {
      console.log(
        "requesting URL to get the champion  details ------>",
        requestURL
      );

      const response = await getData(requestURL);

      console.log(
        "Received Response in champion details top --------> ",
        response
      );

      if (response.status) {
        setChampionData(response.data);
        let championName = response.data.name + " " + response.data.surname;

        // sending the name to the useContext API
        getChampionName(championName);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // function for calling API after closing add tag modal
  const afterModalClose = () => {
    getChampionsData(params.id);
  };

  // useEffect from here
  useEffect(() => {
    getChampionsData(params.id);
  }, [params.id]);

  return (
    <>
      {isLoading ? (
        <section className="course_details_sec placeholder-glow">
          {/* ---- glow more area start ---- */}
          <div className="container">
            <div className="course_detailsBx">
              <div className="topdetails_left">
                <div className="global_details_outer">
                  <div className="glbl_detailsPic">
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "90px", height: "90px" }}
                    ></span>
                  </div>
                  <div className="glbl_detailstext">
                    <div className="details_usrName_hdng">
                      <span
                        className="placeholder d-block rounded-3 mb-2"
                        style={{ width: "215px", height: " 26px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-md rounded-3"
                        style={{ width: "100px" }}
                      ></span>
                    </div>
                    <div className="details_tags expert_details">
                      <span
                        className="placeholder placeholder-md rounded-3 me-3"
                        style={{ width: " 142px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-md rounded-3 me-3"
                        style={{ width: "75px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-md rounded-3"
                        style={{ width: "90px" }}
                      ></span>
                    </div>
                    <div className="hire_btn">
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "108px", height: "50px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="topdetails_right block_rightPnl">
                <div className="asign_top">
                  <div className="exprt_share social_share">
                    <ul>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: " 40px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ---- glow more area end ---- */}
        </section>
      ) : (
        <section className="course_details_sec">
          <div className="container">
            {championData == null ? null : (
              <div className="course_detailsBx">
                {/* --------------------------- Left Section --------------------------- */}
                <div className="topdetails_left">
                  <div className="global_details_outer">
                    <div className="glbl_detailsPic">
                      <img src={championData.fullimage} alt="" />
                    </div>
                    <div className="glbl_detailstext">
                      <div className="details_usrName_hdng">
                        <h3>
                          {championData.name} {championData.surname}
                        </h3>
                        <h5>{championData.position}</h5>
                      </div>
                      <div className="details_tags expert_details">
                        <div className="jb_rating star_color">
                          <i
                            className="material-icons-outlined"
                            style={{ color: "#FBBB21" }}
                          >
                            star
                          </i>
                          <span style={{ color: "#FBBB21" }}>
                            {championData.rating}
                          </span>
                          <h6 style={{ color: "#FBBB21" }}>
                            ({championData.reviews}
                            <em> ratings</em>)
                          </h6>
                        </div>
                        <div className="course_text">
                          <h4 style={{ color: "#ffff" }}>
                            {championData.address}
                          </h4>
                        </div>
                        {/* <div className="course_text">
                          <i className="material-icons-outlined"> people</i>
                          <h4 style={{ color: "#ffff" }}>
                            {championData.enrolledCount} Enrolled
                          </h4>
                        </div> */}
                      </div>
                      {championData.connectdate && (
                        <p className="join_text">
                          Connect Date: {championData.connectdate}
                        </p>
                      )}
                      {championData.isconnect ? null : (
                        <div className="hire_btn">
                          <Link
                            to="#"
                            className="basic_btn ylw_btn"
                            data-bs-toggle="modal"
                            data-bs-target="#contact_owner"
                          >
                            Connect
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* --------------------------- Right Section --------------------------- */}
                <div className="topdetails_right block_rightPnl">
                  <div className="asign_top">
                    <div className="exprt_share social_share">
                      <ul>
                        <li>
                          <Link to="#">
                            <img src={social1} alt="" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <img src={social2} alt="" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <img src={social3} alt="" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <img src={social4} alt="" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#champtag_mdl"
                          >
                            <i className="material-icons-outlined">more</i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="material-icons-outlined">share</i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* --------- Tags list and dropdown start --------- */}
                  <div className="tags_menu">
                    {/* -------- Top 3 tags here -------- */}
                    {championData.tags.length > 0 ? (
                      <ul className="tags_list">
                        {championData.tags.slice(0, 3).map((tags, index) => {
                          return (
                            <li key={index}>
                              <Link to="#">{tags.label}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}

                    {/* -------- Remaining tags here in dropdown --------- */}
                    {championData.tags.length > 3 ? (
                      <div className="tags_drop dropdown">
                        <Link to="#" data-bs-toggle="dropdown">
                          <span className="material-icons-outlined">
                            expand_more
                          </span>
                        </Link>
                        {championData.tags.length > 0 ? (
                          <ul className="dropdown-menu">
                            {championData.tags
                              .slice(3, championData.tags.length)
                              .map((tags, index) => {
                                return (
                                  <li key={index}>
                                    <Link to="#">{tags.label}</Link>
                                  </li>
                                );
                              })}
                          </ul>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {/* --------- Tags list and dropdown end --------- */}
                </div>
              </div>
            )}
          </div>
        </section>
      )}
      <ContactModal
        championData={championData}
        getChampionsData={getChampionsData}
      />
      <AddTagsModal afterModalClose={afterModalClose} />
    </>
  );
};

export default ChampionsDetailsTopSection;
