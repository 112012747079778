/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

const Team = () => {
  const token = localStorage.getItem("token");
  const params = useParams();
  const glowCount = [1, 2, 3];

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // Initial useState to save the Teams array.
  const [teamData, setTeamData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // function for get team details
  const getTeamLists = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_VIEW_COURSE_DETAILS +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      // console.log("requesting URL to get lesson details ------>", requestURL);
      const response = await getData(requestURL);
      console.log(response);
      if (response.status) {
        setTeamData(response.data.members);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
    }
  };

  // useEffect for Features
  useEffect(() => {
    getTeamLists(params.id);
  }, [params.id]);

  // return from here
  return (
    <>
      {/* --------------- Team Body starts here --------------- */}
      <div className="tab-pane show active fade" id="team">
        <div className="course_detailspnl">
          <div className="member_box_outer">
            <div className="contnt_hdng tab_Hdng">
              <h4>{t("Members")}</h4>
            </div>
            {/* ------------ Cards section starts here ------------ */}
            {isLoading ? (
              <div className="member_box_outer">
                <div className="contnt_hdng tab_Hdng placeholder-glow">
                  <span className="placeholder placeholder-sm rounded-3 col-2"></span>
                </div>
                <div className="member_box_outer">
                  <div className="row">
                    {glowCount.map((count) => {
                      return (
                        <div className="col-lg-3 col-sm-6" key={count}>
                          <div className="expertbx_item members_teamItem">
                            <div
                              className="d-flex placeholder-glow"
                              style={{ minHeight: "80px" }}
                            >
                              <span
                                className="placeholder rounded-circle"
                                style={{ width: "40px", height: "40px" }}
                              ></span>
                              <div className="desc flex-fill ps-3">
                                <span className="placeholder placeholder-sm rounded-3 col-8 mb-3"></span>
                                <span className="placeholder placeholder-sm rounded-3 col-6 mb-3"></span>
                                <span className="placeholder placeholder-sm rounded-3 col-5"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="member_box_outer">
                {teamData.length == 0 ? null : (
                  <div className="row">
                    {/* --------------- Card --------------- */}
                    {teamData.map((team, index) => {
                      return (
                        <div
                          className="col-lg-3 col-sm-6"
                          key={team.membershipid}
                        >
                          <div className="expertbx_item members_teamItem">
                            <div className="membrs_teamOuter">
                              <div className="team_membrs_pic">
                                <img src={team.image} alt="" />
                              </div>
                              <div className="membrs_team_details">
                                <div className="team_name">
                                  <h3>{team.name}</h3>
                                  <h5>{team.position}</h5>
                                </div>
                                <div className="team_rating star_color">
                                  <h3>
                                    <i className="material-icons-outlined">
                                      star
                                    </i>
                                    <span>4.5</span>
                                  </h3>

                                  <h6>
                                    32 <span>(valoraciones)</span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            {/* ------------ Cards section ends here ------------ */}
          </div>
        </div>
      </div>
      {/* --------------- Team Body ends here --------------- */}
    </>
  );
};

export default Team;
