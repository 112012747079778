/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";

// import images here
import blankImage from "assets/CourseListAssets/images/blank-img.jpg";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

const PublishedCourses = () => {
  const { t } = useTranslation();
  // saving user token here
  const token = localStorage.getItem("token");

  // glow count
  const glowCount = [1, 2, 3];

  // importing useContext functions here
  const { getCourseId } = useContext(GlobalIndraInfo);

  // for routing
  const history = useHistory();

  // Initial useState to save all the incoming data from API.
  const [publishedCourseList, setPublishedCourseList] = useState();

  // Initial loading status
  const [isLoading, setIsLoading] = useState(false);

  const getPublishedCourseListData = async () => {
    let requestURL =
      url.API_BaseUrl + url.API_VIEW_PUBLISHED_COURSES + `?token=${token}`;

    setIsLoading(true);

    try {
      // console.log(
      //   "requesting URL to get all the published courses ------>",
      //   requestURL
      // );

      const response = await getData(requestURL);
      // console.log("Received Response --------> ", response);

      if (response.status) {
        setPublishedCourseList(response.data);
        setIsLoading(false);
      } else {
        console.log("Some error occurred ------> ", response);
      }
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
    }
  };

  // function to go to the edit page
  const goToEditDetails = (e, id) => {
    e.preventDefault();
    console.log("ID that we are changing is ----> ", id);

    // assigning the received id to useContext
    getCourseId(id);

    // redirecting the user to the edit page (Step 1)
    history.push("/course/add/step1");
  };

  // function to go to the details page
  const goToCourseDetails = (e, id) => {
    e.preventDefault();
    history.push(`/course/details/${id}`);
  };

  // useEffect from here
  useEffect(() => {
    getPublishedCourseListData();
  }, []);

  return (
    <>
      <div className="tab-pane show active fade" id="wishlist">
        <div className="learning_coursePnl">
          <div className="course_topouter">
            <div className="contnt_hdng tab_Hdng">
              <h4>{t("Published Courses")}</h4>
            </div>
            <div className="add_newBtn">
              <Link
                to="#"
                className="basic_btn ylw_btn"
                onClick={(e) => {
                  e.preventDefault();
                  getCourseId(null);
                  history.push("/course/add/step1");
                }}
              >
                <i className="material-icons-outlined">add</i>
                <span>{t("Add New")}</span>
              </Link>
            </div>
          </div>

          <div className="champions_outer">
            {/* ------------- Showing Bootstrap alert when data is being loaded ------------- */}
            {isLoading ? (
              <div className="row">
                {glowCount.map((count) => {
                  return (
                    <div className="col-lg-4 col-sm-6" key={count}>
                      <div className="course_item">
                        <div className="champions_pic">
                          <img src={blankImage} alt="" />
                        </div>
                        <div className="plan p-3 border rounded-3 placeholder-glow">
                          <div className="user_placeHoledr">
                            <div className="default_usr_name">
                              <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                              <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                            </div>
                          </div>
                          <div className="data_btnOuter">
                            <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                          </div>
                          <p className="data_btnOuter placeholder-glow">
                            <span className="placeholder placeholder-sm col-3 rounded-3 ml-0"></span>
                            <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                            <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                          </p>
                          <div className="data_btnOuter">
                            <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="row">
                {publishedCourseList
                  ? publishedCourseList.map((course, index) => {
                      return (
                        <div className="col-lg-4 col-sm-6" key={course.id}>
                          <div className="course_item">
                            {/* ----------- Course Banner starts here ----------- */}
                            {course.image == null ? null : (
                              <div className="champions_pic">
                                <img src={course.image} alt="courseBanner" />
                              </div>
                            )}
                            {/* ----------- Course Banner ends here ----------- */}

                            <div className="champions_textpnl">
                              {/* ----------- Moderator & Star rating starts here ----------- */}
                              <div className="course_hdngauthor">
                                <h4>{course.moderator}</h4>
                                <div className="chappions_rating">
                                  <Link to="#">
                                    <i className="material-icons-outlined">
                                      star
                                    </i>
                                    <span>4.8</span>
                                    <em>(3,940)</em>
                                  </Link>
                                </div>
                              </div>
                              {/* ----------- Moderator & Star rating ends here ----------- */}

                              {/* ----------- Course name starts here ----------- */}
                              <div className="leftchampion_text">
                                <h3>{course.label}</h3>
                              </div>
                              {/* ----------- Course name ends here ----------- */}

                              {/* ----------- Course tags mapping starts here ----------- */}
                              <div className="experts_skill">
                                <ul>
                                  {course.categories ||
                                  course.categories.length > 0
                                    ? course.categories.map((item, index) => {
                                        return (
                                          <li key={item.id}>
                                            <Link to="#">
                                              <span>{item.label}</span>
                                            </Link>
                                          </li>
                                        );
                                      })
                                    : null}
                                </ul>
                              </div>
                              {/* ----------- Course tags mapping ends here ----------- */}

                              {/* ----------- Course 'Show Details' starts here ----------- */}
                              <div className="details_btn coursebtn_outer">
                                <Link
                                  to="#"
                                  className="basic_btn ylw_btn"
                                  onClick={(e) =>
                                    goToCourseDetails(e, course.id)
                                  }
                                >
                                  {t("See Details")}
                                </Link>
                                <Link
                                  to="#"
                                  className="basic_btn ylw_btn"
                                  onClick={(e) => goToEditDetails(e, course.id)}
                                >
                                  {t("Update")}
                                </Link>
                              </div>
                              {/* ----------- Course 'Show Details' ends here ----------- */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            )}
            {/* ------------- Showing Bootstrap alert till here ------------- */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PublishedCourses;
