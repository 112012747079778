/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// for translation
import { useTranslation, Trans } from "react-i18next";

const StartNewChatModal = (props) => {
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  let contacts = props.contacts;

  //console.log("token---->", contactsInfo);

  const [searchName, setSearchName] = useState("");

  // initial useState to accept all the contacts from API
  const [allContacts, setAllContacts] = useState([]);

  /*--- function for search names by keywords ----*/
  function filterByName(name) {
    setSearchName(name);
  }

  /* function for get the new chat person info */
  const newChatHandler = (item) => {
    props.chatPerson(item); // passing info to parent component through props
    //document.body.classList.add("active");
    var allContactsModal = document.querySelector("#start_newchat");
    var modal = bootstrap.Modal.getInstance(allContactsModal);
    modal.hide();
  };

  return (
    <>
      <div className="chat_modalbx">
        <div className="modal fade" id="start_newchat">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {/* ---------- Modal Header starts here ---------- */}
              <div className="modal-header mdl_hdng">
                <h5 className="modal-title">
                  {t("Add Contact to Conversation")}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="material-icons-round">close </span>
                </button>
              </div>
              {/* ---------- Modal Header ends here ---------- */}

              {/* ---------- Modal Body starts here ---------- */}
              <div className="modal-body">
                <div className="group_srchfill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Search added contact")}
                    onChange={(e) => filterByName(e.target.value)}
                  />
                  {/* <Link to="#" className="add_icon">
                    <span className="material-icons-round">add_circle </span>
                  </Link> */}
                </div>

                {/* ----- all contacts area ----- */}
                <div className="group_innrScroll">
                  {contacts.map((item, index) => (
                    <div
                      className={`chat_usrRow  ${
                        item.label
                          .toLowerCase()
                          .includes(searchName.toLowerCase())
                          ? ""
                          : "hide"
                      }`}
                      key={index}
                      onClick={() => newChatHandler(item)}
                    >
                      <Link to="#">
                        <div className="usr_lftPnl">
                          <div className="chatting_user">
                            {item.image == null ? (
                              <span className="material-icons-round">
                                person
                              </span>
                            ) : (
                              <img src={item.image} alt="profile" />
                            )}
                          </div>
                          <div className="user_name">
                            <h3>{item.label}</h3>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
                {/* ----- end of all contacts area ----- */}
              </div>
              {/* ---------- Modal Body ends here ---------- */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartNewChatModal;
