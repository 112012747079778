import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//import custom comonents
import Header from "common/Header";
import Footer from "common/Footer";
import ChallengesDetailsTopSection from "components/ChallengesDetailsComponents/ChallengesDetailsTopSection";
import ChallengeDetailsBottomSection from "components/ChallengesDetailsComponents/ChallengeDetailsBottomSection";
import BreadCrumb from "components/ChallengesDetailsComponents/common/BreadCrumb";

const ChallengesDetails = () => {
  useEffect(() => {
    document.title = "Challenge-Details";
  }, []);
  return (
    <>
      <Header />
      <BreadCrumb />
      <ChallengesDetailsTopSection />
      <ChallengeDetailsBottomSection />
      <Footer />
    </>
  );
};

export default ChallengesDetails;
