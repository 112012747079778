/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { useTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalIndraInfo } from "App";

// url and get params in urls
import queryString from "query-string";

// importing custom components here
import Contents from "./Tabs/Contents";
import Questions from "./Tabs/Questions";
import Task from "./Tabs/Task";
import VideoModal from "./Modal/VideoModal";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const Step4 = (props) => {
  // Getting the course ID created in Step 1
  const {
    courseId,
    getLessonId,
    lessonId,
    lessonTitle,
    getLessonTitle,
    onConfirmStep4,
  } = useContext(GlobalIndraInfo);
  // accessing the user's token here
  const token = localStorage.getItem("token");
  const courseIdFromLocal = sessionStorage.getItem("courseid");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // for routing
  const history = useHistory();

  // toast property
  const toastOptions = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    // theme: "colored",
  };

  /*----- initial state for toggling tabs ------*/
  const [lessons, setLessons] = useState();
  const [contentsTab, setContentsTab] = useState(true);
  const [taskTab, setTaskTab] = useState(false);
  const [questionsTab, setQuestionsTab] = useState(false);
  const [resetAll, setResetAll] = useState(false);
  const [membersInstructor, setMembersInstructor] = useState([]);

  /*----- end of initial state for toggling tabs ------*/

  const [isLoading, setIsLoading] = useState(false);
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);

  // initial state for step four data
  const [stepFourData, setStepFourData] = useState({
    lessonid: "",
    title: "",
    lessons: [],
  });

  //initial state for display lesson details
  const [showLessonDetailsSection, setShowLessonDetailsSection] =
    useState(false);

  // funtion for display contents tab
  const showContentsTab = (e) => {
    e.preventDefault();
    setContentsTab(true);
    setTaskTab(false);
    setQuestionsTab(false);
  };

  // funtion for display tasks tab
  const showTaskTab = (e) => {
    e.preventDefault();
    setContentsTab(false);
    setTaskTab(true);
    setQuestionsTab(false);
  };

  // funtion for display questions tab
  const showQuestionsTab = (e) => {
    e.preventDefault();
    setContentsTab(false);
    setTaskTab(false);
    setQuestionsTab(true);
  };

  // calling API function to send lesson name
  const sendLessonNameHandler = async (e) => {
    e.preventDefault();
    if (stepFourData.title == "") {
      setShowEmptyMessage(true);
    } else {
      let lesson = { title: stepFourData.title };

      let requestURL =
        url.API_BaseUrl +
        url.API_STEP_4_SAVE_LESSON_NAME +
        `?token=${token}` +
        `&cid=${courseId}`;
      setShowEmptyMessage(false);
      setIsLoading(true);
      console.log("Step 4 Sending Request -------->", requestURL);

      try {
        const response = await postData(requestURL, lesson);

        if (response.status) {
          console.log(
            "Received Response in step 4 enter lession -------->",
            response
          );
          setIsLoading(false);
          viewLessonDetailsHandler(courseId);
        } else {
          setIsLoading(false);
          console.log("Some error occurred while submitting the data!");
          console.log("Received Response -------->", response);
        }
        setStepFourData({
          ...stepFourData,
          title: "",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  /*---- function for go to next step -----*/
  const nextStepHandler = async (e) => {
    e.preventDefault();
    let stepFourState = true;
    onConfirmStep4(stepFourState);
    history.push("/course/add/step5");
  };
  /*---- end of function for go to next step -----*/

  /*---- close function for popup close -----*/
  const closeHandler = (e) => {
    e.preventDefault();
    setStepFourData({
      ...stepFourData,
      title: "",
    });
    setResetAll(true);
    document.body.classList.remove("lessons_openpopup");
  };

  const afterClose = () => {
    setResetAll(false);
  };

  // function to list all the lessons added in the particular course
  // it is being called inside the 'sendLessonNameHandler' & useEffect.
  const viewLessonDetailsHandler = async (id) => {
    const requestURL =
      url.API_BaseUrl +
      url.API_STEP_4_COURSE_VIEW +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      const response = await getData(requestURL);
      console.log("step four response----->", response);
      if (response.status) {
        setLessons(response.data.lessons);
        setMembersInstructor(response.data.members);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }

    // setup preview of video
  };

  /* Function to redirect to the previous page without calling the API */
  const goToPreviousPageHandler = (e) => {
    e.preventDefault();
    history.push("/course/add/Step3");
    let stepFourState = false;
    onConfirmStep4(stepFourState);
  };

  /* useEffect function from here */
  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    let stepFourState = false;
    if (courseId != null) {
      stepFourState = true;
      viewLessonDetailsHandler(courseId);
    }
    onConfirmStep4(stepFourState);
  }, [courseId]);

  // Return function from here ....
  return (
    <>
      <div className="col-lg-9">
        {/* --------------- Unknown back arrow button starts here --------------- */}
        <div className="back_progress">
          <div className="page_back">
            <Link to="#">
              <span className="material-icons-outlined">arrow_back</span>
              {t("Back")}
            </Link>
          </div>
          <div className="step_progress">
            <span className="progress_bar" style={{ width: "55%" }}></span>
          </div>
        </div>
        {/* --------------- Unknown back arrow button ends here --------------- */}

        {/* --------------- Form section starts here --------------- */}
        <div className="course_uploadrghtpnl">
          <div className="course_itemInnr">
            <div className="course_stepItem">
              <div className="coursestep_hdng">
                <h3>{t("Add Lessons")}</h3>
                {/* <p>
                  Please upload a trailer video that will help you to gain more
                  audience
                </p> */}
              </div>

              <div className="course_lessons_Innr">
                {/* --------- Lesson Details Section Opener starts here --------- */}
                {lessons ? (
                  <div className="course_lessonsOuter">
                    {lessons.map((item, index) => {
                      return (
                        <div className="course_lessons_row" key={index}>
                          <div className="course_lessons_lft">
                            <p>{item.title}</p>
                            <div className="input_fill jobinput_fill lesson_editfill">
                              <input type="text" className="form-control" />
                            </div>
                          </div>

                          <div className="course_lessons_right">
                            {/* <div className="lessns_edit">
                              <Link to="#">
                                <span className="material-icons-outlined">
                                  edit
                                </span>
                              </Link>
                            </div> */}

                            {/* ------------ Link to activate the Popup starts here ------------ */}
                            <div className="lessns_details_btn lssnn_popupBtn">
                              <Link
                                to="#"
                                role="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  // getLessonId(item.id);
                                  document.body.classList.add(
                                    "lessons_openpopup"
                                  );

                                  // saving lesson id in useContext here
                                  getLessonId(item.id);
                                  getLessonTitle(item.title);
                                  setStepFourData({
                                    ...stepFourData,
                                    lessonid: item.id,
                                    title: item.title,
                                  });
                                }}
                              >
                                {t("Details")}
                                <span className="material-icons-outlined">
                                  chevron_right
                                </span>
                              </Link>
                            </div>
                            {/* ------------ Link to activate the Popup ends here ------------ */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {/* --------- Lesson Details Section Opener ends here --------- */}

                {/* ------------ Lesson details panel section starts here --------------- */}
                <div className="input_fill jobinput_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter Lesson name")}
                    name="lesson"
                    value={stepFourData.title}
                    onChange={(e) =>
                      setStepFourData({
                        ...stepFourData,
                        title: e.target.value,
                      })
                    }
                  />
                  <Link
                    to="#"
                    className="material-icons-round fill_chk"
                    role="button"
                    onClick={(e) => {
                      sendLessonNameHandler(e);
                    }}
                  >
                    send
                  </Link>
                  {/* <div className="spinner-border text-primary"></div> */}

                  {showEmptyMessage && (
                    <p className="text-danger mt-1 pl-2">
                      * {t("Lesson Name Cannot be empty")}
                    </p>
                  )}
                </div>
                {/* ------------ Lesson details panel section ends here --------------- */}
              </div>
            </div>

            {/* -------------------- Popup components start here -------------------- */}
            <div className="ux_details_popupArea uxpop_bx">
              {/* ------------ Link to de-activate the popup starts here ------------ */}
              <div className="ux_popupHdng">
                <h3>{lessonTitle}</h3>
                {/* ----------- Back/Close button starts here ----------- */}
                <span
                  className="ux_cross"
                  onClick={(e) => {
                    closeHandler(e);
                  }}
                >
                  <i className="material-icons-outlined">close</i>
                </span>
                {/* ----------- Back/Close button ends here ----------- */}
              </div>
              {/* ------------ Link to de-activate the popup ends here ------------ */}
              <div className="uxfill_innrBody">
                <div className="ux_fillOuter"></div>

                {/* ------ Tabs section starts here ------ */}
                <div className="ux_tabs_area">
                  {/* ------ sections to trigger the tabs inside the component starts here ------ */}
                  <div className="inner_tab_links">
                    <ul>
                      {/* ------------ Link to activate contents tabs ------------- */}
                      <li className={contentsTab ? "active" : ""}>
                        <Link
                          to="#"
                          onClick={(e) => {
                            showContentsTab(e);
                          }}
                        >
                          <i className="material-icons-outlined">feed</i>
                          <span>{t("Contents")}</span>
                        </Link>
                      </li>

                      {/* ------------ Link to activate task tabs ------------- */}
                      <li className={taskTab ? "active" : ""}>
                        <Link
                          to="#"
                          onClick={(e) => {
                            showTaskTab(e);
                          }}
                        >
                          <i className="material-icons-outlined">task</i>
                          <span>{t("Assignment")}</span>
                        </Link>
                      </li>

                      {/* ------------ Link to activate task tabs ------------- */}
                      <li className={questionsTab ? "active" : ""}>
                        <Link
                          to="#"
                          onClick={(e) => {
                            showQuestionsTab(e);
                          }}
                        >
                          <i className="material-icons-outlined">list_alt</i>
                          <span>{t("Questions")}</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* ------ sections to trigger the tabs inside the component ends here ------ */}

                  {/* ------------ sections to display tab content starts here ------------ */}
                  <div className="inner_tabcontent">
                    {/* {contentsTab && <Contents contentData={contentData} />} */}
                    {contentsTab && (
                      <Contents
                        resetAll={resetAll}
                        afterClose={afterClose}
                        lessonid={stepFourData.lessonid}
                        lessontitle={stepFourData.title}
                        membersInstructor={membersInstructor}
                      />
                    )}
                    {taskTab && (
                      <Task
                        lessonid={stepFourData.lessonid}
                        courseid={courseIdFromLocal}
                      />
                    )}
                    {questionsTab && (
                      <Questions
                        lessonid={stepFourData.lessonid}
                        courseid={courseIdFromLocal}
                      />
                    )}
                  </div>
                  {/* ------------ sections to display tab content ends here ------------ */}
                </div>
                {/* ------ Tabs section ends here ------ */}
              </div>
            </div>
            {/* -------------------- Popup components ends here -------------------- */}
          </div>

          {/* ---- go to next & previous step button area starts here ---- */}
          <div className="nxt_prvbtn_Outer">
            {/* ---- go to previous step button area starts here ---- */}
            <div className="prev_Btn">
              <Link
                to="#"
                className="border_btn"
                onClick={(e) => {
                  goToPreviousPageHandler(e);
                }}
              >
                {t("Back to previous")}
              </Link>
            </div>
            {/* ---- go to previous step button area ends here ---- */}

            {/* ---- go to next step button area starts here ---- */}
            <div className="next_Btn">
              <Link
                to="#"
                className="basic_btn"
                role="button"
                onClick={(e) => {
                  nextStepHandler(e);
                }}
              >
                {t("Go to Next Step")}
              </Link>
            </div>
            {/* ---- go to next step button area ends here ---- */}
          </div>
          {/* ---- go to next & previous step button area ends here ---- */}
        </div>
        {/* --------------- Form section ends here --------------- */}
      </div>

      {/* toast message */}
      <ToastContainer />
    </>
  );
};

export default Step4;
