import React from "react";
import { Link } from "react-router-dom";

const HomeChampionRequire = () => {
  return (
    <section className="system_area pd_30">
      <div className="container">
        <div className="system_outer">
          <div className="system_pic">
            <img src="images/system-pic2.jpg" alt="" />
          </div>
          <div className="system_textPnl">
            <h3>
              Looking for <br /> Champions?
            </h3>
            <div className="system_list">
              <ul>
                <li>
                  <i className="material-icons-outlined">check_circle</i>
                  <span>There are many variations of passages.</span>
                </li>
                <li>
                  <i className="material-icons-outlined">check_circle</i>
                  <span>
                    All the Lorem Ipsum generators on the Internet tend.
                  </span>
                </li>
                <li>
                  <i className="material-icons-outlined">check_circle</i>
                  <span>There are many variations of passages.</span>
                </li>
              </ul>
            </div>
            <div className="explore_btn">
              <Link to="#" className="basic_btn ylw_btn">
                Explore Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeChampionRequire;
