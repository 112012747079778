import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// import custom components here
import Header from "common/Header";
import Banner from "components/ChallengesComponents/Banner";
import ChallengesListArea from "components/ChallengesComponents/ChallengesListArea";
import TabArea from "common/tabarea/TabArea";
import Footer from "common/Footer";

const Challenges = () => {
  useEffect(() => {
    document.title = "Challenges";
  }, []);

  return (
    <>
      <Header />
      <Banner />
      <TabArea module={"challenges"} />
      <ChallengesListArea />
      <Footer />
    </>
  );
};

export default Challenges;
