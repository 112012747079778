/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { GlobalIndraInfo } from "App";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const TaskPopUp = (props) => {
  // accessing the user's token here
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const stepFourTaskName = props.stepFourTaskName;

  // Getting the course ID created in Step 1
  const { courseId, taskId, taskTitle, lessonId } = useContext(GlobalIndraInfo);

  // console.log("course id from context---->", courseId);
  // initial state for step four task popup data

  const [title, setTitle] = useState("");
  const [duration, setDuration] = useState("");
  const [content, setContent] = useState("");

  const [taskPopupAnswer, setTaskPopupAnswer] = useState([]);

  // for checkbox state
  const [checked, setChecked] = useState("");

  const updateOne = () => setCheckedOne(!checkedOne);
  const updateTwo = () => setCheckedTwo(!checkedTwo);
  const updateThree = () => setCheckedThree(!checkedThree);

  //initial state for display error message
  const [taskDetailsErrorMessage, setTaskDetailsErrorMessage] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  /*---- CKEditor classic editor configuration----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      uploadUrl: "https://dev.lobees.com/web/uploads/reports/",
    },
    placeholder: "Type here...",
  };
  /*----- end of CKEditor classic editor part----- */

  const [mcqTypeQuestion, setMCQTypeQuestion] = useState(false);
  const [descriptiveTypeQuestion, setDescriptiveTypeQuestion] = useState(false);

  // Function to handle the radio button clicks
  const questionTypeRadioButtonHandler = (id) => {
    if (id == 0) {
      setMCQTypeQuestion(true);
      setDescriptiveTypeQuestion(false);
    } else {
      setDescriptiveTypeQuestion(true);
      setMCQTypeQuestion(false);
    }
  };

  /* ---------------------- answer field repeater start ---------------------- */
  const answerFieldTemplate = {
    answer: "",
    iscorrect: "",
  };

  // initial state for requirement one block
  const [answerFieldBlock, setAnswerFieldBlock] = useState([
    answerFieldTemplate,
  ]);

  // add requirement one block
  const onAddAnswerFieldBlock = (e) => {
    e.preventDefault();
    setAnswerFieldBlock([...answerFieldBlock, answerFieldTemplate]);
  };

  // insert value requirement one block
  const answerFieldBlockHandler = (e, index) => {
    e.preventDefault();
    const updateAnswerFieldBlock = answerFieldBlock.map((item, i) =>
      index == i ? Object.assign(item, { ["answer"]: e.target.value }) : item
    );

    setAnswerFieldBlock(updateAnswerFieldBlock);

    const answerFieldValue = updateAnswerFieldBlock.map((item, number) => {
      return item;
    });

    setTaskPopupAnswer(answerFieldValue);
  };

  // function for updated answer field block
  const updateAnswerFieldBlockHandler = (data) => {
    console.log(data);
    let arr = [];
    data.map(function (choice) {
      // restructing json object to map with form data
      let choiceSavedBlockElement = {
        id: choice.id,
        answer: choice.label,
        iscorrect: choice.iscorrect,
      };

      arr.push(choiceSavedBlockElement);
    });

    setAnswerFieldBlock(arr);
  };

  // delete requirement one block
  const onDeleteAnswerFieldBlock = (index) => {
    const answerField = [...answerFieldBlock];
    answerField.splice(index, 1);

    setAnswerFieldBlock(answerField);

    const answerFieldValue = answerField.map((item, number) => {
      return item;
    });

    setTaskPopupAnswer(answerFieldValue);
  };
  /* ---------------------- answer field repeater end ---------------------- */

  // function for update question
  const updateHandler = async (e) => {
    setUpdateSuccess(false);
    e.preventDefault();
    if (content == "") {
      setTaskDetailsErrorMessage(true);
      return;
    } else {
      setTaskDetailsErrorMessage(false);
      // console.log("Time selected ----> ", stepFourTaskPopupData.duration);

      let requestURL =
        url.API_BaseUrl +
        url.API_STEP_4_SAVE_LESSON_TASK_CONTENT +
        `?token=${token}` +
        `&cid=${courseId}` +
        `&tid=${taskId}`;
      // console.log("Step 4 Taskpopup sending request --------> ", requestURL);

      let taskPopupData = {
        title: title,
        duration: duration,
        content: content,
        answers: taskPopupAnswer,
      };

      try {
        const response = await postData(requestURL, taskPopupData);

        if (response.status) {
          // console.log("Taskpopup receiving response --------> ", response);
          setUpdateSuccess(true);
          setMCQTypeQuestion(false);
          setDescriptiveTypeQuestion(false);
          document.body.classList.remove("taskopen_popup");
          props.reloadTasks();
        } else {
          console.log("Some error occurred while submitting the data!");
        }
      } catch (error) {
        console.log(error);
      }
      console.log("TaskPopUp Data submitted !!");
    }
  };

  const viewLessonDetailsHandler = async (id) => {
    const requestURL =
      url.API_BaseUrl +
      url.API_VIEW_LESSON_DETAILS +
      `?token=${token}` +
      `&lid=${id}`;

    try {
      const response = await getData(requestURL);
      console.log("step 4 contents tab lesson details ----->", response);
      if (response.status) {
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ---------- useEffect starts here ----------
  useEffect(() => {
    if (props.selectedTask) {
      console.log(props.selectedTask);
      setTitle(props.selectedTask.title);
      setDuration(props.selectedTask.duration);
      setContent(props.selectedTask.content);
      updateAnswerFieldBlockHandler(props.selectedTask.choices);
    }
  }, [props.selectedTask]);

  // ----------- useEffect function based on lesson id -----------
  useEffect(() => {
    if (lessonId != null) {
      viewLessonDetailsHandler(lessonId);
    }
  }, [lessonId]);

  // Return function starts here..
  return (
    <>
      <div className="ux_details_popupArea taskpopup_area">
        {/* ------------ Popup close button section starts here ------------ */}
        <div className="ux_popupHdng">
          <h3>
            <span
              className="task_cross"
              onClick={(e) => {
                e.preventDefault();
                document.body.classList.remove("taskopen_popup");
              }}
            >
              <i className="material-icons-outlined">arrow_back</i>
            </span>
            {taskTitle}
          </h3>
        </div>
        {/* ------------ Popup close button section ends here ------------ */}

        <div className="uxfill_innrBody">
          <div className="ux_fillOuter">
            {/* ---------- Task Name input starts here ---------- */}
            <div className="input_fill jobinput_fill">
              <div className="coursestep_hdng">
                <h3>{t("Assignment Title")}</h3>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Task title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            {/* ---------- Task Name input ends here ---------- */}
          </div>

          <div className="ux_fillOuter">
            {/* ---------- Time input starts here ---------- */}
            <div className="ux_rightFill">
              <div className="input_fill jobinput_fill">
                <div className="coursestep_hdng">
                  <h3>{t("Time (mins)")}</h3>
                </div>
                <input
                  type="number"
                  min={1}
                  className="form-control"
                  placeholder={t("Enter mins")}
                  value={duration != "" ? duration : ""}
                  onChange={(e) => setDuration(e.target.value)}
                />
              </div>
            </div>
            {/* ---------- Time input ends here ---------- */}

            {/* ---------- Task Name input ends here ---------- */}
          </div>

          <div className="taskpnl_outer">
            <div className="course_stepItem">
              <div className="coursestep_hdng">
                <h3>{t("Assignment Details")}</h3>
              </div>
              {/* ------------------ Text Editor Starts here ------------------ */}
              <div className="text_editor">
                <div className="text_msg">
                  <CKEditor
                    className="form-control"
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={content != null ? content : ""}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "250px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      let editContent = editor.getData();
                      //console.log(JSON.stringify(editContent));
                      // setStepFourTaskPopupData({
                      //   ...stepFourTaskPopupData,
                      //   content: editContent,
                      // });
                      setContent(editContent);
                    }}
                  />
                  {taskDetailsErrorMessage && (
                    <p className="text-danger mt-2">
                      * {t("Please fill up Task Details")}
                    </p>
                  )}
                </div>
              </div>
              {/* ------------------ Text Editor Starts here ------------------ */}
            </div>

            {/* ---------- Radio buttons for answers type ---------- */}
            <div className="course_stepItem">
              <div className="task_radio">
                <ul>
                  <li>
                    <label className="radio_circle">
                      <input
                        type="radio"
                        name="rdo2"
                        onClick={() => questionTypeRadioButtonHandler(0)}
                      />
                      <span></span>
                      <p>{t("Multiple Answers")}</p>
                    </label>
                  </li>
                  <li>
                    <label className="radio_circle">
                      <input
                        type="radio"
                        name="rdo2"
                        onClick={() => questionTypeRadioButtonHandler(1)}
                      />
                      <span></span>
                      <p>{t("Descriptive Answer")}</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>

            {/* ---------- Display answers incase of multiple choice ---------- */}
            <div className="course_stepItem">
              {mcqTypeQuestion == true && descriptiveTypeQuestion == false ? (
                <div className="mcqQuestionSection">
                  <div className="coursestep_hdng">
                    <h3>{t("Multiple Answers")}</h3>
                  </div>
                  <div className="answer_outer">
                    {/* --------- Form repeater starts here --------- */}
                    {answerFieldBlock.map((item, index) => {
                      return (
                        <div className="answar_row" key={index}>
                          <div className="answar_hdng">
                            <h4 className="answerLabel">Answer</h4>
                          </div>

                          {/* --------- Inputs starts here --------- */}
                          <div className="answar_filledit">
                            <div className="course_lessons_row">
                              <div className="course_lessons_lft">
                                <div className="input_fill jobinput_fill">
                                  <input
                                    type="text"
                                    name="answer"
                                    className="form-control"
                                    placeholder="Enter answer"
                                    value={item.answer}
                                    onChange={(e) =>
                                      answerFieldBlockHandler(e, index)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* --------- Inputs ends here --------- */}

                          {/* --------- Checkboxes starts here --------- */}
                          <div className="answar_check">
                            <label className="radio_circle">
                              <input
                                type="checkbox"
                                name="iscorrect"
                                defaultChecked={checked}
                                onChange={(e) =>
                                  answerFieldBlockHandler(e, index)
                                }
                              />
                              <span></span>
                              <i>is Correct</i>
                            </label>
                          </div>
                          {/* --------- Checkboxes ends here --------- */}

                          {/* ---- delete button for answer field repeater ----- */}
                          {index != 0 ? (
                            <div className="dlete_btn">
                              <Link
                                to="#"
                                role="button"
                                onClick={() => onDeleteAnswerFieldBlock(index)}
                              >
                                <span className="material-icons-outlined">
                                  delete
                                </span>
                              </Link>
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                    {/* --------- Form repeater ends here --------- */}

                    {/* ---- Add button for answer field repeater ----- */}
                    <div className="addfill_btn">
                      <Link
                        to="#"
                        className="add_plusBtn"
                        role="button"
                        onClick={(e) => {
                          onAddAnswerFieldBlock(e);
                        }}
                      >
                        <i className="material-icons-outlined">
                          add_circle_outline
                        </i>
                        {t("Add Another")}
                      </Link>
                    </div>
                    {/* ---- end of Add button for requirements 1 repeater ----- */}
                  </div>
                </div>
              ) : (
                <>
                  {descriptiveTypeQuestion == true ? (
                    <div className="descriptiveQuestionSection">
                      <p>UI Design required for Descriptive type questions</p>
                    </div>
                  ) : null}
                </>
              )}
            </div>
            {/* {updateSuccess && (
              <p className="text-success">Update Successfully</p>
            )} */}
            {/* ---------- Update button to call the API to send the data ---------- */}
            <div className="update_btn">
              <button
                type="submit"
                className="basic_btn"
                onClick={(e) => {
                  updateHandler(e);
                }}
              >
                {t("Update")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskPopUp;
