/* eslint-disable */
import React, { createContext, useState, useEffect } from "react";

// importing custom components here
import Header from "common/Header";
import CourseLeftPanel from "./CourseLeftPanel";
import CourseRightPanel from "./CourseRightPanel";

const Course = () => {
  useEffect(() => {
    document.title = "Course-Upload";
  }, []);

  return (
    <>
      <Header />

      <section className="create_course_sec">
        <div className="container">
          <div className="course_uploadOuter">
            <div className="row">
              {/* // Left and Right panels here */}
              <CourseLeftPanel />
              <CourseRightPanel />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Course;
