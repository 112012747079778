/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AssignmentAcceptModal from "components/CourseDetailsComponents/Modals/AssignmentAcceptModal";
// importing images here

import PDF from "assets/ConversationAssests/svg/pdf.svg";
import XLS from "assets/ConversationAssests/svg/xls.svg";
import DOCS from "assets/ConversationAssests/svg/docs.svg";
import VIDEO from "assets/ConversationAssests/svg/video.svg";
import ZIP from "assets/ConversationAssests/svg/zip.svg";
import defaultUser from "assets/ConversationAssests/images/default-user.png";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, uploadMultipleFile, postFormdata } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

const LessonTask = (props) => {
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // Initial useState to save the content recceived from the props.
  const [tasksData, setTasksData] = useState(null);
  const [showTaskPane, setShowTaskPane] = useState(false);
  const [assignmentData, setAssignmentData] = useState(null);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [assignTaskId, setAssignTaskId] = useState(null);
  // initial state for uploaded multiple files
  const [media, setMedia] = useState([]);
  // inintial state for condition
  const [isUploading, setIsUploading] = useState(false);
  //initial state for uploaded image id
  const [fileIds, setFileIds] = useState([]);
  const [fileIsBig, setFileIsBig] = useState(false);
  const [messageWarning, setMessageWarning] = useState(false);
  const [fileWarning, setFileWarning] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [comment, setComment] = useState("");
  const [commentWarning, setCommentWarning] = useState(false);
  const [reviewBlock, setReviewBlock] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  // function for toggle assignment details
  const togglePost = (item) => {
    if (item) {
      setAssignmentData(item);
    }

    console.log("Selected Task ---> ", item);

    setShowTaskPane(!showTaskPane);
    var x = document.getElementById("task_panel_assignment");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  };

  // function for accept assignment
  const accpetAssignment = async () => {
    setIsAcceptLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_ACCEPT_TASK +
      `?token=${token}` +
      `&tid=${assignmentData.id}`;

    try {
      let response = await getData(requestURL);
      console.log("response in accept assignment------>", response);
      if (response.status) {
        setAssignTaskId(response.data.assigntaskid);
        let myModal = new bootstrap.Modal(
          document.getElementById("assignmentaccept_success_modal")
        );
        myModal.show();
        props.onConfirmLesson();
        togglePost();
      }
      setIsAcceptLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // function for toggle review block
  const toggleSubmitBlock = () => {
    setReviewBlock(!reviewBlock);
  };

  // allow upload files, show file thumbnail, save files in const media
  const uploadMultipleFiles = async (e) => {
    setFileIsBig(false);
    setFileWarning(false);

    // console.log("files---->", e.target.files);

    const selectedFIles = []; // initial empty values for file array

    const targetFiles = e.target.files; // store file information

    let filesLength = targetFiles.length;

    let proceed = false;

    if (filesLength > 0) {
      for (let i = 0; i < filesLength; i++) {
        let f = targetFiles[i];

        if (f.size <= 12500000) {
          proceed = true;
        } else {
          proceed = false;
        }
      }

      if (proceed) {
        setIsUploading(true);
        const targetFilesObject = [...targetFiles]; // store array of those files

        let requestURL =
          url.API_BaseUrl + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;
        try {
          const response = await uploadMultipleFile(requestURL, targetFiles);
          //console.log("response file multiple---->", response);

          if (response.status == true) {
            let fileResponseId = response.data.map((data) => {
              return data.id;
            });
            setFileIds(fileResponseId);
          } else {
            console.log("Error in file response");
          }

          // check condition whether file array is empty or not
          if (media.length == 0) {
            targetFilesObject.map((file) => {
              return selectedFIles.push(file);
            });

            setMedia(selectedFIles); // changing state of files array
          } else {
            targetFilesObject.map((file) => {
              return selectedFIles.push(file);
            });

            // changing state of files array with previous file array values
            setMedia((media) => [...media, ...selectedFIles]);
          }
          setIsUploading(false);
        } catch (error) {
          console.log("Error in file upload----->", error);
        }
      } else {
        setFileIsBig(true);
      }
    }
  };

  // uploads media files to server and get uploaded file id and setUploadedFileId
  const loadingCircle = [1, 2, 3, 4, 5, 6, 7];

  // functions for submit assignment
  const submitAssignMentHandler = async (e) => {
    if (comment == "") {
      setCommentWarning(true);
    } else {
      setCommentWarning(false);
      let requestURL =
        url.API_BaseUrl +
        url.API_SUBMIT_ASSIGNMENT +
        `?token=${token}` +
        `&tid=${assignmentData.assignedtaskid}`;

      let taskData = {
        comment,
        media: fileIds,
      };

      resetFile();
      setComment("");
      setMedia([]);

      try {
        const response = await postFormdata(requestURL, taskData);
        console.log("submit task for review----->", response);
        if (response.status) {
          toggleSubmitBlock();
          setIsSubmit(true);
          let myModal = new bootstrap.Modal(
            document.getElementById("assignmentaccept_success_modal")
          );
          myModal.show();
          props.onConfirmLesson();
          togglePost();
        } else {
          console.log("response error in lesson");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("inputGroupFile03");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  const resetSubmit = () => {
    if (isSubmit == true) {
      setIsSubmit(false);
    }
  };

  // UseEffect starts from here
  useEffect(() => {
    if (props.tasks) {
      console.log(
        "Received Lesson Tasks in Lesson Task Tab -----> ",
        props.tasks
      );
      // assigning the data to the useState
      setTasksData(props.tasks);
    }
  }, [props.tasks]);

  return (
    <div className="tab-pane fade active show" id="task">
      {tasksData == null ? null : (
        <div className="task_panl lesson_pnl">
          {/*----- assignment lists start here ------*/}
          {!showTaskPane && (
            <div className="task_outer">
              <div className="row">
                {tasksData.map((task, index) => {
                  return (
                    <div className="col-md-6" key={task.id}>
                      <div className="task_item">
                        <div className="time_rounded">
                          <i className="material-icons-outlined">schedule</i>
                          <span>
                            {task.duration} {t("mins")}
                          </span>
                        </div>
                        {task.assignedtaskid != null ? (
                          <div className="time_rounded">
                            <i className="material-icons-outlined">done</i>
                            <span>{t("commenced")}</span>
                          </div>
                        ) : null}

                        <div className="task_textPnl">
                          <h3>{task.title}</h3>
                          {task.content != null ? (
                            <p
                              dangerouslySetInnerHTML={{ __html: task.content }}
                            ></p>
                          ) : null}
                          <div className="task_btn">
                            <Link
                              to="#"
                              className="border_btn"
                              onClick={() => togglePost(task)}
                            >
                              {t("View Details")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {/*----- assignment lists end here ------*/}

          {/*----- assignment details start here ------*/}
          <div className="task_detailspnl" id="task_panel_assignment">
            <div className="task_crossbtn">
              <Link to="#">
                <span className="material-icons-outlined" onClick={togglePost}>
                  close
                </span>
              </Link>
            </div>
            <div className="task_detailsHdng">
              {assignmentData ? <h2>{assignmentData.title}</h2> : null}

              {assignmentData ? (
                <div className="time_rounded">
                  <i className="material-icons-outlined">schedule</i>
                  <span>
                    {assignmentData.duration} {t("mins")}
                  </span>
                </div>
              ) : null}
            </div>

            {assignmentData ? (
              <>
                <div className="task_detailspara">
                  <p
                    dangerouslySetInnerHTML={{ __html: assignmentData.content }}
                  ></p>
                </div>

                <div className="taskbtn_outer">
                  {assignmentData.assignedtaskid != null ? (
                    <Link
                      to="#"
                      className="border_btn taskagain_btn"
                      onClick={toggleSubmitBlock}
                    >
                      {t("Submit task for review")}
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      className="border_btn taskagain_btn"
                      onClick={accpetAssignment}
                    >
                      {t("Accept")}
                      {isAcceptLoading && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </Link>
                  )}

                  <Link
                    to="#"
                    className="border_btn taskback_btn"
                    onClick={togglePost}
                  >
                    {t("Cancel")}
                  </Link>
                </div>

                {reviewBlock && (
                  <div className="commnts_send_innr">
                    <div className="rating_hdng">
                      <h5>{t("Comments/Answer")}</h5>
                    </div>

                    <div className="commnts_sendArea">
                      <textarea
                        className="form-control"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder={t("Write your comments here")}
                      ></textarea>
                      {commentWarning && (
                        <p className="text-danger mb-1">
                          * {t("Please insert comment")}
                        </p>
                      )}
                    </div>

                    <div className="row">
                      {media.map((files, index) => {
                        console.log(media);
                        return (
                          <div className="col-lg-3 col-md-4 col-6" key={index}>
                            <div className="chat_uploadItem">
                              <div className="chat_upload_pic">
                                {/* ---- creating file url for preview those files ----- */}
                                {files.type == "image/jpeg" ||
                                files.type == "image/png" ? (
                                  <img
                                    src={URL.createObjectURL(files)}
                                    alt="images"
                                  />
                                ) : null}
                                {files.type == "text/plain" ? (
                                  <img src={DOCS} alt="docs" />
                                ) : null}
                                {files.type ==
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                files.type == "application/msword" ||
                                files.type ==
                                  "application/vnd.oasis.opendocument.text" ||
                                files.type ==
                                  "application/vnd.ms-word.document.macroEnabled.12" ||
                                files.type ==
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.template" ||
                                files.type ==
                                  "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                                  <img src={DOCS} alt="docs" />
                                ) : null}
                                {files.type == "application/pdf" ? (
                                  <img src={PDF} alt="pdf" />
                                ) : null}
                                {files.type ==
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                files.type == "application/vnd.ms-excel" ? (
                                  <img src={XLS} alt="docs" />
                                ) : null}
                                {files.type == "video/mp4" ? (
                                  <img src={VIDEO} alt="video" />
                                ) : null}
                                {files.type ==
                                "application/x-zip-compressed" ? (
                                  <img src={ZIP} alt="video" />
                                ) : null}
                              </div>
                              <div className="chat_upload_caption">
                                <p>
                                  <span>{files.name}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* ----- loading area for file uploading ----- */}
                    {isUploading && (
                      <div className="d-flex align-items-center justify-content-center mb-1">
                        <h6 className="text-dark fw-bold">
                          {t("Uploading Files")}
                        </h6>
                        {loadingCircle.map(() => {
                          return (
                            <div
                              className="spinner-grow mx-1 text-dark"
                              style={{ height: "6px", width: "6px" }}
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* ----- loading area for file uploading end----- */}

                    {/* ----- Uploading & Submit button starts here ----- */}
                    <div className="taskfile_area">
                      {/* ---------- Upload area ---------- */}
                      <div className="task_file_row">
                        <label>{t("Attach files")}</label>
                        <div className="attached_btn ylw_btn">
                          <input
                            type="file"
                            className="file_btn"
                            id="inputGroupFile03"
                            onChange={uploadMultipleFiles}
                            multiple
                          />
                          {t("Choose Files")}
                        </div>
                      </div>

                      {/* ---------- Submit button ---------- */}
                      <div className="file_submitbtn">
                        <Link
                          to="#"
                          className="border_btn taskagain_btn"
                          onClick={submitAssignMentHandler}
                        >
                          {t("Submit")}
                        </Link>
                      </div>
                    </div>
                    {/* ----- Uploading & Submit button ends here ----- */}
                  </div>
                )}

                {assignmentData.assignedtaskid != null ? (
                  <div className="task_status_outer">
                    <div className="taskstatus_item">
                      <h3>{t("Status")}</h3>
                      <h5>
                        {assignmentData.assignedtaskstatus == 1
                          ? "Submitted"
                          : "Working"}
                      </h5>
                    </div>

                    <div className="taskstatus_item">
                      <h3>{t("Started")}</h3>
                      <h4>
                        {assignmentData.assignedtaskstatus == 1
                          ? assignmentData.assignedtaskdate
                          : "-"}
                      </h4>
                    </div>

                    <div className="taskstatus_item">
                      <h3>{t("Submitted for Review")}</h3>
                      <h4>
                        {assignmentData.deliverytaskstatus == 1
                          ? assignmentData.deliverttaskdate
                          : "-"}
                      </h4>
                    </div>

                    <div className="taskstatus_item">
                      <h3>{t("Reviewed")}</h3>
                      <h4>
                        {assignmentData.approvaltaskstatus == 1
                          ? assignmentData.approvaltaskdate
                          : "-"}
                      </h4>
                    </div>

                    <div className="taskstatus_item">
                      <h3>{t("Audited By")}</h3>
                      <h4>Victor M. Herrero</h4>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : null}
          </div>
          {/*----- assignment details end here ------*/}

          {assignmentData ? (
            <>
              {assignmentData.assignedtaskid != null ? (
                <>
                  {/* --------------- attachments --------------- */}

                  {assignmentData.taskattachments != null &&
                  assignmentData.taskattachments.length > 0 ? (
                    <div className="attachment_area">
                      <div className="attach_hdng">
                        <h3>{t("Attachments")}</h3>
                      </div>
                      <div className="attachemnet_bxOuter">
                        <div className="row">
                          {assignmentData.taskattachments.map(
                            (files, index) => {
                              return (
                                <div className="col-lg-3 col-6" key={index}>
                                  <div className="attachment_item">
                                    <div className="attahdmnt_pic">
                                      <img src={files} alt="uploadedPic" />
                                    </div>

                                    <div className="attahdmnt_text">
                                      <h5>filename.jpg</h5>
                                      <Link to="#">
                                        <span className="material-icons-outlined">
                                          file_download
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* --------------- end attachments ------------- */}

                  {/* ---------------- comments --------------- */}
                  {assignmentData.taskcomments != null &&
                  assignmentData.taskcomments.length > 0 ? (
                    <div className="task_activity_area">
                      <div className="attach_hdng">
                        <h3>{t("Activity")}</h3>
                      </div>
                      <div className="task_tableInnr">
                        <table>
                          <thead>
                            <tr>
                              <th>{t("Comments")}</th>
                              <th>{t("Name")}</th>
                              <th>{t("Date")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {assignmentData.taskcomments.map(
                              (comments, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{comments.comment}</td>
                                    <td>
                                      <div className="task_tableuser">
                                        <div className="task_usr">
                                          <img src={defaultUser} alt="" />
                                        </div>
                                        <div className="task_usr_text">
                                          <span>{comments.user}</span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>{comments.date}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : null}

                  {/* ---------- end comments ------------------- */}
                </>
              ) : null}
            </>
          ) : (
            ""
          )}
        </div>
      )}
      <AssignmentAcceptModal isSubmit={isSubmit} resetSubmit={resetSubmit} />
    </div>
  );
};

export default LessonTask;
