/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalIndraInfo } from "App";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormdata } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const Step6 = (props) => {
  // for routing
  const history = useHistory();

  // Getting the course ID created in Step 1
  const { courseId, onConfirmStep6, onConfirmStep7 } =
    useContext(GlobalIndraInfo);

  // Initial useState for Notification component
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [couponIsNotEmpty, setCouponIsNotEmpty] = useState(false);
  const [referelIsNotEmpty, setReferelIsNotEmpty] = useState(false);
  const [referWarning, setReferWarning] = useState(false);
  const [couponWarning, setCouponWarning] = useState(false);

  // toast property
  const toastOptions = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    // theme: "colored",
  };

  // accessing the user's token here
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const [couponData, setCouponData] = useState([]);
  const [referralsData, setReferralsData] = useState([]);

  /* ---------------------- Coupon repeater start ---------------------- */

  const couponTemplate = {
    code: "",
    percent: "",
  };

  // initial state for requirement one block -------------------------
  const [couponBlock, setCouponBlock] = useState([couponTemplate]);

  // add requirement one block
  const onAddCouponBlock = () => {
    setCouponBlock([...couponBlock, couponTemplate]);
  };

  // insert value requirement one block
  const couponBlockHandler = (e, index) => {
    const updateCouponBlock = couponBlock.map((item, i) =>
      index == i
        ? Object.assign(item, { [e.target.name]: e.target.value })
        : item
    );

    setCouponBlock(updateCouponBlock);

    const couponValue = updateCouponBlock.map((item, number) => {
      return item;
    });

    setCouponData(couponValue);
  };

  // map features value to form repeater
  const updateCouponBlock = (coupons) => {
    let arr = [];

    coupons.map(function (item) {
      // restructing json object to map with form data
      let couponSavedBlockElement = {
        id: item.id,
        code: item.code,
        percent: item.percent,
      };

      arr.push(couponSavedBlockElement);

      // console.log("arr", arr);
    });

    setCouponBlock(arr);
  };

  // delete requirement one block
  const onDeleteCouponBlock = async (item, index) => {
    let couponId = {
      couponid: item.id,
    };
    let requestURL =
      url.API_BaseUrl +
      url.API_DELETE_FORM_REPEATER +
      `?token=${token}` +
      `&cid=${courseId}`;

    try {
      let response = await postFormdata(requestURL, couponId);
      const filteredCouponBlock = [...couponBlock];
      filteredCouponBlock.splice(index, 1);

      setCouponBlock(filteredCouponBlock);

      const couponCodeValue = filteredCouponBlock.map((item, number) => {
        return item;
      });

      setCouponData(couponCodeValue);
    } catch (error) {
      console.log(error);
    }
  };
  /* ---------------------- Coupon repeater end ------------------------ */

  /* ---------------------- referral url repeater start ---------------------- */

  const referralURLTemplate = {
    code: "",
    percent: "",
  };

  // initial state for referral block
  const [referralURLBlock, setReferralURLBlock] = useState([
    referralURLTemplate,
  ]);

  // add referral block
  const onAddReferralURLBlock = () => {
    setReferralURLBlock([...referralURLBlock, referralURLTemplate]);
  };

  // insert value requirement one block
  const referralURLBlockHandler = (e, index) => {
    const updateReferralURLBlock = referralURLBlock.map((item, i) =>
      index == i
        ? Object.assign(item, { [e.target.name]: e.target.value })
        : item
    );

    setReferralURLBlock(updateReferralURLBlock);

    const referralURLValue = updateReferralURLBlock.map((item, number) => {
      return item;
    });

    setReferralsData(referralURLValue);
  };

  // map referral value to form repeater
  const updateReferralBlock = (referrals) => {
    let arr = [];

    referrals.map(function (item) {
      // restructing json object to map with form data
      let featureSavedBlockElement = {
        id: item.id,
        code: item.code,
        percent: item.percent,
      };

      arr.push(featureSavedBlockElement);

      // console.log("arr", arr);
    });

    setReferralURLBlock(arr);
  };

  // delete requirement one block
  const onDeleteReferralURLBlock = async (item, index) => {
    let refferalId = {
      referralid: item.id,
    };
    let requestURL =
      url.API_BaseUrl +
      url.API_DELETE_FORM_REPEATER +
      `?token=${token}` +
      `&cid=${courseId}`;

    try {
      let response = await postFormdata(requestURL, refferalId);
      const filteredReferralURL = [...referralURLBlock];
      filteredReferralURL.splice(index, 1);

      setReferralURLBlock(filteredReferralURL);

      const referralURLValue = filteredReferralURL.map((item, number) => {
        return item;
      });

      setReferralsData(referralURLValue);
    } catch (error) {
      console.log(error);
    }
  };
  /* ---------------------- referral url repeater end ---------------------- */

  /*---- function for go to next step -----*/
  const nextStepHandler = async (e) => {
    e.preventDefault();
    // console.log("step six data ----->", stepSixData);
    if (
      (couponData.length == 0 && couponIsNotEmpty == false) ||
      (referralsData.length == 0 && referelIsNotEmpty == false)
    ) {
      if (couponData.length == 0 && couponIsNotEmpty == false) {
        setCouponWarning(true);
      } else {
        setCouponWarning(false);
      }
      if (referralsData.length == 0 && referelIsNotEmpty == false) {
        setReferWarning(true);
      } else {
        setReferWarning(false);
      }
    } else {
      setCouponWarning(false);
      setReferWarning(false);
      setIsSubmittingData(true);
      let requestURL =
        url.API_BaseUrl +
        url.API_STEP_6_COURSE_CREATE +
        `?token=${token}` +
        `&cid=${courseId}`;

      //console.log("Step 6 sending request --------> ", requestURL);

      let stepSixData = {
        coupons: couponData,
        referrals: referralsData,
      };

      let stepSixState = true;

      console.log("step 6 data ---->", stepSixData);

      try {
        const response = await postData(requestURL, stepSixData);
        console.log("Step 6 receiving response --------> ", response);

        if (response.status) {
          console.log("Data submitted successfully", response);
          onConfirmStep6(stepSixState);
          onConfirmStep7(stepSixState);
          setIsSubmittingData(false);
          history.push("/course/add/step8");
        } else {
          console.log("Some error occurred while submitting the data!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  /*---- end of function for go to next step -----*/

  /*---- function to fetch the course details saved in step 1 -----*/
  const viewCourseDetailsHandler = async (id) => {
    const requestURL =
      url.API_BaseUrl +
      url.API_STEP_6_COURSE_VIEW +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      const response = await getData(requestURL);
      // console.log("step one response----->", response);
      if (response.status) {
        if (response.data.coupons.length > 0) {
          setCouponIsNotEmpty(true);
          updateCouponBlock(response.data.coupons);
        }

        if (response.data.referrals.length > 0) {
          setReferelIsNotEmpty(true);
          updateReferralBlock(response.data.referrals);
        }
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*---- end of function to fetch the course details saved in step 1 -----*/

  /* Function to redirect to the next page without calling the API */
  const goToNextPageHandler = (e) => {
    e.preventDefault();
    history.push("/course/add/step8");
    let stepSixState = true;
    onConfirmStep6(stepSixState);
    onConfirmStep7(stepSixState);
  };

  /* Function to redirect to the previous page without calling the API */
  const goToPreviousPageHandler = (e) => {
    e.preventDefault();
    history.push("/course/add/Step5");
    let stepSixState = false;
    onConfirmStep6(stepSixState);
  };

  /* useEffect function from here */
  useEffect(() => {
    let stepSixState = false;
    if (courseId != null) {
      stepSixState = true;
      // console.log("Step 1 ------> Course ID is empty");
      viewCourseDetailsHandler(courseId);
    }
    onConfirmStep6(stepSixState);
    onConfirmStep7(stepSixState);
  }, [courseId]);

  return (
    <>
      <div className="col-lg-9 col-md-8">
        {/* --------------- Unknown back arrow button starts here --------------- */}
        <div className="back_progress">
          <div className="page_back">
            <Link to="#">
              <span className="material-icons-outlined">arrow_back</span>
              {t("Back")}
            </Link>
          </div>
          <div className="step_progress">
            <span className="progress_bar" style={{ width: "75%" }}></span>
          </div>
        </div>
        {/* --------------- Unknown back arrow button ends here --------------- */}

        {/* --------------- Form section starts here --------------- */}
        <div className="course_uploadrghtpnl">
          <div className="course_itemInnr">
            {/* ------------- Course TextBox section starts here ------------- */}
            <div className="course_stepItem">
              <div className="coursestep_hdng">
                <h3>{t("Add Coupons")}</h3>
                <p>{t("Please add coupons with percentage mentioned")}</p>
              </div>

              <div className="course_fillTop">
                {/* ------------- Course Form Repeater starts here ------------- */}
                {couponBlock.map((item, index) => {
                  return (
                    <div
                      className={index >= 1 ? "row mt-2" : "row"}
                      key={index}
                    >
                      <div className="col-lg-4">
                        <div className="input_fill jobinput_fill">
                          {/* ------- Coupon textbox ------- */}
                          <input
                            type="text"
                            name="code"
                            className="form-control"
                            placeholder={t("Coupon code")}
                            value={item.code}
                            onChange={(e) => couponBlockHandler(e, index)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="input_fill jobinput_fill">
                          {/* ------- Percentage textbox ------- */}
                          <input
                            type="text"
                            name="percent"
                            className="form-control"
                            placeholder={t("Percentage")}
                            value={item.percent}
                            onChange={(e) => couponBlockHandler(e, index)}
                          />
                        </div>
                      </div>
                      {/* ---- delete button for requirements 1 repeater ----- */}
                      {index == 0 ? null : (
                        <div className="dlete_btn">
                          <Link
                            to="#"
                            role="button"
                            onClick={() => onDeleteCouponBlock(item, index)}
                          >
                            <span className="material-icons-outlined">
                              delete
                            </span>
                          </Link>
                        </div>
                      )}

                      {/* ---- delete button for requirements 1 repeater end----- */}
                    </div>
                  );
                })}

                {/* ------------- Course Form Repeater ends here ------------- */}
              </div>

              {/* ---- Add button for Course Form Repeater starts here ----- */}
              <div className="addfill_btn">
                <Link
                  to="#"
                  className="add_plusBtn"
                  role="button"
                  onClick={onAddCouponBlock}
                >
                  <i className="material-icons-outlined">add_circle_outline</i>
                  {t("Add Another")}
                </Link>
              </div>
              {couponWarning && (
                <p className="mt-3 text-danger">
                  * Please insert atleast one coupon
                </p>
              )}
              {/* ---- Add button for Course Form Repeater ends here ----- */}
            </div>
            {/* ------------- Course TextBox section ends here ------------- */}

            {/* ------------- Referral TextBox section starts here ------------- */}
            <div className="course_stepItem">
              <div className="coursestep_hdng">
                <h3>{t("Add Referral URL")}</h3>
                <p>{t("Please add URLs with percentage mentioned")}</p>
              </div>

              <div className="course_fillTop">
                {/* ------------- Referral URL Form Repeater starts here ------------- */}
                {referralURLBlock.map((item, index) => {
                  return (
                    <div
                      className={index >= 1 ? "row mt-2" : "row"}
                      key={index}
                    >
                      <div className="col-lg-9">
                        <div className="input_fill jobinput_fill">
                          <input
                            type="text"
                            name="code"
                            className="form-control"
                            placeholder={t("Referral URL")}
                            value={item.code}
                            onChange={(e) => referralURLBlockHandler(e, index)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="input_fill jobinput_fill">
                          <input
                            type="text"
                            name="percent"
                            className="form-control"
                            placeholder={t("Percentage")}
                            value={item.percent}
                            onChange={(e) => referralURLBlockHandler(e, index)}
                          />
                        </div>
                      </div>

                      {/* ---- delete button for requirements 1 repeater ----- */}
                      {index == 0 ? null : (
                        <div className="dlete_btn">
                          <Link
                            to="#"
                            role="button"
                            onClick={() =>
                              onDeleteReferralURLBlock(item, index)
                            }
                          >
                            <span className="material-icons-outlined">
                              delete
                            </span>
                          </Link>
                        </div>
                      )}

                      {/* ---- delete button for requirements 1 repeater end----- */}
                    </div>
                  );
                })}
                {/* ------------- Referral URL Form Repeater ends here ------------- */}
              </div>

              {/* ---- Add button for Referral Form Repeater starts here ----- */}
              <div className="addfill_btn">
                <Link
                  to="#"
                  className="add_plusBtn"
                  role="button"
                  onClick={onAddReferralURLBlock}
                >
                  <i className="material-icons-outlined">add_circle_outline</i>
                  {t("Add Another")}
                </Link>
              </div>

              {referWarning && (
                <p className="mt-3 text-danger">
                  * Please insert atleast one referral
                </p>
              )}
              {/* ---- Add button for Referral Form Repeater ends here ----- */}
            </div>
            {/* ------------- Referral TextBox section ends here ------------- */}
          </div>

          {isSubmittingData && (
            <div className="rounded">
              <div className="alert alert-info">
                Submitting data
                {t("please wait")} ....
              </div>
            </div>
          )}

          {/* ---- go to next & previous step button area ---- */}
          <div className="nxt_prvbtn_Outer">
            {/* ---- go to previous step button area ---- */}
            <div className="prev_Btn">
              <Link
                to="#"
                className="border_btn"
                onClick={(e) => {
                  goToPreviousPageHandler(e);
                }}
              >
                {t("Back to previous")}
              </Link>
            </div>

            {/* ---- 'Save & Continue' button area ---- */}
            <div className="next_Btn">
              <Link
                to="#"
                className="border_btn"
                role="button"
                onClick={(e) => {
                  nextStepHandler(e);
                }}
              >
                {t("Save & Continue")}
              </Link>
            </div>

            {/* ---- go to next step button area ---- */}
            <div className="next_Btn">
              <Link
                to="#"
                className="basic_btn"
                onClick={(e) => {
                  goToNextPageHandler(e);
                }}
              >
                {t("Go to Next Step")}
              </Link>
            </div>
          </div>
        </div>
        {/* --------------- Form section ends here --------------- */}
      </div>
      <ToastContainer />
    </>
  );
};

export default Step6;
