/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GlobalIndraInfo } from "App";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const Step8 = (props) => {
  // accessing the user's token here
  const token = localStorage.getItem("token");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // for routing
  const history = useHistory();

  // Getting the course ID created in Step 1
  const { courseId, onConfirmStep8, getCourseId } = useContext(GlobalIndraInfo);

  // toast property
  const toastOptions = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  // initial state for step eight data
  const [stepEightData, setStepEightData] = useState({
    authorbio: "",
    terms: 0,
  });

  // initial state for saving returned data from API
  const [savedData, setSavedData] = useState();

  const [checked, setChecked] = useState("");

  // Function to toggle agree terms checkbox
  const checkBoxToggler = (value) => {
    if (value) {
      setStepEightData({ ...stepEightData, terms: 1 });
    } else {
      setStepEightData({ ...stepEightData, terms: 0 });
    }
    //console.log("Checked Value ------> ", value);
  };

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  /* ------------- Language translation imports starts ends ------------- */

  /*---- function to publish the data -----*/
  const publishHandler = async (e) => {
    e.preventDefault();

    // console.log("step eight data ----->", stepEightData);
    if (stepEightData.authorbio == "") {
      alert("Fill all the data");
    } else {
      let publish = 1;

      let requestURL =
        url.API_BaseUrl +
        url.API_STEP_8_COURSE_CREATE +
        `?token=${token}` +
        `&cid=${courseId}`;

      //console.log("Step 8 sending request --------> ", requestURL);

      let stepEightState = true;

      let publishData = {
        publish,
        authorbio: stepEightData.authorbio,
        terms: stepEightData.terms,
      };

      // console.log("Step 8 publish Data --------> ", publishData);

      try {
        const response = await postData(requestURL, publishData);

        // console.log("Step 8 receiving response --------> ", response);

        if (response.status) {
          setShowSuccessMessage(true);
          // console.log("Data submitted successfully", response);
          onConfirmStep8(stepEightState.isStepEightComplete);
          toast.success(
            "Course published, Redirecting please wait ...",
            toastOptions
          );
          getCourseId(null);
          setTimeout(() => history.push("/mycourses"), 3000);
        } else {
          console.log("Some error occurred while submitting the data!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  /*---- end of function to publish the data -----*/

  /*---- function to unpublish the data -----*/
  const unpublishHandler = async (e) => {
    e.preventDefault();

    // console.log("step eight data ----->", stepEightData);
    if (stepEightData.authorbio == "") {
      alert("Fill all the data");
    } else {
      let publish = 0;

      let requestURL =
        url.API_BaseUrl +
        url.API_STEP_8_COURSE_CREATE +
        `?token=${token}` +
        `&cid=${courseId}`;

      //console.log("Step 8 sending request --------> ", requestURL);

      let stepEightState = true;

      let unpublishData = {
        publish,
        authorbio: stepEightData.authorbio,
        terms: stepEightData.terms,
      };

      console.log("Step 8 unpublish Data --------> ", unpublishData);

      try {
        const response = await postData(requestURL, unpublishData);

        console.log("Step 8 receiving response --------> ", response);

        if (response.status) {
          setShowSuccessMessage(true);
          console.log("Data submitted successfully", response);
          // getCourseId(null);
          onConfirmStep8(stepEightState.isStepEightComplete);
          toast.success(
            "Course submitted, Redirecting please wait ...",
            toastOptions
          );
          setTimeout(() => history.push("/mycourses"), 3000);
        } else {
          console.log("Some error occurred while submitting the data!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  /*---- end of function to unpublish the data -----*/

  /*---- function to fetch the course details saved in step 1 -----*/
  const viewCourseDetailsHandler = async (id) => {
    const requestURL =
      url.API_BaseUrl +
      url.API_STEP_8_COURSE_VIEW +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      const response = await getData(requestURL);
      // console.log("step one response----->", response);
      if (response.status) {
        // console.log(
        //   "Step 8 edit data received successfully -----> ",
        //   response.data
        // );
        setSavedData(response.data);
        setStepEightData({
          ...stepEightData,
          authorbio: response.data.authorbio,
        });
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*---- end of function to fetch the course details saved in step 1 -----*/

  /* Function to redirect to the previous page without calling the API */
  const goToPreviousPageHandler = (e) => {
    e.preventDefault();
    history.push("/course/add/Step6");
    let stepEightState = false;
    onConfirmStep8(stepEightState);
  };

  /* useEffect function from here */
  useEffect(() => {
    let stepEightState = false;
    if (courseId != null) {
      stepEightState = true;
      viewCourseDetailsHandler(courseId);
    }
    onConfirmStep8(stepEightState);
  }, [courseId]);

  return (
    <>
      <div className="col-lg-9 col-md-8">
        {/* --------------- Unknown back arrow button starts here --------------- */}
        <div className="back_progress">
          <div className="page_back">
            <Link to="#">
              <span className="material-icons-outlined">arrow_back</span>
              Back
            </Link>
          </div>
          <div className="step_progress">
            <span className="progress_bar" style={{ width: "95%" }}></span>
          </div>
        </div>
        {/* --------------- Unknown back arrow button ends here --------------- */}

        {/* --------------- Form section starts here --------------- */}
        <div className="course_uploadrghtpnl">
          <div className="course_itemInnr">
            <div className="course_stepItem author_bio">
              <div className="coursestep_hdng msg_hdng">
                {/* <h3>Authors Bio</h3> */}
                <h3>{t("Authors Bio")}</h3>
              </div>

              {/* ------------- Text area starts here ------------ */}
              <div className="course_fillTop">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input_fill jobinput_fill description_fill">
                      <textarea
                        className="form-control"
                        placeholder={t("Enter few words about author")}
                        value={
                          stepEightData.authorbio ? stepEightData.authorbio : ""
                        }
                        onChange={(e) =>
                          setStepEightData({
                            ...stepEightData,
                            authorbio: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              {/* ------------- Text area ends here ------------ */}
            </div>

            {/* ------------- Terms and agreements starts here ------------ */}
            <div className="course_stepItem author_bio">
              <div className="coursestep_hdng msg_hdng">
                <h3>{t("Terms and Conditions")}</h3>
              </div>

              {/* ------------- Div containing terms and agreements ------------ */}
              <div className="course_fillTop">
                <div className="privacy_bx">
                  <div className="privacy_para">
                    <p>
                      <Trans i18nKey="Terms_Point_1">
                        A Terms and Conditions agreement acts as legal contracts
                        between you (the company) who has the website or mobile
                        app, and the user who accesses your website/app.
                      </Trans>
                    </p>

                    <p>
                      <Trans i18nKey="Terms_Point_2">
                        Having a Terms and Conditions agreement is completely
                        optional. No laws require you to have one. Not even the
                        super-strict and wide-reaching General Data Protection
                        Regulation (GDPR).
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_3">
                        Your Terms and Conditions agreement will be uniquely
                        yours. While some clauses are standard and commonly seen
                        in pretty much every Terms and Conditions agreement,
                        it's up to you to set the rules and guidelines that the
                        user must agree to.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_4">
                        You can think of your Terms and Conditions agreement as
                        the legal agreement where you maintain your rights to
                        exclude users from your app in the event that they abuse
                        your app, where you maintain your legal rights against
                        potential app abusers, and so on.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_5">
                        Terms and Conditions agreements are also known as Terms
                        of Service or Terms of Use agreements. These terms are
                        interchangeable, practically speaking.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_6">
                        Your Terms and Conditions agreement will be uniquely
                        yours. While some clauses are standard and commonly seen
                        in pretty much every Terms and Conditions agreement,
                        it's up to you to set the rules and guidelines that the
                        user must agree to.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_7">
                        A Terms and Conditions agreement acts as legal contracts
                        between you (the company) who has the website or mobile
                        app, and the user who accesses your website/app.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_8">
                        Having a Terms and Conditions agreement is completely
                        optional. No laws require you to have one. Not even the
                        super-strict and wide-reaching General Data Protection
                        Regulation (GDPR).
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_9">
                        Your Terms and Conditions agreement will be uniquely
                        yours. While some clauses are standard and commonly seen
                        in pretty much every Terms and Conditions agreement,
                        it's up to you to set the rules and guidelines that the
                        user must agree to.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_10">
                        You can think of your Terms and Conditions agreement as
                        the legal agreement where you maintain your rights to
                        exclude users from your app in the event that they abuse
                        your app, where you maintain your legal rights against
                        potential app abusers, and so on.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_11">
                        Terms and Conditions agreements are also known as Terms
                        of Service or Terms of Use agreements. These terms are
                        interchangeable, practically speaking.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_12">
                        Your Terms and Conditions agreement will be uniquely
                        yours. While some clauses are standard and commonly seen
                        in pretty much every Terms and Conditions agreement,
                        it's up to you to set the rules and guidelines that the
                        user must agree to.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_13">
                        A Terms and Conditions agreement acts as legal contracts
                        between you (the company) who has the website or mobile
                        app, and the user who accesses your website/app.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_14">
                        Having a Terms and Conditions agreement is completely
                        optional. No laws require you to have one. Not even the
                        super-strict and wide-reaching General Data Protection
                        Regulation (GDPR).
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_15">
                        Your Terms and Conditions agreement will be uniquely
                        yours. While some clauses are standard and commonly seen
                        in pretty much every Terms and Conditions agreement,
                        it's up to you to set the rules and guidelines that the
                        user must agree to.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_16">
                        You can think of your Terms and Conditions agreement as
                        the legal agreement where you maintain your rights to
                        exclude users from your app in the event that they abuse
                        your app, where you maintain your legal rights against
                        potential app abusers, and so on.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_17">
                        Terms and Conditions agreements are also known as Terms
                        of Service or Terms of Use agreements. These terms are
                        interchangeable, practically speaking.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="Terms_Point_18">
                        Your Terms and Conditions agreement will be uniquely
                        yours. While some clauses are standard and commonly seen
                        in pretty much every Terms and Conditions agreement,
                        it's up to you to set the rules and guidelines that the
                        user must agree to.
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* ------------- Terms and agreements ends here ------------ */}
          </div>

          {/* ------------ Checkbox check area starts here ------------ */}
          <div className="subscribe_chk">
            <label className="radio_circle">
              <input
                type="checkbox"
                defaultChecked={checked}
                onChange={(e) => checkBoxToggler(e.target.checked)}
              />
              <span></span>
              <i>{t("Agree to terms and conditions")}</i>
            </label>
          </div>
          {/* ------------ Checkbox check area ends here ------------ */}

          {/* ---- go to next & previous step button area ---- */}
          <div className="nxt_prvbtn_Outer">
            {/* ---- go to previous step button area ---- */}
            <div className="prev_Btn">
              <Link
                to="#"
                className="border_btn"
                onClick={(e) => {
                  goToPreviousPageHandler(e);
                }}
              >
                {t("Back to previous")}
              </Link>
            </div>

            {/* ---- unpublish & publish button area ---- */}
            <div className="next_Btn">
              {/* --------- Unpublish button --------- */}
              <button
                type="submit"
                style={{ cursor: "pointer" }}
                className="border_btn mr-1"
                onClick={(e) => {
                  unpublishHandler(e);
                }}
              >
                {t("Unpublish")}
              </button>

              {/* --------- Publish button --------- */}
              <button
                type="submit"
                className="basic_btn"
                onClick={(e) => {
                  publishHandler(e);
                }}
              >
                {t("Publish")}
              </button>
            </div>
          </div>
        </div>
        {/* --------------- Form section ends here --------------- */}
      </div>
      <ToastContainer />
    </>
  );
};

export default Step8;
