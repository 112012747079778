import React from "react";
import { Link } from "react-router-dom";
import SimilarGroups from "../common/SimilarGroups";

const Survey = () => {
  return (
    <div className="tab-pane fade show active" id="survey">
      <div className="training_outer">
        <div className="row">
          <div className="col-lg-8">
            <div className="course_detailspnl">
              <div className="contnt_hdng">
                <h4>Survey</h4>
              </div>
              <div className="survey_outer">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="survey_item">
                      <div className="survey_hdng">
                        <h3>CRM Expertise</h3>
                      </div>
                      <div className="job_tags">
                        <ul>
                          <li>
                            <i className="material-icons-outlined">schedule</i>
                            <span>2min 30sec</span>
                          </li>
                        </ul>
                      </div>
                      <div className="survey_para">
                        <p>
                          Over the next 6 years he occasionally poppe dnto my
                          office to politely suggest whate tho ught my
                          priorities should business.
                        </p>
                      </div>
                      <div className="survey_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn"
                          data-bs-toggle="modal"
                          data-target="#survey_mdl1"
                        >
                          Overcome Challenge
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="survey_item">
                      <div className="survey_hdng">
                        <h3>CRM Expertise</h3>
                      </div>
                      <div className="job_tags">
                        <ul>
                          <li>
                            <i className="material-icons-outlined">schedule</i>
                            <span>2min 30sec</span>
                          </li>
                        </ul>
                      </div>
                      <div className="survey_para">
                        <p>
                          Over the next 6 years he occasionally poppe dnto my
                          office to politely suggest whate tho ught my
                          priorities should business.
                        </p>
                      </div>
                      <div className="survey_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn"
                          data-bs-toggle="modal"
                          data-target="#survey_mdl1"
                        >
                          Overcome Challenge
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="survey_item">
                      <div className="survey_hdng">
                        <h3>CRM Expertise</h3>
                      </div>
                      <div className="job_tags">
                        <ul>
                          <li>
                            <i className="material-icons-outlined">schedule</i>
                            <span>2min 30sec</span>
                          </li>
                        </ul>
                      </div>
                      <div className="survey_para">
                        <p>
                          Over the next 6 years he occasionally poppe dnto my
                          office to politely suggest whate tho ught my
                          priorities should business.
                        </p>
                      </div>
                      <div className="survey_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn"
                          data-bs-toggle="modal"
                          data-target="#survey_mdl1"
                        >
                          Overcome Challenge
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="survey_item">
                      <div className="survey_hdng">
                        <h3>CRM Expertise</h3>
                      </div>
                      <div className="job_tags">
                        <ul>
                          <li>
                            <i className="material-icons-outlined">schedule</i>
                            <span>2min 30sec</span>
                          </li>
                        </ul>
                      </div>
                      <div className="survey_para">
                        <p>
                          Over the next 6 years he occasionally poppe dnto my
                          office to politely suggest whate tho ught my
                          priorities should business.
                        </p>
                      </div>
                      <div className="survey_btn complete_btn">
                        <Link to="#" className="basic_btn ylw_btn">
                          Completed
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="survey_item">
                      <div className="survey_hdng">
                        <h3>CRM Expertise</h3>
                      </div>
                      <div className="job_tags">
                        <ul>
                          <li>
                            <i className="material-icons-outlined">schedule</i>
                            <span>2min 30sec</span>
                          </li>
                        </ul>
                      </div>
                      <div className="survey_para">
                        <p>
                          Over the next 6 years he occasionally poppe dnto my
                          office to politely suggest whate tho ught my
                          priorities should business.
                        </p>
                      </div>
                      <div className="survey_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn"
                          data-bs-toggle="modal"
                          data-target="#survey_mdl1"
                        >
                          Overcome Challenge
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="survey_item">
                      <div className="survey_hdng">
                        <h3>CRM Expertise</h3>
                      </div>
                      <div className="job_tags">
                        <ul>
                          <li>
                            <i className="material-icons-outlined">schedule</i>
                            <span>2min 30sec</span>
                          </li>
                        </ul>
                      </div>
                      <div className="survey_para">
                        <p>
                          Over the next 6 years he occasionally poppe dnto my
                          office to politely suggest whate tho ught my
                          priorities should business.
                        </p>
                      </div>
                      <div className="survey_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn"
                          data-bs-toggle="modal"
                          data-target="#survey_mdl1"
                        >
                          Overcome Challenge
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="survey_item">
                      <div className="survey_hdng">
                        <h3>CRM Expertise</h3>
                      </div>
                      <div className="job_tags">
                        <ul>
                          <li>
                            <i className="material-icons-outlined">schedule</i>
                            <span>2min 30sec</span>
                          </li>
                        </ul>
                      </div>
                      <div className="survey_para">
                        <p>
                          Over the next 6 years he occasionally poppe dnto my
                          office to politely suggest whate tho ught my
                          priorities should business.
                        </p>
                      </div>
                      <div className="survey_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn"
                          data-bs-toggle="modal"
                          data-target="#survey_mdl1"
                        >
                          Overcome Challenge
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="survey_item">
                      <div className="survey_hdng">
                        <h3>CRM Expertise</h3>
                      </div>
                      <div className="job_tags">
                        <ul>
                          <li>
                            <i className="material-icons-outlined">schedule</i>
                            <span>2min 30sec</span>
                          </li>
                        </ul>
                      </div>
                      <div className="survey_para">
                        <p>
                          Over the next 6 years he occasionally poppe dnto my
                          office to politely suggest whate tho ught my
                          priorities should business.
                        </p>
                      </div>
                      <div className="survey_btn complete_btn">
                        <Link to="#" className="basic_btn ylw_btn">
                          Completed
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="survey_item">
                      <div className="survey_hdng">
                        <h3>CRM Expertise</h3>
                      </div>
                      <div className="job_tags">
                        <ul>
                          <li>
                            <i className="material-icons-outlined">schedule</i>
                            <span>2min 30sec</span>
                          </li>
                        </ul>
                      </div>
                      <div className="survey_para">
                        <p>
                          Over the next 6 years he occasionally poppe dnto my
                          office to politely suggest whate tho ught my
                          priorities should business.
                        </p>
                      </div>
                      <div className="survey_btn">
                        <Link
                          to="#"
                          className="basic_btn ylw_btn"
                          data-bs-toggle="modal"
                          data-target="#survey_mdl1"
                        >
                          Overcome Challenge
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <SimilarGroups />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Survey;
