import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalIndraInfo } from "App";

const BreadCrumb = () => {
  // getting search params from useContext
  const { championName } = useContext(GlobalIndraInfo);

  return (
    <>
      <div className="bredcum_area">
        <div className="container">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/champions">Champion</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {championName}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </>
  );
};

export default BreadCrumb;
