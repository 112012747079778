import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const HomeEventLists = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section className="events_area pd_30">
      <div className="container">
        <div className="slideHdng_outer">
          <div className="page_hdng">
            <h2>Best Events </h2>
          </div>
          <div className="see_moreBtn">
            <Link to="#" className="border_btn ylwbrdr_btn">
              See All
            </Link>
          </div>
        </div>
        <div className="slide_Outer">
          <div className="events_slider slider_innr">
            <Carousel ssr showDots responsive={responsive}>
              <div className="slide_Item mb-4">
                <div className="events_itemBx">
                  <div className="champions_pic">
                    <img src="images/course-pic1.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="events_dateBx">
                      <h3>6</h3>
                      <h5>
                        FEB <span>2022</span>
                      </h5>
                    </div>
                    <div className="events_hdngauthor">
                      <h4>Madrid,Spain</h4>
                      <div className="evnts_enroll">
                        <i className="material-icons-outlined">people</i>
                        <span>25k Enrolled</span>
                      </div>
                    </div>
                    <div className="leftchampion_text">
                      <h3>Introduction to A.I</h3>
                    </div>
                    <div className="experts_skill">
                      <ul>
                        <li>
                          <Link to="#">
                            <span>Big Tech</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>IT Gients</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>Technology</span>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="details_btn">
                      <Link
                        to="#"
                        // to="event_details.html"
                        className="basic_btn ylw_btn"
                      >
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="events_itemBx">
                  <div className="champions_pic">
                    <img src="images/course-pic2.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="events_dateBx">
                      <h3>6</h3>
                      <h5>
                        FEB <span>2022</span>
                      </h5>
                    </div>
                    <div className="events_hdngauthor">
                      <h4>Madrid,Spain</h4>
                      <div className="evnts_enroll">
                        <i className="material-icons-outlined">people</i>
                        <span>25k Enrolled</span>
                      </div>
                    </div>
                    <div className="leftchampion_text">
                      <h3>Introduction to A.I</h3>
                    </div>
                    <div className="experts_skill">
                      <ul>
                        <li>
                          <Link to="#">
                            <span>Big Tech</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>IT Gients</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>Technology</span>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="details_btn">
                      <Link
                        to="#"
                        // to="event_details.html"
                        className="basic_btn ylw_btn"
                      >
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="events_itemBx">
                  <div className="champions_pic">
                    <img src="images/course-pic3.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="events_dateBx">
                      <h3>6</h3>
                      <h5>
                        FEB <span>2022</span>
                      </h5>
                    </div>
                    <div className="events_hdngauthor">
                      <h4>Madrid,Spain</h4>
                      <div className="evnts_enroll">
                        <i className="material-icons-outlined">people</i>
                        <span>25k Enrolled</span>
                      </div>
                    </div>
                    <div className="leftchampion_text">
                      <h3>Introduction to A.I</h3>
                    </div>
                    <div className="experts_skill">
                      <ul>
                        <li>
                          <Link to="#">
                            <span>Big Tech</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>IT Gients</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>Technology</span>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="details_btn">
                      <Link
                        to="#"
                        // to="event_details.html"
                        className="basic_btn ylw_btn"
                      >
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="events_itemBx">
                  <div className="champions_pic">
                    <img src="images/course-pic3.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="events_dateBx">
                      <h3>6</h3>
                      <h5>
                        FEB <span>2022</span>
                      </h5>
                    </div>
                    <div className="events_hdngauthor">
                      <h4>Madrid,Spain</h4>
                      <div className="evnts_enroll">
                        <i className="material-icons-outlined">people</i>
                        <span>25k Enrolled</span>
                      </div>
                    </div>
                    <div className="leftchampion_text">
                      <h3>Introduction to A.I</h3>
                    </div>
                    <div className="experts_skill">
                      <ul>
                        <li>
                          <Link to="#">
                            <span>Big Tech</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>IT Gients</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>Technology</span>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="details_btn">
                      <Link
                        to="#"
                        // to="event_details.html"
                        className="basic_btn ylw_btn"
                      >
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="events_itemBx">
                  <div className="champions_pic">
                    <img src="images/course-pic3.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="events_dateBx">
                      <h3>6</h3>
                      <h5>
                        FEB <span>2022</span>
                      </h5>
                    </div>
                    <div className="events_hdngauthor">
                      <h4>Madrid,Spain</h4>
                      <div className="evnts_enroll">
                        <i className="material-icons-outlined">people</i>
                        <span>25k Enrolled</span>
                      </div>
                    </div>
                    <div className="leftchampion_text">
                      <h3>Introduction to A.I</h3>
                    </div>
                    <div className="experts_skill">
                      <ul>
                        <li>
                          <Link to="#">
                            <span>Big Tech</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>IT Gients</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>Technology</span>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="details_btn">
                      <Link
                        to="#"
                        // to="event_details.html"
                        className="basic_btn ylw_btn"
                      >
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slide_Item">
                <div className="events_itemBx">
                  <div className="champions_pic">
                    <img src="images/course-pic3.jpg" alt="" />
                  </div>
                  <div className="champions_textpnl">
                    <div className="events_dateBx">
                      <h3>6</h3>
                      <h5>
                        FEB <span>2022</span>
                      </h5>
                    </div>
                    <div className="events_hdngauthor">
                      <h4>Madrid,Spain</h4>
                      <div className="evnts_enroll">
                        <i className="material-icons-outlined">people</i>
                        <span>25k Enrolled</span>
                      </div>
                    </div>
                    <div className="leftchampion_text">
                      <h3>Introduction to A.I</h3>
                    </div>
                    <div className="experts_skill">
                      <ul>
                        <li>
                          <Link to="#">
                            <span>Big Tech</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>IT Gients</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span>Technology</span>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="details_btn">
                      <Link
                        to="#"
                        // to="event_details.html"
                        className="basic_btn ylw_btn"
                      >
                        See Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeEventLists;
