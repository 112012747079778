import React, { useEffect } from "react";

//import custom components
import Header from "common/Header";
import Footer from "common/Footer";
import EventBreadCumb from "components/EventDetailsComponents/EventBreadCumb";
import EventDetailsBanner from "components/EventDetailsComponents/EventDetailsBanner";
import EventDetailsTabs from "components/EventDetailsComponents/EventDetailsTabs";

const EventsDetails = () => {
  useEffect(() => {
    document.title = "Event-Details";
  }, []);

  return (
    <>
      <Header />
      <EventBreadCumb />
      <EventDetailsBanner />
      <EventDetailsTabs />
      <Footer />
    </>
  );
};

export default EventsDetails;
