import React from "react";
import { Link } from "react-router-dom";

//import images
import footerBg from "assets/ActivityAssests/images/ftr-bg.png";

const ActivityFooter = () => {
  return (
    <>
      <footer
        className="footer_outer"
        style={{ backgroundImage: `url(${footerBg})` }}
      >
        <div className="container">
          <div className="footer_top">
            <div className="row">
              <div className="col-lg-2">
                <div className="ftr_logo">
                  <Link to="#">
                    <img src="images/ftr-logo.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-4">
                <div className="ftr_item">
                  <div className="ftr_links">
                    <ul>
                      <li>
                        <Link to="#">Blog Neo</Link>
                      </li>
                      <li>
                        <Link to="#">Contact</Link>
                      </li>
                      <li>
                        <Link to="#">Accessibility</Link>
                      </li>
                      <li>
                        <Link to="#">www.minsait.com/ </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-8">
                <div className="ftr_item">
                  <div className="ftr_links">
                    <ul>
                      <li>
                        <Link to="#">Public Funds</Link>
                      </li>
                      <li>
                        <Link to="#">Site Map</Link>
                      </li>
                      <li>
                        <Link to="#">Legal information</Link>
                      </li>
                      <li>
                        <Link to="#">www.tecnologiasaccesibles.com/en</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-4 col-12">
                <div className="ftr_item">
                  <h3>Follow networks </h3>
                  <div className="ftr_social">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-youtube"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-linkedin-in"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-instagram"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-slideshare"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-solid fa-rss"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ActivityFooter;
