/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// for translation
import { useTranslation, Trans } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ---------------------------------------------------------------------------------- */

const GroupModal = (props) => {
  // Token of the logged In user
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // Temporary JSON data to display in the modal
  const [groupListData, setGroupListData] = useState([]);

  // useState to hold value from search field
  const [searchName, setSearchName] = useState("");

  // function to search value from JSON
  function filterByName(name) {
    setSearchName(name);
  }

  /* function for get the selected group info */
  const selectedGroupHandler = (item) => {
    props.selectedGroup(item);

    var groupAllModal = document.querySelector("#group_modal");
    var modal = bootstrap.Modal.getInstance(groupAllModal);
    modal.hide();
  };

  // useEffect function here
  useEffect(() => {
    if (props.groups != null) {
      //console.log("Data received in GroupModal -------> ", props.groups);
      setGroupListData(props.groups);
    }
  }, [props.groups]);

  // return  function from here
  return (
    <>
      <div className="chat_modalbx">
        <div className="modal fade" id="group_modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {/* ---------- Modal Header starts here ---------- */}
              <div className="modal-header mdl_hdng">
                <h5 className="modal-title">
                  {t("Add Group to Conversation")}
                </h5>
                {/* ---------- Button to hide Modal here ---------- */}
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="material-icons-round">close </span>
                </button>
              </div>
              {/* ---------- Modal Header ends here ---------- */}

              {/* ---------- Modal Body starts here ---------- */}
              <div className="modal-body">
                {/* -------------------- Searchbar starts here -------------------- */}
                <div className="group_srchfill">
                  <input
                    type="text"
                    value={searchName}
                    className="form-control"
                    placeholder={t("Search existing group")}
                    onChange={(e) => filterByName(e.target.value)}
                  />
                </div>
                {/* -------------------- Searchbar ends here -------------------- */}
                <div className="group_innrScroll">
                  {groupListData.map((item, index) => {
                    return (
                      <div
                        className={`chat_usrRow ${
                          item.name
                            .toLowerCase()
                            .includes(searchName.toLowerCase())
                            ? ""
                            : " hide "
                        }`}
                        key={index}
                        onClick={() => selectedGroupHandler(item)}
                      >
                        <Link to="#">
                          <div className="usr_lftPnl">
                            <div className="chatting_user">
                              {item.members.slice(0, 3).map((item2, index2) => {
                                return (
                                  <figure className="group_usr" key={index2}>
                                    {item.image == null ? (
                                      <i className="material-icons-round">
                                        person
                                      </i>
                                    ) : (
                                      <img src={item2.image} alt="image" />
                                    )}
                                  </figure>
                                );
                              })}
                            </div>

                            <div className="user_name">
                              <h3>{item.name}</h3>
                              <p>
                                {item.members
                                  .slice(0, 3)
                                  .map((item2, index2) => item2.name + ` , `)}
                                {item.members.length > 3 ? (
                                  <span>and 3 more</span>
                                ) : null}
                              </p>
                            </div>
                          </div>
                          <div
                            className={`members_check ${
                              item.isOnline ? "ornge_bullet" : ""
                            }`}
                          >
                            <span></span>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
                <div className="group_btnOuter">
                  <ul>
                    <li className="w_100">
                      <Link
                        to="#"
                        className="blue_btn"
                        data-bs-dismiss="modal"
                        data-bs-toggle="modal"
                        data-bs-target="#group_people_mdl"
                      >
                        <i className="material-icons-round"> add_circle </i>
                        <span>{t("Create New")}</span>
                      </Link>
                    </li>

                    {/* <li className="w_50">
                <Link to="#" className="ornage_btn">
                  <i className="material-icons-round"> check_circle </i>
                  <span>Done</span>
                </Link>
              </li> */}
                  </ul>
                </div>
              </div>
              {/* ---------- Modal Body ends here ---------- */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupModal;
