/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

//import images
import placeholderImage from "assets/CourseDetailsAssests/images/blank-img.jpg";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const Teams = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  // 👇️ get ID from url
  const params = useParams();

  // Initial useState to save all the incoming data from API.
  const [jobDetailsData, setJobDetailsData] = useState(null);

  // Function to call the API and get all the data
  const getJobDetailsData = async (id) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_CHALLENGES_DETAILS_TEAM_TAB +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      // console.log(
      //   "requesting URL to get all the job details team tab ------> ",
      //   requestURL
      // );

      const response = await getData(requestURL);
      console.log(
        "Received Response in challenge details team tab --------> ",
        response
      );

      if (response.status) {
        setJobDetailsData(response.data);
      } else {
        console.log("Some error occurred ------> ", response);
      }
    } catch (error) {
      console.log("Error of indra activity post is----->", error);
    }
  };

  // useEffect from here
  useEffect(() => {
    getJobDetailsData(params.id);
  }, []);

  return (
    <>
      {jobDetailsData == null ? (
        // ----------------------- Placeholder starts here -----------------------
        <div className="course_detailspnl">
          <div className="member_box_outer">
            <div className="contnt_hdng tab_Hdng">
              <span
                className="placeholder rounded-3 col-2"
                style={{ height: "22px" }}
              ></span>
            </div>

            <div className="member_box_outer">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="expertbx_item members_teamItem">
                    <div
                      className="membrs_teamOuter"
                      style={{ minHeight: "85px" }}
                    >
                      <span
                        className="placeholder rounded-circle"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                      <div className="desc flex-fill ps-3">
                        <span
                          className="placeholder d-block rounded-3 col-8 mb-3"
                          style={{ height: "20px" }}
                        ></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-6 mb-3"></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-5"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="expertbx_item members_teamItem">
                    <div
                      className="membrs_teamOuter"
                      style={{ minHeight: "85px" }}
                    >
                      <span
                        className="placeholder rounded-circle"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                      <div className="desc flex-fill ps-3">
                        <span
                          className="placeholder d-block rounded-3 col-8 mb-3"
                          style={{ height: "20px" }}
                        ></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-6 mb-3"></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-5"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="member_box_outer">
            <div className="contnt_hdng tab_Hdng">
              <span
                className="placeholder rounded-3 col-2"
                style={{ height: "22px" }}
              ></span>
            </div>
            <div className="member_box_outer">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="expertbx_item members_teamItem">
                    <div
                      className="membrs_teamOuter"
                      style={{ minHeight: "85px" }}
                    >
                      <span
                        className="placeholder rounded-circle"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                      <div className="desc flex-fill ps-3">
                        <span
                          className="placeholder d-block rounded-3 col-8 mb-3"
                          style={{ height: "20px" }}
                        ></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-6 mb-3"></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-5"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="expertbx_item members_teamItem">
                    <div
                      className="membrs_teamOuter"
                      style={{ minHeight: "85px" }}
                    >
                      <span
                        className="placeholder rounded-circle"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                      <div className="desc flex-fill ps-3">
                        <span
                          className="placeholder d-block rounded-3 col-8 mb-3"
                          style={{ height: "20px" }}
                        ></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-6 mb-3"></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-5"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="expertbx_item members_teamItem">
                    <div
                      className="membrs_teamOuter"
                      style={{ minHeight: "85px" }}
                    >
                      <span
                        className="placeholder rounded-circle"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                      <div className="desc flex-fill ps-3">
                        <span
                          className="placeholder d-block rounded-3 col-8 mb-3"
                          style={{ height: "20px" }}
                        ></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-6 mb-3"></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-5"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // ----------------------- Placeholder ends here -----------------------
        <div className="course_detailspnl">
          {/* ----- HR round ----- */}
          <div className="member_box_outer">
            <div className="member_box_outer">
              {jobDetailsData.members != null &&
              jobDetailsData.members.length > 0 ? (
                <div className="row">
                  {jobDetailsData.members.map((member, index) => {
                    return (
                      <div className="col-lg-3 col-sm-6" key={member.id}>
                        <div className="expertbx_item members_teamItem">
                          <div className="membrs_teamOuter">
                            <div className="team_membrs_pic">
                              <img
                                src={
                                  member.image == null
                                    ? placeholderImage
                                    : member.image
                                }
                                alt="MemberPic"
                              />
                            </div>
                            <div className="membrs_team_details">
                              <div className="team_name">
                                <h3>{member.name}</h3>
                                <h5>{member.position}</h5>
                              </div>
                              <div className="team_rating star_color ">
                                <h3>
                                  <i className="material-icons-outlined">
                                    star
                                  </i>
                                  <span>4.5</span>
                                </h3>

                                <h6>
                                  32<span>(valoraciones)</span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="course_detailspnl">
                  <p className="data_btnOuter mb-20">
                    <span className="placeholder placeholder-sm col-3 rounded-3 mb-0"></span>
                  </p>
                  <p className="data_btnOuter mb-20">
                    <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                    <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                  </p>
                  <p className="data_btnOuter mb-20">
                    <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                    <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                  </p>
                  <p className="data_btnOuter mb-20">
                    <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                    <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                  </p>
                  <p className="data_btnOuter mb-20">
                    <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
                    <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* ----- HR round end ----- */}
        </div>
      )}
    </>
  );
};

export default Teams;
